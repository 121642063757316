import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
  useTheme,
  Theme
} from '@material-ui/core';
import ButtonComp from '../ButtonComp';
import Card from '@material-ui/core/Card';
import { Close } from '@material-ui/icons';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';

type styleProps = {
  dialogWidth?: string | number;
};

const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => {
  return {
    buttonAlignment: {
      margin: theme.spacing(2)
    },
    dialogPaper: {
      width: (props) => props.dialogWidth || 420,
      padding: theme.spacing(1, 2, 0, 2)
    },
    cardStyle: {
      boxShadow: '0px 8.93293px 26.7988px rgba(5, 16, 55, 0.1)'
    }
  };
});

type Props = {
  open: boolean;
  dialogWidth?: string | number;
  onCancelClick: () => void;
  onConfirmClick?: () => void;
  btnText?: string;
  handleChange: () => void;
  ranges: any;
};

const UHCalenderComp = ({
  open,
  dialogWidth,
  onCancelClick,
  onConfirmClick,
  btnText,
  handleChange,
  ranges
}: Props) => {
  const theme = useTheme();
  const classes = useStyles({ dialogWidth });

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogContent>
        <Card className={classes.cardStyle}>
          <Close
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer'
            }}
            onClick={onCancelClick}
          />
          <DateRange
            editableDateInputs={true}
            onChange={handleChange}
            moveRangeOnFirstSelection={false}
            ranges={ranges}
          />
        </Card>
      </DialogContent>

      <DialogActions className={classes.buttonAlignment}>
        <Grid container direction="row" justifyContent={'space-evenly'}>
          <ButtonComp
            backgroundColor={'transparent'}
            height={35}
            buttonText="Cancel"
            buttonFontSize={theme.MetricsSizes.small_xx}
            buttonTextColor={theme.Colors.black}
            buttonFontWeight={theme.fontWeight.medium}
            onClickButton={onCancelClick}
            btnWidth="fit-content"
          />
          <ButtonComp
            backgroundColor={'transparent'}
            height={35}
            buttonText={btnText || 'Confirm'}
            buttonFontSize={theme.MetricsSizes.small_xx}
            buttonTextColor={theme.Colors.black}
            buttonFontWeight={theme.fontWeight.medium}
            btnWidth="fit-content"
            onClickButton={onConfirmClick}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(UHCalenderComp);
