import React, { useEffect, useState } from 'react';
import HistoryTable from './HistoryTable';
import { ContentDisplayTiles, Heading } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@material-ui/core';
import { Cart, Recycle } from 'src/Assets';
import { TOTAL_ORDERS_COUNT } from 'src/Config/constant';

const OrderHistory = (props) => {
  const { t } = useTranslation();
  const { orderHistory } = props;
  const theme = useTheme();
  const [orderCount, setOrderCount] = useState(TOTAL_ORDERS_COUNT);
  const [tableData, setTableData] = useState<any>([]);

  const OrderDetails = [
    {
      heading: 'Total Orders',
      subText: orderCount.total_orders,
      iconImage: Cart
    },
    {
      heading: 'Total Recycled',
      subText: orderCount.total_recycled,
      iconImage: Recycle
    }
  ];

  useEffect(() => {
    if (orderHistory?.data?.length) {
      setTableData(orderHistory?.data);
    } else {
      setTableData([]);
    }
    if (orderHistory?.total_recycled && orderHistory?.total_orders) {
      setOrderCount({
        ...orderCount,
        total_orders: orderHistory?.total_orders,
        total_recycled: orderHistory?.total_recycled
      });
    }
  }, []);
  return (
    <Box style={{ marginTop: theme.spacing(5) }}>
      <Heading
        headingText={t('customerVendorList.orderHistory')}
        headerFontWeight={theme.fontWeight.regular}
        headerFontSize={26}
        headingColor={theme.Colors.black}
      />
      <ContentDisplayTiles
        displayContent={OrderDetails}
        isTileTypeOrders={true}
        isNotBackground={true}
        //tabWidth={true}
      />
      <HistoryTable tableData={tableData}/>
    </Box>
  );
};

export default OrderHistory;
