import { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Theme,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SalesTimeComp, UHCalenderComp } from 'src/components';
import { API_SERVICES } from 'src/Services';
import { exportMultipleChartsToPdf } from 'src/Utils';
import ChartComp from './ChartComp';
import { PDFIcon } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.Colors.whiteGreyLight,
      borderRadius: theme.MetricsSizes.regular_x,
      display: 'flex',
      flexDirection: 'column',
      height: '250px',
      width: '250px',
      padding: theme.spacing(2, 7, 2, 3.2),
      '&.MuiGrid-grid-xs-4': {
        maxWidth: '45%',
        flexBasis: '45%'
      }
    },
    text: {
      fontWeight: theme.fontWeight.regular,
      fontSize: theme.MetricsSizes.small_xxx,
      color: theme.Colors.mediumGrey
    },
    subText: {
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.regular_xxx,
      color: theme.Colors.black,
      textAlign: 'center'
    },
    dividerStyle: {
      background: theme.Colors.lightBlueGrey,
      margin: theme.spacing(0.6)
    },
    iconStyle: {
      color: theme.Colors.primary,
      width: theme.MetricsSizes.large_xx,
      height: theme.MetricsSizes.large_xx
    },
    buttonStyle: {
      paddingTop: theme.spacing(2.2)
    },
    labelStyle: {
      flexDirection: 'column'
    },
    gridStyle: {
      borderWidth: '1px',
      borderStyle: 'dashed',
      padding: theme.spacing(0, 0, 0, 0)
    },
    button: {
      minWidth: 0,
      padding: theme.spacing(0, 1, 0, 0)
    },
    iconButtonStyle: {
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.Colors.white
      }
    }
  };
});

const VendorSalesBoxComp = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const contentToDisplay = [
    {
      id: 1,
      heading: 'Today',
      value: 1
    },
    {
      id: 2,
      heading: 'Week',
      value: 2
    },
    {
      id: 3,
      heading: 'Month',
      value: 3
    },
    {
      id: 4,
      heading: 'Custom',
      value: 4
    }
  ];
  const [selectedVendorCountTab, setSelectedVendorCountTab] = useState(1);
  const [selectedVendorTotalKgsTab, setSelectedVendorTotalKgsTab] = useState(1);
  const [selectedVendorCityTab, setSelectedVendorCityTab] = useState(1);
  const [selectedVendorCategoryTab, setSelectedVendorCategoryTab] = useState(1);
  const [countCity, setCountCity] = useState([]);
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const [totalOrder, setTotalOrder] = useState({
    totalOrders: 0,
    PickupCount: 0,
    DropCount: 0
  });
  const [totalKgCount, setTotalKgCount] = useState({
    totalTrash: 0,
    pickupWeight: 0,
    dropWeight: 0
  });
  const [categoryCount, setCategoryCount] = useState([]);
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const fetchData = useCallback(async () => {
    const response: any = await Promise.all([
      API_SERVICES.vendorListService.getVendorCount(selectedVendorCountTab),
      API_SERVICES.vendorListService.getVendorTotalKgs(
        selectedVendorTotalKgsTab
      ),
      API_SERVICES.vendorListService.getAllVendorCity(selectedVendorCityTab),
      API_SERVICES.vendorListService.getAllVendorCategory(
        selectedVendorCategoryTab
      )
    ]);
    let count = {
      totalOrders: parseInt(response[0]?.data?.totalOrders),
      PickupCount: parseInt(response[0]?.data?.ordersCount?.PickupCount),
      DropCount: parseInt(response[0]?.data?.ordersCount?.DropCount)
    };
    setTotalOrder(count);

    let kgCount = {
      totalTrash: parseInt(response[1]?.data?.totalTrash),
      pickupWeight: parseInt(response[1]?.data?.userTrash?.pickupWeight),
      dropWeight: parseInt(response[1].data?.userTrash?.dropWeight)
    };
    setTotalKgCount(kgCount);
    setCountCity(response[2]?.data?.city);
    setCategoryCount(response[3]?.data?.allCategoryOrderCount);
  }, [
    selectedVendorCountTab,
    selectedVendorTotalKgsTab,
    selectedVendorCityTab,
    selectedVendorCategoryTab
  ]);
  let newData = [];
  let newCount = [];
  const cityCount = countCity?.map((city) => {
    newCount.push(city?.orderCount);
    return newCount;
  });
  const cityName: any = countCity?.map((name) => {
    newData.push(name.city);
    return newData;
  });
  let newCategory = [];
  let newCategoryData = [];
  const categoryOne = categoryCount?.map((name) => {
    newCategory.push(name?.categoryName);
    return newCategory;
  });
  const categoryTwo = categoryCount?.map((city) => {
    newCategoryData.push(city?.categoryOrderCount);
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const fromDate = dateSelect[0]?.startDate?.toLocaleDateString('en-US');
  const lastDate = dateSelect[0]?.endDate?.toLocaleDateString('en-US');

  const handleVendorCountTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.vendorListService.getVendorByUserCustom(params);
      let count = {
        totalOrders: parseInt(response?.data?.totalOrders),
        PickupCount: parseInt(response?.data?.ordersCount?.PickupCount),
        DropCount: parseInt(response?.data?.ordersCount?.DropCount)
      };
      setTotalOrder(count);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedVendorCountTab(value);
    }
  };

  const handleVendorTotalKgsChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.vendorListService.vendorByKgCustom(params);
      let kgCount = {
        totalTrash: parseInt(response?.data?.totalTrash),
        pickupWeight: parseInt(response?.data?.userTrash?.pickupWeight),
        dropWeight: parseInt(response.data?.userTrash?.dropWeight)
      };
      setTotalKgCount(kgCount);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedVendorTotalKgsTab(value);
    }
  };
  const handleVendorCityTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.vendorListService.vendorCityCustom(params);
      setCountCity(response?.data?.city);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedVendorCityTab(value);
    }
  };
  const handleVendorCategoryTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.vendorListService.getVendorCategoryCountCustom(
          params
        );
      setCategoryCount(response?.data?.allCategoryOrderCount);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedVendorCategoryTab(value);
    }
  };
  return (
    <>
      <div className="custom-chart">
        <Grid
          container
          xs={12}
          style={{ display: 'flex', marginTop: '30px', gap: '30px' }}
        >
          <Grid
            item
            container
            xs={6}
            style={{ display: 'flex' }}
            className={classes.gridContainer}
          >
            <Grid item xs>
              <SalesTimeComp
                header="Dustman Sales"
                onTabChange={handleVendorCityTabChange}
                contentToDisplay={contentToDisplay}
              />
              <ChartComp
                height="170px"
                width="220px"
                type="bar"
                series={[
                  {
                    name: 'Customer Sales',
                    data: newCount
                  }
                ]}
                options={{
                  chart: {
                    id: 'simple-bar',
                    toolbar: {
                      show: false
                    }
                  },
                  colors: ['#11294C'],
                  xaxis: {
                    categories: newData
                  },
                  yaxis: {
                    show: false
                  },
                  grid: {
                    show: false
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        position: 'top',
                        enabled: true,
                        offsetY: -20,
                        style: {
                          fontSize: '12px',
                          colors: ['#11294C']
                        }
                      },
                      value: {
                        show: true,
                        fontSize: '14px',
                        formatter: function (val: any) {
                          return val;
                        }
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs
            style={{ display: 'flex' }}
            className={classes.gridContainer}
          >
            <Grid item>
              <SalesTimeComp
                header="Dustman Order"
                onTabChange={handleVendorCountTabChange}
                contentToDisplay={contentToDisplay}
              />
            </Grid>
            <Grid
              item
              container
              xs={10}
              direction="row"
              style={{ display: 'flex', justifyContent: 'space-evenly' }}
            >
              <Grid item xs={3}>
                <ChartComp
                  height="150px"
                  width="90px"
                  type="radialBar"
                  series={[totalOrder.PickupCount]}
                  options={{
                    colors: ['#11294C'],
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          name: {
                            position: 'bottom',
                            show: false
                          },
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return val;
                            }
                          }
                        }
                      }
                    },

                    labels: '32'
                  }}
                />
                <Typography align="center">Pick Up</Typography>
              </Grid>
              {/* ))} */}
              <Grid item xs={3}>
                <ChartComp
                  height="150px"
                  width="90px"
                  type="radialBar"
                  series={[totalOrder.DropCount]}
                  options={{
                    colors: ['#11294C'],
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          name: {
                            position: 'top',
                            show: false
                          },
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return val;
                            }
                          }
                        }
                      }
                    },

                    labels: '112'
                  }}
                />
                <Typography align="center">Drop</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: '50px' }}>
              <Typography className={classes.text}>Total Order</Typography>
              <Typography className={classes.subText}>
                {totalOrder.totalOrders}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          style={{ display: 'flex', marginTop: '30px', gap: '30px' }}
        >
          <Grid item xs={6} className={classes.gridContainer}>
            <Grid item style={{ display: 'flex' }}>
              <Grid item xs={4}>
                <ChartComp
                  height="250px"
                  width="250px"
                  type="pie"
                  series={[totalKgCount.pickupWeight, totalKgCount.dropWeight]}
                  options={{
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          position: 'bottom',
                          show: true,
                          offset: -35
                        },
                        value: {
                          show: true,
                          fontSize: '14px',
                          formatter: function (val) {
                            return val;
                          }
                        }
                      }
                    },
                    legend: {
                      show: true,
                      position: 'bottom'
                    },

                    labels: ['Pick Up', 'Drop']
                  }}
                />
              </Grid>
              <Grid item xs style={{ marginLeft: '90px', marginTop: '50px' }}>
                <SalesTimeComp
                  onTabChange={handleVendorTotalKgsChange}
                  contentToDisplay={contentToDisplay}
                />
                <Grid item style={{ textAlign: 'center', padding: '20px' }}>
                  <Typography className={classes.text}>
                    Total Trash Weight
                  </Typography>
                  <Typography className={classes.subText}>
                    {totalKgCount.totalTrash} Kgs
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs className={classes.gridContainer}>
            <Grid item xs>
              <SalesTimeComp
                header="Category Trash"
                onTabChange={handleVendorCategoryTabChange}
                contentToDisplay={contentToDisplay}
              />
              <ChartComp
                height="220px"
                width="430px"
                type="bar"
                series={[
                  {
                    name: 'Category Trash',
                    data: newCategoryData
                  }
                ]}
                options={{
                  chart: {
                    id: 'simple-bar',
                    toolbar: {
                      show: false
                    }
                  },
                  colors: ['#11294C'],
                  xaxis: {
                    categories: newCategory,
                    position: 'bottom',
                    axisBorder: {
                      show: false
                    },
                    labels: {
                      rotate: -65,
                      style: {
                        fontSize: '12px',
                        colors: ['#11294C']
                      }
                    },
                    axisTicks: {
                      show: false
                    },
                    crosshairs: {
                      fill: {
                        type: 'gradient',
                        width: 150,
                        gradient: {
                          colorFrom: '#11294C',
                          colorTo: '#11294C',
                          stops: [20, 100],
                          opacityFrom: 0.4,
                          opacityTo: 0.5
                        }
                      }
                    }
                  },
                  yaxis: {
                    axisBorder: {
                      show: false
                    },
                    axisTicks: {
                      show: false
                    },
                    labels: {
                      show: false
                    }
                  },
                  grid: {
                    show: false
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        position: 'top'
                      },
                      value: {
                        show: true,
                        fontSize: '14px',
                        formatter: function (val) {
                          return val;
                        }
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                      fontSize: '12px',
                      colors: ['#11294C']
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid item>
        <IconButton
          onClick={exportMultipleChartsToPdf}
          className={classes.iconButtonStyle}
        >
          <img src={PDFIcon} />
        </IconButton>
      </Grid>
      {confirmModal.open && (
        <UHCalenderComp
          onCancelClick={() => setConfirmModal({ open: false })}
          handleChange={handleChange}
          ranges={dateSelect}
          {...confirmModal}
        />
      )}
    </>
  );
};
export default VendorSalesBoxComp;
