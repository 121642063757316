import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Chart from 'react-apexcharts';
type ChartProps = {
  type?: any;
  subHeader?: string;
  salesAmount?: number;
  series?: any;
  width?: number | string;
  height?: number | string;
  options?: any;
  isSales?: any;
};
const ChartComp = ({ type, series, options, width, height }: ChartProps) => {
  return (
    <Grid container xs={12} style={{ display: 'flex' }}>
      <Grid item xs={6}>
        <Chart
          options={options}
          series={series}
          type={type}
          height={height}
          width={width}
        />
      </Grid>
    </Grid>
  );
};
export default ChartComp;
