import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const customerListService = {
  getAllCustomerList: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/reportCustomerList`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getCustomerCount: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getCustomerOrderCount/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getCustomerTotalKgs: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerOrderKgs/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getAllCustomerCity: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerOrderCity/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getAllCustomerCategory: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerCategoryCount/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getCustomerCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getCustomerOrderCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllCustomerKgsCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerOrderKgsCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllCustomerCityCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerOrderCityCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllCategoryTrashCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerCategoryCountCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  
};
