import React, { useState, useEffect } from 'react';
import { Box, Grid, Theme, makeStyles, useTheme } from '@material-ui/core';
import { Downloading, WalletManagement } from 'src/Assets';
import {
  ButtonComp,
  DialogComp,
  DialogContentDetails,
  TextInputComponent
} from 'src/components';
import {
  capitalizeFirstLetter,
  dateFormate,
  getSortMonth,
  getToday
} from 'src/Utils';
import CustomerVendorImageBox from './CustomerVendorImageBox';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import useUserInfo from 'src/hooks/useUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    width: 620,
    height: 705,
    padding: theme.spacing(3, 3, 2, 4),
    borderRadius: theme.MetricsSizes.regular
  },
  imgStyle: {
    borderRadius: theme.MetricsSizes.small_x,
    width: '320px',
    height: '200px'
  },
  currentBalanceBoxStyles: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  currentBalanceContentBoxStyles: {
    display: 'flex',
    borderRadius: '2px',
    padding: theme.spacing(4, 0, 4, 0),
    gap: theme.spacing(3)
  },
  currentBalanceTextStyles: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.Colors.primary,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.MetricsSizes.regular_x
  },
  currentBalanceNumberStyles: {
    color: theme.Colors.mediumGrey,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.MetricsSizes.regular_x
  }
}));

type Props = {
  onClose: () => void;
  rowData?: any;
  isVendor?: boolean;
  updatedData?: any;
  type?: number;
};

const AddMoneyModal = ({
  onClose,
  rowData,
  isVendor,
  updatedData,
  type
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [amount, setAmount] = useState('');
  const [paymentRef, setPaymentRef] = useState('');
  const [amountError, setAmountError] = useState(false);

  const { userDetails } = useUserInfo();

  useEffect(() => {
    // Reset the error state when amount changes
    if (amount !== '' && Number(amount) > 0) {
      setAmountError(false);
    }
  }, [amount]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNaN(Number(value)) && value !== '') {
      return;
    }
    setAmount(value);
  };

  const handleClickAddMoney = async () => {
    if (Number(amount) <= 0) {
      setAmountError(true);
      toast.error('Amount must be greater than 0.');
      return;
    }

    try {
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      const data = {
        vendor_id: rowData?.vendor_id,
        payment_mode: 'Offline',
        payment_type: type === 1 ? 'Credit' : 'Debit',
        payment_ref: paymentRef,
        cashier_name: userDetails.name,
        transaction_id: `TID2024CO${randomNumber}`,
        transaction_type: 'Cash',
        transaction_date: new Date(),
        amount
      };

      if (type === 1) {
        const response: any =
          await API_SERVICES.WalletManagementService.createVendorWallet({
            data
          });
        if (response.status === HTTP_STATUSES.SUCCESS) {
          toast.success(response?.data.message);
          updatedData();
          onClose();
        }
      } else if (type === 2) {
        if (Number(amount) > rowData?.wallet_amount) {
          toast.error(
            `Please enter an amount less than or equal to "${rowData?.wallet_amount}" available balance.`
          );
          return;
        }
        const response: any =
          await API_SERVICES.WalletManagementService.deductVendorWallet({
            data
          });
        if (response.status === HTTP_STATUSES.SUCCESS) {
          toast.success(response?.data.message);
          updatedData();
          onClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: t('vendor.contactName'),
        value: rowData?.contact_name || rowData?.contact_person
      },
      {
        content: t('location'),
        value: rowData?.location
      },
      {
        content: t('mobileNumber'),
        value: rowData?.registered_mobile_number || rowData?.mobile_number
      }
    ];
    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
        <Box className={classes.currentBalanceBoxStyles}>
          <Box className={classes.currentBalanceContentBoxStyles}>
            <Box className={classes.currentBalanceTextStyles}>
              <WalletManagement /> <span>Current Balance</span>
            </Box>
            <Box className={classes.currentBalanceNumberStyles}>
              {rowData?.wallet_amount}
            </Box>
          </Box>
        </Box>
        <Grid container justifyContent="center">
          {type === 1 ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInputComponent
                  inputLabel={t('walletManagement.amountToAdd')}
                  labelColor={theme.Colors.primary}
                  value={amount}
                  onChange={handleAmountChange}
                  isError={amountError}
                  helperText={
                    amountError ? 'Amount must be greater than 0' : ''
                  }
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInputComponent
                  inputLabel={t('walletManagement.transactionReference')}
                  labelColor={theme.Colors.primary}
                  value={paymentRef}
                  onChange={(e) => setPaymentRef(e.target.value)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInputComponent
                  inputLabel={t('walletManagement.amountToDeduct')}
                  labelColor={theme.Colors.primary}
                  value={amount}
                  onChange={handleAmountChange}
                  isError={amountError}
                  inputProps={{ maxLength: 6 }}
                  helperText={
                    amountError ? 'Amount must be greater than 0' : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextInputComponent
                  inputLabel={t('walletManagement.reason')}
                  labelColor={theme.Colors.primary}
                  value={paymentRef}
                  onChange={(e) => setPaymentRef(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        <ButtonComp
          backgroundColor={theme.Colors.primary}
          height={'35px'}
          buttonText={type === 1 ? 'Add Money' : 'Deduct Money'}
          buttonFontSize={theme.MetricsSizes.small_x}
          buttonTextColor={theme.Colors.white}
          buttonFontWeight={theme.fontWeight.bold}
          btnWidth={'200px'}
          btnBorderRadius={100}
          onClickButton={handleClickAddMoney}
          style={{ margin: 10 }}
        />
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'DustMan'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default AddMoneyModal;
