import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { ButtonComp, DialogComp, DialogContentDetails } from 'src/components';
import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetter,
  dateFormate,
  getDateFormat,
  getSortMonth,
  getToday
} from 'src/Utils';
import { API_SERVICES } from 'src/Services';
import {
  CUSTOMER_ORDER_STATUS,
  CUSTOMER_STATUS,
  HTTP_STATUSES
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import ImageHeading from 'src/components/ImageBoxContainer/ImageHeading';
import { Downloading } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 3, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    }
  };
});

type Props = {
  onClose: () => void;
  rowData: any;
  isHeading?: boolean;
  handleClickDownload?: (data?: any) => void;
};

const VendorOrderModal = (props: Props) => {
  const { onClose, rowData, isHeading, handleClickDownload } = props;
  const { t, i18n } = useTranslation();
  console.log('rowData', rowData);

  const getSlot = (val) => {
    if (val == 'morning') {
      return '9:00 AM to 12:00 PM';
    } else if (val == 'noon') {
      return '1:00 PM to 4:00 PM';
    } else {
      return '4:00 PM to 7:00 PM';
    }
  };

  const theme = useTheme();
  const classes = useStyles();
  const [vendorOrder, setVendorOrder] = useState<any>();
  const renderDialogContent = () => {
    const day = getToday(rowData?.pickup_time);
    const d = dateFormate(rowData?.pickup_time)?.split('-');
    const month = getSortMonth(d[1]);
    const contentDetails = [
      {
        content: 'Order Id',
        value: vendorOrder?.id
      },
      {
        content: 'Name',
        value: capitalizeFirstLetter(vendorOrder?.contact_name)
      },
      {
        content: t('address'),
        value: `${vendorOrder?.address_line1}, ${vendorOrder?.address_line2}, ${vendorOrder?.address_line3}, ${vendorOrder?.city}, ${vendorOrder?.state}, ${vendorOrder?.pincode}`
      },
      {
        content: t('contactNumber'),
        value: vendorOrder?.registered_mobile_number?.slice(2)
      },
      {
        content: 'Scheduled On',
        value: `${day}, ${d[0]} ${month},  ${getSlot(rowData?.slot)}`
      }
      // { content: t('weight'), value: `${rowData?.quantity_kg} Kgs` },
      // {
      //   content: t('enquiry.pickupDate'),
      //   value: `${getMonth} ${getDate}, ${getYear}`
      // },
      // {
      //   content: t('enquiry.slot'),
      //   value: capitalizeFirstLetter(rowData?.slot)
      // }
    ];
    // const vendorDetails: any = [
    //   {
    //     content: 'Accepted Vendor Name',
    //     value: capitalizeFirstLetter(vendorOrder?.contact_name)
    //   },
    //   {
    //     content: ' Vendor Mobile Number',
    //     value: vendorOrder?.registered_mobile_number?.slice(2)
    //   }
    // ];
    // const completedDetails: any = [
    //   {
    //     content: 'Quantity',
    //     value: vendorOrder?.quantity_kg
    //   },
    //   {
    //     content: 'Price',
    //     value: vendorOrder?.price
    //   }
    //   // {
    //   //   content: 'image',
    //   //   value: vendorOrder?.order_images
    //   // }
    // ];
    const fetchData = async () => {
      try {
        const response: any = await API_SERVICES.vendorOrderService.getById(
          rowData.id
        );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (response?.data?.order) {
            setVendorOrder(response?.data?.order);
          }
        }
      } catch (err) {
        toast.error(err?.message);
      } finally {
      }
    };

    // const AcceptDetails: any = useMemo(() => {
    //   if (CUSTOMER_STATUS[vendorOrder?.status_id] === CUSTOMER_STATUS[2]) {
    //     return contentDetails.concat(vendorDetails);
    //   } else if (
    //     CUSTOMER_STATUS[vendorOrder?.status_id] === CUSTOMER_STATUS[3]
    //   ) {
    //     return contentDetails.concat(vendorDetails, completedDetails);
    //   } else {
    //     return contentDetails;
    //   }
    // }, [vendorOrder]);

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
        {/* <Carousel show={2} dataLength={rowData?.order_images?.length}>
          { rowData?.order_images?.length 
            ? rowData.order_images.map((item, index) => { 
                return (
                  <Grid
                    style={{ display: 'flex', marginTop: theme.spacing(4) }}
                    key={index}
                  >
                    <img
                      className={classes.imgStyle}
                      src={item}
                      alt="image not found"
                    />
                  </Grid>
                );
              })
            : null}
        </Carousel> */}
        <ImageHeading data={rowData} isHeading={isHeading} />
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        {rowData?.status_id === CUSTOMER_ORDER_STATUS['completed'] ? (
          <ButtonComp
            backgroundColor={theme.Colors.primary}
            height={'40px'}
            buttonText={'Download Invoice'}
            buttonFontSize={theme.MetricsSizes.regular}
            buttonTextColor={theme.Colors.white}
            buttonFontWeight={theme.fontWeight.medium}
            btnWidth={'235px'}
            btnBorderRadius={100}
            onClickButton={() => handleClickDownload(rowData)}
            startIcon={<img src={Downloading} />}
          />
        ) : null}
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'DustMan'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default VendorOrderModal;
