import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { DialogComp, DualActionButton, Loader } from 'src/components';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CompleteOrder from './CompleteOrder';
import { useEdit } from 'src/hooks/useEdit';
import { isOneTimePassWord } from 'src/Utils';

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    textStyle: { fontWeight: theme.fontWeight.medium, fontSize: 16 }
  };
});

const CompleteCustomerOrderByAdmin = (props) => {
  const { onClose, open, rowData, updateData } = props;
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  const [count, setCount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isOtpGenerate, setIsOtpGenerate] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const initialValues = {
    otp: ''
  };
  const edit = useEdit(initialValues);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  let totalQuantity = 0;
  let totalPrice = 0;
  rowData?.order_item?.forEach((item) => {
    totalQuantity += item.category_quantity;
    totalPrice += item.category_quantity * item.category_price;
    return { totalPrice, totalQuantity };
  });

  const handleChange = (idx: string | number, quantity: any, price: number) => {
    let updatedOrderItems = [...rowData.order_item];
    updatedOrderItems[idx].category_quantity = Number(quantity);
    updatedOrderItems[idx].final_quantity = Number(quantity);
    updatedOrderItems[idx].price = Number(quantity) * price;
    let newTotalQuantity = 0;
    let newTotalPrice = 0;
    updatedOrderItems.forEach((item) => {
      newTotalQuantity += item.category_quantity;
      newTotalPrice += item.category_quantity * item.category_price;
    });
    setCount(newTotalQuantity);
  };

  const renderDialogContent = () => {
    if (loading) {
      <Loader />;
    } else {
      return (
        <CompleteOrder
          rowData={rowData}
          handleChange={handleChange}
          inputRef={inputRef}
          totalPrice={totalPrice}
          totalQuantity={totalQuantity}
          edit={edit}
          isOtpGenerate={isOtpGenerate}
        />
      );
    }
  };

  const handleCompleteClick = async () => {
    try {
      if (isOtpGenerate) {
        if (
          !edit.allFilled('otp') ||
          !isOneTimePassWord(edit.getValue('otp'))
        ) {
          toast.error('Please enter valid 6 digit otp');
          return;
        }
        const response: any =
          await API_SERVICES.customerOrderService.dustmanCompleteCustomerOrder(
            rowData?.id,
            {
              data: { otp: Number(edit.edits.otp) },
              successMessage: 'Order moved to completed successfully!'
            }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      } else {
        rowData?.order_item?.forEach((item) => {
          if (item.price === 0 && item.final_quantity === 0) {
            item.final_quantity = item.category_quantity;
            item.price = item.category_price * item.final_quantity;
          }
        });
        let data = {
          total_quantity_kg: totalQuantity,
          total_price: totalPrice,
          order_items: rowData?.order_item,
          complete_user_id: localStorage.getItem('userId'),
          is_admin: true
        };
        const response: any =
          await API_SERVICES.generateOtpService.createAdminCompleteCustomerOrder(
            rowData?.order_id,
            { data: data, successMessage: 'Otp sent successfully' }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          setIsOtpGenerate(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAction = () => {
    return (
      <DualActionButton
        buttonText={isOtpGenerate ? 'Complete Order' : 'Generate OTP'}
        onLeftButtonClick={onClose}
        onRightButtonClick={handleCompleteClick}
        btnWidth={150}
      />
    );
  };

  return (
    <DialogComp
      open={open}
      dialogClasses={{ paper: styles.dialogPaper }}
      dialogTitle={'Complete Order'}
      onClose={onClose}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default CompleteCustomerOrderByAdmin;
