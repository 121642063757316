import { useContext, useState } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Box, Drawer,  useTheme } from '@material-ui/core';
import SidebarMenu from './SidebarMenu';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Logo, NavListItem, Scrollbar, UHConfirmModal } from 'src/components';
import { useNavigate } from 'react-router';
import useUserInfo from 'src/hooks/useUserInfo';
import { CONFIRM_MODAL } from 'src/Config/constant';
import { INITIAL_STATE } from 'src/contexts/UserContext';
import { toast } from 'react-hot-toast';
import Icon from '@mdi/react';
import { mdiCogOutline } from '@mdi/js';
import { mdiChartBellCurve } from '@mdi/js';
import { mdiLogout } from '@mdi/js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: theme.sidebar.width,
    color: theme.Colors.white,
    zIndex: 7,
    height: '100%',
    position: 'fixed',
    backgroundColor: theme.Colors.whitePrimary,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: 250
    }
  }
}));

const bottomRoutes = [
  {
    name: 'Reports',
    path: '/dashboards/reports',
    iconComponent: () => (
      //  <ReportsIcon />
      <Icon path={mdiChartBellCurve} size={1} />
    )
  },
  {
    name: 'Settings',
    path: '/dashboards/settings',
    iconComponent: () => <Icon path={mdiCogOutline} size={1} />
  },
  {
    name: 'Log out',
    path: '/login',
    iconComponent: () => <Icon path={mdiLogout} size={1} />
  }
];

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme: Theme = useTheme();
  const styles = useStyles();
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const navigateTo = useNavigate();
  const { updateUserInfo } = useUserInfo();

  const onClickNavItem = (event?: any) => {
    if (event?.target?.textContent !== 'Log out') {
      return;
    }
    const onConfirmClick = () => {
      localStorage.clear();
      navigateTo('/login', { replace: true });
      updateUserInfo(INITIAL_STATE.userDetails);
      toast.success('User logged out successfully!');
    };

    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    let props = {
      title: 'log out',
      description: 'Are you sure want to log out?',
      color: theme.Colors.redPrimary,
      iconType: CONFIRM_MODAL.logout
    };
    setConfirmModal({ open: true, onCancelClick, onConfirmClick, ...props });
  };

  return (
    <>
      <Box
        className={styles.root}
        sx={{
          display: {
            xs: 'none',
            lg: 'flex'
          }
        }}
      >
        <Box py={4} px={7}>
          <Logo />
        </Box>
        <Scrollbar>
          <SidebarMenu />
          <NavListItem
            routes={bottomRoutes}
            logOutTextColor={'red'}
            onClickNavItem={onClickNavItem}
          />
        </Scrollbar>
      </Box>
      <Drawer
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={toggleSidebar}
        variant="temporary"
        elevation={9}
      >
        <Box className={styles.root}>
          <Box
            sx={{
              py: 4,
              pl: { xs: 4, sm: 7, md: 7, lg: 7 }
            }}
          >
            <Logo />
          </Box>
          <Scrollbar>
            <SidebarMenu />
            <NavListItem
              routes={bottomRoutes}
              logOutTextColor={'red'}
              containerStyles={{ mb: 14 }}
            />
          </Scrollbar>
        </Box>
      </Drawer>
      {confirmModal.open ? <UHConfirmModal {...confirmModal} /> : null}
    </>
  );
}

export default Sidebar;
