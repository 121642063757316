import React, { useEffect, useState } from 'react';
import {
  Cancel,
  AssignmentTurnedIn,
  ListAlt,
  MoveToInbox,
  Delete
} from '@material-ui/icons';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  ButtonComp,
  ListItemCell,
  TextInputComponent,
  UHTable
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, InputAdornment } from '@material-ui/core';
import { Compass, TableCalendar } from 'src/Assets';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => ({}));

type Props = {
  onClickActionButton?: (row?: any, selIds?: number[]) => void;
  onClickRejectOrder?: (row: any) => void;
  tableRowData?: any[];
  onClickCancelOrder?: (row: any) => void;
  onClickMoveToDustman?: (row: any) => void;
  onClickViewOrder?: (row: any) => void;
  selectedTab?: any;
  handleAssignVendorModal?: (row?: any) => void;
  handleOpenCalendar?: () => void;
  edit?: any;
  customerMgntChipFilter?: string;
  handleAction?: boolean;
};

const CustomerManagementTable = ({
  onClickActionButton,
  handleAssignVendorModal,
  onClickRejectOrder,
  onClickCancelOrder,
  onClickMoveToDustman,
  tableRowData,
  selectedTab,
  handleOpenCalendar,
  customerMgntChipFilter,
  edit,
  onClickViewOrder
}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList?.sort((current, next) => {
        return current?.updated_at?.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  const columns = [
    {
      field: 'details',
      headerName: 'Customer Name',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.contact_name}
          avatarImg={row?.image_url || 'DustMan'}
          titleStyle={{ textDecoration: 'underline' }}
        />
      )
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.location} subTitle={row?.contact_pincode} />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.mobile_number?.slice(2)} />
      )
    },
    // {
    //   field: 'date',
    //   headerName: t('date'),
    //   flex: 2,
    //   sortable: false,
    //   disableColumnMenu: true,

    //   renderCell: ({ row }) => {
    //     const { getMonth, getDate, getYear, getTime } = getDateFormat(
    //       row?.updated_at
    //     );
    //     return (
    //       <ListItemCell
    //         title={`${getMonth} ${getDate}, ${getYear}`}
    //         subTitle={getTime}
    //       />
    //     );
    //   }
    // },
    {
      field: 'action',
      headerName: selectedTab == 'assignto' ? 'Status' : t('action'),
      flex: 1.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <>
          {row?.status == 1 || row?.status == 3 ? (
            <Chip
              label={row?.status == 1 ? 'Pending' : 'Accepted'}
              style={{
                width: 100,
                fontSize: theme.MetricsSizes.small_x,
                color:
                  row?.status == 1
                    ? theme.Colors.white
                    : theme.Colors.secondary,
                backgroundColor: row?.status == 1 ? '#C4C4C4' : '#eefeef',
                fontWeight: theme.fontWeight.bold,
                height: theme.MetricsSizes.regular_xxx,
                border: '2px solid',
                borderColor:
                  row?.status == 1 ? '#C4C4C4' : theme.Colors.secondary
              }}
            />
          ) : (
            <ButtonComp
              buttonText={
                row?.status == 0
                  ? 'Assign Vendor'
                  : row?.status == 2
                  ? 'Renew Vendor'
                  : row?.status == 5
                  ? 'Reassign Vendor'
                  : row?.status == 1
                  ? 'Pending'
                  : row?.status == 3
                  ? 'Accepted'
                  : row?.status == 4
                  ? 'Reassign'
                  : 'View'
              }
              buttonFontSize={theme.MetricsSizes.small_x}
              height={theme.MetricsSizes.regular_xxx}
              btnBorderRadius={theme.MetricsSizes.regular_x}
              backgroundColor={
                row?.status == 2
                  ? theme.Colors.primary
                  : row?.status == 5
                  ? '#F68B1F'
                  : row?.status == 1
                  ? '#C4C4C4'
                  : theme.Colors.secondary
              }
              onClickButton={() => handleAssignVendorModal(row)}
              disabled={row?.status == 1}
            />
          )}
        </>
      )
    }
  ];

  const [colDefs, setColDefs] = useState(columns);

  useEffect(() => {
    const updatedDefs = [...columns];
    if (selectedTab == 'assignto') {
      updatedDefs.splice(3, 0, {
        field: 'date',
        headerName: 'Vendor',
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          return <ListItemCell title={row?.vendor_company_name} />;
        }
      });
    }
    if (selectedTab == 'newRequest') {
      updatedDefs.splice(3, 0, {
        field: 'date',
        headerName: t('date'),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const { getMonth, getDate, getYear, getTime } = getDateFormat(
            row?.created_at
          );
          let time = getTime?.split(':');
          return (
            <ListItemCell
              title={`${getMonth} ${getDate}, ${getYear}`}
              subTitle={`${time[0]}:${time[1]}${time[2]?.slice(2)}`}
            />
          );
        }
      });
    }
    setColDefs(updatedDefs);
  }, [selectedTab]);

  const renderRowActions = (rowData: any) => {
    if (rowData?.status == 0 || rowData?.status == 2) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('moveToDustman'),
          renderIcon: () => <MoveToInbox />,
          onClick: onClickMoveToDustman
        },
        {
          text: 'Reject',
          renderIcon: () => <Cancel />,
          onClick: onClickRejectOrder
        },
        {
          text: 'Cancel Order',
          renderIcon: () => <Delete />,
          onClick: onClickCancelOrder
        }
      ];
    } else {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('moveToDustman'),
          renderIcon: () => <MoveToInbox />,
          onClick: onClickMoveToDustman
        },
        {
          text: 'Reassign Vendor',
          renderIcon: () => <AssignmentTurnedIn />,
          onClick: handleAssignVendorModal
        },
        {
          text: 'Reject',
          renderIcon: () => <Cancel />,
          onClick: onClickRejectOrder
        }
      ];
    }
    // if(rowData?.status == 4) {
    //   return [
    //     {
    //       text: t('viewOrder'),
    //       renderIcon: () => <ListAlt />,
    //       onClick: onClickViewOrder
    //     },
    //     {
    //       text: t('moveToDustman'),
    //       renderIcon: () => <MoveToInbox />,
    //       onClick: onClickMoveToDustman
    //     },
    //     {
    //       text: 'Reassign Vendor',
    //       renderIcon: () => <Delete />,
    //       onClick: onClickCancelOrder
    //     },
    //     {
    //       text: 'Reject',
    //       renderIcon: () => <Cancel />,
    //       onClick: onClickRejectOrder
    //     },
    //   ];
    // }
    // return [
    //   {
    //     text: t('moveToDustman'),
    //     renderIcon: () => <MoveToInbox />,
    //     onClick: onClickMoveToDustman
    //   },
    //   {
    //     text: 'Reject',
    //     renderIcon: () => <Cancel />,
    //     onClick: onClickRejectOrder
    //   },
    //   {
    //     text: 'Cancel Order',
    //     renderIcon: () => <Delete />,
    //     onClick: onClickCancelOrder
    //   }
    // ];
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Location', key: 'location' },
    { label: 'MobileNumber', key: 'mobile_number' },
    { label: 'Date', key: 'date' }
  ];

  let excelData =
    tableRowData?.length &&
    tableRowData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.created_at);
      return {
        id: idx + 1,
        name: capitalizeFirstLetter(rowData?.contact_name),
        location: `${rowData?.location},${rowData?.pincode}`,
        mobile_number: `${rowData?.mobile_number?.slice(2)}`,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search Location"
            value={edit.getValue('location')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Compass} />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
          />
        </Grid>
        {customerMgntChipFilter == 'Today' ? null : (
          <Grid item>
            <TextInputComponent
              inputWidth={250}
              borderRadius={40}
              inputHeight={30}
              value={edit.getValue('date')}
              placeholder="Search by Date"
              isBackground={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    <img src={TableCalendar} />
                  </InputAdornment>
                )
              }}
              onClick={handleOpenCalendar}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <UHTable
      title={t('customerManagement.allCustomer')}
      columns={colDefs}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      getRowActions={renderRowActions}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={selectedTab == 1 ? 'Customer List' : 'Vendor List'}
      renderSearchBox={renderSearchBox}
    />
  );
};

export default React.memo(CustomerManagementTable);
