import { useCallback, useEffect, useState } from 'react';
import { NewOrder, Completed, Scheduled, Cancelled } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  Heading,
  UHCalenderComp,
  UHConfirmModal,
  Loader,
  MultiSelectChip
} from 'src/components';
import { Box, useTheme } from '@material-ui/core';
import VendorTable from './VendorTable';
import VendorOrderModal from './VendorOrderModal';
import { API_SERVICES } from 'src/Services';
import {
  CONFIRM_MODAL,
  FILTER_CHIPS,
  GET_FILTER_VALUES,
  HTTP_STATUSES,
  ORDER_STATUS,
  ORDERS_COUNT,
  VENDOR_STATUS
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import GenerateOtp from './GenerateOtp';
import { useEdit } from 'src/hooks/useEdit';

type VendorOrders = {
  cancelled: any[];
  confirmed: any[];
  new: any[];
  scheduled: any[];
};

type VendorOrderCount = {
  cancelledOrderCount: number;
  confirmedOrderCount: number;
  newOrderCount: number;
  acceptedOrderCount: number;
};

const statusType = [
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Vehicle Alloted' },
  { id: 5, name: 'Over Due' },
  { id: 6, name: 'Pending Invoice' }
];

function VendorOrder() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [vendorOrderCount, setVendorOrderCount] =
    useState<VendorOrderCount>(ORDERS_COUNT);

  const vendorOrderDetailsTabValues = [
    {
      heading: t('newOrder'),
      subText: vendorOrderCount.newOrderCount,
      iconImage: NewOrder,
      value: 'new'
    },
    {
      heading: t('accepted'),
      subText: vendorOrderCount.acceptedOrderCount,
      iconImage: Scheduled,
      value: 'scheduled'
    },
    {
      heading: t('completed'),
      subText: vendorOrderCount.confirmedOrderCount,
      iconImage: Completed,
      value: 'confirmed'
    },
    {
      heading: t('cancelled'),
      subText: vendorOrderCount.cancelledOrderCount,
      iconImage: Cancelled,
      value: 'cancelled'
    }
  ];
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    vendorOrderDetailsTabValues[0].value
  );
  const [vendorOrderChipFilter, setVendorOrderChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [tableData, setTableData] = useState<VendorOrders>({
    cancelled: [],
    confirmed: [],
    new: [],
    scheduled: []
  });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [openModal, setOpenModal] = useState<any>({ open: false });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });

  const initialValue = {
    date: '',
    location: '',
    status: ''
  };
  const edit = useEdit(initialValue);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChangeVendorOrderChip = (selectedChipItem: string[]) => {
    setVendorOrderChipFilter(selectedChipItem);
  };
  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
    edit.reset();
  };

  const fetchData = useCallback(async () => {
    try {
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any = await API_SERVICES.vendorOrderService.getAllBy(
        GET_FILTER_VALUES[vendorOrderChipFilter[0]],
        {
          searchString: edit.getValue('location'),
          fromDate: edit.getValue('date')?.split('to')[0],
          toDate: edit.getValue('date')?.split('to')[1],
          status_id: edit.getValue('status'),
          ...params
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.vendorOrders && response?.data?.vendorOrdersCount) {
          setVendorOrderCount(response?.data?.vendorOrdersCount);
          setTableData((prev) => {
            return { ...prev, ...response?.data?.vendorOrders };
          });
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [vendorOrderChipFilter, debValue, edit.edits]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const onActionButtonClick = (rowData: any, rowIds?: number[]) => {
    if (
      (selectedTab == 'scheduled' && rowData?.is_ready_to_upload) ||
      rowData?.status_id == ORDER_STATUS.overdue ||
      rowData?.status_id == ORDER_STATUS.confirmed ||
      rowData?.status_id == ORDER_STATUS.pending
    ) {
      return;
    }
    if (rowData?.status_id == ORDER_STATUS.newOrder) {
      const onCancelClick = () => {
        setConfirmModal({ open: false });
      };
      const onConfirmClick = async () => {
        let updateData = {
          status_id: 2,
          orders: rowIds ?? [rowData?.id]
        };
        const response: any = await API_SERVICES.vendorOrderService.replace({
          data: updateData,
          successMessage: t('vendorOrder.acceptVendorOrder')
        });
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          onCancelClick();
          fetchData();
        }
      };
      let props = {
        color: theme.Colors.secondary,
        description: t('vendorOrder.AcceptOrder'),
        title: t('vendorOrder.accept'),
        iconType: CONFIRM_MODAL.publish
      };
      setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
    }
    // if (
    //   rowData &&
    //   Object.keys(rowData).length &&
    //   VENDOR_STATUS[rowData.status_id] !== VENDOR_STATUS[0] &&
    //   VENDOR_STATUS[rowData.status_id] !== VENDOR_STATUS[1]
    // ) {
    return onClickViewOrder(rowData);
    // }
  };

  const onClickConfirmModal = (rowData: any) => {
    setOpenModal({ open: true, rowData: rowData });
  };

  const onClickCancelOrder = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        cancelled_by: 2
        //orders: [rowData?.id]
      };
      const response: any = await API_SERVICES.customerOrderService.cancelOrder(
        rowData?.id,
        {
          data: updateData,
          successMessage: t('vendorOrder.cancelOrder')
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('vendorOrder.cancelVendorOrder'),
      title: t('vendorOrder.cancel'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickViewOrder = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData, isHeading: true });
  };

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');

    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    // setCalendarOpen({ open: false });
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  const handleClickDownload = async (rowData: {
    invoice_url: RequestInfo | URL;
  }) => {
    try {
      const url = `${rowData?.invoice_url}?timestamp=${Date.now()}`;
      console.log('URL:', url);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/octet-stream'
        }
      });

      console.log('Response:', response);
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      console.log('Blob size:', blob.size, 'Blob type:', blob.type);

      // Extract the filename from the URL
      const fileName =
        rowData?.invoice_url.toString().split('/').pop()?.split('?')[0] ||
        'downloaded-file';

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log('There was an error downloading the file:', error);
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Heading headingText={t('overview.vendorOrders')} />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={vendorOrderChipFilter}
          handleChange={handleChangeVendorOrderChip}
          chipStyle={{
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={vendorOrderDetailsTabValues}
          isTileTypeOrders={true}
          onTabChange={handleSetSelectedTab}
          tabValue={selectedTab}
        />
        <Box sx={{ mt: 3 }}>
          <VendorTable
            onClickActionButton={onActionButtonClick}
            tableRowData={tableData[selectedTab]}
            onClickViewOrder={onClickViewOrder}
            onClickCancelOrder={onClickCancelOrder}
            onClickGenerateOtp={onClickConfirmModal}
            renderActionBtn={
              selectedTab === vendorOrderDetailsTabValues[0].value
            }
            handleOpenCalendar={handleOpenCalendar}
            edit={edit}
            vendorOrderChipFilter={vendorOrderChipFilter[0]}
            selectedTab={selectedTab}
            statusType={statusType}
            handleChangeStatus={(e) => {
              edit.update({ status: e.target.value });
            }}
          />
        </Box>
        {modalOpen.open && (
          <VendorOrderModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
            handleClickDownload={handleClickDownload}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {openModal.open && (
          <GenerateOtp
            {...openModal}
            onClose={() => setOpenModal({ open: false })}
            updateData={fetchData}
          />
        )}
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
      </>
    );
  }
}

export default VendorOrder;
