import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useTheme,
  Tabs,
  Tab,
  Divider
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.Colors.whiteGreyLight,
      borderRadius: theme.MetricsSizes.regular_x
    },
    dividerStyle: {
      background: theme.Colors.lightBlueGrey,
      height: '18px'
    },
    buttonAlign: {
      fontSize: '2px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingTop: '5px'
    },
    tabIndicator: {
      display: 'none'
    },
    outerContainer: {
      border: '1px solid',
      borderRadius: '5px',
      borderColor: theme.Colors.primary
    },
    tabContent: {
      borderRight: '1px solid',
      borderColor: theme.Colors.primary,
      paddingRight: '1px'
    },
    borderStyle: {
      borderRight: 'none'
    },
    tabStyle: {
      minWidth: '16px',
      height: '10px',
      padding: theme.spacing(0.1, 1),
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.regular,
      textTransform: 'none',
      '&.MuiButtonBase-root': {
        alignItems: 'flex-start'
      }
    },
    selectedTab: {
      backgroundColor: theme.Colors.primary,
      color: theme.Colors.white,
      borderRight: 'none'
    },
    rootStyle: {
      minHeight: '20px'
    },
    headerStyle: {
      fontSize: theme.MetricsSizes.small_xxx,
      color: theme.Colors.black,
      paddingRight: theme.spacing(1.2),
      fontWeight: theme.fontWeight.bold
    }
  };
});

type SalesProps = {
  header?: string;
  onTabChange?: (val) => void;
  contentToDisplay?: any[];
};
const SalesTimeComp = ({
  header,
  onTabChange,
  contentToDisplay
}: SalesProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    onTabChange(newValue);
  };
  return (
    <>
      <Grid container xs={12}>
        {header ? (
          <Grid item>
            <Typography className={classes.headerStyle}>{header}</Typography>
          </Grid>
        ) : null}
        <Grid item>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="fullWidth"
            className={classes.outerContainer}
            classes={{
              indicator: classes.tabIndicator,
              root: classes.rootStyle
            }}
            style={{ height: '10px' }}
          >
            {/* {contentToDisplay.length */}
            {contentToDisplay?.map((item, index) => {
              return (
                <Tab
                  key={index}
                  value={item.id || index}
                  label={
                    <Typography
                      style={{ fontSize: theme.MetricsSizes.tiny_xxx }}
                    >
                      {item.heading}
                    </Typography>
                  }
                  className={
                    item.id === 4 ? classes.borderStyle : classes.tabContent
                  }
                  classes={{
                    root: classes.tabStyle,
                    selected: classes.selectedTab
                  }}
                />
              );
            })}
            {/* // : null} */}
          </Tabs>
        </Grid>
      </Grid>
    </>
  );
};
export default SalesTimeComp;
