import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const InvoiceManagementService = {
  getTotalCount: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getTotalInvoiceCount`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorInvoice: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/getVendorInvoice/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getCustomerInvoice: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/getCustomerInvoice/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  }
};
