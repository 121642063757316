import React from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import {
  ButtonComp,
  TextInputComponent,
  DialogComp,
  DualActionButton
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { GET_USER_CATEGORY, HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import { useEdit } from 'src/hooks/useEdit';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles<Theme>((theme) => {
  return {
    outerContainer: {
      padding: theme.spacing(0, 8, 5, 8)
    },
    text: {
      fontSize: theme.MetricsSizes.small_x,
      color: theme.Colors.darkGrey,
      paddingTop: theme.spacing(1)
    },
    buttonContainer: {
      paddingLeft: theme.spacing(2),
      alignSelf: 'flex-end'
    },
    dialogContainerStyle: {
      width: 847,
      height: 705,
      padding: theme.spacing(5, 6, 4, 6),
      borderRadius: 18
    }
  };
});

type Props = {
  open: boolean;
  handleDialogClose?: () => void;
  userTypeId?: number;
  isPriceInput?: boolean;
  categoryTypeId?: number;
  updateData?: (region_id: number) => void;
  listItemData?: any;
  dialogType?: string;
  type: number;
  regionId: number;
};

const AddNewPriceMgntDialog = ({
  open,
  handleDialogClose,
  userTypeId,
  categoryTypeId,
  updateData,
  listItemData,
  dialogType,
  type,
  regionId
}: Props) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const defaultDataValues = {
    name: listItemData?.name || '',
    price: listItemData?.price || 0,
    image_url: listItemData?.image_url || '',
    region_id: listItemData?.region_id || regionId || 0
  };
  const edit = useEdit(defaultDataValues);
  const RequiredFields = ['name', 'price', 'image_url'];

  const onBrowseButtonClick = async (event: any) => {
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    img.onload = async () => {
      if (img.width <= 120 && img.height <= 240) {
        const uploadImageRes: any =
          await API_SERVICES.imageUploadService.uploadImage(formData);
        if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (uploadImageRes?.data?.images.length) {
            edit.update({ image_url: uploadImageRes.data.images[0].Location });
          }
        }
      } else {
        alert(`Sorry, this image doesn't look like the size we wanted. It's 
        ${img.width} x ${img.height} but we require 120 x 240 size image or below this size.`);
      }
    };
  };

  const handleSaveButtonClick = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        return toast.error('Please fill all the details');
      }
      let data = {
        ...defaultDataValues,
        ...edit.edits
      };
      if (dialogType === 'edit') {
        const response: any =
          await API_SERVICES.priceMgntService.replaceTrashCategory(
            listItemData?.id,
            {
              data: data,
              successMessage: t('editItemSuccessfull'),
              failureMessage: t('editItemUnSuccessfull')
            }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData(edit.getValue('region_id'));
          handleDialogClose();
        }
      } else {
        const response: any =
          await API_SERVICES.priceMgntService.createTrashCategory(
            categoryTypeId,
            userTypeId,
            type,
            {
              data: data,
              successMessage: t('addNewItemSuccessfull')
            }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData(regionId);
          handleDialogClose();
        }
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderDialogContent = () => {
    return (
      <Grid container className={classes.outerContainer} spacing={2}>
        <Grid item xs={12}>
          <TextInputComponent
            placeholder={GET_USER_CATEGORY[userTypeId]}
            disabled
            inputLabel={t('category')}
            labelColor={theme.Colors.primary}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            value={edit.getValue('name')}
            onChange={(e) =>
              edit.update({ name: capitalizeFirstLetter(e.target.value) })
            }
            inputLabel={t('scrapType')}
            labelColor={theme.Colors.primary}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputComponent
            value={edit.getValue('price')}
            onChange={(e) => edit.update({ price: e.target.value })}
            inputLabel={t('priceRs')}
            labelColor={theme.Colors.primary}
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={9}>
            <TextInputComponent
              value={edit.getValue('image_url')}
              disabled
              inputLabel={t('image')}
              labelColor={theme.Colors.primary}
            />
          </Grid>
          <Grid item xs={3} className={classes.buttonContainer}>
            <ButtonComp
              backgroundColor={theme.Colors.primary}
              buttonText={t('button.browse')}
              buttonFontSize={theme.MetricsSizes.small_xxx}
              buttonTextColor={theme.Colors.white}
              buttonFontWeight={theme.fontWeight.medium}
              disableElevation={true}
              onBrowseButtonClick={onBrowseButtonClick}
              isBrowseButton
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.text}>
          <span>{t('imageSize')}</span>
        </Grid>
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={handleDialogClose}
        disabledRightBtn={!edit.allFilled(...RequiredFields)}
        onRightButtonClick={handleSaveButtonClick}
      />
    );
  };

  return (
    <DialogComp
      open={open}
      onClose={handleDialogClose}
      dialogTitle={dialogType === 'edit' ? t('editTrash') : t('createNew')}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
      maxWidth="md"
      dialogClasses={{ paper: classes.dialogContainerStyle }}
    />
  );
};
export default AddNewPriceMgntDialog;
