import React from 'react';
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  gridStyle: {
    border: '1px solid',
    borderRadius: theme.MetricsSizes.medium,
    borderColor: theme.Colors.black
  },
  buttonStyle: {
    borderRadius: theme.MetricsSizes.medium,
    padding: 10,
    minWidth: 120,
    textAlign: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: theme.MetricsSizes.small_xxx
  }
}));

type SwitchProps = {
  handleClick: (val: any) => void;
  setButtonValue?: any;
  buttonValue?: any;
};
const SwitchComponent = (props: SwitchProps) => {
  const { handleClick, setButtonValue, buttonValue } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useStyles();

  const handleClickVendor = (val) => {
    handleClick(val);
  };
  const handleClickCustomer = (val) => {
    handleClick(val);
  };

  return (
    <Box className={styles.boxStyle}>
      <Grid className={styles.gridStyle}>
        <button
          style={{
            backgroundColor:
              buttonValue == 1 ? theme.Colors.primary : 'transparent',
            color: buttonValue == 1 ? theme.Colors.white : theme.Colors.primary,
            fontWeight:
              buttonValue == 1
                ? theme.fontWeight.regular
                : theme.fontWeight.medium
          }}
          onClick={() => handleClickCustomer(1)}
          className={styles.buttonStyle}
        >
          {t('customer')}
        </button>
        <button
          className={styles.buttonStyle}
          style={{
            backgroundColor:
              buttonValue == 2 ? theme.Colors.primary : 'transparent',
            color: buttonValue == 2 ? theme.Colors.white : theme.Colors.primary,
            fontWeight:
              buttonValue == 2
                ? theme.fontWeight.regular
                : theme.fontWeight.medium
          }}
          onClick={() => handleClickVendor(2)}
        >
          {t('Vendor')}
        </button>
      </Grid>
    </Box>
  );
};

export default SwitchComponent;
