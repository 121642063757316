import { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  ButtonComp,
  DialogComp,
  TextInputComponent,
  DualActionButton
} from 'src/components';
import toast from 'react-hot-toast';
import { ADMIN_ROLE, HANDLE_SUBMIT, HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import { useEdit } from 'src/hooks/useEdit';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 745,
      padding: theme.spacing(4, 4, 3, 4),
      borderRadius: 18
    },
    contentStyle: {
      padding: theme.spacing(0, 10.5)
    },
    buttonContainer: {
      paddingLeft: theme.spacing(2),
      alignSelf: 'flex-end'
    }
  };
});

type permissionData = {
  id: number;
  permission: string;
};

type Props = {
  onClose?: () => void;
  rowData?: any;
  type: string;
  updateData?: () => void;
  permissions: permissionData[];
};

const UserManagementModal = (props: Props) => {
  const { onClose, rowData, type, updateData, permissions } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const USER_INITIAL_DATA = {
    name: rowData?.name || '',
    user_name: rowData?.user_name || '',
    password: rowData?.password || '',
    confirmPassword: rowData?.password || '',
    role: rowData?.role || ADMIN_ROLE.admin,
    permissions: rowData?.permissions || [],
    image_url: rowData?.image_url || ''
  };

  const RequiredFields = [
    'name',
    'user_name',
    'password',
    'confirmPassword',
    'permissions'
  ];
  const edit = useEdit(USER_INITIAL_DATA);

  const nameError = error && !edit.getValue('name');
  const userError = error && !edit.getValue('user_name');
  const permissionError = error && !edit.getValue('permissions')?.length;
  const imgError = error && !edit.getValue('image_url');

  const confirmPasswordError =
    error &&
    edit.getValue('confirmPassword') &&
    edit.getValue('password') &&
    edit.getValue('confirmPassword') !== edit.getValue('password');
  const passwordError =
    (error && !edit.getValue('password')) ||
    (error &&
      edit.getValue('password') &&
      edit.getValue('password').length < 7);

  const handleChangeCheckbox = (event: any) => {
    let targetId = Number(event?.target?.id);
    let targetCheckedState = event?.target?.checked;
    let permissionData = edit.getValue('permissions');
    if (!!targetCheckedState) {
      permissionData.push(targetId);
    } else if (!targetCheckedState) {
      permissionData = permissionData.filter((item) => item !== targetId);
    }
    edit.update({ permissions: permissionData });
  };

  const onBrowseButtonClick = async (event: any) => {
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    img.onload = async () => {
      // if (img.width <= 120 && img.height <= 240) {
      const uploadImageRes: any =
        await API_SERVICES.imageUploadService.uploadImage(formData);
      if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (uploadImageRes?.data?.images.length) {
          edit.update({ image_url: uploadImageRes.data.images[0].Location });
        }
      }
      // } else {
      //   alert(`Sorry, this image doesn't look like the size we wanted. It's
      //   ${img.width} x ${img.height} but we require 120 x 240 size image or below this size.`);
      // }
    };
  };

  const handleCreate = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setError(true);
        return toast.error(t('pleaseFillAllTheDetails'));
      } else if (!edit.getValue('permissions').length) {
        return toast.error(t('pleaseSelectAnyOneOfTheAccessPermission'));
      } else if (
        edit.getValue('confirmPassword') !== edit.getValue('password') ||
        edit.getValue('password').length < 7
      ) {
        return setError(true);
      }
      let userData = { ...USER_INITIAL_DATA, ...edit.edits };
      const createUserRes: any = await API_SERVICES.userService.create({
        data: userData,
        successMessage: t('userCreatedSuccessfully')
      });
      if (createUserRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
        setError(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleEdit = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setError(true);
        return toast.error(t('pleaseFillAllTheDetails'));
      } else if (!edit.getValue('permissions').length) {
        return toast.error(t('pleaseSelectAnyOneOfTheAccessPermission'));
      } else if (
        edit.getValue('confirmPassword') !== edit.getValue('password') ||
        edit.getValue('password').length < 7
      ) {
        return setError(true);
      }
      let userData = { ...USER_INITIAL_DATA, ...edit.edits };
      const createUserRes: any = await API_SERVICES.userService.update(
        rowData?.id,
        {
          data: userData,
          successMessage: t('UserUpdatedSuccessfully')
        }
      );
      if (createUserRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
        setError(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const types = {
    [HANDLE_SUBMIT.viewUser]: {
      dialogTitle: t('userDetails'),
      handleAction: handleEdit
    },
    [HANDLE_SUBMIT.createUser]: {
      dialogTitle: t('createNewUser'),
      handleAction: handleCreate
    }
  };

  const renderDialogContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('name')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('name')}
              onChange={(e) =>
                edit.update({ name: capitalizeFirstLetter(e.target.value) })
              }
              isError={nameError}
              helperText={nameError && 'Please enter your name'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('username')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('user_name')}
              onChange={(e) => edit.update({ user_name: e.target.value })}
              isError={userError}
              helperText={userError && 'Please enter your user name'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('password')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('password')}
              onChange={(e) => edit.update({ password: e.target.value })}
              type={'password'}
              inputProps={{
                maxLength: 12
              }}
              helperText={
                passwordError &&
                t('thePasswordMustContainMinimum7AndMaximum12Characters')
              }
              isError={passwordError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('confirmPassword')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('confirmPassword')}
              type={'password'}
              helperText={
                confirmPasswordError &&
                t('bothPasswordAndConfirmPasswordShouldBeSame')
              }
              isError={confirmPasswordError}
              onChange={(e) => edit.update({ confirmPassword: e.target.value })}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <TextInputComponent
                value={edit.getValue('image_url')}
                disabled
                inputLabel={t('image')}
                labelColor={theme.Colors.primary}
                isError={imgError}
              />
            </Grid>
            <Grid item xs={3} className={classes.buttonContainer}>
              <ButtonComp
                backgroundColor={theme.Colors.primary}
                buttonText={t('button.browse')}
                buttonFontSize={theme.MetricsSizes.small_xxx}
                buttonTextColor="white"
                buttonFontWeight={theme.fontWeight.medium}
                disableElevation={true}
                onBrowseButtonClick={onBrowseButtonClick}
                isBrowseButton
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t('accessPermission')}</Typography>
          </Grid>
          {permissions?.length &&
            permissions.map((item, index) => {
              return (
                <Grid item md={4} sm={6} key={index}>
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    control={
                      <Checkbox
                        id={item.id.toString()}
                        checked={edit.getValue('permissions').includes(item.id)}
                        color="primary"
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label={
                      <Typography style={{ color: permissionError && 'red' }}>
                        {item.permission}
                      </Typography>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={types[type].handleAction}
        // disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };

  return (
    <DialogComp
      dialogTitle={types[type].dialogTitle}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default UserManagementModal;
