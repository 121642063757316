import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export type DefaultProp = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const customerVendorListService = {
  getAllCustomer: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/listCustomerProfiles`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllVendors: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/listVendorProfiles`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getVendorByVendorId: async (vendor_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorProfileByVendorId/vendor/${vendor_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  listAssignedVendors: async (customer_id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminListAssignedVendors/${customer_id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  listAssignedCustomers: async (vendor_id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminListAssignedCustomers/${vendor_id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getCustomerStockDetails: async (customer_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getCustomerStockDetails/${customer_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorStockDetails: async (vendor_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorStockDetails/${vendor_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getCustomerOrderHistory: async (customer_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/customerOrderHistoryByCustomerId/${customer_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorOrderHistory: async (vendor_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorOrderHistoryByVendorId/${vendor_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorCommission: async (vendor_id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/getVendorCommission/${vendor_id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  updateVendorCommission: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/updateCommission/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateCustomer: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateCustomerProfile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateVendor: async (
    id: number,
    vendorId: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateVendorProfile/vendor/${vendorId}/vendor_profile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  createBankDetails: async ({
    data,
    successMessage,
    failureMessage
  }: DefaultProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createBankDetails`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateBankDetails: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateBankDetails/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  getCustomerBankDetailById: async (customer_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/customerBankDetails/${customer_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorBankDetailsById: async (vendor_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorBankDetails/${vendor_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  reAssignDustmanOrderToVendor: async ({
    data,
    successMessage,
    failureMessage
  }: DefaultProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/reassignOrderDustmanToVendor`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateVendorAddress: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateVendorOrderAddress/vendorOrderAddress/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
