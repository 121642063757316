import { useEffect } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import {
  DialogComp,
  DialogContentDetails,
  ListItemCell,
  TextInputComponent,
  DualActionButton
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { ENQUIRY_STATUS, HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      padding: theme.spacing(2, 2, 2, 5),
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

type Props = { onClose: () => void; rowData: any; updateData?: () => void };

const EnquiryManagementModal = (props: Props) => {
  const { onClose, rowData, updateData } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const enquiryDetail = {
    enquiry_details: rowData?.enquiry_details ?? ''
  };
  const edit = useEdit(enquiryDetail);

  useEffect(() => {
    //api call to get data
  }, []);

  const onSaveButtonClick = async () => {
    if (edit.getValue('enquiry_details') === '') {
      return toast.error('Please fill the enquiry details');
    }
    let data = { ...edit.edits };
    const response: any =
      await API_SERVICES.enquiryManagementService.updateEnquiryCall(
        rowData?.id,
        { data, successMessage: 'Enquiry details updated successfully!' }
      );
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      updateData();
      onClose();
    }
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={onSaveButtonClick}
      />
    );
  };

  const renderDialogContent = () => {
    let rightContent = [];
    if (rowData?.order_type === 'Vendor Order') {
      // don't pass to en
      rightContent = [
        { content: t('enquiry.gstNumber'), value: rowData?.gst },
        {
          content: t('address'),
          value: `${rowData?.full_address} ${rowData?.pincode}`
        },
        {
          content: t('enquiry.contactName'),
          value: capitalizeFirstLetter(rowData?.contact_name)
        },

        {
          content: t('enquiry.mobileNumber'),
          value: rowData?.registered_mobile_number?.slice(2)
        },
        { content: 'Email ID', value: rowData?.email_id }
      ];
    } else if (rowData?.order_type === 'Customer Order') {
      // don't pass to en
      rightContent = [
        { content: t('enquiry.gstNumber'), value: rowData?.gst_number || '-' },
        {
          content: t('address'),
          value: `${rowData?.full_address} ${rowData?.pincode}`
        },
        {
          content: t('enquiry.contactName'),
          value: capitalizeFirstLetter(rowData?.contact_name)
        },
        {
          content: t('enquiry.mobileNumber'),
          value: rowData?.registered_mobile_number?.slice(2)
        },
        { content: 'Email ID', value: rowData?.email || '-' }
      ];
    }

    return (
      <Grid>
        <ListItemCell
          avatarImg={rowData?.image_url || 'DUSTMAN'}
          title={capitalizeFirstLetter(rowData?.company_name)}
          titleStyle={{
            fontWeight: theme.fontWeight.bold,
            fontSize: theme.MetricsSizes.regular_xxx,
            color: theme.Colors.blueDark
          }}
          listStyle={{ padding: theme.spacing(1, 0, 3, 0) }}
        />
        <DialogContentDetails contentDetails={rightContent} />
        <br />
        <TextInputComponent
          placeholder="Enter Enquiry Details"
          value={edit.getValue('enquiry_details')}
          onChange={(e) => edit.update({ enquiry_details: e.target.value })}
          disabled={rowData?.status_id === ENQUIRY_STATUS.view}
          inputHeight={145}
          multiline
          maxRows={6}
          borderRadius={6}
        />
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={t('enquiry.enquiryDetails')}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={
        rowData?.status_id === ENQUIRY_STATUS.attend && renderAction
      }
    />
  );
};

export default EnquiryManagementModal;
