import React from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/Utils';
import { DialogComp, DialogContentDetails } from 'src/components';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 620,
      height: 365,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

const CustomerVendorViewDetailsModal = (props) => {
  const { onClose, rowData } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  console.log(rowData);

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: 'GST Number',
        value: rowData?.gst || rowData?.gst_number || '-'
      },
      {
        content: 'Location',
        value: rowData?.city
      },
      {
        content: 'Address',
        value:
          rowData?.full_address ||
          `${rowData?.address_line1 || ''}  ${rowData?.address_line2 || ''} ${
            rowData?.city || ''
          } ${rowData?.state || ''} ${rowData?.pincode || ''}` ||
          '-'
      },
      {
        content: t('vendor.contactName'),
        value: capitalizeFirstLetter(rowData?.contact_name || '-')
      },
      {
        content: t('mobileNumber'),
        value:
          rowData?.registered_mobile_number?.slice(2) ||
          rowData?.mobile_number?.slice(2) ||
          '-'
      },
      {
        content: 'Email Id',
        value: rowData?.email || rowData?.email_id || '-'
      },
      {
        content: 'Documents',
        value: rowData?.document_urls || '-',
        isLink: Boolean(rowData?.document_urls)
      }
    ];

    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
      </Grid>
    );
  };

  return (
    <DialogComp
      open={true}
      onClose={onClose}
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'DUSTMAN'}
      renderDialogContent={renderDialogContent}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        color: theme.Colors.blackMedium
      }}
    />
  );
};

export default CustomerVendorViewDetailsModal;
