import React from 'react';
import {
  TextField,
  Typography,
  useTheme,
  TextFieldProps
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  width?: string | number;
  height?: string | number;
  borderColor?: string;
  isBackground?: boolean;
  borderRadius?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  subText: {
    width: (props) => props.width || '100%',
    marginTop: theme.MetricsSizes.tiny,
    '& .MuiOutlinedInput-multiline': {
      height: (props) => props.height || 46,
      alignItems: 'initial'
    },
    '& .MuiOutlinedInput-input': {
      height: (props) => props.height || 46,
      padding: theme.spacing(0, 1.8),
      fontSize: theme.MetricsSizes.small_x3,
      fontWeight: theme.fontWeight.medium,
      color: theme.Colors.inputText
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => props.borderColor || theme.Colors.lightGrey,
      borderWidth: '1px',
      borderRadius: (props) => props.borderRadius || 0
    },
    '& .MuiFilledInput-root': {
      background: (props) => props.isBackground && theme.Colors.lightGrey
    }
  },
  helperRoot: {
    textTransform: 'none',
    fontSize: theme.MetricsSizes.small_xx
  },
  required: {
    color: theme.Colors.redPrimary,
    fontWeight: theme.fontWeight.bold
  }
}));

type Props = TextFieldProps & {
  inputLabel?: string;
  labelColor?: string;
  inputWidth?: string | number;
  inputHeight?: string | number;
  variant?: string;
  borderColor?: string;
  isError?: boolean;
  required?: boolean;
  placeholderText?: string;
  textColor?: string;
  isBackground?: boolean;
  borderRadius?: number;
};

const TextInputComponent = (props: Props) => {
  const theme: Theme = useTheme();
  const {
    inputLabel,
    labelColor,
    textColor,
    inputWidth,
    placeholderText,
    variant = 'outlined',
    inputHeight,
    borderColor,
    isError = false,
    required = false,
    isBackground = false,
    borderRadius,
    ...rest
  } = props;
  const styles = useStyles({
    width: inputWidth,
    height: inputHeight,
    borderColor: (isError && theme.Colors.redPrimary) || borderColor,
    isBackground: isBackground,
    borderRadius: borderRadius
  });
  return (
    <>
      {inputLabel && (
        <Typography
          style={{
            color:
              (isError && theme.Colors.redPrimary) ||
              labelColor ||
              theme.Colors.primary,
            fontWeight: theme.fontWeight.medium
          }}
        >
          {inputLabel}
          {required && <span className={styles.required}>&nbsp;*</span>}
        </Typography>
      )}
      <TextField
        className={styles.subText}
        size="medium"
        variant={variant}
        FormHelperTextProps={{ classes: { root: styles.helperRoot } }}
        error={isError}
        {...rest}
      />
    </>
  );
};

export default React.memo(TextInputComponent);
