import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type VenOrderProp = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};
export const vendorOrderService = {
  getAllBy: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllvendorOrders/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },

  getById: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorOrderById/order/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  replace: async ({ data, successMessage, failureMessage }: VenOrderProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceOrder/order`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
