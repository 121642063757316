import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  Grid,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  contentDetails?: any[];
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    leftContentStyle: {
      color: theme.Colors.mediumBlack
    },
    rightContentStyle: {
      color: theme.Colors.mediumGrey
    }
  };
});

const DialogContentDetails = (props: Props) => {
  const { contentDetails } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {contentDetails.length &&
        contentDetails.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={3}>
                <Typography variant="h4" className={classes.leftContentStyle}>
                  {item.content}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h4" className={classes.leftContentStyle}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {item.isLink ? (
                  item?.value?.length ? (
                    item?.value?.map((item: { document_url: string }) => {
                      return (
                        <>
                          <a
                            href={item?.document_url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              opacity: 1,
                              color: '#4285F4',
                              cursor: 'pointer',
                              fontSize: theme.MetricsSizes.small_xxx,
                              fontWeight: theme.fontWeight.regular,
                              textDecoration: 'none'
                            }}
                          >
                            {item?.document_url?.split(
                              'https://dustman-kyc-document.s3.ap-south-1.amazonaws.com/'
                            )}
                          </a>
                          <br />
                        </>
                      );
                    })
                  ) : (
                    <Typography
                      style={{
                        fontSize: theme.MetricsSizes.small_xxx,
                        fontWeight: theme.fontWeight.regular
                      }}
                    >
                      {t('documentNotAvailable')}
                    </Typography>
                  )
                ) : (
                  <Typography
                    variant="h4"
                    className={classes.rightContentStyle}
                  >
                    {item.value}
                  </Typography>
                )}
              </Grid>
            </React.Fragment>
          );
        })}
    </Grid>
  );
};

export default DialogContentDetails;
