import {
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  AddressBook,
  Location,
  DeleteIcon,
  PDFIcon,
  Pincode,
  Banner,
  BannerImage
} from 'src/Assets';
import {
  ContentDisplayTiles,
  Loader,
  UHConfirmModal,
  ItemListingComp
} from 'src/components';
import { CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import PincodeCreateModal from './PincodeCreateModal';
import AddressBoxComp from './AddressBoxComp';
import AddressCreateModal from './AddressCreateModal';
import UHExcelExport from 'src/components/UHExcelExport';
import { getDateFormat } from 'src/Utils';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import RegionListItems from './RegionListItems';
import AddNewRegionModal from './AddNewRegionModal';
import AddNewBannerModal from './AddNewBannerModal';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconButtonContainer: {
      padding: theme.spacing(2, 3, 2)
    },
    iconButtonStyle: {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.Colors.white
      }
    },
    headerStyle: {
      textAlign: 'end',
      border: '1px solid red',
      paddingRight: 40
    },
    listItemClass: {
      width: 400
    }
  };
});

function Settings() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [dustmanLocation, setDustmanLocation] = useState([]);
  const [selectedTab, setSelectedTab] = useState<string | number>(0);
  const [openModal, setOpenModal] = useState<any>({ open: false });
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const [loading, setLoading] = useState<boolean>(true);
  const [openAreaModal, setOpenAreaModal] = useState<any>({ open: false });
  const [openRegionModal, setOpenRegionModal] = useState<any>({ open: false });
  const [openBannerModal, setOpenBannerModal] = useState<any>({ open: false });
  const [pincodeData, setPincodeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [pinCodeAreas, setPinCodeAreas] = useState([]);
  const [bannerData, setBannerData] = useState([]);

  const addressDetailsTabValues = [
    {
      id: 0,
      heading: t('address'),
      subText: '',
      iconImage: AddressBook
    },
    {
      id: 1,
      heading: t('enquiry.pincode'),
      subText: '',
      iconImage: Pincode
    },
    {
      id: 2,
      heading: t('region'),
      subText: '',
      iconImage: Location
    },
    {
      id: 3,
      heading: t('banner'),
      subText: '',
      iconImage: Banner
    }
  ];

  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return value === index && <>{children}</>;
  }

  const handleCreateOrEditAddress = async (item?: any, type?: string) => {
    if (type === CONFIRM_MODAL.edit) {
      setOpenModal({
        open: true,
        locationData: item,
        type: type
      });
      return;
    }
    setOpenModal({ open: true, type: type });
  };

  const handleDeleteAddress = async (item: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      const response: any =
        await API_SERVICES.settingsPageService.deleteAddress(item?.id, {
          successMessage: 'Address deleted successfully!'
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: 'Are you sure want to delete the address?',
      title: 'Delete',
      iconType: CONFIRM_MODAL.delete
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const dataList = [
    {
      heading: 'Pincode',
      pincodeData: pincodeData
    },
    {
      heading: 'Area',
      pincodeData: pincodeData
    },
    {
      heading: 'Enable/Disable',
      pincodeData: pincodeData
    }
  ];

  const handleDeleteListItem = async (item) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      const response: any =
        await API_SERVICES.settingsPageService.deletePincode(item?.id, {
          successMessage: 'Vendor Location deleted successfully',
          failureMessage: 'failure'
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('settings.deleteArea'),
      title: t('delete'),
      iconType: CONFIRM_MODAL.delete
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const handleEnableOrDisableLocation = (item) => {
    console.log('item', item);
  };

  let excelPincodeData =
    pincodeData.length &&
    pincodeData.map((data, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(data?.created_at);
      return {
        id: idx + 1,
        area: data?.area,
        pincode: data?.pincode,
        is_enable: data?.is_enable,
        created_at: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  let excelRegionData =
    regionData?.length &&
    regionData?.map((data, idx) => {
      let area = data?.areas?.map((item) => `${item.area}`);
      return {
        id: idx + 1,
        region: data?.region_name,
        area: area.toString(),
        isAvailable: data?.is_available
      };
    });

  let excelRegionHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Region', key: 'region' },
    { label: 'Area', key: 'area' },
    { label: 'Is Available', key: 'isAvailable' }
  ];

  let excelBannerData =
    bannerData.length &&
    bannerData.map((data, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(data?.created_at);
      return {
        id: idx + 1,
        name: data?.title,
        img: data?.image_url,
        is_enable: data?.is_enable,
        created_at: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  let excelBannerHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Banner Image', key: 'img' },
    { label: 'Is Enable', key: 'is_enable' },
    { label: 'Date', key: 'created_at' }
  ];

  let excelPincodeHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Area', key: 'area' },
    { label: 'Pincode', key: 'pincode' },
    { label: 'IsEnabled', key: 'is_enable' },
    { label: 'Date', key: 'created_at' }
  ];

  const handlePdfClick = (fileName: string) => {
    if (
      !excelPincodeData.length ||
      !excelRegionData.length ||
      !excelBannerData.length
    ) {
      return;
    }
    if (fileName == 'Pincode') {
      let excelData = excelPincodeData;
      let excelHeaders = excelPincodeHeaders;
      //let pdfDoc = new jsPDF('p', 'pt', 'a2');
      let pdfDoc = new jsPDF();
      const pdfHeader = excelHeaders.length
        ? excelHeaders.map((item) => {
            return { header: item.label, dataKey: item.key };
          })
        : [];
      pdfDoc.text(fileName, 10, 10);
      autoTable(pdfDoc, {
        body: excelData,
        columns: pdfHeader,
        styles: {
          cellWidth: 'wrap',
          cellPadding: 4
        },
        margin: { top: 10, vertical: 10, horizontal: 10 },
        tableWidth: 'auto',
        startY: 15,
        horizontalPageBreak: true,
        horizontalPageBreakRepeat: 0
      });
      pdfDoc.save(`${fileName}.pdf`);
    }
    if (fileName == 'Region') {
      let excelData = excelRegionData;
      let excelHeaders = excelRegionHeaders;

      let pdfDoc = new jsPDF();
      const pdfHeader = excelHeaders.map((item) => ({
        header: item.label,
        dataKey: item.key
      }));

      pdfDoc.text(fileName, 10, 10);

      autoTable(pdfDoc, {
        body: excelData,
        columns: pdfHeader,
        styles: {
          cellPadding: 3,
          cellWidth: 'wrap'
        },
        columnStyles: {
          id: { cellWidth: 15 },
          region: { cellWidth: 'auto' },
          area: { cellWidth: 110 },
          isAvailable: { cellWidth: 30 }
        },
        tableWidth: 'wrap',
        margin: { top: 10, left: 10, right: 10 },
        startY: 15,
        pageBreak: 'avoid'
      });

      pdfDoc.save(`${fileName}.pdf`);
    }
    if (fileName == 'Banner') {
      let excelData = excelBannerData;
      let excelHeaders = excelBannerHeaders;
      //let pdfDoc = new jsPDF('p', 'pt', 'a2');
      let pdfDoc = new jsPDF();
      const pdfHeader = excelHeaders.length
        ? excelHeaders.map((item) => {
            return { header: item.label, dataKey: item.key };
          })
        : [];
      pdfDoc.text(fileName, 10, 10);
      autoTable(pdfDoc, {
        body: excelData,
        columns: pdfHeader,
        styles: {
          cellWidth: 'wrap',
          cellPadding: 4
        },
        margin: { top: 10, vertical: 10, horizontal: 10 },
        tableWidth: 'auto',
        startY: 15,
        horizontalPageBreak: true,
        horizontalPageBreakRepeat: 0
      });
      pdfDoc.save(`${fileName}.pdf`);
    }
  };

  const renderIcons = (item) => {
    return (
      <>
        <Checkbox
          edge="start"
          className={classes.iconButtonContainer}
          onChange={() => handleEnableOrDisableLocation(item)}
          checked={item?.is_enable}
          disabled={!item?.is_enable}
        />
        <IconButton
          edge="start"
          className={classes.iconButtonContainer}
          onClick={() => handleDeleteListItem(item)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  const handleAddOrEditBanner = (item?: any, type?: string) => {
    if (type === CONFIRM_MODAL.edit) {
      setOpenBannerModal({
        open: true,
        bannerDetails: item,
        type: type
      });
      return;
    }
    setOpenBannerModal({ open: true, type: type });
  };

  const handleAddOrEditListItem = (item?: any, type?: string) => {
    if (type === CONFIRM_MODAL.edit) {
      setOpenAreaModal({
        open: true,
        areaDetails: item,
        type: type,
        areaData: areaData
      });
      return;
    }
    setOpenAreaModal({ open: true, type: type, areaData: pinCodeAreas });
  };

  const fetchData = async () => {
    try {
      const response: any = await Promise.all([
        API_SERVICES.generalService.getDustmanLocation(),
        API_SERVICES.settingsPageService.getAllPincode(),
        API_SERVICES.regionManagementService.getAllRegion(),
        API_SERVICES.regionManagementService.getAllArea(),
        API_SERVICES.settingsPageService.getAllBanner(),
        API_SERVICES.regionManagementService.getAllAreas()
      ]);
      if (response[0]?.status <= HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.Location) {
          setDustmanLocation(response[0]?.data?.Location);
        }
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[1]?.data?.pincode?.length) {
          setPincodeData(response[1]?.data?.pincode);
        }
      }
      if (response[2]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[2]?.data?.result?.length) {
          setRegionData(response[2]?.data?.result);
        }
      }
      if (response[3]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[3]?.data?.pincode?.length) {
          setAreaData(response[3]?.data?.pincode);
        }
      }
      if (response[4]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[4]?.data?.user?.length) {
          setBannerData(response[4]?.data?.user);
        }
      }
      if (response[5]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[5]?.data?.pincode?.length) {
          setPinCodeAreas(response[5]?.data?.pincode);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrEditRegionListItem = (item?: any, type?: string) => {
    if (type === CONFIRM_MODAL.edit) {
      let newAreas = [...item.areas, ...pinCodeAreas];
      setOpenRegionModal({
        open: true,
        regionDetails: item,
        type: type,
        areaData: newAreas
      });
      return;
    }
    setOpenRegionModal({ open: true, type: type, areaData: pinCodeAreas });
  };

  const handleDeleteBanner = (item?: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      const response: any = await API_SERVICES.settingsPageService.deleteBanner(
        item?.id,
        {
          successMessage: 'Banner deleted successfully!'
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: 'Are you sure want to delete the Banner?',
      title: 'Delete',
      iconType: CONFIRM_MODAL.delete
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const handleDeleteRegion = async (item: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      const response: any =
        await API_SERVICES.regionManagementService.deleteRegion(
          item?.region_id,
          {
            successMessage: 'Region deleted successfully!'
          }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: 'Are you sure want to delete the region?',
      title: 'Delete',
      iconType: CONFIRM_MODAL.delete
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <ContentDisplayTiles
          displayContent={addressDetailsTabValues}
          isTileTypeOrders={true}
          onTabChange={handleSetSelectedTab}
          tabValue={selectedTab}
          withBottomLine
          dividerBgColor={theme.Colors.mediumBlack}
        />
        <TabPanel value={selectedTab} index={0}>
          <AddressBoxComp
            data={dustmanLocation}
            handleCreateAddress={() =>
              handleCreateOrEditAddress({}, CONFIRM_MODAL.create)
            }
            handleDeleteAddress={handleDeleteAddress}
            handleCreateOrEditAddress={(item) =>
              handleCreateOrEditAddress(item, CONFIRM_MODAL.edit)
            }
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Grid item xs={8}>
            <RegionListItems
              regionData={pincodeData}
              excelHeaders={excelPincodeHeaders}
              excelData={excelPincodeData}
              handleAddNewListItem={() =>
                handleAddOrEditListItem('', CONFIRM_MODAL.create)
              }
              handleEditListItem={(item) =>
                handleAddOrEditListItem(item, CONFIRM_MODAL.edit)
              }
              handleDeleteListItem={handleDeleteListItem}
              heading={['Pincode', 'Area', 'Enable/Disable']}
              handlePdfClick={handlePdfClick}
              isPincode
            />
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <RegionListItems
            regionData={regionData}
            excelHeaders={excelRegionHeaders}
            excelData={excelRegionData}
            handleAddNewListItem={() =>
              handleAddOrEditRegionListItem('', CONFIRM_MODAL.create)
            }
            handleEditListItem={(item) =>
              handleAddOrEditRegionListItem(item, CONFIRM_MODAL.edit)
            }
            handleDeleteListItem={handleDeleteRegion}
            heading={['Region', 'Area', 'Enable/Disable']}
            handlePdfClick={handlePdfClick}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <Grid xs={8}>
            <RegionListItems
              regionData={bannerData}
              excelHeaders={excelBannerHeaders}
              excelData={excelBannerData}
              handleAddNewListItem={() =>
                handleAddOrEditBanner('', CONFIRM_MODAL.create)
              }
              handleEditListItem={(item) =>
                handleAddOrEditBanner(item, CONFIRM_MODAL.edit)
              }
              handleDeleteListItem={handleDeleteBanner}
              heading={['Name', 'Banner', 'Enable/Disable']}
              isBanner={true}
            />
          </Grid>
        </TabPanel>
        {openModal.open && (
          <AddressCreateModal
            {...openModal}
            onClose={() => setOpenModal({ open: false })}
            updateData={fetchData}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {openAreaModal.open && (
          <PincodeCreateModal
            {...openAreaModal}
            onClose={() => setOpenAreaModal({ open: false })}
            updateData={fetchData}
          />
        )}
        {openRegionModal.open && (
          <AddNewRegionModal
            {...openRegionModal}
            onClose={() => setOpenRegionModal({ open: false })}
            updateData={fetchData}
          />
        )}
        {openBannerModal?.open && (
          <AddNewBannerModal
            {...openBannerModal}
            onClose={() => setOpenBannerModal({ open: false })}
            updateData={fetchData}
          />
        )}
      </>
    );
  }
}

export default Settings;
