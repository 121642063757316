import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const AdminWalletService = {
  getAllRazorpayTransaction: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllRazorpayTransactionsWithFilters`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getCurrentBalance: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getCurrentBalance`,
      method: 'get',
    });
    return apiRequest(options);
  },
  getAllRazorpayXTransaction: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/transferData`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  }
};
