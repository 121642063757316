import React, { useEffect, useState } from 'react';
import UHTable from 'src/components/UHTable';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  ButtonComp,
  ListItemCell,
  MultipleSelectComp,
  TextInputComponent
} from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import { useTranslation } from 'react-i18next';
import {
  Done,
  ListAlt,
  MoreVert,
  Payment,
  PlaylistAddCheck,
  Search
} from '@material-ui/icons';
import { Chip, Grid, IconButton, InputAdornment } from '@material-ui/core';
import MenuActionComp from 'src/components/MenuActionComp';
import { TableCalendar } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => ({}));

const payoutStatus = ['Processing', 'Completed'];
type Props = {
  handleClickViewDetails?: (row: any, num?: number) => void;
  tableRowData?: any[];
  handleClickPayout?: (row: any, isPayoutButton?: any) => void;
  selectedTab?: any;
  handleChange?: (val: any) => void;
  handleClickAddMoney?: (row: any) => void;
  handleClick?: (row: any) => void;
  edit?: any;
  handleOpenCalendar?: () => void;
};

const CustomerVendorPaymentTable = ({
  handleClickViewDetails,
  handleClickPayout,
  tableRowData,
  selectedTab,
  handleChange,
  edit,
  handleOpenCalendar,
  handleClickAddMoney,
  handleClick
}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedActionRow, setSelectedActionRow] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClickIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowData: any
  ) => {
    if (rowData?.id === selectedActionRow?.id) {
      setSelectedActionRow(null);
      setAnchorEl(null);
    } else {
      setSelectedActionRow(rowData);
      setAnchorEl(e.currentTarget);
    }
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.company_name)} />
      )
    },
    {
      field: 'details',
      headerName: t('vendor.contactName'),
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.contact_person)}
          //avatarImg={row?.image_url || 'DustMan'}
        />
      )
    },
    {
      field: 'order',
      headerName: 'Order No',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.order_no} />
    },
    {
      field: 'orderdate',
      headerName: 'Order Date',
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.orderdate
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },
    {
      field: 'balance',
      headerName: selectedTab == 3 ? 'Total Amount' : 'Account Balance',
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.amount} />
    },
    {
      field: 'status',
      headerName: t('customerVendorList.status'),
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Chip
          label={capitalizeFirstLetter(row?.payout_status)}
          style={{
            backgroundColor:
              row?.payout_status.toLowerCase() == 'processing'
                ? theme.Colors.primary
                : row?.payout_status.toLowerCase() == 'cancelled' ||
                  row?.payout_status.toLowerCase() == 'rejected' ||
                  row?.payout_status.toLowerCase() == 'failed'
                ? theme.Colors.redPrimary
                : row?.payout_status.toLowerCase() == 'pending' ||
                  row?.payout_status.toLowerCase() == 'queued' ||
                  row?.payout_status.toLowerCase() == 'scheduled'
                ? theme.Colors.blueDark
                : row?.payout_status.toLowerCase() == 'reversed'
                ? theme.Colors.grey
                : theme.Colors.secondary,
            color: theme.Colors.whitePure
          }}
        />
      )
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <IconButton onClick={(e) => handleClickIcon(e, row)}>
          <MoreVert />
        </IconButton>
      )
    }
  ];

  const renderRowActions = (rowData: any) => {
    let isPayoutButton =
      rowData?.payout_status.toLowerCase() == 'cancelled' ||
      rowData?.payout_status.toLowerCase() == 'rejected' ||
      rowData?.payout_status.toLowerCase() == 'failed';

    if (
      selectedTab === 3 &&
      rowData?.payout_status.toLowerCase() == 'processing'
    ) {
      return [
        {
          text: isPayoutButton ? 'Payout' : t('viewDetails'),
          renderIcon: () =>
            isPayoutButton ? <PlaylistAddCheck /> : <ListAlt />,
          onClick: () => {
            handleClickPayout(rowData, isPayoutButton);
            handleClose();
          }
        },
        {
          text: 'Payment Status',
          renderIcon: () => <Done />,
          onClick: () => {
            handleClick(rowData);
            handleClose();
          }
        }
      ];
    }
    if (
      selectedTab === 3 &&
      rowData?.payout_status.toLowerCase() == 'completed'
    ) {
      return [
        {
          text: isPayoutButton ? 'Payout' : t('viewDetails'),
          renderIcon: () =>
            isPayoutButton ? <PlaylistAddCheck /> : <ListAlt />,
          onClick: () => {
            handleClickPayout(rowData, isPayoutButton);
            handleClose();
          }
        }
      ];
    }
    if (
      selectedTab === 4 &&
      rowData?.payout_status.toLowerCase() == 'processing'
    ) {
      return [
        {
          text: 'Add Money',
          renderIcon: () => <Payment />,
          onClick: () => {
            handleClickAddMoney(rowData);
            handleClose();
          }
        },
        {
          text: isPayoutButton ? 'Payout' : t('viewDetails'),
          renderIcon: () =>
            isPayoutButton ? <PlaylistAddCheck /> : <ListAlt />,
          onClick: () => {
            handleClickPayout(rowData, isPayoutButton);
            handleClose();
          }
        },
        {
          text: 'Payment Status',
          renderIcon: () => <Done />,
          onClick: () => {
            handleClick(rowData);
            handleClose();
          }
        }
      ];
    }
    if (
      selectedTab === 4 &&
      rowData?.payout_status.toLowerCase() == 'completed'
    ) {
      return [
        {
          text: 'Add Money',
          renderIcon: () => <Payment />,
          onClick: () => {
            handleClickAddMoney(rowData);
            handleClose();
          }
        },
        {
          text: isPayoutButton ? 'Payout' : t('viewDetails'),
          renderIcon: () =>
            isPayoutButton ? <PlaylistAddCheck /> : <ListAlt />,
          onClick: () => {
            handleClickPayout(rowData, isPayoutButton);
            handleClose();
          }
        }
      ];
    }
    return [];
  };

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search"
            value={edit.getValue('search')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    style={{
                      width: 16,
                      height: 16,
                      color: 'rgba(34, 51, 69, 0.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
          />
        </Grid>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
        <Grid item>
          <MultipleSelectComp
            isPlaceholderNone
            placeholderText={'All'}
            selectWidth={150}
            selectBoxStyle={{ marginTop: 0 }}
            selectItems={
              payoutStatus?.length &&
              payoutStatus?.map((item: any) => {
                return {
                  label: item,
                  value: item
                };
              })
            }
            value={edit.getValue('payout_status')}
            borderRadius={40}
            selectHeight={35}
            background={theme.Colors.greyDark}
            iconColor={theme.Colors.primary}
            onChange={(e) => {
              edit.update({ payout_status: e.target.value });
            }}
            displayEmpty
            renderValue={(value: any) => {
              return value ? value : 'Action';
            }}
            required
          />
        </Grid>
      </>
    );
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Name', key: 'name' },
    { label: 'Order No', key: 'order_no' },
    { label: 'Amount', key: 'amount' },
    { label: 'Order Date', key: 'date' }
  ];

  let excelData =
    tableRowData?.length &&
    tableRowData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.orderdate);
      return {
        id: idx + 1,
        company_name: capitalizeFirstLetter(rowData?.company_name),
        name: capitalizeFirstLetter(rowData?.contact_person),
        order_no: `${rowData?.order_no}`,
        amount: `${rowData?.amount}`,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  return (
    <>
      <UHTable
        title={
          selectedTab == 3
            ? t('walletManagement.customerPayment')
            : t('walletManagement.vendorPayout')
        }
        columns={columns}
        rows={tableRowData}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        renderSearchBox={renderSearchBox}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={
          selectedTab == 3
            ? t('walletManagement.customerPayment')
            : t('walletManagement.vendorPayout')
        }
      />
      <MenuActionComp
        open={open}
        selectedActionRow={selectedActionRow}
        rowActions={renderRowActions(selectedActionRow)}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default React.memo(CustomerVendorPaymentTable);
