import React, { useRef, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import {
  ButtonComp,
  DialogComp,
  DualActionButton,
  TextInputComponent
} from 'src/components';
import { UploadBanner } from 'src/Assets';
import { API_SERVICES } from 'src/Services';
import './drag-drop.css';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => {
  return {
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    },
    dialogPaper: {
      width: 580,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    },
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        backgroundColor: theme.Colors.white,
        color: theme.Colors.primary
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: theme.Colors.white
      }
    },
    textStyle: {
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.medium,
      paddingLeft: '0px'
    }
  };
});

const AddNewBannerModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { open, onClose, type, bannerDetails, updateData } = props;
  const [isError, setIsError] = useState(false);
  const dragRef = useRef(null);

  const initialValues = {
    title: bannerDetails?.title || '',
    image_url: bannerDetails?.image_url || '',
    is_enable: bannerDetails?.is_enable || false,
    sub_title: bannerDetails?.sub_title || ''
  };

  const edit = useEdit(initialValues);
  const RequiredFields = ['title', 'image_url'];

  const bannerError = isError && !edit.getValue('title');
  const imgError = isError && !edit.getValue('image_url');
  const checkboxError = isError && !edit.getValue('is_enable');

  const types = {
    [CONFIRM_MODAL.create]: {
      handleType: 1
    },
    [CONFIRM_MODAL.edit]: {
      handleType: 2
    }
  };

  const onBrowseButtonClick = async (event: any) => {
    if (
      event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png'
    ) {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      let img = new Image();
      img.src = window.URL.createObjectURL(event.target.files[0]);
      img.onload = async () => {
        // if (img.width <= 120 && img.height <= 240) {
        const uploadImageRes: any =
          await API_SERVICES.imageUploadService.uploadImage(formData);
        if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (uploadImageRes?.data?.images.length) {
            edit.update({ image_url: uploadImageRes.data.images[0].Location });
          }
        }
        // } else {
        //   alert(`Sorry, this image doesn't look like the size we wanted. It's
        //   ${img.width} x ${img.height} but we require 120 x 240 size image or below this size.`);
        // }
      };
    } else {
      toast.error('Image Only you can Upload');
    }
  };

  const onDragEnter = () => dragRef.current.classList.add('dragover');
  const onDragLeave = () => dragRef.current.classList.remove('dragover');

  const handleContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('banner')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('title')}
              onChange={(e) => {
                edit.update({ title: e.target.value });
              }}
              required
              isError={bannerError}
              helperText={bannerError && 'Please enter the Banner Name'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              border: '1px dashed #E0E0E0',
              borderRadius: 10,
              padding: '30px 20px',
              columnGap: 10
            }}
          >
            <div
              ref={dragRef}
              className="drag-drop"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDragLeave}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}
              >
                <img src={UploadBanner} />
                {edit.getValue('image_url') ? (
                  <img
                    src={edit.getValue('image_url')}
                    width={50}
                    height={50}
                  />
                ) : (
                  <Typography
                    style={{ color: '#979797', fontSize: 18, fontWeight: 400 }}
                  >
                    Browse files or drag and drop
                  </Typography>
                )}
              </div>
              <input
                type={'file'}
                name=""
                id=""
                value={''}
                accept="image/png, image/jpg, image/jpeg"
                onChange={onBrowseButtonClick}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={edit.getValue('is_enable')}
                  onChange={(e) => edit.update({ is_enable: e.target.checked })}
                />
              }
              label={
                <Typography
                  className={classes.textStyle}
                  style={{ color: checkboxError && 'red' }}
                >
                  {t('settings.enableOrDisable')}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleSave = async () => {
    if (!edit.allFilled(...RequiredFields)) {
      setIsError(true);
      return toast.error('Please fill all the required fields');
    } else {
      setIsError(false);
    }
    let data = { ...initialValues, ...edit.edits };
    if (types[type].handleType === 2) {
      const response: any = await API_SERVICES.settingsPageService.updateBanner(
        bannerDetails?.id,
        {
          data: data,
          successMessage: 'Banner edited successfully',
          failureMessage: 'Failed to edit Banner'
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
      }
    } else if (types[type].handleType === 1) {
      const response: any = await API_SERVICES.settingsPageService.createBanner(
        {
          data: data,
          successMessage: 'Banner added successfully',
          failureMessage: 'Failed to create Banner'
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
      }
    }
    onClose();
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleSave}
        disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };
  return (
    <DialogComp
      open={open}
      dialogTitle={
        types[type].handleType === 2
          ? t('settings.editBanner')
          : t('settings.addBanner')
      }
      dialogTitleStyle={{ color: theme.Colors.primary }}
      dialogClasses={{ paper: classes.dialogPaper }}
      onClose={onClose}
      renderDialogContent={handleContent}
      renderAction={renderAction}
    />
  );
};

export default AddNewBannerModal;
