import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { ButtonComp, DialogComp, DialogContentDetails } from 'src/components';
import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetter,
  dateFormate,
  getSortMonth,
  getToday
} from 'src/Utils';
import { API_SERVICES } from 'src/Services';
import {
  CUSTOMER_ORDER_STATUS,
  CUSTOMER_STATUS,
  HTTP_STATUSES
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import ImageHeading from 'src/components/ImageBoxContainer/ImageHeading';
import { Downloading } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    }
  };
});

type Props = {
  onClose: () => void;
  rowData: any;
  handleAssignVendor: (data?: any) => void;
  isHeading?: boolean;
  handleClickDownload?: (data?: any) => void;
};

const CustomerOrderModal = (props: Props) => {
  const {
    onClose,
    rowData,
    handleAssignVendor,
    isHeading,
    handleClickDownload
  } = props;
  const { t, i18n } = useTranslation();

  const getSlot = (val) => {
    if (val.toLowerCase() == 'morning') {
      return '9:00 AM to 12:00 PM';
    } else if (val.toLowerCase() == 'afternoon') {
      return '1:00 PM to 4:00 PM';
    } else {
      return '4:00 PM to 7:00 PM';
    }
  };

  const theme = useTheme();
  const classes = useStyles();
  const [modalAccept, setModalAccept] = useState<any>();
  const renderDialogContent = () => {
    const day = getToday(rowData?.pickup_time);
    const d = dateFormate(rowData?.pickup_time)?.split('-');
    const month = getSortMonth(d[1]);

    const contentDetails = [
      {
        content: 'Order Id',
        value: rowData?.order_id
      },
      {
        content: t('address'),
        value: `${rowData?.address_line1}, ${rowData?.address_line2}, ${rowData?.address_line3}, ${rowData?.city}, ${rowData?.state}, ${rowData?.pincode}`
      },
      {
        content: t('contactNumber'),
        value: rowData?.order_mobile_number
      },
      {
        content: 'Scheduled On',
        value: `${day}, ${d[0]} ${month},  ${getSlot(rowData?.slot)}`
      }
    ];
    const vendorDetails: any = [
      {
        content: 'Accepted Vendor Name',
        value: capitalizeFirstLetter(modalAccept?.vendor_contact_name)
      },
      {
        content: ' Vendor Mobile Number',
        value: modalAccept?.vendor_mobile_number?.slice(2)
      }
    ];
    const assignVendorDetails: any = [
      {
        content: 'Assigned Vendor',
        value: capitalizeFirstLetter(rowData?.vendor_company_name)
      },
      {
        content: ' Vendor Mobile Number',
        value: rowData?.vendor_mobile_number?.slice(2)
      }
    ];
    const fetchData = async () => {
      try {
        const response: any =
          await API_SERVICES.customerOrderService.getAcceptedOrder(rowData.id);
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (response?.data?.order) {
            setModalAccept(response?.data?.order);
          }
        }
      } catch (err) {
        toast.error(err?.message);
      } finally {
      }
    };

    const AcceptDetails: any = useMemo(() => {
      if (CUSTOMER_STATUS[modalAccept?.status_id] === CUSTOMER_STATUS[2]) {
        return contentDetails.concat(vendorDetails);
      } else if (
        CUSTOMER_STATUS[modalAccept?.status_id] === CUSTOMER_STATUS[3]
      ) {
        return contentDetails.concat(vendorDetails);
      } else if (rowData?.status == 1) {
        return contentDetails.concat(assignVendorDetails);
      } else {
        return contentDetails;
      }
    }, [modalAccept]);

    useEffect(() => {
      fetchData();
    }, []);
    return (
      <Grid>
        <DialogContentDetails contentDetails={AcceptDetails} />
        <ImageHeading data={rowData} isHeading={isHeading} />
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        {isHeading ? (
          rowData?.status_id == 0 ? (
            <ButtonComp
              backgroundColor={theme.Colors.secondary}
              height={'35px'}
              buttonText={'Assign Vendor'}
              buttonFontSize={theme.MetricsSizes.small_x}
              buttonTextColor={theme.Colors.white}
              buttonFontWeight={theme.fontWeight.bold}
              btnWidth={'131px'}
              btnBorderRadius={100}
              onClickButton={() => handleAssignVendor(rowData)}
              style={{ margin: 10 }}
            />
          ) : null
        ) : (
          <ButtonComp
            backgroundColor={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? theme.Colors.secondary
                : theme.Colors.primary
            }
            height={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? '35px'
                : '40px'
            }
            buttonText={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? 'Assign Vendor'
                : 'Download Invoice'
            }
            buttonFontSize={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? theme.MetricsSizes.small_x
                : theme.MetricsSizes.regular
            }
            buttonTextColor={theme.Colors.white}
            buttonFontWeight={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? theme.fontWeight.bold
                : theme.fontWeight.medium
            }
            btnWidth={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
                ? '131px'
                : '235px'
            }
            btnBorderRadius={100}
            onClickButton={() => handleClickDownload(rowData)}
            startIcon={
              rowData?.status_id === CUSTOMER_ORDER_STATUS['completed'] ? (
                <img src={Downloading} />
              ) : (
                ''
              )
            }
          />
        )}
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'DustMan'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default CustomerOrderModal;
