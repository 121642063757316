import {
  CustomerManagement,
  CustomerVendorList,
  OverViewIcon,
  ScheduledIcon,
  WalletManagement
} from 'src/Assets/Images';
import { Box } from '@material-ui/core';
import { NavListItem } from 'src/components';
import {
  mdiAccountOutline,
  mdiAccountCogOutline,
  mdiCurrencyRupee,
  mdiHeadset,
  mdiAccountSupervisorOutline,
  mdiReceiptTextCheckOutline
} from '@mdi/js';
import Icon from '@mdi/react';
const routes = [
  {
    name: 'Overview',
    path: '/dashboards/overview',
    iconComponent: () => <OverViewIcon />
    // <Icon path={mdiPoll} size={1} />
  },
  {
    name: 'New Request',
    path: '/dashboards/new-request',
    iconComponent: () => <Icon path={mdiAccountSupervisorOutline} size={1} />
  },
  {
    name: 'Customer Order',
    path: '/dashboards/customer-order',
    iconComponent: () => <ScheduledIcon />
  },
  {
    name: 'Dustman Order',
    path: '/dashboards/dustman-order',
    iconComponent: () => <Icon path={mdiAccountOutline} size={1} />
  },
  {
    name: 'Customer Management',
    path: '/dashboards/customer-management',
    iconComponent: () => <CustomerManagement />
  },
  {
    name: 'Customer & Vendor List',
    path: '/dashboards/customer-vendor-list',
    iconComponent: () => <CustomerVendorList />
  },
  {
    name: 'Wallet Management',
    path: '/dashboards/wallet-management',
    iconComponent: () => <WalletManagement />
  },
  {
    name: 'Admin Wallet',
    path: '/dashboards/admin-wallet',
    iconComponent: () => <WalletManagement />
  },
  {
    name: 'Invoice Management',
    path: '/dashboards/invoice-management',
    iconComponent: () => <Icon path={mdiReceiptTextCheckOutline} size={1} />
  },
  {
    name: 'Price Management',
    path: '/dashboards/price-management',
    iconComponent: () => <Icon path={mdiCurrencyRupee} size={1} />
  },
  {
    name: 'Enquiry Management',
    path: '/dashboards/enquiry-management',
    iconComponent: () => <Icon path={mdiHeadset} size={1} />
  },
  {
    name: 'User Management',
    path: '/dashboards/user-management',
    iconComponent: () => <Icon path={mdiAccountCogOutline} size={1} />
  }
];

const SidebarMenu = () => {
  return (
    <Box>
      <NavListItem routes={routes} />
    </Box>
  );
};

export default SidebarMenu;
