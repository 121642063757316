import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { createVendorData, vendorMgData } from './AdminStub';

type vendorCreate = {
  data: {
    order_management_id: string;
    name: string;
    gst: string;
    full_address: string;
    location: string;
    contact_name: string;
    mobile_number: string;
    landline_number: string;
    email_id: string;
    website: string;
    established_year: string;
    vehicle_owned: number[];
    image_url: string;
    map_url: string;
    pincode: string;
  };
  successMessage?: string;
  failureMessage?: string;
};
type VendorPatch = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};
export const vendorManagementService = {
  create: async ({ data, successMessage, failureMessage }: vendorCreate) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createvendor`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllBy: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/allVendors/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
    //return vendorMgData.vendorMgGet(id);
  },

  getById: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorById/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  update: async (
    id: number,
    vendorId: number,
    { data, successMessage, failureMessage }: VendorPatch
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateVendorProfile/vendor/${vendorId}/vendor_profile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async (
    id: number,
    { data, successMessage, failureMessage }: VendorPatch
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceVendorProfile/vendor_profile/${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  createByAdmin: async ({
    data,
    successMessage,
    failureMessage
  }: vendorCreate) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createVendorByAdmin`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
    //return createVendorData.createVendorGet;
  }
};
