import React, { useState, useEffect, useCallback } from 'react';
import { NewOrder, Completed, Scheduled, Cancelled } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  Heading,
  Loader,
  MultiSelectChip,
  SwitchComponent
} from 'src/components';
import { useTheme } from '@material-ui/core';
import { API_SERVICES } from 'src/Services';
import {
  CUSTOMER_REGIS_COUNT,
  FILTER_CHIPS,
  GET_FILTER_VALUES,
  HTTP_STATUSES,
  ORDERS_COUNT,
  VENDOR_REGIS_COUNT
} from 'src/Config/constant';
import { useTranslation } from 'react-i18next';

function Overview() {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [customerOrderChipFilter, setCustomerOrderChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [vendorOrderChipFilter, setVendorOrderChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [vendorRegisChipFilter, setVendorRegisChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [customerRegisChipFilter, setCustomerRegisChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [customerOrderCount, setCustomerOrderCount] = useState(ORDERS_COUNT);
  const [vendorOrderCount, setVendorOrderCount] = useState(ORDERS_COUNT);
  const [vendorRegisCount, setVendorRegisCount] = useState(VENDOR_REGIS_COUNT);
  const [customerRegisCount, setCustomerRegisCount] =
    useState(CUSTOMER_REGIS_COUNT);
  const [button, setButton] = useState(1);

  const customerOrderDetails = [
    {
      heading: t('newOrder'),
      subText: customerOrderCount.newOrderCount,
      iconImage: NewOrder
    },
    {
      heading: t('assignedOrder'),
      subText: customerOrderCount.acceptedOrderCount,
      iconImage: Scheduled
    },
    {
      heading: t('completed'),
      subText: customerOrderCount.confirmedOrderCount,
      iconImage: Completed
    },
    {
      heading: t('cancelled'),
      subText: customerOrderCount.cancelledOrderCount,
      iconImage: Cancelled
    }
  ];
  const vendorOrderDetails = [
    {
      heading: t('newOrder'),
      subText: vendorOrderCount.newOrderCount,
      iconImage: NewOrder
    },
    {
      heading: t('accepted'),
      subText: vendorOrderCount.acceptedOrderCount,
      iconImage: Scheduled
    },
    {
      heading: t('completed'),
      subText: vendorOrderCount.confirmedOrderCount,
      iconImage: Completed
    },
    {
      heading: t('cancelled'),
      subText: vendorOrderCount.cancelledOrderCount,
      iconImage: Cancelled
    }
  ];

  const vendorRegisDetails = [
    {
      heading: t('overview.newRequest'),
      subText: vendorRegisCount.newRequest,
      iconImage: NewOrder
    },
    {
      heading: t('overview.approved'),
      subText: vendorRegisCount.approved,
      iconImage: Completed
    },
    {
      heading: t('overview.rejected'),
      subText: vendorRegisCount.rejected,
      iconImage: Cancelled
    }
  ];

  const customerRegisDetails = [
    {
      heading: t('overview.newRequest'),
      subText: customerRegisCount.newRequest,
      iconImage: NewOrder
    },
    {
      heading: t('overview.approved'),
      subText: customerRegisCount.approved,
      iconImage: Completed
    },
    {
      heading: t('overview.rejected'),
      subText: customerRegisCount.rejected,
      iconImage: Cancelled
    }
  ];

  const handleChangeCustomerOrderChip = (selectedChipItem: string[]) => {
    setCustomerOrderChipFilter(selectedChipItem);
  };
  const handleChangeVendorOrderChip = (selectedChipItem: string[]) => {
    setVendorOrderChipFilter(selectedChipItem);
  };
  const handleChangeVendorRegisChip = (selectedChipItem: string[]) => {
    if (button == 1) {
      setCustomerRegisChipFilter(selectedChipItem);
    } else {
      setVendorRegisChipFilter(selectedChipItem);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const response: any = await Promise.all([
        API_SERVICES.overViewServices.getCustomerOrderOverView(
          GET_FILTER_VALUES[customerOrderChipFilter[0]]
        ),
        API_SERVICES.overViewServices.vendorOrderOverView(
          GET_FILTER_VALUES[vendorOrderChipFilter[0]]
        ),
        API_SERVICES.overViewServices.vendorRequestsOverView(
          GET_FILTER_VALUES[vendorRegisChipFilter[0]]
        ),
        API_SERVICES.overViewServices.customerRequestOverView(
          GET_FILTER_VALUES[customerRegisChipFilter[0]]
        )
      ]);

      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.customerOrderCount) {
          setCustomerOrderCount(response[0]?.data?.customerOrderCount);
        }
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[1]?.data?.vendorOrdersCount) {
          setVendorOrderCount(response[1]?.data?.vendorOrdersCount);
        }
      }
      if (response[2]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[2]?.data?.vendorRequestCount) {
          setVendorRegisCount(response[2]?.data?.vendorRequestCount);
        }
      }
      if (response[3]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[3]?.data?.customerRequestCount) {
          setCustomerRegisCount(response[3]?.data?.customerRequestCount);
        }
      }
    } catch (err) {
      console.log(err?.message, '---error overview---');
    } finally {
      setLoading(false);
    }
  }, [
    customerOrderChipFilter,
    vendorOrderChipFilter,
    vendorRegisChipFilter,
    customerRegisChipFilter
  ]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);  

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <SwitchComponent
          handleClick={(val) => setButton(val)}
          buttonValue={button}
          setButtonValue={setButton}
        />
        <Heading
          headingText={
            button == 1
              ? `${t('overview.newRequest')} from Customer`
              : `${t('overview.newRequest')} from Vendor`
          }
        />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={
            button == 1 ? customerRegisChipFilter : vendorRegisChipFilter
          }
          handleChange={handleChangeVendorRegisChip}
          chipStyle={{
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={
            button == 1 ? customerRegisDetails : vendorRegisDetails
          }
          isTileTypeOrders={true}
          withBottomLine={true}
        />
        <Heading headingText={t('customerOrders')} headingMargin={10} />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={customerOrderChipFilter}
          handleChange={handleChangeCustomerOrderChip}
          chipStyle={{
            //cannot apply makestyle
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={customerOrderDetails}
          isTileTypeOrders={true}
          withBottomLine={true}
        />
        <Heading headingText={t('overview.vendorOrders')} headingMargin={10}/>
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={vendorOrderChipFilter}
          handleChange={handleChangeVendorOrderChip}
          chipStyle={{
            //cannot apply makestyle
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={vendorOrderDetails}
          isTileTypeOrders={true}
        />
      </>
    );
  }
}

export default Overview;
