import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { PriceSheet, StockAvatar } from 'src/Assets';
import { ContentDisplayTiles, ListItemCell } from 'src/components';
import AssignedCustomerTable from './AssignedCustomerTable';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import StockUpdate from './StockUpdate';
import OrderHistory from './OrderHistory';
import VendorCommission from './VendorCommission';

const useStyles = makeStyles((theme) => {
  return {
    avatarStyle: {
      width: 64,
      height: 64
    }
  };
});

const VendorDetails = () => {
  const { state }: any = useLocation();
  console.log('location', state);
  const rowData: any = state?.rowData;
  const tab: any = state?.tab;
  console.log('tab', tab);

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  const vendorTabValues = [
    {
      heading: t('customerVendorList.assignedCustomer'),
      iconImage: PriceSheet,
      value: 0
    },
    {
      heading: t('customerVendorList.stockUpdate'),
      iconImage: PriceSheet,
      value: 1
    },
    {
      heading: t('customerVendorList.orderHistory'),
      iconImage: PriceSheet,
      value: 2
    },
    {
      heading: t('customerVendorList.commission'),
      iconImage: PriceSheet,
      value: 3
    }
  ];

  const [selectedTab, setSelectedTab] = useState<string | number>(
    tab ? tab : vendorTabValues[0].value
  );
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [vendorStock, setVendorStock] = useState<any>([]);
  const [orderHistory, setOrderHistory] = useState<any>();
  const [commissionData, setCommissionData] = useState<any>({});

  const fetchData = async () => {
    try {
      setCustomerDetails([]);
      setVendorStock([]);
      let region: any = { region_id: rowData?.vendor_id };
      const response: any = await Promise.all([
        API_SERVICES.customerVendorListService.listAssignedCustomers(
          rowData?.vendor_id
        ),
        API_SERVICES.customerVendorListService.getVendorStockDetails(
          rowData?.vendor_id
        ),
        API_SERVICES.customerVendorListService.getVendorOrderHistory(
          rowData?.vendor_id
        ),
        API_SERVICES.customerVendorListService.getVendorCommission(
          rowData?.vendor_id
        )
      ]);
      console.log('rrrrrrrrrrrrrrrrrrrrr', response[3]);

      if (
        response[0]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[0]?.data?.data?.length
      ) {
        setCustomerDetails(response[0]?.data?.data);
      }
      if (
        response[1]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[1]?.data?.stockDetails?.combined_data?.length
      ) {
        setVendorStock(response[1]?.data?.stockDetails?.combined_data);
      }
      if (
        response[2]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[2]?.data
      ) {
        setOrderHistory(response[2]?.data);
      }
      if (
        response[3]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[3]?.data
      ) {
        setCommissionData(response[3]?.data?.commissionDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const data = [
    {
      id: 1,
      name: 'GeneralWaste',
      image_url: StockAvatar,
      commission: 20
    },
    {
      id: 2,
      name: 'General Waste',
      image_url: StockAvatar,
      commission: 50
    },
    {
      id: 3,
      name: 'Paper Waste',
      image_url: StockAvatar,
      commission: 40
    }
  ];

  function TabPanel(props) {
    const { children, value, index } = props;

    return value === index && <>{children}</>;
  }
  const handleSelectedTab = (value: number | string) => {
    setSelectedTab(value);
  };
  return (
    <>
      <ListItemCell
        avatarImg={rowData?.image_url || 'DustMan'}
        title={rowData?.contact_name}
        titleStyle={{
          fontSize: theme.MetricsSizes.medium_xx,
          color: theme.Colors.blackPrimary,
          fontWeight: theme.fontWeight.medium
        }}
        avatarClassNameStyles={styles.avatarStyle}
      />
      <ContentDisplayTiles
        displayContent={vendorTabValues}
        isTileTypeOrders={true}
        onTabChange={handleSelectedTab}
        tabValue={selectedTab}
        withBottomLine
      />
      <TabPanel value={selectedTab} index={0}>
        {customerDetails?.length ? (
          <AssignedCustomerTable tableRowData={customerDetails} />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h2" style={{ color: theme.Colors.primary }}>
              Admin didn't assign any customer for this vendor
            </Typography>
          </div>
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <StockUpdate
          vendor={true}
          headingText={t('customerVendorList.vendorStock')}
          stockDetails={vendorStock}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <OrderHistory orderHistory={orderHistory} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <VendorCommission rowData={rowData} />
      </TabPanel>
    </>
  );
};

export default VendorDetails;
