import React from 'react';
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { BannerImage, DeleteIcon, PDFIcon } from 'src/Assets';
import EditIcon from '@material-ui/icons/Edit';
import UHExcelExport from 'src/components/UHExcelExport';

const useStyles = makeStyles((theme) => {
  return {
    headingContainer: {
      padding: theme.spacing(1),
      background: theme.Colors.whitePrimary,
      borderBottom: '3px solid',
      borderBottomColor: theme.Colors.accentGrey
    },
    listItemContainer: {
      padding: theme.spacing(1),
      //   borderBottom: '1px solid',
      //   borderBottomColor: theme.Colors.accentGrey,
      marginRight: 80
    },
    headingStyle: {
      fontSize: theme.MetricsSizes.regular,
      fontWeight: theme.fontWeight.bold,
      color: theme.Colors.primary
    },
    addNewContainer: {
      padding: theme.spacing(1),
      borderBottom: '1px solid',
      borderBottomColor: theme.Colors.accentGrey,
      background: theme.Colors.lightShadeGrey,
      cursor: 'pointer'
    },
    addNewStyle: {
      fontSize: theme.MetricsSizes.small_xxx,
      fontWeight: theme.fontWeight.mediumBold,
      color: theme.Colors.primary
    },
    iconButtonStyle: {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.Colors.white
      }
    },
    listItemsStyle: {
      fontSize: theme.MetricsSizes.regular,
      fontWeight: theme.fontWeight.regular,
      color: theme.Colors.greyPrimary,
      textWarp: 'wrap'
    }
  };
});

type regionProps = {
  handleDeleteListItem?: (item?: any) => void;
  handleAddNewListItem?: () => void;
  handleEditListItem?: (item?: any) => void;
  handleEnableOrDisableLocation?: (item?: any) => void;
  regionData: any[];
  excelData: any;
  excelHeaders: any;
  handlePdfClick?: (name?: string) => void;
  heading: any[];
  isBanner?: boolean;
  isPincode?: boolean;
};

const RegionListItems = (props: regionProps) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    handleDeleteListItem,
    handleAddNewListItem,
    handleEditListItem,
    handleEnableOrDisableLocation,
    regionData,
    excelData,
    excelHeaders,
    handlePdfClick,
    heading,
    isBanner = false,
    isPincode
  } = props;

  return (
    <Grid>
      <Grid container style={{ paddingTop: 30 }}>
        {heading?.length &&
          heading?.map((item, idx) => (
            <Grid
              item
              xs={
                isBanner
                  ? idx == 1
                    ? 6
                    : 3
                  : isPincode
                  ? idx == 1
                    ? 5
                    : 3
                  : idx == 1
                  ? 7
                  : 2
              }
              className={styles.headingContainer}
              key={idx}
            >
              <Typography className={styles.headingStyle}>{item}</Typography>
            </Grid>
          ))}
      </Grid>
      <Grid item>
        <List disablePadding>
          {regionData?.length
            ? regionData?.map((item) => (
                <>
                  <ListItem className={styles.listItemContainer}>
                    <Grid item xs={isBanner || isPincode ? 3 : 2}>
                      <ListItemText
                        primary={
                          isPincode
                            ? item.pincode
                            : item.region_name || item.title
                        }
                        className={styles.listItemsStyle}
                        disableTypography={true}
                        style={{
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word'
                        }}
                      />
                    </Grid>
                    {isBanner ? (
                      <img src={item.image_url} width={110} height={50} />
                    ) : isPincode ? (
                      <Grid item xs={5}>
                        <ListItemText
                          primary={item.area}
                          className={styles.listItemsStyle}
                          disableTypography={true}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        container
                        item
                        xs={7}
                        style={{
                          flexWrap: 'wrap'
                        }}
                      >
                        {item.areas?.length &&
                          item.areas?.map(
                            (area: { area: any }, idx: React.Key) => (
                              <Grid item key={idx}>
                                <ListItemText
                                  primary={`${area.area},`}
                                  className={styles.listItemsStyle}
                                  disableTypography={true}
                                />
                              </Grid>
                            )
                          )}
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <ListItemSecondaryAction
                        style={{
                          paddingRight: isBanner ? 0 : isPincode ? 60 : 80
                        }}
                      >
                        <Checkbox
                          edge="start"
                          onChange={() => handleEnableOrDisableLocation(item)}
                          checked={item?.is_available || item?.is_enable}
                          disabled={
                            isPincode || isBanner
                              ? !item?.is_enable
                              : !item?.is_available
                          }
                          style={{ marginRight: 15 }}
                        />
                        <IconButton
                          edge="start"
                          onClick={() => handleDeleteListItem(item)}
                          style={{ marginRight: 15 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          onClick={() => handleEditListItem(item)}
                          style={{ marginRight: 15 }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </Grid>
                  </ListItem>
                  <hr style={{ width: isBanner ? '100%' : '91.7%' }} />
                </>
              ))
            : null}
          <Grid container>
            <Grid item xs={isBanner || isPincode ? 3 : 2}>
              <ListItem className={styles.addNewContainer}>
                <ListItemText
                  className={styles.addNewStyle}
                  primary={isBanner ? '+ ADD BANNER' : '+ ADD NEW'}
                  disableTypography={true}
                  onClick={handleAddNewListItem}
                />
              </ListItem>
            </Grid>
            {isBanner ? null : (
              <Grid item xs={8}>
                <ListItem>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      style={{ display: 'flex' }}
                      className={styles.iconButtonStyle}
                    >
                      <UHExcelExport
                        excelData={excelData}
                        excelHeaders={excelHeaders}
                        fileName={'Region'}
                      />
                      <IconButton
                        onClick={() =>
                          handlePdfClick(isPincode ? 'Pincode' : 'Region')
                        }
                        className={styles.iconButtonStyle}
                      >
                        <img src={PDFIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
            )}
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

export default RegionListItems;
