import { useState } from 'react';
import { PriceSheet } from 'src/Assets/Images';
import CustomerTrashCategory from './customerTrashCategory';
import { useTranslation } from 'react-i18next';
import VendorTrashCategory from './vendorTrashCategory';
import CustomerVendorMarketPrice from './CustomerVendorMarketPrice';
import { ContentDisplayTiles } from 'src/components';

function PriceManagement() {
  const [tabToDisplay, setTabToDisplay] = useState(0);
  const { t } = useTranslation();

  const handleSetSelectedTab = (value) => {
    setTabToDisplay(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return value === index && <>{children}</>;
  }

  const detailsToDisplay = [
    {
      id: 1,
      heading: t('customerTrash'),
      subText: t('category'),
      iconImage: PriceSheet
    },
    {
      id: 2,
      heading: t('vendorTrashCategory'),
      subText: t('category'),
      iconImage: PriceSheet
    },
    {
      id: 3,
      heading: t('marketPrice'),
      subText: '',
      iconImage: PriceSheet
    }
  ];

  return (
    <>
      <ContentDisplayTiles
        displayContent={detailsToDisplay}
        isTileTypeOrders={false}
        onTabChange={handleSetSelectedTab}
        withBottomLine
        tabWidth={true}
      />
      <TabPanel value={tabToDisplay} index={0}>
        <CustomerTrashCategory />
      </TabPanel>
      <TabPanel value={tabToDisplay} index={1}>
        <VendorTrashCategory />
      </TabPanel>
      <TabPanel value={tabToDisplay} index={2}>
        <CustomerVendorMarketPrice />
      </TabPanel>
    </>
  );
}

export default PriceManagement;
