import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type assignVendorProps = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const customerManagementService = {
  getAllCustomerManageMent: async (id?: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerManageMent/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  assignVendor: async ({
    data,
    successMessage,
    failureMessage
  }: assignVendorProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/assignVendor`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  
  customerMovetoDustman: async ({
    data,
    successMessage,
    failureMessage
  }: assignVendorProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/moverToDustmanCustomer`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  rejectCustomerByVendor: async ({
    data,
    successMessage,
    failureMessage
  }: assignVendorProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateAssignCustomerByVendor`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }

};
