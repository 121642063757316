import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { DefaultProp } from './customerOrderService';
import {
  createTrashData,
  highLightCustomerOrderData,
  priceData,
  trashData
} from './AdminStub';

type TrashProp = {
  data: {
    image_url: string;
    name: string;
    price: number;
    distance_price: number;
    distance_km: number;
  };
  successMessage?: string;
  failureMessage?: string;
};

export const priceMgntService = {
  getAllTrashCategory: async (
    categoryTypeId: number,
    userTypeId: number,
    type: number,
    regionId: number,
    params?: any
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categories/categoryType/${categoryTypeId}/UserType/${userTypeId}/type/${type}/region/${regionId}/categoryItems/getAllCategory`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
    //return trashData.trashDataGet(categoryTypeId,userTypeId);
  },
  // categoryType/1/UserType/1/type/1/region/1/categoryItems/getAllCategory
  createTrashCategory: async (
    categoryTypeId: number,
    userTypeId: number,
    type: number,
    { data, successMessage, failureMessage }: TrashProp,
    params?: any
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categories/categoryType/${categoryTypeId}/UserType/${userTypeId}/type/${type}/categoryItems/create`,
      method: 'post',
      data: data,
      params: params
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
    //return createTrashData.trashDataCreate;
  },

  getById: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categories/getCategoryById/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  replaceTrashCategory: async (
    id: number,
    { data, successMessage, failureMessage }: TrashProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categories/replaceCategory/${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, { data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categories/updateCategory/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllOrderPrice: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllOrderPrice`,
      method: 'get'
    });
    return apiRequest(options);
    //return priceData.priceDataGet;
  },

  replaceOrderPrice: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replace/replaceOrderPrice/ ${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllHighLightCustomerOrder: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllHighLightCustomerOrder`,
      //   headers: ADDITIONAL_HEADERS,
      method: 'get'
    });
    return apiRequest(options);
    //return highLightCustomerOrderData.OrderDataGet;
  },

  replaceHighLightCustomerOrder: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceHighLightCustomerOrder/ ${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
