import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import toast from 'react-hot-toast';
import { NewOrder } from 'src/Assets';
import {
  ButtonComp,
  ContentDisplayTiles,
  ListItemCell,
  UHTable
} from 'src/components';
import { API_SERVICES } from 'src/Services';
import VendorListModal from './VendorListModal';
import { capitalizeFirstLetter } from 'src/Utils';

const VendorReport = () => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<string | number>(0);
  const [sortData, setSortData] = useState(false);
  const [rows, setRows] = useState([]);
  const [vendorCount, setVendorCount] = useState(0);
  const [openModal, setOpenModal] = useState<any>({ open: false });
  const [tableData, setTableData] = useState([]);

  const vendorDetailsTabValues = [
    {
      id: 0,
      heading: 'Total Vendor',
      subText: vendorCount,
      iconImage: NewOrder
    }
  ];

  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
  };
  const handleModalClick = (rowData) => {
    setOpenModal({ open: true, rowData: rowData });
  };
  const fetchData = useCallback(async () => {
    try {
      const response: any =
        await API_SERVICES.vendorListService.getAllVendorList();

      setRows(response?.data?.allVendors);
      setVendorCount(response?.data?.vendorCount);
    } catch (err) {
      toast.error(err?.message);
    }
  }, [rows]);

  useEffect(() => {
    if (rows?.length) {
      let newList = rows;
      if (!sortData) {
        newList?.sort((current, next) => {
          return current.updated_at.localeCompare(next.updated_at);
        });
      }
      if (sortData) {
        newList?.sort((current, next) => {
          return next.updated_at.localeCompare(current.updated_at);
        });
      }
      setTableData([...newList]);
    } else {
      setTableData([]);
    }
  }, [rows, sortData]);

  useEffect(() => {
    fetchData();
  }, []);
  let excelHeaders = [
    { label: 'Contact Name', key: 'contact_name' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Email Id', key: 'email_id' },
    { label: 'Location', key: 'location' },
    { label: 'Pincode', key: 'pincode' },
    { label: 'Mobile Number', key: 'registered_mobile_number' },
    { label: 'Full Address', key: 'full_address' }
  ];

  let excelData =
    rows?.length &&
    rows.map((rowData) => {
      return {
        contact_name: rowData?.contact_name,
        name: rowData?.name,
        email_id: rowData?.email_id,
        location: rowData?.location,
        pincode: rowData?.pincode,
        registered_mobile_number: rowData?.registered_mobile_number?.slice(2),
        full_address: rowData?.full_address
      };
    });

  const columns = [
    {
      field: 'S.No',
      headerName: '',
      sortable: false,
      flex: 1,
      type: 'number',
      disableColumnMenu: true,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
    },
    {
      field: 'company_name',
      headerName: 'Company Name',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.company_name)} />
      )
    },
    {
      field: 'GSTNumber',
      headerName: 'GST Number',
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.gst} />
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.location} subTitle={row?.pincode} />
      )
    },
    {
      field: 'mobile_number',
      headerName: 'Mobile Number',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.registered_mobile_number.slice(2)} />
      )
    },
    {
      field: 'kgs',
      headerName: 'Kgs',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.quantity_kg} />
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ButtonComp
          buttonText="View"
          buttonFontSize={theme.MetricsSizes.small_x}
          btnBorderRadius={theme.MetricsSizes.regular_x}
          btnWidth={71}
          height={theme.MetricsSizes.regular_xxx}
          onClick={() => handleModalClick(row)}
        />
      )
    }
  ];
  const onSortClick = () => {
    setSortData(!sortData);
  };

  return (
    <>
      <ContentDisplayTiles
        displayContent={vendorDetailsTabValues}
        isTileTypeOrders={true}
        onTabChange={handleSetSelectedTab}
        tabValue={selectedTab}
      />
      <UHTable
        checkboxSelection={false}
        columns={columns}
        rows={tableData}
        autoHeight={true}
        onSortClick={onSortClick}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={'Vendor List'}
      />
      {openModal.open && (
        <VendorListModal
          onClose={() => setOpenModal({ open: false })}
          {...openModal}
        />
      )}
    </>
  );
};

export default VendorReport;
