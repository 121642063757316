import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import {
  DialogComp,
  TextInputComponent,
  MultipleSelectComp,
  DualActionButton
} from 'src/components';
import { useEdit } from 'src/hooks/useEdit';

const useStyles = makeStyles((theme) => {
  return {
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    },
    dialogPaper: {
      width: 580,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    },
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        backgroundColor: theme.Colors.white,
        color: theme.Colors.primary
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: theme.Colors.white
      }
    },
    textStyle: {
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.medium,
      paddingLeft: '0px'
    }
  };
});

const AddNewRegionModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { open, onClose, type, regionDetails, areaData, updateData } = props;
  const [isError, setIsError] = useState(false);

  const initialValues = {
    region_name: regionDetails?.region_name || '',
    area_ids: regionDetails?.areas?.map((item) => item?.id) || [],
    is_available: regionDetails?.is_available || false
  };

  const edit = useEdit(initialValues);
  const RequiredFields = ['region_name', 'area_ids'];

  const regionError = isError && !edit.getValue('region_name');
  const areaError = isError && !edit.getValue('area_ids')?.length;
  const checkboxError = isError && !edit.getValue('is_available');

  const types = {
    [CONFIRM_MODAL.create]: {
      handleType: 1
    },
    [CONFIRM_MODAL.edit]: {
      handleType: 2
    }
  };

  const handleSave = async () => {
    if (!edit.allFilled(...RequiredFields)) {
      setIsError(true);
      return toast.error('Please fill all the required fields');
    } else {
      setIsError(false);
    }
    let data = { ...initialValues, ...edit.edits };
    let updatedData = {
      area_ids: edit.edits.area_ids,
      is_available: edit.edits.is_available
    };
    if (types[type].handleType === 2) {
      const response: any =
        await API_SERVICES.regionManagementService.updateRegion(
          regionDetails?.region_id,
          {
            data: updatedData,
            successMessage: 'Region edited successfully',
            failureMessage: 'Failed to edit Region'
          }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
      }
    } else if (types[type].handleType === 1) {
      const response: any =
        await API_SERVICES.regionManagementService.createRegion({
          data: data,
          successMessage: 'Region added successfully',
          failureMessage: 'Failed to create Region'
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
      }
    }
    onClose();
  };

  const renderValue = (selected: any[]) => {
    if (!selected.length) {
      return 'Select Area';
    }
    return (
      <>
        {selected?.length &&
          selected?.map((item, idx) => (
            <span key={idx} style={{ paddingRight: 5 }}>
              {areaData?.length &&
                (areaData?.filter((data) => data.id == item)[0]
                  ?.area as string[])}
            </span>
          ))}
      </>
    );
  };

  const handleContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('region')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('region_name')}
              onChange={(e) => {
                edit.update({ region_name: e.target.value });
              }}
              required
              isError={regionError}
              helperText={regionError && 'Please enter the Region'}
              disabled={types[type].handleType === 2}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleSelectComp
              multiple={true}
              value={edit.getValue('area_ids')}
              onChange={(e: any) => {
                edit.update({ area_ids: e.target.value });
              }}
              displayEmpty
              renderValue={renderValue}
              titleText={t('settings.area')}
              selectItems={
                areaData?.length &&
                areaData?.map((item) => ({
                  label: item.area,
                  value: item.id
                }))
              }
              required
              isCheckbox={true}
              isError={areaError}
              helperText={areaError && 'Please select at least one area'}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={edit.getValue('is_available')}
                  onChange={(e) =>
                    edit.update({ is_available: e.target.checked })
                  }
                />
              }
              label={
                <Typography
                  className={classes.textStyle}
                  style={{ color: checkboxError && 'red' }}
                >
                  {t('settings.enableOrDisable')}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleSave}
        disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };
  return (
    <DialogComp
      open={open}
      dialogTitle={
        types[type].handleType === 2
          ? t('settings.editRegion')
          : t('settings.addRegion')
      }
      dialogTitleStyle={{ color: theme.Colors.primary }}
      dialogClasses={{ paper: classes.dialogPaper }}
      onClose={onClose}
      renderDialogContent={handleContent}
      renderAction={renderAction}
    />
  );
};

export default AddNewRegionModal;
