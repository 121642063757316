import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { Cancel, ListAlt } from '@material-ui/icons';
import {
  ButtonComp,
  TextInputComponent,
  ListItemCell,
  UHTable,
  MultipleSelectComp
} from 'src/components';
import { capitalizeFirstLetter, dateFormate, getDateFormat } from 'src/Utils';
import { VENDOR_STATUS, USER_TYPE_ID, ORDER_STATUS } from 'src/Config/constant';
import { useTranslation } from 'react-i18next';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Compass, TableCalendar } from 'src/Assets';
import { ChipComp } from 'src/components/MultiSelectChip/ChipComp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      },
      '& .MuiChip-label': {
        fontSize: theme.MetricsSizes.tiny_xx,
        fontWeight: theme.fontWeight.regular
      },
      '& .MuiChip-root': {
        fontSize: theme.MetricsSizes.tiny_xx,
        fontWeight: theme.fontWeight.regular
      }
    },
    chipStyle: {
      display: 'flex',
      padding: theme.MetricsSizes.tiny
    },
    tooltipStyle: {
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      color: theme.Colors.neutralBlack,
      padding: 12,
      borderRadius: 8
    },
    arrowStyle: {
      color: theme.Colors.white
    }
  })
);

type Props = {
  onClickActionButton?: (row?: any, selIds?: number[]) => void;
  onClickViewOrder?: (row: any) => void;
  onClickGenerateOtp?: (row: any) => void;
  tableRowData?: any[];
  onClickCancelOrder?: (row: any) => void;
  renderActionBtn?: boolean;
  handleOpenCalendar?: () => void;
  edit?: any;
  vendorOrderChipFilter?: string;
  selectedTab?: any;
  handleChangeStatus?: (val?: any) => void;
  statusType: any[];
};

const VendorTable = ({
  onClickActionButton,
  onClickViewOrder,
  onClickCancelOrder,
  onClickGenerateOtp,
  tableRowData,
  renderActionBtn = false,
  edit,
  handleOpenCalendar,
  vendorOrderChipFilter,
  selectedTab,
  handleChangeStatus,
  statusType
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  const onGetSelectedRows = (selectedIDs: number[]) => {
    setSelectedRows(selectedIDs);
  };

  const chipStyleColor = (userType: any) => {
    if (userType === USER_TYPE_ID.commercial) {
      return 'paleturquoise';
    } else if (userType === USER_TYPE_ID.industry) {
      return 'peachpuff';
    } else if (userType === USER_TYPE_ID.vendorPickup) {
      return 'beige';
    } else if (userType === USER_TYPE_ID.vendorDrop) {
      return 'yellowGreen';
    }
  };
  const columns = [
    {
      field: 'orderType',
      headerName: t('vendor.orderType'),
      flex: 1.4,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        return (
          <Grid>
            <Typography style={{ textDecoration: 'underline' }}>
              {row?.order_type}
            </Typography>
            <Typography variant="subtitle2" className={classes.root}>
              <ChipComp
                label={row?.user_type}
                className={classes.chipStyle}
                size="small"
                variant="default"
                style={{
                  backgroundColor: chipStyleColor(row?.user_type_id)
                }}
              />
            </Typography>
          </Grid>
        );
      }
    },
    {
      field: 'orderDetails',
      headerName: t('orderDetails'),
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={`Order ${row?.id}`}
          subTitle={capitalizeFirstLetter(row?.contact_name) || row?.first_name}
        />
      )
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 1.4,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.city)}
          subTitle={row?.pincode}
        />
      )
    },
    {
      field: 'order',
      headerName: t('order'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        const totalCategoryQuantity = row?.order_item.reduce(
          (total: any, item: { category_quantity: any }) =>
            total + item.category_quantity,
          0
        );
        const categoryNames = row?.order_item.map(
          (item: { category_name: any }) => item.category_name
        );
        return (
          <Tooltip
            title={
              <>
                {row?.order_item.map((item, idx) => (
                  <Grid key={idx}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: theme.fontWeight.medium
                      }}
                    >
                      {`${item.category_name} - ${item.category_quantity} Kgs`}
                    </Typography>
                  </Grid>
                ))}
              </>
            }
            arrow
            placement="top"
            classes={{
              tooltip: classes.tooltipStyle,
              arrow: classes.arrowStyle
            }}
          >
            <div>
              <ListItemCell
                title={`${totalCategoryQuantity} Kgs`}
                subTitle={categoryNames?.toString()}
                // isBadgeEnable={
                //   row?.status_id === CUSTOMER_ORDER_STATUS['newOrder'] &&
                //   highlightWeight !== null &&
                //   totalCategoryQuantity >= highlightWeight
                // }
              />
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'updated_at',
      headerName: t('date'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.created_at
        );
        console.log('row?.created_at', row?.created_at);

        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1.7,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        let disableBtn =
          selectedRows.length > 1 && selectedRows.includes(row.id);
        return (
          <ButtonComp
            btnBorderRadius={theme.MetricsSizes.regular_x}
            buttonText={
              selectedTab == 'scheduled' && row?.is_ready_to_upload
                ? 'Pending Invoice'
                : row?.status_id == ORDER_STATUS.pending
                ? 'Pending'
                : row?.status_id == ORDER_STATUS.confirmed
                ? 'Vehicle Alloted'
                : row?.status_id == ORDER_STATUS.overdue
                ? 'Over Due'
                : row?.status_id == ORDER_STATUS.newOrder
                ? t('vendorOrder.accept')
                : t('button.view')
              // selectedTab == 'scheduled' && row?.is_ready_to_upload
              //   ? 'Pending Invoice'
              //   : VENDOR_STATUS[row?.status_id] !== VENDOR_STATUS[0] &&
              //     VENDOR_STATUS[row?.status_id] !== VENDOR_STATUS[1]
              //   ? t('button.view')
              //   : t('vendorOrder.accept')
            }
            disabled={disableBtn && renderActionBtn}
            buttonFontSize={theme.MetricsSizes.small_x}
            btnWidth={111}
            height={theme.MetricsSizes.regular_xxx}
            onClickButton={() => onClickActionButton(row)}
            backgroundColor={
              selectedTab == 'scheduled' && row?.is_ready_to_upload
                ? '#8a00c2'
                : row?.status_id == ORDER_STATUS.pending
                ? theme.Colors.primary
                : row?.status_id == ORDER_STATUS.overdue
                ? theme.Colors.orangeSecondary
                : row?.status == 1
                ? '#C4C4C4'
                : theme.Colors.secondary
            }
          />
        );
      }
    }
  ];
  const classes = useStyles();

  const renderRowActions = (rowData: any) => {
    if (
      VENDOR_STATUS[rowData?.status_id] === VENDOR_STATUS[0] ||
      VENDOR_STATUS[rowData?.status_id] === VENDOR_STATUS[1]
    ) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('cancelOrder'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        }
      ];
    } else if (selectedTab == 'scheduled' && rowData?.is_ready_to_upload) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('customerOrder.cancel'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        }
      ];
    } else if (selectedTab == 'scheduled') {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('vendorOrder.generateOtp'),
          renderIcon: () => <VpnKeyIcon />,
          onClick: onClickGenerateOtp
        },
        {
          text: t('customerOrder.cancel'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        }
      ];
    } else {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        }
      ];
    }
  };

  const onSortClick = () => {
    setSortData(!sortData);
  };

  let excelHeaders = [
    { label: 'Contact Name', key: 'contact_name' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Order ID', key: 'orderDetails' },
    { label: 'Location', key: 'location' },
    { label: 'Quantity Kg', key: 'quantity_kg' },
    { label: 'Mobile Number', key: 'order_mobile_number' },
    { label: 'Date', key: 'date' }
  ];

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData) => {
      let data = rowData?.order_item?.map((item: any) => {
        return [item.category_quantity];
      });
      let qty = data.flat();
      const totalQty = qty.reduce((acc: any, curr: any) => acc + curr, 0);
      return {
        contact_name: capitalizeFirstLetter(rowData?.contact_name) ?? '',
        company_name: capitalizeFirstLetter(rowData?.company_name) ?? '',
        orderDetails: rowData?.id,
        location: `${rowData?.city},${rowData?.pincode}`,
        quantity_kg: `${totalQty} Kgs`,
        order_mobile_number: rowData?.order_mobile_number?.slice(2),
        date: dateFormate(rowData?.pickup_time)
      };
    });

  const [colDefs, setColDefs] = useState(columns);
  useEffect(() => {
    const updatedDefs = [...columns];
    if (selectedTab === 'confirmed') {
      updatedDefs.splice(4, 0, {
        field: 'final_order',
        headerName: 'Final Weight',
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        disableColumnSeparator: true,
        renderCell: ({ row }) => {
          const totalCategoryQuantity = row?.order_item?.reduce(
            (total: any, item: { final_quantity: any }) =>
              total + item.final_quantity,
            0
          );
          const categoryNames = row?.order_item?.map(
            (item: { category_name: any }) => item?.category_name
          );
          return (
            <Tooltip
              title={
                <>
                  {row?.order_item?.map((item, idx) => (
                    <Grid key={idx}>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: theme.fontWeight.medium
                        }}
                      >
                        {`${item?.category_name} - ${item?.final_quantity} Kgs`}
                      </Typography>
                    </Grid>
                  ))}
                </>
              }
              arrow
              placement="top"
              classes={{
                tooltip: classes.tooltipStyle,
                arrow: classes.arrowStyle
              }}
            >
              <div>
                <ListItemCell
                  title={`${totalCategoryQuantity} Kgs`}
                  subTitle={categoryNames?.toString()}
                />
              </div>
            </Tooltip>
          );
        }
      });
    }
    setColDefs(updatedDefs);
  }, [selectedTab]);

  const renderSearchBox = () => {
    return (
      <>
        {selectedTab == 'scheduled' ? (
          <Grid item style={{ paddingRight: 20 }}>
            <MultipleSelectComp
              isPlaceholderNone
              value={edit.getValue('status')}
              selectItems={
                statusType?.length &&
                statusType?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id
                  };
                })
              }
              borderRadius={40}
              selectHeight={35}
              background={theme.Colors.greyDark}
              iconColor={theme.Colors.primary}
              onChange={handleChangeStatus}
              displayEmpty
              renderValue={(value: any) => {
                return value
                  ? statusType?.filter((item) => item.id === value)[0].name
                  : 'Status';
              }}
              required
            />
          </Grid>
        ) : null}
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search Location"
            value={edit.getValue('location')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Compass} />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
          />
        </Grid>
        {vendorOrderChipFilter == 'Today' ? null : (
          <Grid item>
            <TextInputComponent
              inputWidth={250}
              borderRadius={40}
              inputHeight={30}
              value={edit.getValue('date')}
              placeholder="Search by Date"
              isBackground={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    <img src={TableCalendar} />
                  </InputAdornment>
                )
              }}
              onClick={handleOpenCalendar}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <UHTable
      title={t('allOrders')}
      columns={colDefs}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      getRowActions={renderRowActions}
      onSortClick={onSortClick}
      onSelectionModelChange={onGetSelectedRows}
      renderSearchBox={renderSearchBox}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={'VendorOrder'}
    />
  );
};

export default VendorTable;
