import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { ButtonComp, ListItemCell } from 'src/components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      width: 480,
      height: 223,
      padding: theme.spacing(5, 1, 6, 1),
      borderRadius: 24
    }
  };
});

const ForgetPasswordModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { open, onClickButton } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>
        <ListItemCell
          title={t('contactAdminForPassword')}
          titleStyle={{ fontSize: 30, fontWeight: theme.fontWeight.medium }}
        />
      </DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <ButtonComp
          buttonText={t('ok')}
          buttonFontSize={12}
          btnWidth={108}
          height={35}
          btnBorderRadius={40}
          buttonFontWeight={theme.fontWeight.bold}
          onClickButton={onClickButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ForgetPasswordModal;
