import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  MiLocation,
  Person,
  PersonSwap,
  Phone,
  Published,
  AvatarImage,
  LogoDustman
} from 'src/Assets';
import { ListItemCell } from 'src/components';
import CustomerViewVendorModal from '../CustomerManagement/CustomerViewVendorModal';

const useStyles = makeStyles((theme) => {
  return {
    gridStyle: {
      padding: 30,
      flexWrap: 'nowrap',
      borderRadius: 15,
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      height: '100%',
      marginTop: 10
    },
    textStyle: {
      fontSize: 20,
      fontWeight: theme.fontWeight.bold,
      lineHeight: 1.5
    },
    imageStyle: {
      width: 24,
      height: 24
    },
    locationStyle: {
      width: 30,
      height: 30
    },
    titleStyle: {
      fontSize: 18,
      fontWeight: theme.fontWeight.medium
    }
  };
});

const AssignedVendors = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const { details, updateData } = props;
  const [assignModal, setAssignModal] = useState<any>({ open: false });

  return (
    <Box style={{ marginTop: '55px' }}>
      {details?.length ? (
        details?.map((item, idx) => {
          return (
            <>
              <Grid
                container
                justifyContent="space-between"
                key={idx}
                style={{ paddingBottom: 25, flexWrap: 'nowrap' }}
              >
                <Grid
                  item
                  xs
                  container
                  alignItems="center"
                  className={styles.gridStyle}
                >
                  <Grid item style={{ padding: 10 }}>
                    <img src={MiLocation} />
                  </Grid>
                  <Grid item style={{ paddingLeft: 30 }}>
                    <Typography className={styles.textStyle}>
                      {`${item?.address_line1} ${item?.address_line2}`},
                    </Typography>
                    <Typography className={styles.textStyle}>
                      {`${item?.address_line3}`},
                    </Typography>
                    <Typography className={styles.textStyle}>
                      {`${item?.city} ${item?.pincode}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    justifyContent: 'center',
                    padding: 10,
                    alignSelf: 'center',
                    display: 'inline-flex'
                  }}
                >
                  <img src={PersonSwap} />
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 40,
                    // width: 428,
                    flexWrap: 'nowrap',
                    borderRadius: 15,
                    boxShadow: '0px 4px 4px #00000042',
                    backgroundColor: theme.Colors.white,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%'
                  }}
                >
                  <Typography
                    style={{
                      textAlign: 'right',
                      cursor: 'pointer',
                      fontWeight: theme.fontWeight.bold,
                      background:
                        '-webkit-linear-gradient(45deg, #6BB043 100%, #F68D1F 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                    onClick={() => {
                      console.log('iiiiiiiiii', item);

                      setAssignModal({
                        open: true,
                        rowData: item,
                        updateData: updateData
                      });
                    }}
                  >
                    Reassign
                  </Typography>
                  <ListItemCell
                    avatarImg={
                      item?.is_dustman ? LogoDustman : AvatarImage || 'DustMan'
                    }
                    title={
                      item?.is_dustman
                        ? 'Dustman Waste Management & Recycling'
                        : item.company_name
                    }
                    titleStyle={{
                      fontSize: 18,
                      fontWeight: theme.fontWeight.medium,
                      paddingRight: item?.is_dustman ? 70 : 0
                    }}
                    listStyle={{ paddingBottom: 10 }}
                    avatarClassNameStyles={styles.imageStyle}
                  />
                  <ListItemCell
                    avatarImg={Person}
                    title={item?.is_dustman ? 'Dustman' : item?.contact_name}
                    avatarClassNameStyles={styles.imageStyle}
                    titleStyle={{
                      fontSize: 18,
                      fontWeight: theme.fontWeight.medium
                    }}
                    listStyle={{ paddingBottom: 15 }}
                  />
                  <ListItemCell
                    avatarImg={Phone}
                    title={
                      item?.is_dustman
                        ? '89399 09555, 89399 76555'
                        : item?.registered_mobile_number
                    }
                    avatarClassNameStyles={styles.imageStyle}
                    titleStyle={{
                      fontWeight: theme.fontWeight.medium,
                      fontSize: 16
                    }}
                    listStyle={{ paddingBottom: 10 }}
                  />
                  <ListItemCell
                    avatarImg={MiLocation}
                    avatarClassNameStyles={styles.imageStyle}
                    title={
                      item?.is_dustman
                        ? 'SF.No.179/6A, 8A, 9A, Mambakkam Road, Mambakkam Village, Sriperumbudur, Kanchipuram,Tn-602106.'
                        : `${item?.vendor_address} ${item?.vendor_pincode}`
                    }
                    titleStyle={{
                      fontWeight: theme.fontWeight.bold,
                      fontSize: 14
                    }}
                    listStyle={{ paddingBottom: 10 }}
                  />
                  <ListItemCell
                    avatarImg={Published}
                    title={
                      item?.is_dustman
                        ? item?.updated_at?.split('T')[0]
                        : `${item?.subscription_from?.split('T')[0]} to ${
                            item?.subscription_to?.split('T')[0]
                          }`
                    }
                    avatarClassNameStyles={styles.imageStyle}
                    titleStyle={{
                      fontWeight: theme.fontWeight.medium,
                      fontSize: 16
                    }}
                    listStyle={{ paddingBottom: 10 }}
                  />
                </Grid>
              </Grid>
              {idx == details?.length - 1 ? null : (
                <Divider
                  style={{
                    backgroundColor: theme.Colors.lightBlue,
                    marginBottom: 20
                  }}
                />
              )}
            </>
          );
        })
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        >
          <Typography variant="h2" style={{ color: theme.Colors.primary }}>
            Admin didn't assign any vendor for this customer
          </Typography>
        </div>
      )}
      {assignModal.open ? (
        <CustomerViewVendorModal
          onClose={() => setAssignModal({ open: false })}
          {...assignModal}
          //updateData={fetchData}
        />
      ) : null}
    </Box>
  );
};

export default AssignedVendors;
