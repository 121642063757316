import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { overViewData } from './AdminStub';

export const overViewServices = {
  getCustomerOrderOverView: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/customerOrderOverView/${id}`,
      method: 'get'
    });
    return apiRequest(options);
    // return overViewData.customerOrderGet(id);
  },

  vendorOrderOverView: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorOrderOverView/${id}`,
      method: 'get'
    });
    return apiRequest(options);
    //return overViewData.vendorOrderGet(id);
  },

  customerRequestOverView: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/customerRequestsOverView/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  vendorRequestsOverView: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorRequestsOverView/${id}`,
      method: 'get'
    });
    return apiRequest(options);
    //return overViewData.vendorRequestGet(id);
  }
};
