import React, { useEffect, useState } from 'react';
import UHTable from 'src/components/UHTable';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ButtonComp, ListItemCell, TextInputComponent } from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import { useTranslation } from 'react-i18next';
import {
  ListAlt,
  MoreVert,
  Payment,
  PlaylistAddCheck,
  Search
} from '@material-ui/icons';
import { Chip, Grid, IconButton, InputAdornment } from '@material-ui/core';
import MenuActionComp from 'src/components/MenuActionComp';
import { TableCalendar } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => ({}));

type Props = {
  handleClickViewDetails?: (row: any, num?: number) => void;
  tableRowData?: any[];
  selectedTab?: any;
  handleChange?: (val: any) => void;
  handleClickAddMoney?: (row: any) => void;
  edit?: any;
  handleOpenCalendar?: () => void;
};

const CustomerVendorWalletTable = ({
  handleClickViewDetails,
  tableRowData,
  selectedTab,
  handleChange,
  edit,
  handleOpenCalendar,
  handleClickAddMoney
}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedActionRow, setSelectedActionRow] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClickIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowData: any
  ) => {
    if (rowData?.id === selectedActionRow?.id) {
      setSelectedActionRow(null);
      setAnchorEl(null);
    } else {
      setSelectedActionRow(rowData);
      setAnchorEl(e.currentTarget);
    }
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.company_name)}
          isLink={
            selectedTab == 1
              ? '/dashboards/view-details'
              : '/dashboards/view-details'
          }
          state={{ ...row, selectedTab: selectedTab }}
        />
      )
    },
    {
      field: 'details',
      headerName: t('vendor.contactName'),
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(
            row?.contact_name || row?.contact_person
          )}
          //avatarImg={row?.image_url || 'DustMan'}
        />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.mobile_number || row?.registered_mobile_number}
        />
      )
    },
    {
      field: 'balance',
      headerName: selectedTab === 1 ? 'Total Amount' : 'Account Balance',
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={selectedTab == 2 ? row?.wallet_amount : row?.order_amount}
        />
      )
    }
  ];
  const [colDefs, setColDefs] = useState(columns);

  useEffect(() => {
    const updatedDefs = [...columns];
    if (selectedTab == 2) {
      updatedDefs.push({
        field: 'action',
        headerName: t('action'),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <IconButton onClick={(e) => handleClickIcon(e, row)}>
            <MoreVert />
          </IconButton>
        )
      });
    }
    setColDefs(updatedDefs);
  }, [selectedTab]);

  const renderRowActions = (rowData: any) => {
    if (selectedTab === 2) {
      return [
        // {
        //   text: t('viewDetails'),
        //   renderIcon: () => <ListAlt />,
        //   onClick: () => handleClickViewDetails(rowData, 2)
        // },
        {
          text: 'Add Money',
          renderIcon: () => <Payment />,
          onClick: () => {
            handleClickAddMoney(rowData);
            handleClose();
          }
        }
        // {
        //   text: 'Payout',
        //   renderIcon: () => <PlaylistAddCheck />,
        //   onClick: () => {
        //     handleClickPayout(rowData);
        //     handleClose();
        //   }
        // }
      ];
    }
    return [];
  };

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search"
            value={edit.getValue('search')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    style={{
                      width: 16,
                      height: 16,
                      color: 'rgba(34, 51, 69, 0.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
          />
        </Grid>
        <Grid item>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
      </>
    );
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Location', key: 'location' },
    { label: 'MobileNumber', key: 'registered_mobile_number' },
    { label: 'Join Date', key: 'date' },
    {
      label: selectedTab == 1 ? 'Total Amount' : 'Account Balance',
      key: 'amount'
    }
  ];

  let excelData =
    tableRowData?.length &&
    tableRowData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.created_at);
      return {
        id: idx + 1,
        name: capitalizeFirstLetter(rowData?.contact_name),
        location: `${rowData?.location ? rowData?.location : ''} ${
          rowData?.location && rowData?.pincode ? ',' : ''
        }${rowData?.pincode ? rowData?.pincode : ''}`,
        registered_mobile_number: rowData?.registered_mobile_number.slice(2),
        amount:
          selectedTab == 1 ? rowData?.order_amount : rowData?.wallet_amount,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  return (
    <>
      <UHTable
        title={
          selectedTab == 1
            ? t('walletManagement.customersWallet')
            : t('walletManagement.vendorsWallet')
        }
        columns={colDefs}
        rows={tableRowData}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        renderSearchBox={renderSearchBox}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={selectedTab == 1 ? 'Customer List' : 'Vendor List'}
      />
      {selectedTab == 2 ? (
        <MenuActionComp
          open={open}
          selectedActionRow={selectedActionRow}
          rowActions={renderRowActions(selectedActionRow)}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      ) : null}
    </>
  );
};

export default React.memo(CustomerVendorWalletTable);
