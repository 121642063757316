import { useCallback, useState } from 'react';
import { useTheme, Grid } from '@material-ui/core';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BoxComp } from 'src/components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  rootMainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: `${theme.sidebar.width}`
    }
  }
}));
export type HeaderOutletContext = { searchValue: string | null };

const SidebarLayout = () => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const routesLocation = useLocation();
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const HeaderRoutes = [
    {
      path: '/dashboards/overview',
      header: t('permission.overview'),
      info: t('description.overallInformation')
    },
    {
      path: '/dashboards/new-request',
      header: t('permission.newRequest'),
      info: t('description.manageYourVendor')
    },
    {
      path: '/dashboards/customer-order',
      header: t('permission.customerOrder'),
      info: t('description.manageYourCustomerOrdersInformation')
    },
    {
      path: '/dashboards/dustman-order',
      header: t('permission.dustmanOrder'),
      info: t('description.manageYourVendorOrdersInformation')
    },
    {
      path: '/dashboards/customer-management',
      header: t('permission.customerManagement'),
      info: t('description.assignVendorToCustomer')
    },
    {
      path: '/dashboards/customer-vendor-list',
      header: t('permission.customerVendorList')
      // info: t('description.assignVendorToCustomer')
    },
    {
      path: '/dashboards/customer-vendor-list/customer-details',
      header: t('permission.customerVendorList')
      // info: t('description.assignVendorToCustomer')
    },
    {
      path: '/dashboards/customer-vendor-list/vendor-details',
      header: t('permission.customerVendorList')
      // info: t('description.assignVendorToCustomer')
    },
    {
      path: '/dashboards/wallet-management',
      header: t('permission.walletManagement')
      // info: t('description.manageYourAdminUsers')
    },
    {
      path: '/dashboards/admin-wallet',
      header: t('permission.adminWallet')
      // info: t('description.manageYourAdminUsers')
    },
    {
      path: '/dashboards/invoice-management',
      header: t('permission.invoiceDetails')
      // info: t('description.manageYourAdminUsers')
    },
    {
      path: '/dashboards/invoice-management/invoice-details',
      header: t('permission.invoiceDetails')
      // info: t('description.manageYourAdminUsers')
    },
    {
      path: '/dashboards/price-management',
      header: t('permission.priceManagement'),
      info: t('description.manageYourPriceAndOrderInformation')
    },
    {
      path: '/dashboards/enquiry-management',
      header: t('enquiryManagement'),
      info: t('description.manageYourPriceAndOrderInformation')
    },
    {
      path: '/dashboards/user-management',
      header: t('permission.userManagement'),
      info: t('description.manageYourAdminUsers')
    },

    // {
    //   path: '/dashboards/deposit-management',
    //   header: t('depositManagement'),
    //   info: t('description.manageYourPriceAndOrderInformation')
    // },
    {
      path: '/dashboards/reports',
      header: t('permission.report'),
      info: t('description.overallInformation')
    },
    {
      path: '/dashboards/settings',
      header: t('permission.setting'),
      info: t('description.manageYourAddressAndPincodeSettings')
    },
    {
      path: '/dashboards/view-details',
      header: t('walletManagement.viewDetails')
    }
  ];

  const onsearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  return (
    <Grid>
      <Sidebar />
      <Grid className={styles.rootMainContainer}>
        <BoxComp>
          {HeaderRoutes?.map((item, index) => {
            if (item.path === routesLocation.pathname) {
              return (
                <Header
                  key={index}
                  header={item.header}
                  info={item.info}
                  searchValue={searchValue}
                  onsearchInputChange={onsearchInputChange}
                  isSearch={index == 0 ? false : true}
                />
              );
            }
          })}
          <Outlet context={{ searchValue }} />
        </BoxComp>
      </Grid>
    </Grid>
  );
};

export default SidebarLayout;
