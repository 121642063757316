import React, { useEffect, useState } from 'react';
import { IconButton, useTheme } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ListItemCell, UHTable } from 'src/components';
import { getDateFormat, getToday } from 'src/Utils';

const HistoryTable = ({ tableData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [rowItems, setRowItems] = useState([]);

  useEffect(() => {
    if (tableData?.length) {
      setRowItems(tableData);
    } else {
      setRowItems([]);
    }
  }, [tableData]);

  const getSlot = (val) => {
    if (val.toLowerCase() == 'morning') {
      return '9:00 AM - 12:00 PM';
    } else if (val.toLowerCase() == 'afternoon') {
      return '1:00 PM - 4:00 PM';
    } else {
      return '4:00 PM - 7:00 PM';
    }
  };

  const columns = [
    {
      field: 'order_number',
      headerName: t('customerVendorList.orderNumber'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.order_id} />
    },
    {
      field: 'scheduled',
      headerName: t('customerVendorList.scheduled'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear } = getDateFormat(row?.pickup_time);

        return (
          <ListItemCell
            title={`Scheduled ${getToday(
              row?.pickup_time
            )}, ${getDate}, ${getMonth} ${getYear}`}
          />
        );
      }
    },
    {
      field: 'timing',
      headerName: t('customerVendorList.timing'),
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={getSlot(row?.slot)} />
    },
    {
      field: 'status',
      headerName: t('customerVendorList.status'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={
            row?.status_id == 0
              ? 'New Request'
              : row?.status_id == 1
              ? 'Pending'
              : row?.status_id == 2
              ? 'Confirmed'
              : row?.status_id == 3
              ? 'Completed'
              : 'Canceled'
          }
          titleStyle={{
            color:
              row?.status_id == 0
                ? theme.Colors.primary
                : row?.status_id == 1
                ? theme.Colors.orangeSecondary
                : row?.status_id == 2
                ? theme.Colors.darkGreen
                : row?.status_id == 3
                ? theme.Colors.secondary
                : theme.Colors.redPrimary
          }}
        />
      )
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <IconButton onClick={(e) => console.log(e)}>
          <MoreVert />
        </IconButton>
      )
    }
  ];
  return (
    <UHTable
      columns={columns}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      getRowId={(row) => row?.order_id}
      isDownloadButton={false}
      //   excelData={excelData}
      //   excelHeaders={excelHeaders}
      //   fileName={'CustomerOrder'}
      // onFilterClick={() => {
      //   console.log('e');
      // }}
    />
  );
};

export default HistoryTable;
