import React, { useEffect, useState } from 'react';
import { Cancel, Done, ListAlt, MoveToInbox } from '@material-ui/icons';
import {
  Chip,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  ButtonComp,
  ListItemCell,
  MultipleSelectComp,
  TextInputComponent,
  UHTable
} from 'src/components';
import { capitalizeFirstLetter, dateFormate, getDateFormat } from 'src/Utils';
import { CUSTOMER_ORDER_STATUS, HTTP_STATUSES } from 'src/Config/constant';
import { useTranslation } from 'react-i18next';
import { API_SERVICES } from 'src/Services';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Compass, TableCalendar } from 'src/Assets';

const useStyles = makeStyles((theme) => {
  return {
    tooltipStyle: {
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      color: theme.Colors.neutralBlack,
      padding: 12,
      borderRadius: 8
    },
    arrowStyle: {
      color: theme.Colors.white
    }
  };
});

type Props = {
  onClickActionButton?: (row?: any, selIds?: number[]) => void;
  onClickViewOrder?: (row: any) => void;
  tableRowData?: any[];
  onClickCancelOrder?: (row: any) => void;
  onClickMoveToDustman?: (row: any) => void;
  renderActionBtn?: boolean;
  selectedTab?: any;
  onClickReassignVendor?: (row?: any) => void;
  onClickCompleteOrder?: (row?: any) => void;
  onClickDownloadInvoice?: (row?: any) => void;
  handleOpenCalendar?: () => void;
  handleChangeStatus?: (val?: any) => void;
  edit?: any;
  statusType?: any[];
  customerOrderChipFilter?: string;
};

const CustomerTable = ({
  onClickActionButton,
  onClickViewOrder,
  onClickCancelOrder,
  onClickMoveToDustman,
  tableRowData,
  renderActionBtn = false,
  selectedTab,
  onClickReassignVendor,
  handleOpenCalendar: handleOpenCalendar,
  edit,
  statusType,
  onClickCompleteOrder,
  handleChangeStatus,
  customerOrderChipFilter,
  onClickDownloadInvoice
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [highlightWeight, setHighlightWeight] = useState(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  useEffect(() => {
    const fetchData = async () => {
      const response: any =
        await API_SERVICES.priceMgntService.getAllHighLightCustomerOrder();

      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.length) {
          setHighlightWeight(response.data[0].highlight_customer_order);
        }
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      field: 'details',
      headerName: 'Customer Name',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={`Order ${row?.id}`}
          subTitle={capitalizeFirstLetter(row?.contact_name)}
          avatarImg={row?.image_url || 'DUSTMAN'}
          titleStyle={{ textDecoration: 'underline' }}
        />
      )
    },
    {
      field: 'city',
      headerName: t('location'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.city)}
          subTitle={row?.pincode}
        />
      )
    },
    {
      field: 'order',
      headerName: t('order'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const totalCategoryQuantity = row?.order_item?.reduce(
          (total: any, item: { category_quantity: any }) =>
            total + item.category_quantity,
          0
        );

        const categoryNames = row?.order_item?.map(
          (item: { category_name: any }) => item?.category_name
        );
        return (
          <Tooltip
            title={
              <>
                {row?.order_item?.map((item, idx) => (
                  <Grid key={idx}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: theme.fontWeight.medium
                      }}
                    >
                      {`${item?.category_name} - ${item?.category_quantity} Kgs`}
                    </Typography>
                  </Grid>
                ))}
              </>
            }
            arrow
            placement="top"
            classes={{
              tooltip: classes.tooltipStyle,
              arrow: classes.arrowStyle
            }}
          >
            <div>
              <ListItemCell
                title={`${totalCategoryQuantity} Kgs`}
                subTitle={categoryNames?.toString()}
                isBadgeEnable={
                  row?.status_id === CUSTOMER_ORDER_STATUS['newOrder'] &&
                  highlightWeight !== null &&
                  totalCategoryQuantity >= highlightWeight
                }
              />
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'date',
      headerName: selectedTab == 'new' ? t('date') : 'Vendor',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.created_at
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={
              selectedTab == 'new'
                ? `${getMonth} ${getDate}, ${getYear}`
                : row?.vendor_company_name
            }
            subTitle={
              selectedTab == 'new'
                ? `${time[0]}:${time[1]}${time[2].slice(2)}`
                : row?.vendor_mobile_number?.slice(2)
            }
          />
        );
      }
    }
    // {
    //   field: 'action',
    //   headerName: selectedTab == 'scheduled' ? t('status') : t('action'),
    //   flex: 1.5,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: ({ row }) => {
    //     let disableBtn =
    //       selectedRows.length > 1 && selectedRows.includes(row.id);
    //     return (
    //       <>
    //         {selectedTab == 'cancelled' || selectedTab == 'confirmed' ? (
    //           <Chip
    //             label={selectedTab == 'confirmed' ? 'Completed' : 'Cancelled'}
    //             style={{
    //               color: theme.Colors.white,
    //               background:
    //                 selectedTab == 'confirmed'
    //                   ? theme.Colors.secondary
    //                   : '#e40909'
    //             }}
    //           />
    //         ) : (
    //           <ButtonComp
    //             btnBorderRadius={theme.MetricsSizes.regular_x}
    //             buttonText={
    //               row?.status_id == 1
    //                 ? 'Pending'
    //                 : row?.status_id == 2
    //                 ? 'Vehicle Alloted'
    //                 : row?.status_id == 5
    //                 ? 'Over Due'
    //                 : row?.status === CUSTOMER_ORDER_STATUS['newOrder']
    //                 ? t('customerOrder.assignVendor')
    //                 : row?.status == 1
    //                 ? 'Pending'
    //                 : row?.status == 2
    //                 ? 'Renew Vendor'
    //                 : row?.status == 5
    //                 ? 'Reassign Vendor'
    //                 : t('button.view')
    //             }
    //             disabled={renderActionBtn && disableBtn}
    //             buttonFontSize={theme.MetricsSizes.small_x}
    //             btnWidth={99}
    //             height={theme.MetricsSizes.regular_xxx}
    //             onClickButton={() => onClickActionButton(row)}
    //             buttonFontWeight={theme.fontWeight.bold}
    //             backgroundColor={
    //               row?.status_id == 1 || row?.status == 2
    //                 ? theme.Colors.primary
    //                 : row?.status_id == 5 || row?.status == 5
    //                 ? theme.Colors.orangeSecondary
    //                 : row?.status == 1
    //                 ? '#C4C4C4'
    //                 : theme.Colors.secondary
    //             }
    //           />
    //         )}
    //       </>
    //     );
    //   }
    // }
  ];

  const [colDefs, setColDefs] = useState(columns);
  useEffect(() => {
    const updatedDefs = [...columns];
    if (selectedTab == 'scheduled') {
      updatedDefs.push({
        field: 'action',
        headerName: t('status'),
        flex: 1.7,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <ButtonComp
            btnBorderRadius={theme.MetricsSizes.regular_x}
            buttonText={
              row?.is_ready_to_upload
                ? 'Pending Invoice'
                : row?.status_id == 1
                ? 'Pending'
                : row?.status_id == 2
                ? 'Vehicle Alloted'
                : row?.status_id == 5
                ? 'Over Due'
                : ''
            }
            //disabled={renderActionBtn && disableBtn}
            buttonFontSize={theme.MetricsSizes.small_x}
            btnWidth={111}
            height={theme.MetricsSizes.regular_xxx}
            onClickButton={() => onClickActionButton(row)}
            buttonFontWeight={theme.fontWeight.bold}
            backgroundColor={
              row?.is_ready_to_upload
                ? '#8a00c2'
                : row?.status_id == 1 || row?.status == 2
                ? theme.Colors.primary
                : row?.status_id == 5 || row?.status == 5
                ? theme.Colors.orangeSecondary
                : row?.status == 1
                ? '#C4C4C4'
                : theme.Colors.secondary
            }
          />
        )
      });
    }
    if (selectedTab == 'new') {
      updatedDefs.push({
        field: 'action',
        headerName: t('status'),
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <ButtonComp
            btnBorderRadius={theme.MetricsSizes.regular_x}
            buttonText={
              row?.status === CUSTOMER_ORDER_STATUS['newOrder']
                ? t('customerOrder.assignVendor')
                : row?.status == 1
                ? 'Pending'
                : row?.status == 2
                ? 'Renew Vendor'
                : row?.status == 5
                ? 'Reassign Vendor'
                : t('button.view')
            }
            //disabled={renderActionBtn && disableBtn}
            buttonFontSize={theme.MetricsSizes.small_x}
            btnWidth={99}
            height={theme.MetricsSizes.regular_xxx}
            onClickButton={() => onClickActionButton(row)}
            buttonFontWeight={theme.fontWeight.bold}
            backgroundColor={
              row?.status_id == 1 || row?.status == 2
                ? theme.Colors.primary
                : row?.status_id == 5 || row?.status == 5
                ? theme.Colors.orangeSecondary
                : row?.status == 1
                ? '#C4C4C4'
                : theme.Colors.secondary
            }
          />
        )
      });
    }
    if (selectedTab === 'confirmed') {
      updatedDefs.splice(3, 0, {
        field: 'final_order',
        headerName: 'Final Weight',
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const totalCategoryQuantity = row?.order_item?.reduce(
            (total: any, item: { final_quantity: any }) =>
              total + item.final_quantity,
            0
          );
          const categoryNames = row?.order_item?.map(
            (item: { category_name: any }) => item?.category_name
          );
          return (
            <Tooltip
              title={
                <>
                  {row?.order_item?.map((item, idx) => (
                    <Grid key={idx}>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: theme.fontWeight.medium
                        }}
                      >
                        {`${item?.category_name} - ${item?.final_quantity} Kgs`}
                      </Typography>
                    </Grid>
                  ))}
                </>
              }
              arrow
              placement="top"
              classes={{
                tooltip: classes.tooltipStyle,
                arrow: classes.arrowStyle
              }}
            >
              <div>
                <ListItemCell
                  title={`${totalCategoryQuantity} Kgs`}
                  subTitle={categoryNames?.toString()}
                  isBadgeEnable={
                    row?.status_id === CUSTOMER_ORDER_STATUS['newOrder'] &&
                    highlightWeight !== null &&
                    totalCategoryQuantity >= highlightWeight
                  }
                />
              </div>
            </Tooltip>
          );
        }
      });
    }
    setColDefs(updatedDefs);
  }, [selectedTab]);

  // useEffect(() => {
  //   const updatedDefs = [...columns];
  //   if (handleAction) {
  //     updatedDefs.push({
  //       field: 'action',
  //       headerName: selectedTab == 'scheduled' ? t('status') : t('action'),
  //       flex: 1.5,
  //       sortable: false,
  //       disableColumnMenu: true,
  //       renderCell: ({ row }) => (
  //         <ButtonComp
  //           btnBorderRadius={theme.MetricsSizes.regular_x}
  //           buttonText={
  //             row?.status_id == 1
  //               ? 'Pending'
  //               : row?.status_id == 2
  //               ? 'Vehicle Alloted'
  //               : row?.status_id == 5
  //               ? 'Over Due'
  //               : row?.status === CUSTOMER_ORDER_STATUS['newOrder']
  //               ? t('customerOrder.assignVendor')
  //               : row?.status == 1
  //               ? 'Pending'
  //               : row?.status == 2
  //               ? 'Renew Vendor'
  //               : row?.status == 5
  //               ? 'Reassign Vendor'
  //               : t('button.view')
  //           }
  //           //disabled={renderActionBtn && disableBtn}
  //           buttonFontSize={theme.MetricsSizes.small_x}
  //           btnWidth={99}
  //           height={theme.MetricsSizes.regular_xxx}
  //           onClickButton={() => onClickActionButton(row)}
  //           buttonFontWeight={theme.fontWeight.bold}
  //           backgroundColor={
  //             row?.status_id == 1 || row?.status == 2
  //               ? theme.Colors.primary
  //               : row?.status_id == 5 || row?.status == 5
  //               ? theme.Colors.orangeSecondary
  //               : row?.status == 1
  //               ? '#C4C4C4'
  //               : theme.Colors.secondary
  //           }
  //         />
  //       )
  //     });
  //   }
  //   setColDefs(updatedDefs);
  // }, [handleAction]);

  const renderRowActions = (rowData: any) => {
    if (rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('cancelOrder'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        },
        {
          text: t('moveToDustman'),
          renderIcon: () => <MoveToInbox />,
          onClick: onClickMoveToDustman
        }
      ];
    }
    if (
      rowData?.status === CUSTOMER_ORDER_STATUS['pending'] &&
      rowData?.status_id === CUSTOMER_ORDER_STATUS['newOrder']
    ) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('cancelOrder'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        }
      ];
    } else if (
      rowData?.status_id === CUSTOMER_ORDER_STATUS['pending'] ||
      rowData?.status_id == 5
    ) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: 'Reassign Vendor',
          renderIcon: () => <AssignmentTurnedInIcon />,
          onClick: onClickReassignVendor
        },
        {
          text: 'Complete Order',
          renderIcon: () => <Done />,
          onClick: onClickCompleteOrder
        },
        {
          text: t('cancelOrder'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        }
      ];
    } else if (rowData?.status_id === CUSTOMER_ORDER_STATUS['confirmed']) {
      return [
        {
          text: 'Reassign Vendor',
          renderIcon: () => <AssignmentTurnedInIcon />,
          onClick: onClickReassignVendor
        },
        {
          text: 'Complete Order',
          renderIcon: () => <Done />,
          onClick: onClickCompleteOrder
        },
        {
          text: t('cancelOrder'),
          renderIcon: () => <Cancel />,
          onClick: onClickCancelOrder
        },
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        }
      ];
    } else if (rowData?.status_id === CUSTOMER_ORDER_STATUS['completed']) {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: 'Download Invoice',
          renderIcon: () => <GetAppIcon />,
          onClick: onClickDownloadInvoice
        }
      ];
    } else {
      return [
        {
          text: t('viewOrder'),
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        }
        // {
        //   text: t('cancelOrder'),
        //   renderIcon: () => <Cancel />,
        //   onClick: onClickCancelOrder
        // }
      ];
    }
  };

  const onSortClick = () => {
    setSortData(!sortData);
  };

  const onGetSelectedRows = (selectedIDs: number[]) => {
    setSelectedRows(selectedIDs);
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Order ID', key: 'orderDetails' },
    { label: 'Location', key: 'location' },
    { label: 'User Type', key: 'user_type' },
    { label: 'Order', key: 'order' },
    { label: 'Mobile Number', key: 'order_mobile_number' },
    { label: 'Date', key: 'date' }
  ];

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData, idx) => {
      let data = rowData?.order_item?.map((item: any) => {
        return [item.category_quantity];
      });
      let qty = data?.flat();
      const totalQty = qty?.reduce((acc: any, curr: any) => acc + curr, 0);
      return {
        id: idx + 1,
        name: capitalizeFirstLetter(rowData?.contact_name),
        orderDetails: `Order ${rowData?.order_id}`,
        location: `${rowData?.city},${rowData?.pincode}`,
        user_type: rowData?.user_type,
        order: `${totalQty} Kgs`,
        order_mobile_number: rowData?.order_mobile_number,
        date: dateFormate(rowData?.pickup_time)
      };
    });

  const renderSearchBox = () => {
    return (
      <>
        {selectedTab == 'new' || selectedTab == 'scheduled' ? (
          <Grid item style={{ paddingRight: 20 }}>
            <MultipleSelectComp
              isPlaceholderNone
              value={edit.getValue('status')}
              selectItems={
                statusType?.length &&
                statusType?.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id
                  };
                })
              }
              borderRadius={40}
              selectHeight={35}
              background={theme.Colors.greyDark}
              iconColor={theme.Colors.primary}
              onChange={handleChangeStatus}
              displayEmpty
              renderValue={(value: any) => {
                return value
                  ? statusType?.filter((item) => item.id === value)[0].name
                  : 'Status';
              }}
              required
            />
          </Grid>
        ) : null}
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('location')}
            placeholder="Search Location"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Compass} />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
          />
        </Grid>
        {customerOrderChipFilter == 'Today' ? null : (
          <Grid item>
            <TextInputComponent
              inputWidth={250}
              borderRadius={40}
              inputHeight={30}
              value={edit.getValue('date')}
              placeholder="Search by Date"
              isBackground={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    <img src={TableCalendar} />
                  </InputAdornment>
                )
              }}
              onClick={handleOpenCalendar}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <UHTable
      title={t('allOrders')}
      columns={colDefs}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      getRowActions={renderRowActions}
      onSortClick={onSortClick}
      onSelectionModelChange={onGetSelectedRows}
      renderSearchBox={renderSearchBox}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={'CustomerOrder'}
    />
  );
};

export default React.memo(CustomerTable);
