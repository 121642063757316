import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  ListItemCell,
  MultipleSelectComp,
  TextInputComponent,
  UHTable
} from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import { useTranslation } from 'react-i18next';
import {
  ListAlt,
  AssignmentTurnedIn,
  Block,
  Edit,
  MonetizationOn
} from '@material-ui/icons';
import { VENDOR_MG_STATUS } from 'src/Config/constant';
import { Grid, InputAdornment } from '@material-ui/core';
import { Compass, TableCalendar } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => ({}));

type Props = {
  onClickActionButton?: (row?: any, selIds?: number[]) => void;
  onClickViewOrder?: (row: any) => void;
  tableRowData?: any[];
  onClickCancelOrAccept?: (row: any, statusId: number) => void;
  onClickMoveToDustman?: (row: any) => void;
  onClickReassignVendor?: (row: any) => void;
  selectedTab?: any;
  regionData?: any[];
  handleOpenCalendar?: () => void;
  handleChangeRegion?: (val: any) => void;
  handleEpr?: boolean;
  edit?: any;
  onClickEditVendor?: (row: any) => void;
  onClickEditCustomer?: (row: any) => void;
  onClickViewCommission?: (row: any) => void;
};

const CustomerVendorListTable = ({
  onClickActionButton,
  onClickReassignVendor,
  onClickViewOrder,
  onClickCancelOrAccept,
  onClickMoveToDustman,
  onClickEditVendor,
  tableRowData,
  selectedTab,
  regionData,
  handleOpenCalendar,
  handleChangeRegion,
  onClickEditCustomer,
  onClickViewCommission,
  edit,
  handleEpr = selectedTab == 1 ? true : false
}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  const onSortClick = () => {
    setSortData(!sortData);
  };

  const columns = [
    {
      field: 'details',
      headerName: t('vendor.contactName'),
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.contact_name)}
          avatarImg={row?.image_url || 'DustMan'}
          isLink={
            row?.customer_id
              ? '/dashboards/customer-vendor-list/customer-details'
              : '/dashboards/customer-vendor-list/vendor-details'
          }
          state={{ rowData: row }}
        />
      )
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.location == '' ? '-' : row?.location}
          subTitle={row?.pincode}
        />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const mobileNumber =
          row?.mobile_number || row?.registered_mobile_number;
        const mobileNumberWithoutCountryCode = mobileNumber.startsWith('91')
          ? mobileNumber.slice(2)
          : mobileNumber;
        return <ListItemCell title={mobileNumberWithoutCountryCode} />;
      }
    },
    {
      field: 'date',
      headerName: t('joinDate'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.created_at
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    }
  ];

  const [colDefs, setColDefs] = useState(columns);

  useEffect(() => {
    const updatedDefs = [...columns];
    if (handleEpr) {
      updatedDefs.push({
        headerName: 'EPR/Non EPR',
        field: 'epr',
        sortable: false,
        disableColumnMenu: true,
        flex: 1.5,
        renderCell: ({ row }) => (
          <ListItemCell title={row?.is_ebr ? 'EPR' : 'Non EPR'} />
        )
      });
    }
    setColDefs(updatedDefs);
  }, [handleEpr]);

  const renderRowActions = (rowData: any) => {
    if (handleEpr) {
      return [
        {
          text: 'View Details',
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        // {
        //   text: t('moveToDustman'),
        //   renderIcon: () => <MoveToInbox />,
        //   onClick: onClickMoveToDustman
        // },
        {
          text: 'Reassign Vendor',
          renderIcon: () => <AssignmentTurnedIn />,
          onClick: onClickReassignVendor
        },

        {
          text: t('reject'),
          renderIcon: () => <Block />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Rejected)
        },
        {
          text: 'Edit',
          renderIcon: () => <Edit />,
          onClick: onClickEditCustomer
        }
      ];
    } else if (handleEpr == false) {
      return [
        {
          text: 'Edit',
          renderIcon: () => <Edit />,
          onClick: onClickEditVendor
        },
        {
          text: 'Commission',
          renderIcon: () => <MonetizationOn />,
          onClick: onClickViewCommission
        },
        {
          text: 'View Details',
          renderIcon: () => <ListAlt />,
          onClick: onClickViewOrder
        },
        {
          text: t('reject'),
          renderIcon: () => <Block />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Rejected)
        }
      ];
    } else {
      return [];
    }
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Name', key: 'name' },
    { label: 'MobileNumber', key: 'registered_mobile_number' },
    { label: 'Join Date', key: 'date' }
  ];

  let excelData =
    tableRowData?.length &&
    tableRowData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.created_at);
      return {
        id: idx + 1,
        company_name: capitalizeFirstLetter(rowData?.company_name),
        name: capitalizeFirstLetter(rowData?.contact_name),
        registered_mobile_number: `${
          rowData?.registered_mobile_number?.startsWith('91')
            ? rowData?.registered_mobile_number?.slice(2)
            : rowData?.registered_mobile_number
        }`,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <MultipleSelectComp
            isPlaceholderNone
            placeholderText={'All'}
            selectWidth={150}
            selectItems={
              regionData?.length &&
              regionData?.map((item: any) => {
                return {
                  label: item.region_name,
                  value: item.region_id
                };
              })
            }
            value={edit.getValue('region_id')}
            borderRadius={40}
            selectHeight={35}
            background={theme.Colors.greyDark}
            iconColor={theme.Colors.primary}
            onChange={handleChangeRegion}
            displayEmpty
            renderValue={(value: any) => {
              return value
                ? regionData?.filter((item) => item.region_id === value)[0]
                    ?.region_name
                : 'All';
            }}
            required
          />
        </Grid>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('location')}
            placeholder="Search Location"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Compass} />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
          />
        </Grid>
        <Grid item>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
      </>
    );
  };

  return (
    <UHTable
      title={
        selectedTab == 1
          ? t('customerManagement.allCustomer')
          : t('customerManagement.allVendor')
      }
      columns={colDefs}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      onSortClick={onSortClick}
      getRowActions={renderRowActions}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={selectedTab == 1 ? 'Customer List' : 'Vendor List'}
      renderSearchBox={renderSearchBox}
    />
  );
};

export default React.memo(CustomerVendorListTable);
