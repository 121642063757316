import { Grid, makeStyles, Theme } from '@material-ui/core';
import { ItemListingComp } from 'src/components';
import { useCallback, useEffect, useState } from 'react';
import AddNewPriceMgntDialog from './addNewPriceMgntDialog';
import { API_SERVICES } from 'src/Services';
import {
  HTTP_STATUSES,
  TRASH_CATEGORY_ID,
  USER_TYPE,
  USER_TYPE_ID
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';

const useStyles = makeStyles<Theme>((theme) => {
  return {
    outerContainer: {
      // margin: theme.spacing(3, 0, 0, 2.5)
    },
    dialogStyle: {
      marginTop: 50
    },
    root: {
      borderRadius: theme.MetricsSizes.large_x,
      paddingInline: theme.spacing(1.5),
      backgroundColor: theme.Colors.whitePrimary
    }
  };
});

function MarkerPrice(props) {
  const classes = useStyles({});
  const { button, regionId } = props;

  const [openAddNewDialog, setOpenAddNewDialog] = useState<any>({
    open: false
  });
  const [commercialUserTrashItemList, setCommercialUserTrashItemList] =
    useState([]);
  const [industryUserTrashItemList, setIndustryUserTrashItemList] = useState(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);
  const fetchData = useCallback(
    async () => {
      try {
        let params: any = {};
        if (debValue !== '') {
          params.searchString = debValue;
        }
        const response: any =
          button == 1
            ? await Promise.all([
                API_SERVICES.priceMgntService.getAllTrashCategory(
                  TRASH_CATEGORY_ID.marketPrice,
                  USER_TYPE_ID.commercial,
                  USER_TYPE.customer,
                  regionId || 0
                ),
                API_SERVICES.priceMgntService.getAllTrashCategory(
                  TRASH_CATEGORY_ID.marketPrice,
                  USER_TYPE_ID.industry,
                  USER_TYPE.customer,
                  regionId || 0
                )
              ])
            : await Promise.all([
                API_SERVICES.priceMgntService.getAllTrashCategory(
                  TRASH_CATEGORY_ID.marketPrice,
                  USER_TYPE_ID.vendorPickup,
                  USER_TYPE.vendor,
                  regionId || 0
                ),
                API_SERVICES.priceMgntService.getAllTrashCategory(
                  TRASH_CATEGORY_ID.marketPrice,
                  USER_TYPE_ID.vendorDrop,
                  USER_TYPE.vendor,
                  regionId || 0
                )
              ]);

        if (response[0]?.status <= HTTP_STATUSES.BAD_REQUEST) {
          if (response[0]?.data?.categories) {
            setCommercialUserTrashItemList(response[0].data.categories);
          }
        }
        if (response[1]?.status <= HTTP_STATUSES.BAD_REQUEST) {
          if (response[1]?.data?.categories) {
            setIndustryUserTrashItemList(response[1].data.categories);
          }
        }
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    },
    [debValue, regionId, button]
  );

  const handleDialogClose = () => {
    setOpenAddNewDialog({ open: false });
  };

  const handleAddNewItem = (
    catTypeId: number,
    userTypeId: number,
    type: number,
    regionId: number
  ) => {
    setOpenAddNewDialog({
      open: true,
      userTypeId: userTypeId,
      categoryTypeId: catTypeId,
      type: type,
      regionId: regionId
    });
  };

  const handleEditListItem = async (item: any) => {
    setOpenAddNewDialog({
      open: true,
      userTypeId: item.user_type_id,
      categoryTypeId: item.category_type_id,
      listItemData: item,
      dialogType: 'edit',
      regionId: regionId
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Grid className={classes.outerContainer}>
        <Grid container spacing={2} justifyContent="space-between"></Grid>
        <Grid
          container
          spacing={4}
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            height: '100%',
            flexWrap: 'nowrap',
            padding: 30
            // overflowX: 'scroll'
          }}
        >
          <Grid item style={{ minWidth: '400px' }}>
            <ItemListingComp
              heading={button == 1 ? t('commercialUser') : t('enquiry.pickUp')}
              listOfItems={commercialUserTrashItemList}
              handleAddNewListItem={() =>
                handleAddNewItem(
                  TRASH_CATEGORY_ID.marketPrice,
                  button == 1
                    ? USER_TYPE_ID.commercial
                    : USER_TYPE_ID.vendorPickup,
                  button == 1 ? USER_TYPE.customer : USER_TYPE.vendor,
                  regionId
                )
              }
              withEditField={true}
              handleEditListItem={handleEditListItem}
              isAvatarImg={true}
            />
          </Grid>
          {button == 1 ? (
            <Grid item style={{ minWidth: '400px' }}>
              <ItemListingComp
                heading={t('industryUser')}
                listOfItems={industryUserTrashItemList}
                handleAddNewListItem={() =>
                  handleAddNewItem(
                    TRASH_CATEGORY_ID.marketPrice,
                    USER_TYPE_ID.industry,
                    USER_TYPE.customer,
                    regionId
                  )
                }
                withEditField={true}
                handleEditListItem={handleEditListItem}
                isAvatarImg={true}
              />
            </Grid>
          ) : null}
        </Grid>
        {openAddNewDialog.open && (
          <AddNewPriceMgntDialog
            handleDialogClose={handleDialogClose}
            updateData={fetchData}
            {...openAddNewDialog}
          />
        )}
      </Grid>
    );
  }
}

export default MarkerPrice;
