import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DialogComp, DialogContentDetails } from 'src/components';
import TransactionTable from './TransactionTable';

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    },
    textStyle: {
      color: '#777777',
      fontSize: 24,
      fontWeight: theme.fontWeight.bold
    },
    input: {
      borderRadius: '40px',
      background: '#D2D2D2'
    }
  };
});

const VendorCustomerWalletViewModal = (props) => {
  const { rowData, onClose } = props;
  const classes = useStyles();

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: 'Contace Name',
        value: rowData?.contace_name
      },
      {
        content: 'Location',
        value: rowData?.location
      },
      {
        content: 'Mobile Number',
        value: rowData?.mobile_number
      }
    ];
    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
        <Grid xs={11} style={{ paddingTop: 20 }}>
          <TransactionTable />
        </Grid>
      </Grid>
    );
  };
  return (
    <DialogComp
      open={true}
      dialogTitle={rowData?.company_name}
      avatarImg={rowData?.image_url || 'DUSTMAN'}
      onClose={onClose}
      renderDialogContent={renderDialogContent}
      dialogClasses={{ paper: classes.dialogPaper }}
    />
  );
};

export default VendorCustomerWalletViewModal;
