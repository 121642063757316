import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer';
import { makeStyles } from '@material-ui/styles';
import { Loader } from 'src/components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowX: 'hidden'
  },
  gridHeight: {
    height: '100%',
    flex: 1
  },
  rightContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  leftContainer: {
    background: theme.Colors.whitePrimary,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

function Login() {
  const styles = useStyles();
  const theme: Theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Box className={styles.root}>
        <Helmet>
          <title>{t('dustman')}</title>
        </Helmet>
        <Grid container className={styles.gridHeight}>
          <Grid item sm={5} className={styles.leftContainer}>
            <LeftContainer />
          </Grid>
          <Grid item xs={12} sm={7} className={styles.rightContainer}>
            <RightContainer setLoading={setLoading} />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Login;
