import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import {
  CustomerEnquiryData,
  enquiryData,
  vendorEnquiryData
} from './AdminStub';

type EnquiryProp = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const enquiryManagementService = {
  getAllEnquiryCalls: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getEnquiryCallProfiles/time/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
    //return enquiryData.enquiryGet;
  },

  updateEnquiryCall: async (
    id: number,
    { data, successMessage, failureMessage }: EnquiryProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateEnquiryDetails/enquiry_management/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  createVendorOrder: async (
    id: number,
    { data, successMessage, failureMessage }: EnquiryProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createVendorOrder/orderer/${id}`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
    //return vendorEnquiryData.vendorGet(id);
  },

  createCustomerOrder: async (
    id: number,
    { data, successMessage, failureMessage }: EnquiryProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createCustomerOrder/orderer/${id}`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
    //return CustomerEnquiryData.CustomerCreate;
  },
  deleteEnquiry: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteEnquiryCall/enquiry_management/${id}`,
      method: 'delete'
    });

    return apiRequest(options);
  }
};
