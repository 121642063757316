import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type defaultProps = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const settingsPageService = {
  createAddress: async ({
    data,
    successMessage,
    failureMessage
  }: defaultProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createDustmanLocation`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateLocation: async (
    id: number,
    {
      successMessage,
      failureMessage
    }: {
      successMessage?: string;
      failureMessage?: string;
    }
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateLocation/${id}`,
      method: 'patch'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  replaceLocation: async (
    id: number,
    { data, successMessage, failureMessage }: defaultProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceLocation/${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  deleteAddress: async (
    id: number,
    {
      successMessage,
      failureMessage
    }: {
      successMessage?: string;
      failureMessage?: string;
    }
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteLocation/${id}`,
      method: 'delete'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  createPincode: async ({
    data,
    successMessage,
    failureMessage
  }: defaultProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createPincode`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllPincode: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllPincode`,
      method: 'get'
    });
    return apiRequest(options);
  },

  replacePincode: async (
    id: number,
    { data, successMessage, failureMessage }: defaultProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replacePincode/${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  deletePincode: async (
    id: number,
    {
      successMessage,
      failureMessage
    }: { successMessage?: string; failureMessage: string }
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deletePincode/${id}`,
      method: 'delete'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  createBanner: async ({
    data,
    successMessage,
    failureMessage
  }: defaultProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createBanner`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllBanner: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllBanner`,
      method: 'get'
    });
    return apiRequest(options);
  },
  updateBanner: async (
    id: number,
    { data, successMessage, failureMessage }: defaultProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateBanner/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  deleteBanner: async (
    id: number,
    {
      successMessage,
      failureMessage
    }: { successMessage?: string; failureMessage?: string }
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteBanner/${id}`,
      method: 'delete'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
