import React, { useRef, useState } from 'react';
import {
  Avatar,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { TextInputComponent } from 'src/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingContainer: {
      padding: theme.spacing(1),
      background: theme.Colors.whitePrimary,
      borderBottom: '3px solid',
      borderBottomColor: theme.Colors.accentGrey
    },
    listItemContainer: {
      padding: theme.spacing(1),
      borderBottom: '1px solid',
      borderBottomColor: theme.Colors.accentGrey
    },
    headingStyle: {
      fontSize: theme.MetricsSizes.regular,
      fontWeight: theme.fontWeight.bold,
      color: theme.Colors.primary
    },
    listItemsStyle: {
      fontSize: theme.MetricsSizes.regular,
      fontWeight: theme.fontWeight.regular,
      color: theme.Colors.greyPrimary
    },
    addNewContainer: {
      padding: theme.spacing(1),
      borderBottom: '1px solid',
      borderBottomColor: theme.Colors.accentGrey,
      background: theme.Colors.lightShadeGrey,
      cursor: 'pointer'
    },
    addNewStyle: {
      fontSize: theme.MetricsSizes.small_xxx,
      fontWeight: theme.fontWeight.mediumBold,
      color: theme.Colors.primary
    },
    imageStyle: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    IconContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    amountFieldStyle: {
      display: 'flex',
      backgroundColor: theme.Colors.whitePrimary,
      padding: theme.MetricsSizes.small_x,
      width: 93,
      height: theme.MetricsSizes.large_x,
      alignItems: 'center'
    },
    textStyle: {
      textTransform: 'capitalize'
    }
  })
);

const VendorCommissionList = ({ heading, listOfItems, handleChange, inputRef }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container direction="column">
      <Grid item className={classes.headingContainer}>
        <Typography className={classes.headingStyle}>{heading}</Typography>
      </Grid>
      <Grid item>
        <List disablePadding>
          {listOfItems?.map((item, index) => {
            return (
              <ListItem key={index} className={classes.listItemContainer}>
                <ListItemAvatar>
                  <Avatar className={classes.imageStyle}>
                    <img src={item?.image_url} alt="image" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemsStyle}
                  primary={item?.name}
                  disableTypography={true}
                />
                <ListItemSecondaryAction className={classes.IconContainer}>
                  <TextInputComponent
                    ref={inputRef}
                    value={item.commission}
                    //type="number"
                    inputWidth={100}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default VendorCommissionList;
