import React, { useState, useCallback } from 'react';
import {
  Grid,
  Typography,
  useTheme,
  IconButton,
  makeStyles,
  Theme
} from '@material-ui/core';
import { ButtonComp, TextInputComponent } from 'src/components';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useNavigate } from 'react-router';
import { HTTP_STATUSES, LOCAL_STORAGE_KEY } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { API_SERVICES } from 'src/Services';
import useUserInfo from 'src/hooks/useUserInfo';
import ForgetPasswordModal from './ForgetPasswordModal';
import LeftContainer from './LeftContainer';
import { useTranslation } from 'react-i18next';

const useStyles: any = makeStyles((theme) => ({
  centerAlign: {
    marginTop: theme.MetricsSizes.regular_xxx
  },
  logoContain: {
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'initial',
      textAlign: 'center',
      margin: theme.MetricsSizes.regular_x
    }
  },
  eyeIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  heading: {
    fontSize: theme.MetricsSizes.medium_x,
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.blueMedium
  },
  subText: {
    fontSize: theme.MetricsSizes.small_xxx,
    fontWeight: theme.fontWeight.regular,
    color: theme.Colors.blueMedium,
    marginTop: theme.MetricsSizes.tiny_xx
  },
  forgotTxt: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: theme.MetricsSizes.tiny_xxx,
    color: theme.Colors.blueGrey
  },
  buttonStyle: {
    background: 'transparent',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-root': {
      padding: 0
    },
    fontWeight: theme.fontWeight.regular
  },
  labelStyle: {
    padding: 0
  },
  boxCard: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingRight: theme.MetricsSizes.large_xxx,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.MetricsSizes.large_x,
      paddingRight: theme.MetricsSizes.small
    }
  },
  PoweredText: {
    fontWeight: theme.fontWeight.medium,
    color: theme.Colors.primary
  },
  unitiveTxt: {
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.MetricsSizes.small_xx,
    color: theme.Colors.blueAsh
  },
  textContainerStyle: {
    marginTop: theme.MetricsSizes.regular_xxx
  }
}));

const RightContainer = ({
  setLoading
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const styles = useStyles();
  const theme: Theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState({ username: '', password: '' });
  const [openModal, setOpenModal] = useState(false);
  const navigateTo = useNavigate();
  const { updateUserInfo } = useUserInfo();
  const { t } = useTranslation();

  const onClickLogin = useCallback(async () => {
    try {
      if (loginForm.username === '' && loginForm.password === '') {
        return toast.error(t('pleaseEnterYourUsernameAndPassword'));
      } else if (loginForm.username === '') {
        return toast.error(t('pleaseEnterYourUsername'));
      } else if (loginForm.password === '') {
        return toast.error(t('pleaseEnterYourPassword'));
      }
      setLoading(true);
      let data = {
        user_name: loginForm.username,
        password: loginForm.password
      };
      const response: any = await API_SERVICES.authService.userLogin({
        data
      });
      if (response?.data?.code < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.data?.length) {
          localStorage.setItem(
            LOCAL_STORAGE_KEY.USER_ID,
            JSON.stringify(response?.data.data[0].id)
          );
          localStorage.setItem(
            LOCAL_STORAGE_KEY.TOKEN,
            JSON.stringify(response?.data?.token)
          );
          const getUserRes: any = await API_SERVICES.userService.getById(
            response?.data.data[0].id
          );
          if (getUserRes?.status < HTTP_STATUSES.BAD_REQUEST) {
            updateUserInfo((prevState) => {
              return { ...prevState, ...getUserRes?.data?.user };
            });
          }
        }
        navigateTo('/dashboards/overview', { replace: true });
      }
    } catch (e) {
      console.log(e, '---login err-----');
    } finally {
      setLoading(false);
    }
  }, [loginForm]);

  const onClickEyeIcon = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container direction="column">
      <Grid container item xs={12}>
        <Grid item xs={12} className={styles.logoContain}>
          <LeftContainer />
        </Grid>
        <Grid item container xs={12} alignItems="center" direction="column">
          <Typography className={styles.heading}>{t('welcome')}</Typography>
          <Typography className={styles.subText}>
            {t('letsBuildSomethingGreat')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={styles.textContainerStyle}
        >
          <Grid item xs={8} className={styles.centerAlign}>
            <TextInputComponent
              inputLabel={t('userName')}
              variant="outlined"
              labelColor={theme.Colors.darkGreen}
              value={loginForm.username}
              size="medium"
              onChange={(e) =>
                setLoginForm({ ...loginForm, username: e.target.value })
              }
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  onClickLogin();
                }
              }}
            />
          </Grid>
          <Grid item xs={8} className={styles.centerAlign}>
            <TextInputComponent
              inputLabel={t('password')}
              variant="outlined"
              labelColor={theme.Colors.darkGreen}
              size="medium"
              type={showPassword ? t('text') : t('password')}
              onChange={(e) =>
                setLoginForm({ ...loginForm, password: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.eyeIcon}
                    onClick={onClickEyeIcon}
                    disableFocusRipple
                    disableRipple
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                )
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  onClickLogin();
                }
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography className={styles.forgotTxt}>
              <ButtonComp
                buttonText={t('forgotPassword')}
                className={styles.buttonStyle}
                onClickButton={() => setOpenModal(!openModal)}
                classes={{ root: styles.labelStyle }}
              />
            </Typography>
          </Grid>

          <Grid item xs={8} style={{ marginTop: theme.MetricsSizes.x_large }}>
            <ButtonComp buttonText={t('login')} onClickButton={onClickLogin} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.boxCard}>
        <Typography className={styles.PoweredText}>
          <span className={styles.unitiveTxt}>{t('poweredBy')}</span>
          {t('unitiveTechnologies')}
        </Typography>
      </Grid>
      {openModal ? (
        <ForgetPasswordModal
          open={openModal}
          onClickButton={() => setOpenModal(false)}
        />
      ) : null}
    </Grid>
  );
};
export default RightContainer;
