import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type createOtpProps = {
  data: { total_quantity_kg: number; total_price: number; order_items: any[] };
  successMessage?: string;
  failureMessage?: string;
};

interface CompleteVendorOrderProp extends Omit<createOtpProps, 'data'> {
  data: { otp: number };
}
export const generateOtpService = {
  create: async (
    id: number,
    { data, successMessage, failureMessage }: createOtpProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/generateOtpToVendorOrder/order/${id}`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  update: async (
    id: number,
    { data, successMessage, failureMessage }: CompleteVendorOrderProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/dustmanCompleteVendorOrder/order/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: { message: successMessage },
      failure: { message: failureMessage }
    };
    return apiRequest(options, toastMessageConfig);
  },
  createAdminCompleteCustomerOrder: async (
    id: number,
    { data, successMessage, failureMessage }: createOtpProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/dustmanGenerateOtpToCustomerOrder/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
