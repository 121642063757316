import {
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import AddNewPriceMgntDialog from './addNewPriceMgntDialog';
import { API_SERVICES } from 'src/Services';
import {
  HTTP_STATUSES,
  TRASH_CATEGORY_ID,
  USER_TYPE,
  USER_TYPE_ID
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import MultipleSelectComp from 'src/components/MultipleSelectComp';
import { TextInputComponent, ItemListingComp } from 'src/components';
import SearchIcon from '@material-ui/icons/Search';
import { useEdit } from 'src/hooks/useEdit';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';

const useStyles = makeStyles<Theme>((theme) => {
  return {
    outerContainer: {
      // margin: theme.spacing(3, 0, 0, 2.5)
    },
    dialogStyle: {
      marginTop: 50
    },
    root: {
      borderRadius: theme.MetricsSizes.large_x,
      paddingInline: theme.spacing(1.5),
      backgroundColor: theme.Colors.whitePrimary
    }
  };
});

function VendorTrashCategory() {
  const classes = useStyles({});
  const [openAddNewDialog, setOpenAddNewDialog] = useState<any>({
    open: false
  });
  // const [homeUserTrashItemList, setHomeUserTrashItemList] = useState([]);
  const [commercialUserTrashItemList, setCommercialUserTrashItemList] =
    useState([]);
  const [industryUserTrashItemList, setIndustryUserTrashItemList] = useState(
    []
  );
  const [regionData, setRegionData] = useState([]);
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const initialValue = {
    id: 0,
    name: ''
  };

  const edit = useEdit(initialValue);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const fetchData = useCallback(async () => {
    try {
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any = await Promise.all([
        API_SERVICES.priceMgntService.getAllTrashCategory(
          TRASH_CATEGORY_ID.vendorTrash,
          USER_TYPE_ID.vendorPickup,
          USER_TYPE.vendor,
          edit.getValue('id') || 0,
          edit.getValue('name') == ''
            ? params
            : { searchString: edit.getValue('name'), ...params }
        ),
        API_SERVICES.priceMgntService.getAllTrashCategory(
          TRASH_CATEGORY_ID.vendorTrash,
          USER_TYPE_ID.vendorDrop,
          USER_TYPE.vendor,
          edit.getValue('id') || 0,
          edit.getValue('name') == ''
            ? params
            : { searchString: edit.getValue('name'), ...params }
        )
      ]);

      if (response[0]?.status <= HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.categories) {
          setCommercialUserTrashItemList(response[0].data.categories);
        }
      }

      if (response[1]?.status <= HTTP_STATUSES.BAD_REQUEST) {
        if (response[1]?.data?.categories) {
          setIndustryUserTrashItemList(response[1].data.categories);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [debValue, edit.edits]);

  const handleDialogClose = () => {
    setOpenAddNewDialog({ open: false });
  };

  const handleAddNewItem = (
    catTypeId: number,
    userTypeId: number,
    type: number,
    regionId: number
  ) => {
    setOpenAddNewDialog({
      open: true,
      userTypeId: userTypeId,
      categoryTypeId: catTypeId,
      type: type,
      regionId: edit.getValue('id')
    });
  };

  const handleEditListItem = async (item: any) => {
    setOpenAddNewDialog({
      open: true,
      userTypeId: item.user_type_id,
      categoryTypeId: item.category_type_id,
      listItemData: item,
      dialogType: 'edit',
      regionId: edit.getValue('id')
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getAllRegion = async () => {
    try {
      setRegionData([]);
      const response: any =
        await API_SERVICES.regionManagementService.getAllRegion();
      if (response?.data?.statusCode <= HTTP_STATUSES.BAD_REQUEST) {
        setRegionData(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRegion();
  }, []);
  if (loading) {
    return <Loader />;
  } else {
    return (
      <Grid className={classes.outerContainer}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={4} style={{ padding: 30 }}>
            <MultipleSelectComp
              isPlaceholderNone
              selectItems={
                regionData?.length &&
                regionData?.map((item: any) => {
                  return {
                    label: item.region_name,
                    value: item.region_id
                  };
                })
              }
              value={edit.getValue('id')}
              borderRadius={40}
              selectHeight={35}
              background={theme.Colors.greyDark}
              iconColor={theme.Colors.primary}
              //selectBoxStyle={{ minWidth: 200 }}
              //classes={{ root: classes.selectStyle }}
              onChange={(e) => {
                edit.update({
                  id: e.target.value
                });
                //fetchData(e.target.value);
              }}
              // isError={isError}
              // helperText={helperText}
              displayEmpty
              placeholderText={'All'}
              renderValue={(value: any) => {
                return value
                  ? regionData?.filter((item) => {
                      return item.region_id === value;
                    })[0]?.region_name
                  : 'All';
              }}
              required
            />
          </Grid>
          <Grid xs={4} style={{ padding: 30 }}>
            <TextInputComponent
              fullWidth={true}
              type="search"
              inputHeight={35}
              placeholder="Search..."
              onChange={(e) => {
                edit.update({ name: e.target.value });
              }}
              value={edit.getValue('name')}
              borderColor="white"
              InputProps={{
                classes: {
                  root: classes.root
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            height: '100%',
            flexWrap: 'nowrap',
            padding: 30
            // overflowX: 'scroll'
          }}
        >
          <Grid item style={{ minWidth: '400px' }}>
            <ItemListingComp
              heading={t('enquiry.pickUp')}
              listOfItems={commercialUserTrashItemList}
              handleAddNewListItem={() =>
                handleAddNewItem(
                  TRASH_CATEGORY_ID.vendorDropTrash,
                  USER_TYPE_ID.vendorPickup,
                  USER_TYPE.vendor,
                  edit.getValue('id')
                )
              }
              withEditField={true}
              handleEditListItem={handleEditListItem}
              isAvatarImg={true}
            />
          </Grid>
          <Grid item style={{ minWidth: '400px' }}>
            <ItemListingComp
              heading={t('enquiry.drop')}
              listOfItems={industryUserTrashItemList}
              handleAddNewListItem={() =>
                handleAddNewItem(
                  TRASH_CATEGORY_ID.vendorDropTrash,
                  USER_TYPE_ID.vendorDrop,
                  USER_TYPE.vendor,
                  edit.getValue('id')
                )
              }
              withEditField={true}
              handleEditListItem={handleEditListItem}
              isAvatarImg={true}
            />
          </Grid>
        </Grid>
        {openAddNewDialog.open && (
          <AddNewPriceMgntDialog
            handleDialogClose={handleDialogClose}
            updateData={fetchData}
            {...openAddNewDialog}
          />
        )}
      </Grid>
    );
  }
}

export default VendorTrashCategory;
