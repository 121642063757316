import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Login from 'src/content/Login';
import Overview from 'src/content/Overview';
import CustomerOrder from 'src/content/CustomerOrder';
import VendorOrder from 'src/content/VendorOrder';
import UserManagement from 'src/content/UserManagement';
import PriceManagement from 'src/content/PriceManagement';
import EnquiryManagement from 'src/content/EnquiryManagement';
import Reports from 'src/content/Reports';
import Settings from 'src/content/Settings';
import PrivateRoute from './PrivateRoute';
import { Status404 } from './components';
import PermissionRoute from './PermissionRoute';
import { PERMISSIONS } from './Config/constant';
import WalletManagement from './content/WalletManagement';
import CustomerVendorList from './content/CustomerVendorList';
import InvoiceManagement from './content/InvoiceManagement';
import NewRequest from './content/NewRequest';
import CustomerDetails from './content/CustomerVendorList/CustomerDetails';
import VendorDetails from './content/CustomerVendorList/VendorDetails';
import CustomerManagement from './content/CustomerManagement';
import DownloadInvoice from './content/InvoiceManagement/DownloadInvoice';
import CustomerVendorViewDetails from 'src/content/WalletManagement/CustomerVendorViewDetails';
import AdminWallet from './content/AdminWallet';

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <PrivateRoute path="/dashboards" />
      },
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <PrivateRoute component={SidebarLayout} />,
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        element: (
          <PermissionRoute
            component={Overview}
            permissionId={PERMISSIONS.overview}
          />
        )
      },
      {
        path: 'new-request',
        element: (
          <PermissionRoute
            component={NewRequest}
            permissionId={PERMISSIONS.newRequest}
          />
        )
      },
      {
        path: 'customer-order',
        element: (
          <PermissionRoute
            component={CustomerOrder}
            permissionId={PERMISSIONS.customerOrder}
          />
        )
      },
      {
        path: 'dustman-order',
        element: (
          <PermissionRoute
            component={VendorOrder}
            permissionId={PERMISSIONS.dustmanOrder}
          />
        )
      },
      {
        path: 'customer-management',
        element: (
          <PermissionRoute
            component={CustomerManagement}
            permissionId={PERMISSIONS.customerManagement}
          />
        )
      },
      {
        path: 'customer-vendor-list',
        element: (
          <PermissionRoute
            component={CustomerVendorList}
            permissionId={PERMISSIONS.customerVendorList}
          />
        )
      },
      {
        path: 'wallet-management',
        element: (
          <PermissionRoute
            component={WalletManagement}
            permissionId={PERMISSIONS.walletManagement}
          />
        )
      },
      {
        path: 'admin-wallet',
        element: (
          <PermissionRoute
            component={AdminWallet}
            permissionId={PERMISSIONS.adminWallet}
          />
        )
      },
      {
        path: 'invoice-management',
        element: (
          <PermissionRoute
            component={InvoiceManagement}
            permissionId={PERMISSIONS.invoiceManagement}
          />
        )
      },
      {
        path: 'price-management',
        element: (
          <PermissionRoute
            component={PriceManagement}
            permissionId={PERMISSIONS.priceManagement}
          />
        )
      },
      {
        path: 'enquiry-management',
        element: (
          <PermissionRoute
            component={EnquiryManagement}
            permissionId={PERMISSIONS.enquiryManagement}
          />
        )
      },

      {
        path: 'user-management',
        element: (
          <PermissionRoute
            component={UserManagement}
            permissionId={PERMISSIONS.userManagement}
          />
        )
      },
      {
        path: 'reports',
        element: (
          <PermissionRoute
            component={Reports}
            permissionId={PERMISSIONS.reports}
          />
        )
      },
      {
        path: 'settings',
        element: (
          <PermissionRoute
            component={Settings}
            permissionId={PERMISSIONS.settings}
          />
        )
      },
      {
        path: 'customer-vendor-list/customer-details',
        element: (
          <PermissionRoute
            component={CustomerDetails}
            permissionId={PERMISSIONS.customerVendorList}
          />
        )
      },
      {
        path: 'customer-vendor-list/vendor-details',
        element: (
          <PermissionRoute
            component={VendorDetails}
            permissionId={PERMISSIONS.customerVendorList}
          />
        )
      },
      {
        path: 'invoice-management/invoice-details',
        element: (
          <PermissionRoute
            component={DownloadInvoice}
            permissionId={PERMISSIONS.invoiceManagement}
          />
        )
      },
      {
        path: 'view-details',
        element: (
          <PermissionRoute
            component={CustomerVendorViewDetails}
            permissionId={PERMISSIONS.walletManagement}
          />
        )
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default routes;
