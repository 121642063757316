import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { PriceSheet } from 'src/Assets';
import { ContentDisplayTiles, ListItemCell } from 'src/components';
import AssignedVendors from './AssignedVendors';
import StockUpdate from './StockUpdate';
import OrderHistory from './OrderHistory';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme) => {
  return {
    avatarStyle: {
      width: 64,
      height: 64
    },
    dividerStyle: {}
  };
});

const CustomerDetails = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const rowData: any = state?.rowData;

  const customerTabValues = [
    {
      heading: t('customerVendorList.assignedVendor'),
      iconImage: PriceSheet,
      value: 0
    },
    {
      heading: t('customerVendorList.stockUpdate'),
      iconImage: PriceSheet,
      value: 1
    },
    {
      heading: t('customerVendorList.orderHistory'),
      iconImage: PriceSheet,
      value: 2
    }
  ];

  const [selectedTab, setSelectedTab] = useState<string | number>(
    customerTabValues[0].value
  );
  const [vendorDetails, setVendorDetails] = useState<any>([]);
  const [customerStock, setCustomerStock] = useState<any>([]);
  const [orderHistory, setOrderHistory] = useState<any>();

  const handleSelectedTab = (value: number | string) => {
    setSelectedTab(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return value === index && <>{children}</>;
  }

  const fetchData = async () => {
    try {
      setVendorDetails([]);
      setCustomerStock([]);
      let region: any = { region_id: rowData?.customer_id };
      const response: any = await Promise.all([
        API_SERVICES.customerVendorListService.listAssignedVendors(
          rowData?.customer_id
        ),
        API_SERVICES.customerVendorListService.getCustomerStockDetails(
          rowData?.customer_id
        ),
        API_SERVICES.customerVendorListService.getCustomerOrderHistory(
          rowData?.customer_id
        )
      ]);
      if (
        response[0]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[0]?.data?.data?.length
      ) {
        setVendorDetails(response[0]?.data?.data);
      }
      if (
        response[1]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[1]?.data?.stockDetails?.combined_data?.length
      ) {
        setCustomerStock(response[1]?.data?.stockDetails?.combined_data);
      }
      if (
        response[2]?.status < HTTP_STATUSES.BAD_REQUEST &&
        response[2]?.data
      ) {
        setOrderHistory(response[2]?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ListItemCell
        avatarImg={rowData?.image_url || 'DustMan'}
        title={capitalizeFirstLetter(rowData?.contact_name)}
        titleStyle={{
          fontSize: theme.MetricsSizes.medium_xx,
          color: theme.Colors.blackPrimary,
          fontWeight: theme.fontWeight.medium
        }}
        avatarClassNameStyles={styles.avatarStyle}
      />
      <ContentDisplayTiles
        displayContent={customerTabValues}
        isTileTypeOrders={true}
        onTabChange={handleSelectedTab}
        tabValue={selectedTab}
        tabWidth={true}
      />
      <Divider
        style={{
          background:
            selectedTab == 0 ? theme.Colors.mediumBlack : theme.Colors.lightBlue
        }}
      />
      <TabPanel value={selectedTab} index={0}>
        <AssignedVendors details={vendorDetails} updateData={fetchData} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <StockUpdate
          headingText={t('customerVendorList.customerStock')}
          stockDetails={customerStock}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <OrderHistory orderHistory={orderHistory} />
      </TabPanel>
    </>
  );
};

export default CustomerDetails;
