import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { RupeeSymbol } from 'src/Assets';
import { ButtonComp, TextInputComponent } from 'src/components';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import OTPInput from 'otp-input-react';

const useStyles = makeStyles((theme) => {
  return {
    gridStyle: {
      justifyContent: 'space-between',
      background: theme.Colors.secondary,
      color: theme.Colors.white,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 13,
      marginBottom: 10
    },
    textStyle: { fontWeight: theme.fontWeight.medium, fontSize: 16 },
    containerStyle: {
      border: '1px solid',
      borderRadius: 10,
      borderColor: theme.Colors.lightShadeGrey,
      marginTop: 15
    },
    badgeStyle: {
      width: 44,
      height: 43,
      borderRadius: 50,
      backgroundColor: theme.Colors.secondary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      right: 90
    },
    titleStyle: {
      fontSize: 14,
      fontWeight: theme.fontWeight.bold,
      color: theme.Colors.black,
      paddingBottom: 10
    },
    amountStyle: {
      color: theme.Colors.white,
      fontSize: 11,
      fontWeight: theme.fontWeight.bold,
      paddingLeft: 5
    },
    totalStyle: {
      backgroundColor: theme.Colors.secondary,
      color: theme.Colors.white,
      padding: 6,
      textAlign: 'center',
      borderBottomRightRadius: 15,
      position: 'absolute',
      fontSize: 11,
      fontWeight: theme.fontWeight.bold
    },
    inputOtpStyle: {
      background: 'transparent',
      border: 'none',
      borderBottom: '2px solid black',
      fontWeight: theme.fontWeight.mediumBold,
      fontSize: '16px'
    },
    otpStyle: {
      padding: theme.spacing(2, 2, 2, 0),
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.MetricsSizes.small_xx,
      color: theme.Colors.primary
    },
    imageStyle: {
      width: 145,
      borderRadius: 12,
      height: 100,
      border: '1px solid rgba(0, 0, 0, 0.2)'
    }
  };
});
const CompleteOrder = (props) => {
  const {
    rowData,
    handleChange,
    // onBrowseButtonClick,
    inputRef,
    totalPrice,
    totalQuantity,
    edit,
    isOtpGenerate
  } = props;
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  console.log(rowData, 'rowData');
  const [imageData, setImageData] = useState<any>([]);

  useEffect(() => {
    setImageData(rowData);
  }, [rowData]);

  const onBrowseButtonClick = async (event: any, idx) => {
    try {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      let img = new Image();
      img.src = window.URL.createObjectURL(event.target.files[0]);
      img.onload = async () => {
        const uploadImageRes: any =
          await API_SERVICES.imageUploadService.uploadImage(formData);
        if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (uploadImageRes?.data?.images?.length) {
            let updatedOrderItems = [...rowData.order_item];
            updatedOrderItems[idx].final_image =
              uploadImageRes.data.images[0].Location;
            rowData?.order_item?.forEach((item) => {
              if (item.price === 0 && item.final_quantity === 0) {
                item.final_quantity = item.category_quantity;
                item.price = item.category_price * item.final_quantity;
              }
            });
            setImageData({ ...rowData, updatedOrderItems });
          }
        }
      };
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  return (
    <Grid>
      <Box style={{ position: 'relative', marginBottom: 30 }}>
        {imageData?.order_item?.length &&
          imageData?.order_item?.map((item, idx) => {
            let quantity = item?.category_quantity;
            return (
              <Grid
                xs={10}
                key={idx}
                style={{
                  display: 'flex'
                }}
              >
                <Grid container item xs className={styles.containerStyle}>
                  <Grid container item xs>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <img
                        className={styles.imageStyle}
                        src={item?.image_url}
                      />
                    </Grid>
                    <Grid style={{ alignSelf: 'center', paddingLeft: 20 }}>
                      <Typography className={styles.titleStyle}>
                        {item.category_name}
                      </Typography>
                      <Grid style={{ display: 'inline-flex' }}>
                        <TextInputComponent
                          ref={inputRef}
                          value={quantity}
                          type="number"
                          // borderColor="transparent"
                          inputWidth={150}
                          onChange={(e) => {
                            handleChange(
                              idx,
                              e?.target?.value,
                              item?.category_price
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Kgs
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end'
                    }}
                  >
                    {item?.final_image ? (
                      <>
                        <img
                          className={styles.imageStyle}
                          src={item.final_image}
                        />
                        <Typography
                          ref={inputRef}
                          className={styles.totalStyle}
                        >
                          {`${item.final_quantity} Kg`}
                        </Typography>
                      </>
                    ) : (
                      <ButtonComp
                        backgroundColor={theme.Colors.primary}
                        buttonText={t('button.browse')}
                        buttonFontSize={theme.MetricsSizes.small_xxx}
                        buttonTextColor="white"
                        height={30}
                        buttonFontWeight={theme.fontWeight.medium}
                        disableElevation={true}
                        onBrowseButtonClick={(e) => onBrowseButtonClick(e, idx)}
                        isBrowseButton
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item className={styles.badgeStyle}>
                  <img src={RupeeSymbol} />
                  <Typography className={styles.amountStyle}>
                    {item?.category_price}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Box>
      <Grid container xs={10} className={styles.gridStyle}>
        <Typography className={styles.textStyle}>Weight</Typography>
        <Typography
          className={styles.textStyle}
        >{`${totalQuantity} Kgs`}</Typography>
      </Grid>
      <Grid container xs={10} className={styles.gridStyle}>
        <Typography className={styles.textStyle}>Price</Typography>
        <>
          <Typography className={styles.textStyle}>
            <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
            {totalPrice}
          </Typography>
        </>
      </Grid>
      {isOtpGenerate ? (
        <Grid item xs={10}>
          <Typography className={styles.otpStyle}>Enter Otp</Typography>
          <OTPInput
            OTPLength={6}
            otpType="number"
            autoFocus={false}
            inputClassName={styles.inputOtpStyle}
            value={edit.getValue('otp')}
            onChange={(val: any) => {
              edit.update({ otp: val });
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
export default CompleteOrder;
