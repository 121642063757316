import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  InputAdornment,
  Theme,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import VendorCommissionList from './VendorCommissionList';
import { DualActionButton, TextInputComponent } from 'src/components';
import { useEdit } from 'src/hooks/useEdit';
import SearchIcon from '@material-ui/icons/Search';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      borderRadius: theme.MetricsSizes.large_x,
      paddingInline: theme.spacing(1.5),
      backgroundColor: theme.Colors.whitePrimary
    }
  };
});

const VendorCommission = ({ rowData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [commissionData, setCommissionData] = useState<any>({});
  const [listOfCommercialItems, setListOfCommercialItems] = useState([]);
  const [listOfIndustryItems, setListOfIndustryItems] = useState([]);

  const initialValue = {
    search: ''
  };

  const edit = useEdit(initialValue);

  const fetchData = async () => {
    try {
      setCommissionData([]);
      let searchData = { searchString: edit.getValue('search') };
      const response: any =
        await API_SERVICES.customerVendorListService.getVendorCommission(
          rowData?.vendor_id,
          searchData
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST && response?.data) {
        setCommissionData(response?.data?.commissionDetails);
        setListOfCommercialItems(
          response?.data?.commissionDetails?.commercial_commissions
        );
        setListOfIndustryItems(
          response?.data?.commissionDetails?.industrial_commissions
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [edit.edits]);

  const handleChangeCommercial = (idx, commission) => {
    const commissionValue = Number(commission);
    if (isNaN(commissionValue) || commissionValue < 0 || commissionValue > 99) {
      toast.error("Please Enter Commission Below 100%");
      return; 
    }
    const updatedCommission = listOfCommercialItems.map((item, index) =>
      index === idx ? { ...item, commission: commissionValue } : item
    );
    setListOfCommercialItems(updatedCommission);
  };
  
  const handleChangeIndustry = (idx, commission) => {
    const commissionValue = Number(commission);
    if (isNaN(commissionValue) || commissionValue < 0 || commissionValue > 99) {
      toast.error("Please Enter Commission Below 100%");
      return; 
    }
    const updatedCommission = listOfIndustryItems.map((item, index) =>
      index === idx ? { ...item, commission: commissionValue } : item
    );
    setListOfIndustryItems(updatedCommission);
  };
  

  const handleUpdateCommission = async () => {
    try {
      let commission_list = listOfCommercialItems?.map(
        (item) => {
          return {
            id: Number(item.id),
            commercial_commission: Number(item.commission)
          };
        }
      );
      let industrial_list = listOfIndustryItems?.map((item) => {
        return {
          id: Number(item.id),
          industrial_commission: Number(item.commission)
        };
      });
      let payload = {
        user_type: commissionData?.user_type,
        user_id: commissionData?.user_id,
        type: 1,
        commercial_list: commission_list,
        industrial_list: industrial_list
      };
      const response: any =
        await API_SERVICES.customerVendorListService.updateVendorCommission(
          commissionData?.id,
          {
            data: payload,
            successMessage: 'Commission Details Updated Successfully'
          }
        );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" style={{ marginTop: 20 }}>
        <Grid item>
          <Typography
            style={{
              fontSize: theme.MetricsSizes.medium,
              fontWeight: theme.fontWeight.regular
            }}
          >
            Commission Details
          </Typography>
        </Grid>
        <Grid item>
          <TextInputComponent
            fullWidth={true}
            type="search"
            inputHeight={35}
            placeholder="Search..."
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
            value={edit.getValue('search')}
            borderColor="white"
            InputProps={{
              classes: {
                root: classes.root
              },
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ padding: 20 }}>
        <Grid item xs={5}>
          <VendorCommissionList
            heading={'Commercial User'}
            listOfItems={listOfCommercialItems}
            handleChange={handleChangeCommercial}
            inputRef={inputRef}
          />
        </Grid>
        <Grid item xs={5}>
          <VendorCommissionList
            heading={'Industrial User'}
            listOfItems={listOfIndustryItems}
            handleChange={handleChangeIndustry}
            inputRef={inputRef}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <DualActionButton
          onLeftButtonClick={() => {}}
          onRightButtonClick={handleUpdateCommission}
        />
      </Grid>
    </>
  );
};

export default VendorCommission;
