import { useCallback, useEffect, useState } from 'react';
import { NewOrder, Completed, Scheduled, Cancelled } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  Heading,
  UHConfirmModal,
  Loader,
  MultiSelectChip,
  UHCalenderComp
} from 'src/components';
import { Box, Divider, makeStyles, Theme, useTheme } from '@material-ui/core';
import CustomerTable from './CustomerTable';
import CustomerOrderModal from './CustomerOrderModal';
import { API_SERVICES } from 'src/Services';
import {
  CONFIRM_MODAL,
  CUSTOMER_ORDER_STATUS,
  FILTER_CHIPS,
  GET_FILTER_VALUES,
  HTTP_STATUSES,
  ORDERS_COUNT
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import CustomerViewVendorModal from '../CustomerManagement/CustomerViewVendorModal';
import { useEdit } from 'src/hooks/useEdit';
import CompleteCustomerOrderByAdmin from './CompleteCustomerOrderByAdmin';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      padding: theme.spacing(0, 2, 0, 5)
    }
  };
});

type CustomerOrders = {
  cancelled: any[];
  confirmed: any[];
  new: any[];
  scheduled: any[];
  pending: any[];
};

type CustomerOrderCount = {
  cancelledOrderCount: number;
  confirmedOrderCount: number;
  newOrderCount: number;
  acceptedOrderCount: number;
  pendingOrderCount: number;
};

const statusType = [
  { id: 4, name: 'Assign Vendor' },
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Renew Vendor' },
  { id: 5, name: 'Reassign Vendor' }
];

const statusIdType = [
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Vehicle Alloted' },
  { id: 5, name: 'Over Due' },
  { id: 6, name: 'Pending Invoice' }
];

const CustomerOrder = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [customerOrderCount, setCustomerOrderCount] =
    useState<CustomerOrderCount>(ORDERS_COUNT);
  const customerOrderDetailsTabValues = [
    {
      heading: t('newOrder'),
      subText: customerOrderCount.newOrderCount,
      iconImage: NewOrder,
      value: 'new'
    },
    {
      heading: t('assignedOrder'),
      subText: customerOrderCount.acceptedOrderCount,
      iconImage: Scheduled,
      value: 'scheduled'
    },
    {
      heading: t('completed'),
      subText: customerOrderCount.confirmedOrderCount,
      iconImage: Completed,
      value: 'confirmed'
    },
    {
      heading: t('cancelled'),
      subText: customerOrderCount.cancelledOrderCount,
      iconImage: Cancelled,
      value: 'cancelled'
    }
  ];
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    customerOrderDetailsTabValues[0].value
  );
  const [customerOrderChipFilter, setCustomerOrderChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [tableData, setTableData] = useState<CustomerOrders>({
    cancelled: [],
    confirmed: [],
    new: [],
    scheduled: [],
    pending: []
  });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [assignModal, setAssignModal] = useState<any>({ open: false });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const [orderCompleteOpen, setOrderCompleteOpen] = useState<any>({
    open: false
  });

  const initialValue = {
    date: '',
    status: '',
    location: ''
  };
  const edit = useEdit(initialValue);
  const classes = useStyles();
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChangeCustomerOrderChip = (selectedChipItem: string[]) => {
    setCustomerOrderChipFilter(selectedChipItem);
    edit.update({ date: '', location: '', status: '' });
  };

  const handleSelectedTab = (value: number | string) => {
    setSelectedTab(value);
    edit.update({ date: '', location: '', status: '' });
  };

  const fetchData = useCallback(async () => {
    try {
      const params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      let searchData = {
        toDate: edit.getValue('date').split('to')[1],
        fromDate: edit.getValue('date').split('to')[0],
        status: edit.getValue('status') == 4 ? 0 : edit.getValue('status'),
        searchString: edit.getValue('location'),
        ...params
      };
      let searchDataById = {
        toDate: edit.getValue('date').split('to')[1],
        fromDate: edit.getValue('date').split('to')[0],
        status_id: edit.getValue('status'),
        searchString: edit.getValue('location'),
        ...params
      };
      const response: any = await API_SERVICES.customerOrderService.getAllBy(
        GET_FILTER_VALUES[customerOrderChipFilter[0]],
        selectedTab == customerOrderDetailsTabValues[0].value
          ? searchData
          : searchDataById
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (
          response?.data?.customerOrders &&
          response?.data?.customerOrderCount
        ) {
          setCustomerOrderCount(response?.data?.customerOrderCount);
          setTableData((prev) => {
            return { ...prev, ...response?.data?.customerOrders };
          });
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [customerOrderChipFilter, debValue, edit.edits]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const onActionButtonClick = (rowData?: any) => {
    setModalOpen({ open: false });
    if (
      rowData?.status === CUSTOMER_ORDER_STATUS['newOrder'] ||
      rowData?.status == 2 ||
      (rowData?.status === 5 && rowData?.status_id === 0)
    ) {
      setAssignModal({ open: true, rowData: rowData });
    }
    if (rowData?.status == 3 && rowData?.status_id == 3) {
      setModalOpen({ open: false });
    }
    if (rowData?.is_ready_to_upload) {
      return;
    }
  };

  const onClickReassignVendor = (rowData?: any) => {
    setAssignModal({ open: true, rowData: rowData });
  };

  const onClickCancelOrder = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        cancelled_by: 2
        //orders: [rowData?.id]
      };
      const response: any = await API_SERVICES.customerOrderService.cancelOrder(
        rowData?.order_id,
        {
          data: updateData,
          successMessage: 'Customer order cancelled successfully!'
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('customerOrder.cancelCustomerOrder'),
      title: t('customerOrder.cancel'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickMoveToDustman = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let data = {
        orderer_address_id: rowData?.orderer_address_id,
        customer_id: rowData?.customer_id
      };
      const response: any =
        await API_SERVICES.customerOrderService.moveToDustman({
          data: data,
          successMessage: t('customerOrder.moveToDustmanSuccess')
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.primary,
      description: t('customerOrder.customerOrderMoveToDustman'),
      title: t('customerOrder.moveToDustman'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickViewOrder = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData, isHeading: true });
  };

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    // let new =
    console.log(
      dateSelect[0].startDate,
      `${fromDate[1]}/${fromDate[0]}/${fromDate[2]}`,
      '<-------'
    );

    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };
  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  const onClickCompleteOrder = (rowData: any) => {
    setOrderCompleteOpen({ open: true, rowData: rowData });
  };

  const onClickDownloadInvoice = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData, isHeading: false });
  };

  const handleClickDownload = async (rowData: {
    invoice_url: RequestInfo | URL;
  }) => {
    try {
      const url = `${rowData?.invoice_url}?timestamp=${Date.now()}`;
      console.log('URL:', url);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/octet-stream'
        }
      });

      console.log('Response:', response);
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      console.log('Blob size:', blob.size, 'Blob type:', blob.type);

      // Extract the filename from the URL
      const fileName =
        rowData?.invoice_url.toString().split('/').pop()?.split('?')[0] ||
        'downloaded-file';

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName; // Use the extracted filename
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl); // Clean up
    } catch (error) {
      console.log('There was an error downloading the file:', error);
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Heading headingText={t('customerOrders')} />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={customerOrderChipFilter}
          handleChange={handleChangeCustomerOrderChip}
          chipStyle={{
            //cannot apply makestyle styles
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={customerOrderDetailsTabValues}
          isTileTypeOrders={true}
          onTabChange={handleSelectedTab}
          tabValue={selectedTab}
        />
        <Divider style={{ backgroundColor: theme.Colors.lightBlue }} />
        <Box sx={{ mt: 3 }}>
          <CustomerTable
            onClickActionButton={onActionButtonClick}
            tableRowData={tableData[selectedTab]}
            onClickViewOrder={onClickViewOrder}
            onClickCancelOrder={onClickCancelOrder}
            onClickMoveToDustman={onClickMoveToDustman}
            selectedTab={selectedTab}
            renderActionBtn={
              selectedTab === customerOrderDetailsTabValues[0].value
            }
            onClickReassignVendor={onClickReassignVendor}
            handleOpenCalendar={handleOpenCalendar}
            edit={edit}
            onClickCompleteOrder={onClickCompleteOrder}
            onClickDownloadInvoice={onClickDownloadInvoice}
            statusType={
              selectedTab === customerOrderDetailsTabValues[0].value
                ? statusType
                : statusIdType
            }
            handleChangeStatus={(e) => {
              edit.update({
                status: e.target.value
              });
            }}
            customerOrderChipFilter={customerOrderChipFilter[0]}
          />
        </Box>
        {modalOpen.open && (
          <CustomerOrderModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
            handleAssignVendor={onActionButtonClick}
            handleClickDownload={handleClickDownload}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {assignModal.open ? (
          <CustomerViewVendorModal
            onClose={() => setAssignModal({ open: false })}
            {...assignModal}
            updateData={fetchData}
          />
        ) : null}
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
        {orderCompleteOpen?.open ? (
          <CompleteCustomerOrderByAdmin
            onClose={() => setOrderCompleteOpen({ open: false })}
            open={orderCompleteOpen?.open}
            {...orderCompleteOpen}
            updateData={fetchData}
          />
        ) : null}
      </>
    );
  }
};

export default CustomerOrder;
