import React from 'react';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import ImageBoxContainer from '.';
import { RupeeSymbol } from 'src/Assets';
import { CUSTOMER_ORDER_STATUS } from 'src/Config/constant';

const useStyles = makeStyles((theme) => ({
  headingStyle: {
    color: '#333333',
    fontSize: 24,
    fontWeight: theme.fontWeight.bold,
    opacity: 0.5,
    textAlign: 'center',
    paddingRight: 5
  },
  gridStyle: {
    justifyContent: 'space-between',
    background: theme.Colors.secondary,
    color: theme.Colors.white,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 13,
    marginBottom: 10
  },
  textStyle: { fontWeight: theme.fontWeight.medium, fontSize: 16 }
}));

const ImageHeading = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, isHeading } = props;

  return (
    <>
      {isHeading ? (
        <Grid container style={{ paddingTop: 20, paddingBottom: 10, gap: 10 }}>
          <Typography className={styles.headingStyle}>
            Category Details
          </Typography>
          <Typography className={styles.headingStyle}>-</Typography>
          <Typography className={styles.headingStyle}>
            {data?.is_ready_to_upload ? 'Delivery Summary' : 'Order Summary'}
          </Typography>
        </Grid>
      ) : null}
      <Grid style={{ paddingBottom: 20 }}>
        <ImageBoxContainer
          data={data?.order_item}
          statusId={data?.status_id}
          isUpload={data?.is_ready_to_upload}
        />
      </Grid>
      {data?.status_id === CUSTOMER_ORDER_STATUS['completed'] ||
      data?.is_ready_to_upload ? (
        <Grid container xs={10} className={styles.gridStyle}>
          <Typography className={styles.textStyle}>Weight</Typography>
          <Typography
            className={styles.textStyle}
          >{`${data?.quantity_kg} Kgs`}</Typography>
        </Grid>
      ) : null}
      {data?.status_id === CUSTOMER_ORDER_STATUS['completed'] ||
      data?.is_ready_to_upload ? (
        <Grid container xs={10} className={styles.gridStyle}>
          <Typography className={styles.textStyle}>Price</Typography>
          <>
            <Typography className={styles.textStyle}>
              <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
              {data?.total_price}
            </Typography>
          </>
        </Grid>
      ) : null}
    </>
  );
};

export default ImageHeading;
