import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const generalService = {
  getVehicles: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVehicles`,
      method: 'get'
    });
    return apiRequest(options);
  },

  getVendorAddress: async (vendorId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerVendorOrderAddresss/vendor/${vendorId}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  getDustmanLocation: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getDustmanLocation`,
      method: 'get'
    });
    return apiRequest(options);
  },

  getCustomerAddress: async (customerId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerContacts/customer/${customerId}`,
      method: 'get'
    })
    return apiRequest(options);
  }
};
