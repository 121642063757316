import { useCallback, useEffect, useState } from 'react';
import { NewOrder, Completed, Cancelled } from 'src/Assets/Images';
import {
  Heading,
  UHConfirmModal,
  Loader,
  MultiSelectChip,
  ContentDisplayTiles
} from 'src/components';
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import {
  CONFIRM_MODAL,
  CUSTOMER_REGIS_COUNT,
  GET_FILTER_VALUES,
  HTTP_STATUSES,
  VENDOR_MG_STATUS,
  VENDOR_REGIS_COUNT
} from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import CustomerVendorTable from './CustomerVendorTable';
import { useEdit } from 'src/hooks/useEdit';
import CustomerVendorViewModal from './CustomerVendorViewModal';
import EditCustomerVendor from './EditCustomerVendor';

const FILTER_CHIPS = ['Today', 'This Week', 'This Month', 'All'];

const useStyles = makeStyles((theme) => ({
  dividerStyle: {
    background: theme.Colors.black
  },
  containerStyle: {
    paddingRight: theme.spacing(2.8)
  },
  headingStyle: {
    paddingLeft: theme.spacing(2.5)
  }
}));

type VendorOrders = {
  rejected: any[];
  approved: any[];
  newRequest: any[];
};

type VendorCount = {
  approved: number;
  newRequest: number;
  rejected: number;
};

function CustomerVendorManagement(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { buttonVal } = props;
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [vendorCount, setVendorCount] =
    useState<VendorCount>(VENDOR_REGIS_COUNT);
  const [customerCount, setCustomerCount] = useState(CUSTOMER_REGIS_COUNT);

  const vendorRequestTabValue = [
    {
      heading: t('overview.newRequest'),
      subText: vendorCount.newRequest,
      iconImage: NewOrder,
      value: 'newRequest'
    },
    {
      heading: t('overview.approved'),
      subText: vendorCount.approved,
      iconImage: Completed,
      value: 'approved'
    },
    {
      heading: t('overview.rejected'),
      subText: vendorCount.rejected,
      iconImage: Cancelled,
      value: 'rejected'
    }
  ];

  const customerRequestTabValue = [
    {
      heading: t('overview.newRequest'),
      subText: customerCount.newRequest,
      iconImage: NewOrder,
      value: 'newRequest'
    },
    {
      heading: t('overview.approved'),
      subText: customerCount.approved,
      iconImage: Completed,
      value: 'approved'
    },
    {
      heading: t('overview.rejected'),
      subText: customerCount.rejected,
      iconImage: Cancelled,
      value: 'rejected'
    }
  ];

  const [selectedTab, setSelectedTab] = useState<string | number>(
    customerRequestTabValue[0].value
  );

  const [vendorSelectedTab, setVendorSelectedTab] = useState<string | number>(
    vendorRequestTabValue[0].value
  );
  const [vendorChipFilter, setVendorChipFilter] = useState([FILTER_CHIPS[0]]);
  const [customerChipFilter, setCustomerChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [openVendorCreateModal, setOpenVendorCreateModal] = useState<any>({
    open: false
  });
  const [tableData, setTableData] = useState<VendorOrders>({
    rejected: [],
    approved: [],
    newRequest: []
  });
  const [customerTableData, setCustomerTableData] = useState<VendorOrders>({
    rejected: [],
    approved: [],
    newRequest: []
  });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });

  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChangeVendorManagementChip = (selectedChipItem: string[]) => {
    if (buttonVal == 1) {
      setCustomerChipFilter(selectedChipItem);
    } else {
      setVendorChipFilter(selectedChipItem);
    }
  };
  const handleSetSelectedTab = (value) => {
    if (buttonVal == 1) {
      setSelectedTab(value);
    } else {
      setVendorSelectedTab(value);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any = await Promise.all([
        API_SERVICES.vendorManagementService.getAllBy(
          GET_FILTER_VALUES[vendorChipFilter[0]],
          params
        ),
        API_SERVICES.newRequestService.getAllCustomers(
          GET_FILTER_VALUES[customerChipFilter[0]],
          params
        )
      ]);
      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (
          response[0]?.data?.vendorRequests &&
          response[0]?.data?.vendorsCount
        ) {
          setVendorCount(response[0]?.data?.vendorsCount);
          setTableData((prev) => {
            return { ...prev, ...response[0]?.data?.vendorRequests };
          });
        }
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (
          response[1]?.data?.customerRequests &&
          response[1]?.data?.customerCount
        ) {
          setCustomerCount(response[1]?.data?.customerCount);
          setCustomerTableData((prev) => {
            return { ...prev, ...response[1]?.data?.customerRequests };
          });
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [vendorChipFilter, customerChipFilter, debValue, buttonVal]);

  const onActionButtonClick = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData });
  };

  const onClickCancelOrAccept = (rowData: any, statusId: number) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        status_id: statusId
      };
      const response: any =
        buttonVal == 1
          ? await API_SERVICES.newRequestService.adminUpdateCustomer(rowData.id, {
              data: updateData,
              successMessage:
                statusId === VENDOR_MG_STATUS.Accepted
                  ? t('newRequest.customerAcceptMessage')
                  : t('newRequest.customerRejectMessage')
            })
          : await API_SERVICES.newRequestService.adminUpdateVendor(
              rowData.vendor_id,
              rowData.id,
              {
                data: updateData,
                successMessage:
                  statusId === VENDOR_MG_STATUS.Accepted
                    ? t('vendorManagement.vendorAcceptMessage')
                    : t('vendorManagement.vendorRejectMessage')
              }
            );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.secondary,
      description:
        buttonVal == 1
          ? t('newRequest.acceptCustomer')
          : t('vendorManagement.acceptVendor'),
      title: t('vendorManagement.accept'),
      iconType: CONFIRM_MODAL.accept
    };
    if (statusId === VENDOR_MG_STATUS.Rejected) {
      props = {
        color: theme.Colors.redPrimary,
        description:
          buttonVal == 1
            ? t('newRequest.rejectCustomer')
            : t('vendorManagement.rejectVendor'),
        title: t('vendorManagement.reject'),
        iconType: CONFIRM_MODAL.reject
      };
    }
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onCreateOrEditButtonClick = (rowData?: any, type?: string) => {
    if (type === CONFIRM_MODAL.edit) {
      setOpenVendorCreateModal({
        open: true,
        rowData: rowData,
        type: buttonVal
      });
      return;
    }
    setOpenVendorCreateModal({ open: true, type: buttonVal });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Grid container>
          <Grid className={classes.containerStyle} item>
            <Heading
              headingText={
                buttonVal == 1
                  ? t('customerManagement.customerRequest')
                  : t('vendor.vendorRequest')
              }
            />
            <MultiSelectChip
              chipItems={FILTER_CHIPS}
              selectedChipItem={
                buttonVal == 1 ? customerChipFilter : vendorChipFilter
              }
              handleChange={handleChangeVendorManagementChip}
              chipStyle={{
                padding: theme.spacing(2, 0.8),
                height: theme.MetricsSizes.large
              }}
            />
          </Grid>
        </Grid>
        <ContentDisplayTiles
          displayContent={
            buttonVal == 1 ? customerRequestTabValue : vendorRequestTabValue
          }
          isTileTypeOrders={true}
          onTabChange={handleSetSelectedTab}
          tabValue={buttonVal == 1 ? selectedTab : vendorSelectedTab}
        />
        <Box sx={{ mt: 3 }}>
          <CustomerVendorTable
            onClickActionButton={onActionButtonClick}
            tableRowData={
              buttonVal == 1
                ? customerTableData[selectedTab]
                : tableData[vendorSelectedTab]
            }
            onClickCancelOrAccept={onClickCancelOrAccept}
            onClickEditButton={(rowData) =>
              onCreateOrEditButtonClick(rowData, CONFIRM_MODAL.edit)
            }
            buttonVal={buttonVal}
          />
        </Box>
        {modalOpen.open && (
          <CustomerVendorViewModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
          />
        )}
        {openVendorCreateModal.open && (
          <EditCustomerVendor
            onClose={() => setOpenVendorCreateModal({ open: false })}
            updateData={fetchData}
            {...openVendorCreateModal}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
      </>
    );
  }
}

export default CustomerVendorManagement;
