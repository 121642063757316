import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type regionProps = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const regionManagementService = {
  getAllRegion: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllRegion`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getRegionById: async (region_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getRegionById/${region_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  createRegion: async ({
    data,
    successMessage,
    failureMessage
  }: regionProps) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createRegion`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateRegion: async (
    id: number,
    { data, successMessage, failureMessage }: regionProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateRegion/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  deleteRegion: async (
    id: number,
    {
      successMessage,
      failureMessage
    }: {
      successMessage?: string;
      failureMessage?: string;
    }
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteRegion/${id}`,
      method: 'delete'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };

    return apiRequest(options, toastMessageConfig);
  },
  getAllArea: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllAreas`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getAllAreas: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllArea`,
      method: 'get'
    });
    return apiRequest(options);
  }
};
