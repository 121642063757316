import React, { useEffect, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import MarkerPrice from './MarketPrice';
import { MultipleSelectComp, SwitchComponent } from 'src/components';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { useEdit } from 'src/hooks/useEdit';

const CustomerVendorMarketPrice = () => {
  const theme = useTheme();
  const [button, setButton] = useState(1);
  const [regionData, setRegionData] = useState([]);

  const initialValue = {
    id: 0
  };

  const edit = useEdit(initialValue);

  const getAllRegion = async () => {
    try {
      setRegionData([]);
      const response: any =
        await API_SERVICES.regionManagementService.getAllRegion();
      if (response?.data?.statusCode <= HTTP_STATUSES.BAD_REQUEST) {
        setRegionData(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRegion();
  }, [edit.getValue('id')]);

  return (
    <>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={4} style={{ padding: 30 }}>
          <MultipleSelectComp
            isPlaceholderNone
            selectItems={
              regionData?.length &&
              regionData?.map((item: any) => {
                return {
                  label: item.region_name,
                  value: item.region_id
                };
              })
            }
            placeholderText={'All'}
            value={edit.getValue('id')}
            borderRadius={40}
            selectHeight={35}
            background={theme.Colors.greyDark}
            iconColor={theme.Colors.primary}
            //selectBoxStyle={{ minWidth: 200 }}
            //classes={{ root: classes.selectStyle }}
            onChange={(e) => {
              edit.update({
                id: e.target.value
              });
            }}
            // isError={isError}
            // helperText={helperText}
            displayEmpty
            renderValue={(value: any) => {
              return value
                ? regionData?.filter((item) => {
                    return item.region_id === value;
                  })[0]?.region_name
                : 'All';
            }}
            required
          />
        </Grid>
        <SwitchComponent
          buttonValue={button}
          setButtonValue={setButton}
          handleClick={(val) => {
            edit.update({ id: edit.getValue('id') });
            setButton(val);
          }}
        />
      </Grid>
      <MarkerPrice button={button} regionId={edit.getValue('id')} />
    </>
  );
};

export default CustomerVendorMarketPrice;
