import React from 'react';
import { ItemListingComp } from 'src/components';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { StockAvatar } from 'src/Assets';

const StockUpdate = (props) => {
  const theme = useTheme();
  const { headingText, vendor, stockDetails } = props;
  const data = [
    {
      id: 1,
      name: 'GeneralWaste',
      image_url: StockAvatar,
      weight: 200
    },
    {
      id: 2,
      name: 'General Waste',
      image_url: StockAvatar,
      weight: 500
    },
    {
      id: 3,
      name: 'Paper Waste',
      image_url: StockAvatar,
      weight: 400
    }
  ];
  return (
    <>
      {stockDetails?.length ? (
        <>
          {headingText ? (
            <Typography
              style={{
                fontSize: vendor ? 28 : 26,
                fontWeight: vendor
                  ? theme.fontWeight.bold
                  : theme.fontWeight.regular,
                color: vendor ? '#979797' : theme.Colors.black,
                paddingBottom: 20,
                paddingTop: 20
              }}
            >
              {headingText}
            </Typography>
          ) : null}
          <Grid
            item
            xs={vendor ? 4 : 12}
            style={{ marginLeft: theme.spacing(5) }}
          >
            <ItemListingComp
              listOfItems={stockDetails}
              withEditField={false}
              isAvatarImg={true}
              isAddButton={false}
              isEditIcon={false}
              backgroundColor={theme.Colors.white}
            />
          </Grid>
        </>
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        >
          <Typography variant="h2" style={{ color: theme.Colors.primary }}>
            Currently no details available
          </Typography>
        </div>
      )}
    </>
  );
};

export default StockUpdate;
