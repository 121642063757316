import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import toast from 'react-hot-toast';
import { NewOrder } from 'src/Assets';
import {
  ButtonComp,
  ContentDisplayTiles,
  ListItemCell,
  UHTable
} from 'src/components';
import { USER_TYPE_ID } from 'src/Config/constant';
import { useDebounce } from 'src/hooks/useDebounce';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { API_SERVICES } from 'src/Services';
import CustomerListModal from './CustomerListModal';
import { useTranslation } from 'react-i18next';
type Props = {
  renderActionBtn?: boolean;
  tableRowData?: any[];
};
const count = {
  HomeCount: 0,
  CommercialCount: 0,
  IndustrialCount: 0
};

const useStyles = makeStyles((theme: Theme) => ({
  avatarStyle: {
    width: 35,
    height: 35
  }
}));

const CustomerReport = () => {
  const theme = useTheme();
  const styles = useStyles();
  const [totalUser, setTotalUser] = useState(0);
  const [customerCount, setCustomerCount] = useState(count);

  const customerDetailsTabValues = [
    {
      heading: 'Total Customers',
      subText: totalUser,
      iconImage: NewOrder,
      value: 'total'
    },
    {
      heading: 'Commercial User',
      subText: customerCount?.CommercialCount,
      iconImage: NewOrder,
      value: 'commercial'
    },
    {
      heading: 'Industrial User',
      subText: customerCount?.IndustrialCount,
      iconImage: NewOrder,
      value: 'industrial'
    }
  ];
  const [selectedTab, setSelectedTab] = useState<string | number>(
    customerDetailsTabValues[0].value
  );

  const [openModal, setOpenModal] = useState<any>({ open: false });
  const [rowItems, setRowItems] = useState<any>([]);
  const { searchValue } = useSearchVal();
  const { t } = useTranslation();
  const debValue = useDebounce(searchValue, 2000);
  const [sortData, setSortData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const response: any =
        await API_SERVICES.customerListService.getAllCustomerList();
      setRowItems(response?.data?.allCustomers);
      setCustomerCount(response?.data?.userCount);
      setTotalUser(response?.data?.totalUser);
    } catch (err) {
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSetSelectedTab = (value: number | string) => {
    setSelectedTab(value);
  };

  const filterRowData = useMemo(() => {
    if (rowItems?.length) {
      if (selectedTab === customerDetailsTabValues[0]?.value) {
        return rowItems || [];
      }
      if (selectedTab === customerDetailsTabValues[1]?.value) {
        return (
          rowItems.filter(
            (item: { user_type_id: number }) =>
              item.user_type_id === USER_TYPE_ID?.commercial
          ) || []
        );
      }
      if (selectedTab === customerDetailsTabValues[2]?.value) {
        return (
          rowItems.filter(
            (item: { user_type_id: number }) =>
              item.user_type_id === USER_TYPE_ID?.industry
          ) || []
        );
      }
    } else {
      return [];
    }
  }, [selectedTab, rowItems]);

  useEffect(() => {
    let newList = filterRowData;
    if (!sortData) {
      newList?.sort(
        (current: { location: string }, next: { location: any }) => {
          return current.location.localeCompare(next.location);
        }
      );
    }
    if (sortData) {
      newList?.sort(
        (current: { location: any }, next: { location: string }) => {
          return next.location.localeCompare(current.location);
        }
      );
    }
    setTableData([...newList]);
  }, [filterRowData, sortData]);
  const handleModalClick = (rowData: any) => {
    setOpenModal({ open: true, rowData: rowData });
  };
  const onSortClick = () => {
    setSortData(!sortData);
  };
  const excelHeaders = [
    { label: 'Contact Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Location', key: 'location' },
    { label: 'Order', key: 'order' },
    { label: 'Mobile Number', key: 'registered_mobile_number' },
    { label: 'Pincode', key: 'pincode' }
  ];

  const excelData =
    filterRowData?.length &&
    filterRowData?.map(
      (rowData: {
        contact_name: string;
        email: string;
        location: string;
        quantity_kg: any;
        registered_mobile_number: string;
        pincode: string;
      }) => {
        return {
          name: rowData?.contact_name,
          email: rowData?.email,
          location: rowData?.location,
          order: `${rowData?.quantity_kg} Kgs`,
          mobile_number: rowData?.registered_mobile_number,
          pincode: rowData?.pincode
        };
      }
    );
  const columns: any[] = [
    {
      field: 'S.No',
      headerName: '',
      sortable: false,
      flex: 1.5,
      type: 'number',
      disableColumnMenu: true,
      renderCell: (index: {
        api: { getRowIndex: (arg0: any) => number };
        row: { id: any };
      }) => index.api.getRowIndex(index.row.id) + 1
    },
    {
      field: 'contact_name',
      headerName: 'Contact Name',
      flex: 5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.contact_name}
          avatarImg={row?.image_url || 'DUSTMAN'}
          avatarClassNameStyles={styles.avatarStyle}
        />
      )
    },
    {
      field: 'company_name',
      headerName: 'Company Name',
      flex: 5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.company_name} />
    },
    {
      field: 'orders',
      headerName: 'Quantity Kgs',
      flex: 4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.quantity_kg} />
    },
    {
      field: 'registered_mobile_number',
      headerName: 'Mobile Number',
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.registered_mobile_number} />
      )
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ButtonComp
          buttonText={t('button.view')}
          btnBorderRadius={theme.MetricsSizes.regular_x}
          buttonFontSize={theme.MetricsSizes.small_x}
          btnWidth={71}
          height={theme.MetricsSizes.regular_xxx}
          onClick={() => handleModalClick(row)}
        />
      )
    }
  ];

  return (
    <>
      <ContentDisplayTiles
        displayContent={customerDetailsTabValues}
        isTileTypeOrders={true}
        onTabChange={handleSetSelectedTab}
        tabValue={selectedTab}
      />
      <UHTable
        checkboxSelection={false}
        columns={columns}
        rows={tableData}
        autoHeight={true}
        onSortClick={onSortClick}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={t('customerList')}
      />
      {openModal.open && (
        <CustomerListModal
          onClose={() => setOpenModal({ open: false })}
          {...openModal}
        />
      )}
    </>
  );
};

export default CustomerReport;
