import React from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { DialogComp, DialogContentDetails } from 'src/components';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 620,
      height: 365,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

// type Props = {
//   onClose: () => void;
//   rowData: any;
// };

const VendorDetailsModal = (props) => {
  const { onClose, rowData, acceptedVendor } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: t('vendor.contactName'),
        value: capitalizeFirstLetter(rowData?.contact_name)
      },
      {
        content: t('mobileNumber'),
        value:
          rowData?.registered_mobile_number?.slice(2) ||
          rowData?.mobile_number?.slice(2)
      },
      {
        content: 'Email',
        value: rowData?.email_id
          ? rowData.email_id
          : rowData?.email && rowData.email !== ''
          ? rowData.email
          : '-'
      },
      {
        content: 'GST Number',
        value: rowData?.gst
          ? rowData.gst
          : rowData?.gst_number !== undefined &&
            rowData.gst_number !== 'undefined'
          ? rowData.gst_number
          : '-'
      },
      {
        content: 'Address',
        value:
          rowData?.full_address != ''
            ? rowData?.full_address
            : `${rowData?.address_line1}, ${rowData?.address_line2}`
      },
      {
        content: 'Location',
        value:
          rowData?.location == ''
            ? rowData?.city == ''
              ? '-'
              : rowData?.city
            : rowData?.location
      }
    ];

    const vendorDetails = [
      {
        content: 'Assigned Vendor',
        value: rowData?.vendor_company_name
      },
      {
        content: 'Vendor Contact Name',
        value: rowData?.vendor_contact_name
      },
      {
        content: ' Vendor Mobile Number',
        value: rowData?.vendor_mobile_number?.slice(2)
      }
    ];

    return (
      <Grid>
        <DialogContentDetails
          contentDetails={acceptedVendor ? vendorDetails : contentDetails}
        />
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'Dustman'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        color: theme.Colors.blackMedium
      }}
      renderDialogContent={renderDialogContent}
    />
  );
};

export default VendorDetailsModal;
