import {
  Chip,
  Grid,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCalendar } from 'src/Assets';
import {
  ListItemCell,
  Loader,
  TextInputComponent,
  UHTable
} from 'src/components';
import {
  capitalizeFirstLetter,
  getDateFormat,
  getDateUnixFormat
} from 'src/Utils';

const useStyles = makeStyles((theme) => {
  return {
    tooltipStyle: {
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      color: theme.Colors.neutralBlack,
      padding: 12,
      borderRadius: 8
    },
    arrowStyle: {
      color: theme.Colors.white
    }
  };
});

const RazorpayXTable = ({
  selectedTab,
  tableData,
  handleOpenCalendar,
  edit,
  loading
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      field: 'order',
      headerName: 'Order Number',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={
            row?.source?.entity == 'payout' && row?.source?.notes?.notes_key_1
              ? `Order ${row?.source?.notes?.notes_key_1}`
              : '-'
          }
          subTitle={
            row?.source?.entity == 'payout'
              ? row?.source?.notes?.notes_key_2
              : ''
          }
        />
      )
    },
    //   {
    //     field: 'details',
    //     headerName: t('vendor.contactName'),
    //     flex: 1,
    //     sortable: false,
    //     disableColumnMenu: true,
    //     renderCell: ({ row }) => (
    //       <ListItemCell title={capitalizeFirstLetter(row?.contact_person)} />
    //     )
    //   },
    {
      field: 'amount',
      headerName: 'Total Amount',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Typography style={{ fontWeight: theme.fontWeight.medium }}>
          {row?.source?.amount / 100}
        </Typography>
      )
    },
    {
      field: 'tax',
      headerName: 'Tax & Fees',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Typography>
          {row?.source?.entity != 'payout' ? '-' : row?.source?.fees / 100}
        </Typography>
      )
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.id} />
    },
    {
      field: 'date',
      headerName: 'Transaction Date',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateUnixFormat(
          row?.created_at
        );
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${getTime}`}
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Chip
          label={
            row?.source?.entity == 'fund_account.validation'
              ? 'Failure'
              : row?.source?.entity == 'payout'
              ? capitalizeFirstLetter(row?.source?.status)
              : 'Completed'
          }
          style={{
            width: 100,
            fontSize: theme.MetricsSizes.small_x,
            color:
              row?.source?.entity == 'fund_account.validation'
                ? theme.Colors.redPrimary
                : row?.source?.entity == 'payout'
                ? theme.Colors.darkGreen
                : theme.Colors.secondary,
            backgroundColor:
              row?.source?.entity == 'fund_account.validation'
                ? '#ecaaaa63'
                : row?.source?.entity == 'payout'
                ? '#ceeccf'
                : '#eefeef',
            fontWeight: theme.fontWeight.bold,
            height: theme.MetricsSizes.regular_xxx,
            border: '2px solid',
            borderColor:
              row?.source?.entity == 'fund_account.validation'
                ? theme.Colors.redPrimary
                : row?.source?.entity == 'payout'
                ? theme.Colors.darkGreen
                : theme.Colors.secondary
          }}
        />
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Typography
          style={{
            color:
              row?.source?.entity == 'fund_account.validation'
                ? theme.Colors.redPrimary
                : row?.source?.entity == 'payout'
                ? theme.Colors.orangeDark
                : theme.Colors.darkGreen,
            fontSize: theme.MetricsSizes.regular,
            fontWeight: theme.fontWeight.medium
          }}
        >
          {row?.source?.entity == 'fund_account.validation'
            ? 'Failure'
            : row?.source?.entity == 'payout'
            ? 'Withdraw'
            : 'Deposited'}
        </Typography>
      )
    }
  ];

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search"
            value={edit.getValue('search')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    style={{
                      width: 16,
                      height: 16,
                      color: 'rgba(34, 51, 69, 0.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
          />
        </Grid>
        <Grid item>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
      </>
    );
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Order Number', key: 'order' },
    { label: 'Name', key: 'name' },
    { label: 'Total Amount', key: 'amount' },
    { label: 'Tax & Fees', key: 'tax' },
    { label: 'Transaction ID', key: 'transaction_id' },
    { label: 'Transaction Date', key: 'date' }
  ];

  let excelData =
    tableData?.length &&
    tableData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.created_at);
      let amt = rowData?.tax + rowData?.fees;
      return {
        id: idx + 1,
        order:
          rowData?.source?.entity == 'payout' &&
          rowData?.source?.notes?.notes_key_1
            ? `Order ${rowData?.source?.notes?.notes_key_1}`
            : '-',
        name:
          rowData?.source?.entity == 'payout' &&
          rowData?.source?.notes?.notes_key_2
            ? rowData?.source?.notes?.notes_key_2
            : '',
        amount: rowData?.amount / 100,
        tax:
          rowData?.source?.entity != 'payout'
            ? '-'
            : rowData?.source?.fees / 100,
        transaction_id: `${rowData?.id}`,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });
  if (loading) {
    return <Loader />;
  } else {
    return (
      <UHTable
        columns={columns}
        rows={tableData}
        title={'Razorpay-X'}
        autoHeight
        renderSearchBox={renderSearchBox}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName="Razorpay-X Details"
      />
    );
  }
};

export default RazorpayXTable;
