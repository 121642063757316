import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import {
  ListItemCell,
  UHTable,
  ButtonComp,
  TextInputComponent
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, dateFormate, getDateFormat } from 'src/Utils';
import { ENQUIRY_STATUS } from 'src/Config/constant';
import { Compass, TableCalendar } from 'src/Assets';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    fontSize: theme.MetricsSizes.small_x,
    fontWeight: theme.fontWeight.regular,
    color: theme.Colors.greyLight
  }
}));

type Props = {
  tableData?: any[];
  renderRowAction?: any;
  onClickActionButton?: (row: any) => void;
  handleOpenCalendar?: () => void;
  edit?: any;
  enquiryRequestChipFilter?: string;
};

const EnquiryTable = ({
  tableData,
  renderRowAction,
  onClickActionButton,
  handleOpenCalendar,
  enquiryRequestChipFilter,
  edit
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const [sortData, setSortData] = useState(false);
  const [rowItems, setRowItems] = useState([]);

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.contact_name ?? `${row?.first_name} ${row?.last_name}`}
        />
      )
    },
    {
      field: 'userType',
      headerName: t('enquiry.userType'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.user_type} />
    },
    {
      field: 'mobileNumber',
      headerName: t('enquiry.mobileNumber'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.registered_mobile_number?.slice(2)} />
      )
    },
    {
      field: 'date',
      headerName: t('enquiry.date'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row.created_at
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ButtonComp
          btnBorderRadius={theme.MetricsSizes.regular_x}
          buttonText={row.status_id === ENQUIRY_STATUS.view ? 'View' : 'Attend'}
          buttonFontSize={theme.MetricsSizes.small_x}
          btnWidth={theme.spacing(9)}
          backgroundColor={
            row.status_id === ENQUIRY_STATUS.view
              ? theme.Colors.spanishGrey
              : theme.Colors.secondary
          }
          height={theme.MetricsSizes.medium}
          onClickButton={() => onClickActionButton(row)}
        />
      )
    }
  ];

  useEffect(() => {
    let newList = tableData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableData, sortData]);

  const onSortClick = () => {
    setSortData(!sortData);
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Order Type', key: 'order_type' },
    { label: 'Mobile Number', key: 'mobile_number' },
    { label: 'Created Date', key: 'created_at' }
  ];

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData,idx) => {
      return {
        id: idx + 1,
        name: capitalizeFirstLetter(rowData?.contact_name),
        email: rowData?.email ?? rowData?.email_id,
        order_type: rowData?.order_type,
        mobile_number: rowData?.registered_mobile_number?.slice(2),
        created_at: dateFormate(rowData?.created_at)
      };
    });

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search By User"
            value={edit.getValue('location')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon style={{width: 16, height: 16, color: 'rgba(34, 51, 69, 0.3)'}}/>
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
          />
        </Grid>
        {enquiryRequestChipFilter == 'Today' ? null : (
          <Grid item>
            <TextInputComponent
              inputWidth={250}
              borderRadius={40}
              inputHeight={30}
              value={edit.getValue('date')}
              placeholder="Search by Date"
              isBackground={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    <img src={TableCalendar} />
                  </InputAdornment>
                )
              }}
              onClick={handleOpenCalendar}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <UHTable
      title={t('enquiry.allEnquiriesList')}
      columns={columns}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      hideFooterPagination={true}
      getRowActions={renderRowAction}
      onSortClick={onSortClick}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={'Enquiry Management'}
      renderSearchBox={renderSearchBox}
    />
  );
};

export default EnquiryTable;
