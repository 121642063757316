import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  DialogComp,
  TextInputComponent,
  DualActionButton
} from 'src/components';
import {
  CONFIRM_MODAL,
  HTTP_STATUSES,
  MAP_URL,
  GOOGLE_API_KEY
} from 'src/Config/constant';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { isValidPinCode, capitalizeFirstLetter } from 'src/Utils';
import Geocode from 'react-geocode';

const useStyles = makeStyles((theme: Theme) => {
  return {
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    },
    dialogPaper: {
      width: 847,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    }
  };
});

type Props = {
  open?: boolean;
  onClose?: () => void;
  updateData?: () => void;
  locationData?: any;
  type?: string;
};

const AddressCreateModal = (props: Props) => {
  const { open, onClose, locationData, updateData, type } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const initialValues = {
    dustman_location: locationData?.dustman_location || '',
    address_line1: locationData?.address_line1 || '',
    address_line2: locationData?.address_line2 || '',
    address_line3: locationData?.address_line3 || '',
    city: locationData?.city || '',
    state: locationData?.state || '',
    pincode: locationData?.pincode || '',
    map_location: ''
  };
  const edit = useEdit(initialValues);
  const RequiredFields = [
    'dustman_location',
    'address_line1',
    'address_line2',
    'address_line3',
    'city',
    'state',
    'pincode'
  ];
  const dustmanName = isError && !edit.getValue('address_line1');
  const dustmanStreet = isError && !edit.getValue('address_line2');
  const dustmanArea = isError && !edit.getValue('address_line3');
  const dustmanLocation = isError && !edit.getValue('dustman_location');
  const dustmanCity = isError && !edit.getValue('city');
  const dustmanState = isError && !edit.getValue('state');
  const dustmanPincode =
    (isError && !edit.allFilled('pincode')) ||
    (isError &&
      edit.allFilled('pincode') &&
      !isValidPinCode(edit.getValue('pincode')));

  const types = {
    [CONFIRM_MODAL.create]: {
      handleType: 1
    },
    [CONFIRM_MODAL.edit]: {
      handleType: 2
    }
  };
  const data = { ...initialValues, ...edit.edits };
  const handleSave = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setIsError(true);
        return toast.error('Please fill all the required fields');
      } else if (!isValidPinCode(edit.getValue('pincode'))) {
        setIsError(true);
        return;
      } else {
        setIsError(false);
      }
      let data = { ...initialValues, ...edit.edits };
      if (types[type].handleType === 2) {
        const response: any =
          await API_SERVICES.settingsPageService.replaceLocation(
            locationData?.id,
            {
              data: data,
              successMessage: 'Dustman address edited successfully',
              failureMessage: 'Failed to edit dustman address'
            }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      } else if (types[type].handleType === 1) {
        const response: any =
          await API_SERVICES.settingsPageService.createAddress({
            data: data,
            successMessage: 'Dustman Address added successfully',
            failureMessage: 'Failed to create Dustman Address'
          });
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      }
      onClose();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const getLatLng = async () => {
    await Geocode.fromAddress(data.pincode, GOOGLE_API_KEY)
      .then((response: any) => {
        const address = response?.results[0].geometry.location;
        edit.update({
          map_location: MAP_URL + address.lat + ',' + address.lng + ''
        });
      })
      .catch((error) => {
        //toast.error('Coordinates are invalid');
      });
  };
  useEffect(() => {
    getLatLng();
  }, [data.pincode]);

  const handleKeyDown = (event) => {
    // Check if the key pressed is a number (key codes 48-57 represent '0'-'9')
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  };

  const handleContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.houseNo')}
              labelColor={theme.Colors.primary}
              isError={dustmanName}
              helperText={dustmanName && 'Please enter your House/Flat Name'}
              value={edit.getValue('address_line1')}
              onChange={(e) =>
                edit.update({
                  address_line1: capitalizeFirstLetter(e.target.value)
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.streetName')}
              labelColor={theme.Colors.primary}
              isError={dustmanStreet}
              helperText={dustmanStreet && 'Please enter your street name'}
              required
              value={edit.getValue('address_line2')}
              onChange={(e) =>
                edit.update({
                  address_line2: capitalizeFirstLetter(e.target.value)
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.area')}
              labelColor={theme.Colors.primary}
              isError={dustmanArea}
              helperText={dustmanArea && 'Please enter your area'}
              required
              value={edit.getValue('address_line3')}
              onChange={(e) =>
                edit.update({
                  address_line3: capitalizeFirstLetter(e.target.value)
                })
              }
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.location')}
              labelColor={theme.Colors.primary}
              isError={dustmanLocation}
              helperText={dustmanLocation && 'Please enter your location'}
              value={edit.getValue('dustman_location')}
              onChange={(e) =>
                edit.update({
                  dustman_location: capitalizeFirstLetter(e.target.value)
                })
              }
              required
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.city')}
              labelColor={theme.Colors.primary}
              isError={dustmanCity}
              helperText={dustmanCity && 'Please enter your city'}
              required
              value={edit.getValue('city')}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                edit.update({
                  city: capitalizeFirstLetter(e.target.value)
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.state')}
              labelColor={theme.Colors.primary}
              isError={dustmanState}
              helperText={dustmanState && 'Please enter your state'}
              required
              placeholderText={t('settings.state')}
              value={edit.getValue('state')}
              onChange={(e) =>
                edit.update({
                  state: capitalizeFirstLetter(e.target.value)
                })
              }
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.pinCode')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('pincode')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                edit.update({ pincode: e.target.value });
              }}
              isError={dustmanPincode}
              helperText={dustmanPincode && 'Please enter your valid pincode'}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const handleAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleSave}
        disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };
  return (
    <DialogComp
      open={open}
      dialogTitle={
        types[type].handleType === 2
          ? t('settings.editAddress')
          : t('settings.addNewAddress')
      }
      dialogTitleStyle={{ color: theme.Colors.primary }}
      dialogClasses={{ paper: classes.dialogPaper }}
      onClose={onClose}
      renderDialogContent={handleContent}
      renderAction={handleAction}
    />
  );
};
export default AddressCreateModal;
