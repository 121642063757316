import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type newRequestProps = {
  data?: any;
  successMessage?: string;
  failureMessage?: string;
};

export const newRequestService = {
  getAllCustomers: async (id: number,params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/allCustomers/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  updateCustomer: async (
    id: number,
    { data, successMessage, failureMessage }: newRequestProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateCustomerProfile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: { message: successMessage },
      failure: { message: failureMessage }
    };
    return apiRequest(options, toastMessageConfig);
  },
  adminUpdateCustomer: async (
    id: number,
    { data, successMessage, failureMessage }: newRequestProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminUpdateCustomerProfile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: { message: successMessage },
      failure: { message: failureMessage }
    };
    return apiRequest(options, toastMessageConfig);
  },
  adminUpdateVendor: async (
    vendor_id: number,
    id: number,
    { data, successMessage, failureMessage }: newRequestProps
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminUpdateVendorProfile/vendor/${vendor_id}/vendor_profile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: { message: successMessage },
      failure: { message: failureMessage }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
