import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ButtonComp,
  DialogComp,
  MultipleSelectComp,
  TextInputComponent
} from 'src/components';
import VendorModalTable from './VendorModalTable';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { useEdit } from 'src/hooks/useEdit';
import toast from 'react-hot-toast';
import VendorDetailsModal from './VendorDetailsModal';
import { customerVendorListService } from 'src/Services/customerVendorListService';

const selectDuration = [
  {
    id: '1',
    duration: '3'
  },
  {
    id: '2',
    duration: '6'
  },
  {
    id: '3',
    duration: '12'
  }
];

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    },
    textStyle: {
      color: '#777777',
      fontSize: 24,
      fontWeight: theme.fontWeight.bold
    },
    input: {
      borderRadius: '40px',
      background: '#D2D2D2'
    }
  };
});

const CustomerViewVendorModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { rowData, onClose, updateData } = props;
  const [vendorList, setVendorList] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState();
  const [regionData, setRegionData] = useState([]);
  const [areaName, setAreaName] = useState('');
  const [openModal, setOpenModal] = useState<any>({ open: false });
  const [selectionModel, setSelectionModel] = React.useState([]);
  console.log('rowDatarowDatarowDatarowData', rowData);

  const initialValue = {
    months: '',
    subscription_from: '',
    subscription_to: '',
    vendor_id: '',
    orderer_address_id: '',
    customer_id: '',
    region_id: rowData?.region_id || '',
    area_id: '',
    areaName: ''
  };

  const edit = useEdit(initialValue);

  const monthError = isError && !edit.getValue('months');
  const fromError =
    isError && monthError && !edit.getValue('subscription_from');
  const toError = isError && monthError && !edit.getValue('subscription_to');

  const handleChangeRegion = (e) => {
    edit.update({ region_id: e.target.value, area_id: '' });
    if (e.target.value) {
      getLocation(e.target.value);
    } else {
      getAllRegion();
    }
  };

  const getAllRegion = async () => {
    try {
      setRegionData([]);
      const response: any =
        await API_SERVICES.regionManagementService.getAllRegion();
      if (response?.data?.statusCode <= HTTP_STATUSES.BAD_REQUEST) {
        setRegionData(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorList = async () => {
    try {
      setVendorList([]);
      let params: any = {
        region_id: edit.getValue('region_id')
      };
      let area: any = {
        region_id: edit.getValue('region_id'),
        location: edit.edits.areaName
      };
      const response: any =
        await API_SERVICES.customerVendorListService.getAllVendors(
          edit.getValue('region_id') == '' && edit.getValue('area_id') == ''
            ? ''
            : edit.getValue('region_id') != '' && edit.getValue('area_id') == ''
            ? params
            : area
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.vendorProfiles?.length) {
          setVendorList(response?.data?.vendorProfiles);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocation = async (id: number) => {
    try {
      setLocationData([]);
      const response: any =
        await API_SERVICES.regionManagementService.getRegionById(id);
      if (
        response?.status < HTTP_STATUSES.BAD_REQUEST &&
        response?.data?.result?.areas?.length
      ) {
        setLocationData(response?.data?.result?.areas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRegion();
    edit.update({
      customer_id: rowData?.customer_id,
      orderer_address_id: rowData?.orderer_address_id
        ? rowData?.orderer_address_id
        : rowData?.contact_id
    });
    if (edit.getValue('region_id') == '') {
      return;
    } else {
      getLocation(edit.getValue('region_id'));
    }
  }, []);

  useEffect(() => {
    getVendorList();
  }, [edit.getValue('region_id'), edit.getValue('area_id')]);

  const onSelectionModelChange = (selection) => {
    setSelectionModel((prevModel) =>
      selection.filter((newId) => !prevModel.includes(newId))
    );
  };

  const handleOpenVendor = (rowData?: any) => {
    setOpenModal({ open: true, rowData: rowData });
  };

  const renderDialogContent = () => {
    const today = new Date().toISOString().split('T')[0];
    return (
      <Grid>
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid
            item
            style={{
              display: 'flex',
              alignSelf: 'center',
              alignItems: 'center'
            }}
          >
            <Typography className={classes.textStyle}>Region</Typography>
            <Typography
              className={classes.textStyle}
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              :
            </Typography>
            <MultipleSelectComp
              isPlaceholderNone
              placeholderText={'All'}
              selectItems={
                regionData?.length &&
                regionData?.map((item: any) => {
                  return {
                    label: item.region_name,
                    value: item.region_id
                  };
                })
              }
              value={edit.getValue('region_id')}
              borderRadius={40}
              selectHeight={35}
              background={theme.Colors.greyDark}
              iconColor={theme.Colors.primary}
              onChange={handleChangeRegion}
              displayEmpty
              renderValue={(value: any) => {
                return value
                  ? regionData?.filter((item) => {
                      return item.region_id === value;
                    })[0]?.region_name
                  : 'All';
              }}
              required
            />
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              alignSelf: 'center',
              alignItems: 'center'
            }}
          >
            <Typography className={classes.textStyle}>Area</Typography>
            <Typography
              className={classes.textStyle}
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              :
            </Typography>
            <MultipleSelectComp
              isPlaceholderNone
              placeholderText={'All'}
              selectItems={
                locationData?.length &&
                locationData?.map((item: any) => {
                  return {
                    label: item.area,
                    value: item.id
                  };
                })
              }
              value={edit.getValue('area_id')}
              borderRadius={40}
              selectHeight={35}
              background={theme.Colors.greyDark}
              iconColor={theme.Colors.primary}
              onChange={(e) => {
                let areaName = locationData?.filter((item) => {
                  return item.id === e.target.value;
                })[0]?.area;
                edit.update({ areaName: areaName, area_id: e.target.value });
              }}
              displayEmpty
              renderValue={(value: any) => {
                return value
                  ? locationData?.filter((item) => {
                      return item.id === value;
                    })[0]?.area
                  : 'All';
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid style={{ paddingTop: 10 }}>
          <VendorModalTable
            vendorList={vendorList}
            onSelectionModelChange={onSelectionModelChange}
            handleOpenVendor={handleOpenVendor}
            selectionModel={selectionModel}
          />
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Typography
              className={classes.textStyle}
              style={{
                fontSize: 20,
                paddingRight: theme.spacing(4),
                color: monthError && fromError && '#e40909'
              }}
            >
              Select Duration
            </Typography>
          </Grid>
          {selectDuration?.length &&
            selectDuration?.map((item, idx) => {
              return (
                <FormControl component="fieldset" key={idx}>
                  <RadioGroup
                    value={edit.getValue('months')}
                    // onChange={(e) => {
                    //   edit.update({ months: item.id });
                    //   handleClick(e, item.id);
                    // }}
                  >
                    <FormControlLabel
                      disabled={
                        edit.getValue('subscription_from') !== '' ||
                        edit.getValue('subscription_to') !== ''
                      }
                      key={idx}
                      value={item.duration}
                      control={
                        <Radio
                          color="primary"
                          onChange={(e) => {
                            edit.update({ months: item.duration });
                          }}
                        />
                      }
                      label={`${item.duration} Months`}
                      style={{
                        fontSize: 12,
                        fontWeight: theme.fontWeight.regular,
                        color: theme.Colors.black
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              );
            })}
          {monthError && fromError ? (
            <Typography style={{ color: '#e40909' }}>
              Please select the duration
            </Typography>
          ) : null}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ padding: '20px 0px' }}
        >
          <Grid item style={{ float: 'left', width: '47%' }}>
            <hr style={{ color: '#263238' }} />
          </Grid>
          <Grid item>
            <Typography
              style={{
                padding: '0px 10px',
                fontSize: 16,
                fontWeight: theme.fontWeight.medium
              }}
            >
              Or
            </Typography>
          </Grid>
          <Grid item style={{ float: 'right', width: '47%' }}>
            <hr style={{ color: '#263238' }} />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item style={{ paddingRight: theme.spacing(4) }}>
            <Typography className={classes.textStyle} style={{ fontSize: 20 }}>
              Select By Date Range
            </Typography>
          </Grid>
          <Grid
            item
            style={{ display: 'inline-flex', justifyContent: 'space-evenly' }}
          >
            <Grid style={{ paddingRight: theme.spacing(4) }}>
              <TextInputComponent
                type="date"
                InputProps={{
                  className: classes.input
                }}
                borderRadius={90}
                isBackground={true}
                value={edit.getValue('subscription_from')}
                onChange={(e) =>
                  edit.update({ subscription_from: e.target.value })
                }
                defaultValue={today}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  min: today // Set the minimum date to today
                }}
                disabled={edit.getValue('months') !== ''}
                isError={fromError}
                helperText={fromError && 'Please select the from date'}
              />
            </Grid>
            <Grid style={{ paddingRight: theme.spacing(4) }}>
              <TextInputComponent
                type="date"
                InputProps={{
                  className: classes.input
                }}
                isBackground={true}
                borderRadius={40}
                value={edit.getValue('subscription_to')}
                onChange={(e) =>
                  edit.update({ subscription_to: e.target.value })
                }
                defaultValue={today}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  min: today // Set the minimum date to today
                }}
                disabled={edit.getValue('months') !== ''}
                isError={toError}
                helperText={toError && 'Please select the to date'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleAssignVendor = async () => {
    if (
      (edit.getValue('months') == '' &&
        edit.getValue('subscription_from') == '') ||
      (edit.getValue('subscription_from') == '' && edit.getValue('to') == '')
    ) {
      setIsError(true);
      return toast.error('Please fill required field');
    }
    if (selectionModel?.length == 0) {
      return toast.error('Please select any vendor to assign');
    }

    setIsError(false);
    let vendorId =
      selectionModel?.length &&
      vendorList?.filter((item) => item?.id === selectionModel[0])[0].vendor_id;

    let data = {
      months: Number(edit.getValue('months')),
      orderer_address_id: edit.getValue('orderer_address_id')
        ? edit.getValue('orderer_address_id')
        : 1,
      vendor_id: vendorId,
      customer_id: edit.getValue('customer_id'),
      subscription_to: edit.getValue('subscription_to'),
      subscription_from: edit.getValue('subscription_from')
    };
    console.log('rrrrrrrrrrr', data);

    if (rowData?.is_dustman) {
      const response: any =
        await customerVendorListService.reAssignDustmanOrderToVendor({
          data: data
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        toast.success(response?.data?.message);
        onClose();
        updateData();
      }
    } else {
      const response: any =
        await API_SERVICES.customerManagementService.assignVendor({
          data: data
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        toast.success(response?.data?.message);
        onClose();
        updateData();
      }
    }
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        <ButtonComp
          buttonText={rowData?.status == 4 ? 'ReAssign' : 'Assign'}
          btnWidth={150}
          buttonFontSize={20}
          buttonFontWeight={theme.fontWeight.medium}
          btnBorderRadius={50}
          onClickButton={handleAssignVendor}
          disabled={edit.getValue('vendor_id') == ''}
          style={{ marginBottom: 10 }}
        />
      </Grid>
    );
  };

  return (
    <>
      <DialogComp
        dialogTitle={rowData?.company_name}
        avatarImg={rowData?.image_url || 'DustMan'}
        open={true}
        onClose={onClose}
        dialogClasses={{ paper: classes.dialogPaper }}
        renderDialogContent={renderDialogContent}
        renderAction={renderAction}
      />
      {openModal?.open ? (
        <VendorDetailsModal
          onClose={() => setOpenModal({ open: false })}
          {...openModal}
        />
      ) : null}
    </>
  );
};

export default CustomerViewVendorModal;
