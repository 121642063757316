import { useState, useEffect, useCallback } from 'react';
import { ButtonComp, Heading, Loader } from 'src/components';
import UserManagementModal from './UserManagementModal';
import UserManagementTable from './UserManagementTable';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useTheme } from '@material-ui/core';
import { API_SERVICES } from 'src/Services';
import { HANDLE_SUBMIT, HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';

function UserManagement() {
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation();
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setData([]);
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any = await API_SERVICES.userService.getAll(params);
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.users) {
          setData(response.data.users);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [debValue]);

  // useEffect(() => {
  //   fetchData();
  //   if (!modalOpen.open) {
  //     const interval = setInterval(() => {
  //       fetchData();
  //     }, 60000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [fetchData]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    const fetchPermissions = async () => {
      const response: any =
        await API_SERVICES.userService.getAccessPermissions();
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data) {
          setPermissions(response?.data?.accessPermission);
          console.log(response?.data, 'response?.data');
        }
      }
    };
    fetchPermissions();
  }, []);

  const onActionButtonClick = (rowData?: any) => {
    setModalOpen({
      open: true,
      rowData: rowData,
      type: HANDLE_SUBMIT.viewUser
    });
  };

  const onCreateButtonClick = () => {
    setModalOpen({
      open: true,
      type: HANDLE_SUBMIT.createUser
    });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Heading headingText={t('createNewUser')} />
        <ButtonComp
          btnWidth={84}
          backgroundColor={theme.Colors.primary}
          buttonFontSize={theme.MetricsSizes.tiny_xxx}
          btnBorderRadius={theme.MetricsSizes.large}
          buttonText={t('button.create')}
          onClickButton={() => onCreateButtonClick()}
          endIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
          height={theme.MetricsSizes.large}
          style={{ marginBottom: theme.MetricsSizes.large }}
        />
        <UserManagementTable
          onClickActionButton={onActionButtonClick}
          rowsData={data}
          updateData={fetchData}
        />
        {modalOpen.open && (
          <UserManagementModal
            onClose={() => setModalOpen({ open: false })}
            permissions={permissions}
            {...modalOpen}
            updateData={fetchData}
          />
        )}
      </>
    );
  }
}

export default UserManagement;
