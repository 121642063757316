import { useCallback, useEffect, useState } from 'react';
import { NewOrder, Completed, Scheduled, Cancelled } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  Heading,
  UHConfirmModal,
  Loader,
  MultiSelectChip,
  UHCalenderComp
} from 'src/components';
import { Box, Divider, useTheme } from '@material-ui/core';
import { API_SERVICES } from 'src/Services';
import {
  CONFIRM_MODAL,
  CUSTOMER_MGNT_COUNT,
  CUSTOMER_STATUS,
  FILTER_CHIPS,
  GET_FILTER_VALUES,
  HTTP_STATUSES
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import CustomerOrderModal from '../CustomerOrder/CustomerOrderModal';
import CustomerManagementTable from './CustomerManagementTable';
import CustomerViewVendorModal from './CustomerViewVendorModal';
import { useEdit } from 'src/hooks/useEdit';
import VendorDetailsModal from './VendorDetailsModal';

type CustomerMgnt = {
  assignto: any[];
  rejectedrequest: any[];
  newRequest: any[];
  rejected: any[];
};

type CustomerMgntCount = {
  assigntoCount: number;
  newRequestCount: number;
  rejectedCount: number;
  rejectedrequestCount: number;
};

const CustomerOrder = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [customerCount, setCustomerCount] =
    useState<CustomerMgntCount>(CUSTOMER_MGNT_COUNT);
  const CustomerMgntTabValue = [
    {
      heading: t('newCustomer'),
      subText: customerCount.newRequestCount,
      iconImage: NewOrder,
      value: 'newRequest'
    },
    {
      heading: t('assigned'),
      subText: customerCount.assigntoCount,
      iconImage: Scheduled,
      value: 'assignto'
    },
    {
      heading: t('overview.rejected'),
      subText: customerCount.rejectedCount,
      iconImage: Cancelled,
      value: 'rejected'
    },
    {
      heading: t('rejectionRequest'),
      subText: customerCount.rejectedrequestCount,
      iconImage: Completed,
      value: 'rejectedrequest'
    }
  ];
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    CustomerMgntTabValue[0].value
  );
  const [customerMgntChipFilter, setCustomerMgntChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [tableData, setTableData] = useState<CustomerMgnt>({
    assignto: [],
    rejectedrequest: [],
    newRequest: [],
    rejected: []
  });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [assignModal, setAssignModal] = useState<any>({ open: false });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const [viewModal, setViewModal] = useState<any>({ open: false });

  const initialValue = {
    date: '',
    location: ''
  };
  const edit = useEdit(initialValue);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChangeCustomerOrderChip = (selectedChipItem: string[]) => {
    setCustomerMgntChipFilter(selectedChipItem);
  };

  const handleSelectedTab = (value: number | string) => {
    setSelectedTab(value);
    edit.update({ date: '', location: '' });
  };

  const fetchData = useCallback(async () => {
    try {
      const params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any =
        await API_SERVICES.customerManagementService.getAllCustomerManageMent(
          GET_FILTER_VALUES[customerMgntChipFilter[0]],
          {
            searchString: edit.getValue('location'),
            fromDate: edit.getValue('date')?.split('to')[0],
            toDate: edit.getValue('date')?.split('to')[1],
            ...params
          }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (
          response?.data?.customerManagementRequest &&
          response?.data?.customerManageMentCount
        ) {
          setCustomerCount(response?.data?.customerManageMentCount);
          setTableData((prev) => {
            return { ...prev, ...response?.data?.customerManagementRequest };
          });
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [customerMgntChipFilter, debValue, edit.edits]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const onClickRejectOrder = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    let updateData = {
      orderer_address_id: rowData?.contact_id,
      status: 4
    };
    const onConfirmClick = async () => {
      const response: any =
        await API_SERVICES.customerManagementService.rejectCustomerByVendor({
          data: updateData,
          successMessage: 'Customer rejected successfully!'
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.secondary,
      description: t('customerOrder.customerOrderReject'),
      title: t('reject'),
      iconType: CONFIRM_MODAL.reject
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickCancelOrder = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        status_id: 4,
        orders: [rowData?.id]
      };
      const response: any = await API_SERVICES.customerOrderService.replace({
        data: updateData,
        successMessage: 'Customer order cancelled successfully!'
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('customerOrder.cancelCustomerOrder'),
      title: t('customerOrder.cancel'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickMoveToDustman = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let data = {
        orderer_address_id: rowData?.contact_id,
        customer_id: rowData?.customer_id
      };
      const response: any =
        await API_SERVICES.customerOrderService.moveToDustman({
          data: data,
          successMessage: t('customerOrder.moveToDustmanSuccess')
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.primary,
      description: t('customerOrder.customerOrderMoveToDustman'),
      title: t('customerOrder.moveToDustman'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickViewOrder = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData });
  };

  const handleAssignVendorModal = (rowData: any) => {
    setAssignModal({ open: true, rowData: rowData });
  };

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Heading headingText={'New Customers'} />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={customerMgntChipFilter}
          handleChange={handleChangeCustomerOrderChip}
          chipStyle={{
            //cannot apply makestyle styles
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
        />
        <ContentDisplayTiles
          displayContent={CustomerMgntTabValue}
          isTileTypeOrders={true}
          onTabChange={handleSelectedTab}
          tabValue={selectedTab}
        />
        <Divider style={{ backgroundColor: theme.Colors.lightBlue }} />
        <Box sx={{ mt: 3 }}>
          <CustomerManagementTable
            tableRowData={tableData[selectedTab]}
            onClickRejectOrder={onClickRejectOrder}
            onClickCancelOrder={onClickCancelOrder}
            onClickViewOrder={onClickViewOrder}
            onClickMoveToDustman={onClickMoveToDustman}
            handleAssignVendorModal={handleAssignVendorModal}
            edit={edit}
            handleOpenCalendar={handleOpenCalendar}
            selectedTab={selectedTab}
            customerMgntChipFilter={customerMgntChipFilter[0]}
          />
        </Box>
        {modalOpen.open && (
          <VendorDetailsModal
            {...modalOpen}
            onClose={() => setModalOpen({ open: false })}
          />
        )}
        {assignModal.open ? (
          <CustomerViewVendorModal
            onClose={() => setAssignModal({ open: false })}
            {...assignModal}
            updateData={fetchData}
            // edit={edit}
          />
        ) : null}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
        {}
        {viewModal?.open ? (
          <VendorDetailsModal
            {...viewModal}
            onClose={() => setViewModal({ open: false })}
            acceptedVendor
          />
        ) : null}
      </>
    );
  }
};

export default CustomerOrder;
