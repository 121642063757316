import React, { useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { ContentDisplayTiles } from 'src/components';
import { PriceSheet } from 'src/Assets';
import CustomerReport from './CustomerReport';
import SalesBoxComp from './SalesBoxComp';
import VendorReport from './VendorReport';
import VendorSalesBoxComp from './VendorSalesBoxComp';

const useStyles = makeStyles((theme: Theme) => {});
type Props = {
  renderActionBtn?: boolean;
};
type VendorListTableData = {
  vendorList: any[];
};

const Reports = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  //  const [tableData, setTableData] = useState<VendorListTableData>(
  //   vendorList:[];
  //  );

  const allDetailsTabValues = [
    {
      // id: 0,
      heading: 'Customer Sales',
      subText: '',
      iconImage: PriceSheet
    },
    {
      // id: 1,
      heading: 'Dustman Sales',
      subText: '',
      iconImage: PriceSheet
    },
    {
      // id: 2,
      heading: 'Vendor List',
      subText: '',
      iconImage: PriceSheet
    },
    {
      // id: 3,
      heading: 'Customer List',
      subText: '',
      iconImage: PriceSheet
    }
  ];
  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
  };
  function TabPanel(props) {
    const { children, value, index } = props;
    return value === index && <>{children}</>;
  }

  return (
    <>
      <ContentDisplayTiles
        displayContent={allDetailsTabValues}
        isTileTypeOrders={true}
        onTabChange={handleSetSelectedTab}
        tabValue={selectedTab}
      />
      <TabPanel value={selectedTab} index={0}>
        <SalesBoxComp />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <VendorSalesBoxComp />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <VendorReport />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <CustomerReport />
      </TabPanel>
    </>
  );
};
export default Reports;
