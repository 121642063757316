import React, { useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { DialogComp, TextInputComponent } from 'src/components';
import { useTranslation } from 'react-i18next';
import DualActionButton from 'src/components/DualActionButton';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { isPhoneNumber, capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    },
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    }
  };
});

export type VehicleData = {
  id: number;
  name: string;
  description: string;
  image: string;
};

type Props = {
  onClose: () => void;
  updateData?: () => void;
  rowData?: any;
  type?: string;
};

const EditCustomerVendor = (props: Props) => {
  const { onClose, updateData, rowData, type } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  const defaultValues = {
    company_name: rowData?.company_name || '',
    contact_name: rowData?.contact_name || '',
    mobile_number: rowData?.registered_mobile_number
  };
  const edit = useEdit(defaultValues);
  const companyNameError = isError && !edit.allFilled('company_name');
  const mobileError =
    (isError && !edit.allFilled('mobile_number')) ||
    (isError &&
      edit.allFilled('mobile_number') &&
      !isPhoneNumber(edit.getValue('mobile_number')));

  const handleCreate = async () => {
    try {
      // if (!isPhoneNumber(edit.getValue('mobile_number'))) {
      //   setIsError(true);
      //   return;
      // } else {
      //   setIsError(false);
      // }
      let userData = { ...edit.edits };
      let response: any;
      if (type == '1') {
        response = await API_SERVICES.newRequestService.adminUpdateCustomer(
          rowData?.id,
          {
            data: userData,
            successMessage: 'Customer updated successfully!',
            failureMessage: 'Error: Failed to update customer'
          }
        );
      } else if (type == '2') {
        response = await API_SERVICES.newRequestService.adminUpdateVendor(
          rowData?.vendor_id,
          rowData?.id,
          {
            data: userData,
            successMessage: 'Vendor updated successfully!',
            failureMessage: 'Error: Failed to update vendor'
          }
        );
      }
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
        setIsError(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderDialogContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.companyName')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('company_name')}
              onChange={(e) => {
                edit.update({
                  company_name: capitalizeFirstLetter(e.target.value)
                });
              }}
              isError={companyNameError}
              helperText={companyNameError && 'Please enter your company name'}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.contactName')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('contact_name')}
              onChange={(e) =>
                edit.update({
                  contact_name: capitalizeFirstLetter(e.target.value)
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('mobileNumber')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('mobile_number')}
              onChange={(e) => {
                if (
                  isNaN(Number(e.target.value)) ||
                  e.target.value?.length > 12
                ) {
                  return;
                }
                edit.update({ mobile_number: e.target.value });
              }}
              helperText={mobileError && t('pleaseEnterYourValidMobileNumber')}
              isError={mobileError}
              required
              inputProps={{
                readOnly: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleCreate}
        disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };

  return (
    <DialogComp
      dialogTitle={
        type == '2' ? t('editVendorDetails') : t('editCustomerDetails')
      }
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        color: theme.Colors.blackMedium
      }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default EditCustomerVendor;
