import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const vendorListService = {
  getAllVendorList: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/reportVendorList`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorCount: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorOrderByUserType/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorTotalKgs: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorOrderKgs/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getAllVendorCity: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorOrderCity/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getAllVendorCategory: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorCategoryCount/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getVendorByUserCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getVendorOrderByUserTypeCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  vendorByKgCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorOrderByKgCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  vendorCityCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/vendorOrderCityCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getVendorCategoryCountCustom: async (params: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorCategoryCountCustom`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  }
};
