export const AND_SEPARATOR = ';';
export const OR_SEPARATOR = ',';
export const ASSIGNMENT_SEPARATOR = '=';
export const MAP_URL = 'http://maps.google.com/maps?q=';
export const GOOGLE_API_KEY = 'AIzaSyBSFGHNNkMngUmdJPUi9bfZb5oLOKQW7Y0';

export const HTTP_STATUSES = {
  OK: 200,
  SUCCESS: 201,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  SERVER_ERROR: 500
};

export const TOAST_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  LINK: 'link'
};

export const VENDOR_MANAGEMENT_STATUS = {
  1: 'New',
  2: 'Accepted',
  3: 'Rejected'
};

export const VENDOR_MG_STATUS = {
  New: 1,
  Accepted: 2,
  Rejected: 3
};

export const CUSTOMER_STATUS = {
  0: 'NewOrder',
  1: 'Published',
  2: 'Accepted',
  3: 'Completed',
  4: 'Cancelled'
};
export const ADMIN_ROLE = {
  superAdmin: 'Super Admin',
  admin: 'Admin'
};
export const VENDOR_STATUS = {
  0: 'New',
  1: 'Pending',
  2: 'Accepted',
  3: 'Completed',
  4: 'Cancelled',
  5: 'OverDue'
};

export const ORDER_STATUS = {
  newOrder: 0,
  pending: 1,
  confirmed: 2,
  completed: 3,
  cancelled: 4,
  overdue: 5
};

export const DEPOSIT_STATUS = {
  1: 'New',
  2: 'Accepted',
  3: 'Rejected'
};

export const DEPOSIT_Mg_STATUS = {
  New: 1,
  Accepted: 2,
  Rejected: 3
};

export const HANDLE_SUBMIT = {
  viewUser: 'viewUser',
  createUser: 'editOrCreateUser'
};

export const CONFIRM_MODAL = {
  delete: 'delete',
  publish: 'publish',
  cancel: 'cancel',
  accept: 'accept',
  reject: 'reject',
  moveToDustman: 'moveToDustman',
  create: 'create',
  edit: 'edit',
  logout: 'logout'
};

export const ORDERS_COUNT = {
  cancelledOrderCount: 0,
  confirmedOrderCount: 0,
  newOrderCount: 0,
  acceptedOrderCount: 0,
  pendingOrderCount: 0
};

export const CUSTOMER_MGNT_COUNT = {
  assigntoCount: 0,
  newRequestCount: 0,
  rejectedCount: 0,
  rejectedrequestCount: 0
};

export const VENDOR_REGIS_COUNT = {
  approved: 0,
  newRequest: 0,
  rejected: 0
};

export const CUSTOMER_REGIS_COUNT = {
  approved: 0,
  newRequest: 0,
  rejected: 0
};

export const DEPOSIT_COUNT = {
  newRequest: 0,
  approved: 0,
  rejected: 0
};

export const FILTER_CHIPS = ['Today', 'This Week', 'This Month', 'All'];

export const GET_FILTER_VALUES = {
  ['Today']: 1,
  ['This Week']: 2,
  ['This Month']: 3,
  ['All']: 4
};

export const DEFAULT_TABLE_ROW_COUNT = 5;

export const USER_TYPE_ID = {
  commercial: 1,
  industry: 2,
  vendorPickup: 3,
  vendorDrop: 4
};
export const USER_TYPE = {
  customer: 1,
  vendor: 2
};
export const GET_USER_CATEGORY = {
  1: 'Commercial User',
  2: 'Industry User',
  3: 'Vendor Pickup',
  4: 'vendor Drop'
};

export const TRASH_CATEGORY_ID = {
  customerTrash: 1,
  vendorDropTrash: 2,
  marketPrice: 3,
  vendorTrash: 2,
  vendorPickupTrash: 3
};

export const ENQUIRY_STATUS = {
  attend: 1,
  view: 2
};

export const PERMISSIONS = {
  overview: 1,
  newRequest: 2,
  customerOrder: 3,
  dustmanOrder: 4,
  customerManagement: 5,
  customerVendorList: 6,
  walletManagement: 7,
  invoiceManagement: 8,
  priceManagement: 9,
  enquiryManagement: 10,
  userManagement: 11,
  reports: 12,
  settings: 13,
  adminWallet: 14
};

export const CUSTOMER_ORDER_STATUS = {
  newOrder: 0,
  pending: 1,
  confirmed: 2,
  completed: 3,
  cancelled: 4
};

export const TOTAL_ORDERS_COUNT = {
  total_orders: 0,
  total_recycled: 0
};

export const LOCAL_STORAGE_KEY = {
  USER_ID: 'userId',
  TOKEN: 'token'
};

export const INVOICE_COUNT = {
  customer_invoice: 0,
  vendor_invoice: 0
};
