import React from 'react';
import { Box, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RupeeSign, RupeeSymbol } from 'src/Assets';
import { CUSTOMER_ORDER_STATUS } from 'src/Config/constant';

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    border: '1px solid',
    borderRadius: 10,
    borderColor: theme.Colors.lightShadeGrey,
    marginTop: 15
  },
  badgeStyle: {
    width: 44,
    height: 43,
    borderRadius: 50,
    backgroundColor: theme.Colors.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 90
  },
  titleStyle: {
    fontSize: 14,
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.black,
    paddingBottom: 10
  },
  subTextStyle: {
    fontSize: 12,
    fontWeight: theme.fontWeight.medium,
    color: theme.Colors.black,
    opacity: 0.4
  },
  amountStyle: {
    color: theme.Colors.white,
    fontSize: 11,
    fontWeight: theme.fontWeight.bold,
    paddingLeft: 5
  },
  totalStyle: {
    backgroundColor: theme.Colors.secondary,
    color: theme.Colors.white,
    padding: 6,
    textAlign: 'center',
    borderBottomRightRadius: 15,
    position: 'absolute',
    fontSize: 11,
    fontWeight: theme.fontWeight.bold
  },
  imageStyle: {
    width: 145,
    borderRadius: 12,
    height: 100,
    border: '1px solid rgba(0, 0, 0, 0.2)'
  }
}));

const ImageBoxContainer = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, statusId, isUpload } = props;

  return (
    <Box style={{ position: 'relative' }}>
      {data?.length &&
        data?.map((item, idx) => (
          <Grid
            xs={10}
            key={idx}
            style={{
              display: 'flex'
            }}
          >
            <Grid container item xs className={styles.containerStyle}>
              <Grid container item xs>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <img
                    style={
                      {
                        //position: 'relative',
                      }
                    }
                    className={styles.imageStyle}
                    src={item?.image_url}
                  />
                  {statusId === CUSTOMER_ORDER_STATUS['completed'] ||
                  isUpload ? (
                    <Typography className={styles.totalStyle}>
                      {`${item.category_quantity} Kg`}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid style={{ alignSelf: 'center', paddingLeft: 20 }}>
                  <Typography className={styles.titleStyle}>
                    {item.category_name}
                  </Typography>
                  {statusId === CUSTOMER_ORDER_STATUS['completed'] ||
                  isUpload ? (
                    <Typography
                      variant="subtitle2"
                      className={styles.subTextStyle}
                    >
                      {t('value')} -{' '}
                      <img
                        src={RupeeSign}
                        width={10}
                        height={15}
                        style={{ paddingTop: 5 }}
                      />
                      {item?.category_price * item?.final_quantity}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      className={styles.subTextStyle}
                    >
                      {`${item.category_quantity} Kgs`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {statusId === CUSTOMER_ORDER_STATUS['completed'] || isUpload ? (
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <img className={styles.imageStyle} src={item.final_image} />
                  <Typography className={styles.totalStyle}>
                    {`${item.final_quantity} Kg`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid item className={styles.badgeStyle}>
              <img src={RupeeSymbol} />
              <Typography className={styles.amountStyle}>
                {item?.category_price}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default ImageBoxContainer;
