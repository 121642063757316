import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  createStyles,
  Divider,
  Tab,
  Tabs
} from '@material-ui/core';
import { RupeeIcon } from 'src/Assets';

type Props = {
  bgColor: string;
  height: string;
  isTileTypeOrders: boolean;
  NumOfTiles: number;
  dividerBgColor?: string;
  isNotBackground?: boolean;
  tabWidth?: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    outerContainer: {
      borderRadius: theme.spacing(1),
      border: '1px solid',
      borderColor: theme.Colors.lightBlue,
      background: theme.Colors.white,
      margin: theme.spacing(2, 0, 4.3, 0),
      justifyContent: 'space-between',
      width: (props) =>
        props.NumOfTiles > 3
          ? '100%'
          : props.NumOfTiles == 2
          ? props.tabWidth
            ? `${props.NumOfTiles * 25}%`
            : `${props.NumOfTiles * 50}%`
          : props.tabWidth
          ? '100%'
          : `${props.NumOfTiles * 25}%`,

      '& .MuiTabs-flexContainer': {}
    },
    tabItemContainer: {
      borderRight: '1px solid',
      borderRightColor: theme.Colors.lightBlue,
      margin: theme.spacing(1, 0, 1, 1.25),
      paddingTop: (props) =>
        props.isTileTypeOrders ? theme.spacing(1) : theme.spacing(2.75),
      paddingBottom: (props) =>
        props.isTileTypeOrders ? theme.spacing(1) : theme.spacing(2.75),
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1)
    },
    contentContainer: {
      borderRadius: theme.spacing(1),
      textTransform: 'capitalize',
      padding: theme.spacing(0.5, 0.25, 0.5, 0.25),
      margin: theme.spacing(0.25),
      width: '100%'
    },
    textStyle: {
      fontSize: theme.MetricsSizes.small_xxx,
      fontWeight: theme.fontWeight.regular,
      textAlign: 'left',
      color: theme.Colors.black,
      paddingBottom: (props) =>
        props.isTileTypeOrders ? theme.spacing(0) : theme.spacing(0.25)
    },
    numberStyle: {
      fontSize: (props) =>
        props.isTileTypeOrders
          ? theme.MetricsSizes.medium_x
          : theme.MetricsSizes.small_xxx,
      fontWeight: (props) =>
        props.isTileTypeOrders
          ? theme.fontWeight.mediumBold
          : theme.fontWeight.regular,
      textAlign: 'left',
      color: theme.Colors.black,
      paddingTop: (props) =>
        props.isTileTypeOrders ? theme.spacing(0) : theme.spacing(0.25)
    },
    iconStyle: {
      background: theme.Colors.white,
      boxShadow: '0px 2px 10px rgba(124, 141, 181, 0.12)',
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1.9),
      marginRight: theme.spacing(3.25)
    },
    root: {
      width: '100%'
    },

    iconWrapper: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-evenly'
    },

    selectedTab: {
      background: (props) =>
        props.isNotBackground
          ? ''
          : 'linear-gradient(115.76deg, #3e4959 -80.62%, rgba(242, 242, 242, 0) 30.55%)'
    },
    tabIndicator: {
      display: 'none'
    },
    dividerStyle: {
      background: (props) => props.dividerBgColor || theme.Colors.lightBlue
    }
  })
);

type TabValues = {
  heading: string;
  subText?: number | string;
  iconImage: any;
  value?: string | number;
  iconSymbol?: any;
};

const ContentDisplayTiles = ({
  backgroundColor,
  height,
  displayContent,
  isTileTypeOrders,
  onTabChange,
  withBottomLine,
  tabValue,
  dividerBgColor,
  isNotBackground = false,
  tabWidth
}: {
  children?: JSX.Element | any;
  backgroundColor?: string;
  height?: string;
  displayContent?: TabValues[];
  isTileTypeOrders?: boolean;
  onTabChange?: (val: number | string) => void;
  withBottomLine?: boolean;
  tabValue?: string | number;
  dividerBgColor?: string;
  isNotBackground?: boolean;
  tabWidth?: boolean;
}) => {
  const classes = useStyles({
    bgColor: backgroundColor,
    height,
    isTileTypeOrders,
    NumOfTiles: displayContent.length,
    dividerBgColor: dividerBgColor,
    isNotBackground,
    tabWidth
  });
  const [tabToDisplay, setTabToDisplay] = useState<number | string>(
    tabValue || 0
  );

  const handleTabChange = (event: any, value: number | string) => {
    setTabToDisplay(value);
    if (onTabChange) onTabChange(value);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Tabs
          value={tabToDisplay}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          className={classes.outerContainer}
          classes={{
            indicator: classes.tabIndicator
          }}
        >
          {displayContent.map((item, index) => {
            return (
              <Tab
                key={index}
                value={item.value || index}
                icon={
                  <img
                    src={item.iconImage}
                    alt="image"
                    className={classes.iconStyle}
                  />
                }
                label={
                  <div className={classes.contentContainer}>
                    <Typography className={classes.textStyle}>
                      {item.heading}
                    </Typography>
                    <Typography className={classes.numberStyle}>
                      {item.iconSymbol ? <img src={item.iconSymbol} /> : null}
                      {item.subText}
                    </Typography>
                  </div>
                }
                className={classes.tabItemContainer}
                classes={{
                  wrapper: classes.iconWrapper,
                  selected: classes.selectedTab
                }}
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {withBottomLine && <Divider classes={{ root: classes.dividerStyle }} />}
      </Grid>
    </Grid>
  );
};
export default ContentDisplayTiles;
