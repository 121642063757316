import React, { useState } from 'react';
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  DialogComp,
  DialogContentDetails,
  DualActionButton,
  ListItemCell,
  TextInputComponent
} from 'src/components';
import {
  capitalizeFirstLetter,
  isGSTNumber,
  isLandline,
  isPhoneNumber,
  isValidEmail,
  isValidPinCode,
  isWebsiteName
} from 'src/Utils';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES, USER_TYPE } from 'src/Config/constant';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 1110,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

type Props = {
  onClose: () => void;
  rowData: any;
  updatedData: any;
  bankData: any;
};

const CustomerEditModal = ({
  onClose,
  rowData,
  updatedData,
  bankData
}: Props) => {
  console.log('bankData', bankData);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  console.log('rowData', rowData);

  const initialValues = {
    company_name: rowData?.company_name || '',
    contact_name: rowData?.contact_name || '',
    phone_no: rowData?.registered_mobile_number || '',
    email: rowData?.email || '',
    full_address: rowData?.full_address || '',
    pincode: rowData?.pincode || '',
    gst_number: rowData?.gst_number || '',
    pan_number: rowData?.pan_number || ''
  };

  const RequiredFields = ['company_name', 'contact_name'];

  const bankValue = {
    bank_name: bankData[0]?.name || '',
    account_number: bankData[0]?.account_number || '',
    ifsc_code: bankData[0]?.ifsc_code || ''
  };

  const edit = useEdit(initialValues);
  const editBank = useEdit(bankValue);

  const companyError = isError && !edit.getValue('company_name');
  const contactError = isError && !edit.getValue('contact_name');
  const pincodeError =
    (isError && !edit.allFilled('pincode')) ||
    (isError &&
      edit.allFilled('pincode') &&
      !isValidPinCode(edit.getValue('pincode')));
  const phoneError =
    (isError && !edit.allFilled('phone_no')) ||
    (isError &&
      edit.allFilled('phone_no') &&
      !isPhoneNumber(edit.getValue('phone_no')));
  const gstError =
    isError &&
    edit.allFilled('gst_number') &&
    !isGSTNumber(edit.getValue('gst_number'));
  const panError = isError && edit.allFilled('pan_number');
  const emailError =
    isError && edit.allFilled('email') && !isValidEmail(edit.getValue('email'));
  const addressError = isError && !edit.getValue('address');
  const bankNameError = isError && !editBank.getValue('bank_name');
  const accountError = isError && !editBank.getValue('account_number');
  const ifscError =
    (isError && !editBank.getValue('ifsc_code')) ||
    (isError && editBank.getValue('ifsc_code').length < 11);

  const RequiredBank = ['bank_name', 'account_number', 'ifsc_code'];

  const renderDialogContent = () => {
    return (
      <>
        <ListItemCell
          avatarImg={rowData?.image_url || 'DUSTMAN'}
          title={capitalizeFirstLetter(rowData?.company_name)}
          titleStyle={{
            fontSize: theme.MetricsSizes.regular_xxx,
            fontWeight: theme.fontWeight.bold
          }}
        />
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.companyName')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('company_name')}
              onChange={(e) =>
                edit.update({
                  company_name: capitalizeFirstLetter(e.target.value)
                })
              }
              isError={companyError}
              helperText={companyError && 'Please enter your company name'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'Contact Person'}
              labelColor={theme.Colors.primary}
              value={edit.getValue('contact_name')}
              onChange={(e) =>
                edit.update({
                  contact_name: capitalizeFirstLetter(e.target.value)
                })
              }
              isError={contactError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'Phone No'}
              labelColor={theme.Colors.primary}
              value={edit.getValue('phone_no')}
              onChange={(e) => {
                if (
                  isNaN(Number(e.target.value)) ||
                  e.target.value?.length > 12
                ) {
                  return;
                }
                edit.update({ phone_no: e.target.value });
              }}
              isError={phoneError}
              inputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.emailID')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('email')}
              onChange={(e) =>
                edit.update({
                  email: e.target.value
                })
              }
              isError={emailError}
              helperText={emailError && 'Please enter your valid email id'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('address')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('full_address')}
              onChange={(e) => edit.update({ full_address: e.target.value })}
              isError={addressError}
              helperText={addressError && 'Please enter your address detail'}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('enquiry.pincode')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('pincode')}
              onChange={(e) => edit.update({ pincode: Number(e.target.value) })}
              isError={pincodeError}
              helperText={pincodeError && 'Please enter your valid pincode'}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'Bank Name'}
              labelColor={theme.Colors.primary}
              value={editBank.getValue('bank_name')}
              onChange={(e) =>
                editBank.update({ bank_name: e.target.value.toUpperCase() })
              }
              isError={bankNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'Account Number'}
              labelColor={theme.Colors.primary}
              value={editBank.getValue('account_number')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                editBank.update({ account_number: e.target.value });
              }}
              isError={accountError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'IFSC Code'}
              labelColor={theme.Colors.primary}
              value={editBank.getValue('ifsc_code')}
              onChange={(e) =>
                editBank.update({ ifsc_code: e.target.value.toUpperCase() })
              }
              isError={ifscError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'GST Number'}
              labelColor={theme.Colors.primary}
              value={edit.getValue('gst_number')}
              onChange={(e) => edit.update({ gst_number: e.target.value })}
              isError={gstError}
              helperText={gstError && 'Please enter your valid GST Number'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={'PAN Number'}
              labelColor={theme.Colors.primary}
              value={edit.getValue('pan_number')}
              onChange={(e) => edit.update({ pan_number: e.target.value })}
              isError={panError}
              helperText={panError && 'Please enter your valid PAN number'}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const handleEditCustomer = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setIsError(true);
        return toast.error('Please fill all the fields');
      }
      if(!editBank.allFilled(...RequiredBank)) {
        setIsError(true);
        return toast.error('Please fill the bank details');
      }
      let data = { ...edit.edits };
      const response: any =
        await API_SERVICES.customerVendorListService.updateCustomer(
          rowData?.id,
          {
            data: data,
            successMessage: 'Customer Profile Updated Successfully!'
          }
        );
      if (response.status === HTTP_STATUSES.OK) {
        const bankResponse: any =
          await API_SERVICES.customerVendorListService.getCustomerBankDetailById(
            rowData?.customer_id
          );
        if (bankResponse.data.data?.length) {
          let data = {
            name: editBank.getValue('bank_name'),
            account_number: editBank.getValue('account_number'),
            ifsc_code: editBank.getValue('ifsc_code'),
            user_id: rowData?.customer_id,
            user_type: USER_TYPE.customer,
            connected_id: rowData?.connection_id
          };
          const updateResponse: any =
            await API_SERVICES.customerVendorListService.updateBankDetails(
              bankResponse.data.data[0]?.id,
              {
                data: data
              }
            );
          console.log(updateResponse);
          if (updateResponse.status === HTTP_STATUSES.OK) {
            updatedData();
            onClose();
          }
        } else {
          let data = {
            user_id: rowData?.customer_id,
            user_type: USER_TYPE.customer,
            name: editBank.getValue('bank_name'),
            account_number: editBank.getValue('account_number'),
            ifsc_code: editBank.getValue('ifsc_code'),
            connected_id: rowData?.connection_id
          };
          const createResponse: any =
            await API_SERVICES.customerVendorListService.createBankDetails({
              data: data
            });
          console.log(createResponse);
          if (createResponse.status === HTTP_STATUSES.SUCCESS) {
            updatedData();
            onClose();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAction = () => {
    return (
      <DualActionButton
        onRightButtonClick={handleEditCustomer}
        onLeftButtonClick={onClose}
      />
    );
  };
  return (
    <DialogComp
      open={true}
      onClose={onClose}
      dialogTitle={'Edit Customer'}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        color: theme.Colors.blackMedium
      }}
    />
  );
};

export default CustomerEditModal;
