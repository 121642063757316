import React, { useState } from 'react';

export type UserDetails = {
  id: number;
  name: string;
  user_name: string;
  password: string;
  role: string;
  created_at: string;
  updated_at: string;
  permissions: number[];
  image_url: string;
};

export type UserInfo = {
  userDetails: UserDetails;
  updateUserInfo: React.Dispatch<React.SetStateAction<UserDetails>>;
};

export const INITIAL_STATE: UserInfo = {
  userDetails: {
    id: 0,
    name: '',
    user_name: '',
    password: '',
    created_at: '',
    updated_at: '',
    role: '',
    permissions: [],
    image_url: ''
  },
  updateUserInfo: () => undefined
};

export const UserInfoContext = React.createContext({
  ...INITIAL_STATE
});

type Props = {
  children: any;
};

export const UserInfoProvider = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState<UserDetails>(
    INITIAL_STATE.userDetails
  );

  const contextValue = React.useMemo(() => {
    return {
      userDetails: userDetails,
      updateUserInfo: setUserDetails
    };
  }, [userDetails, setUserDetails]);

  return (
    <UserInfoContext.Provider value={contextValue}>
      {children}
    </UserInfoContext.Provider>
  );
};
