import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Theme,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PDFIcon } from 'src/Assets';
import { SalesTimeComp, UHCalenderComp } from 'src/components';
import { API_SERVICES } from 'src/Services';
import ChartComp from './ChartComp';
import { exportMultipleChartsToPdf } from 'src/Utils';
import 'jspdf-autotable';
type Props = {
  contentData?: any[];
  renderContent?: any;
  image?: any;
};
const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.Colors.whiteGreyLight,
      borderRadius: theme.MetricsSizes.regular_x,
      display: 'flex',
      flexDirection: 'column',
      height: '250px',
      width: '250px',
      padding: theme.spacing(2, 7, 2, 3.2),
      '&.MuiGrid-grid-xs-4': {
        maxWidth: '45%',
        flexBasis: '45%'
      }
    },
    text: {
      fontWeight: theme.fontWeight.regular,
      fontSize: theme.MetricsSizes.small_xxx,
      color: theme.Colors.mediumGrey,
      margin: theme.spacing(0, 0, 1, 1)
    },
    subText: {
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.regular_xxx,
      color: theme.Colors.black,
      textAlign: 'center'
    },
    dividerStyle: {
      background: theme.Colors.lightBlueGrey,
      margin: theme.spacing(0.6)
    },
    iconStyle: {
      color: theme.Colors.primary,
      width: theme.MetricsSizes.large_xx,
      height: theme.MetricsSizes.large_xx
    },
    buttonStyle: {
      paddingTop: theme.spacing(2.2)
    },
    labelStyle: {
      flexDirection: 'column'
    },
    gridStyle: {
      borderWidth: '1px',
      borderStyle: 'dashed',
      padding: theme.spacing(0, 0, 0, 0)
    },
    button: {
      minWidth: 0,
      padding: theme.spacing(0, 1, 0, 0)
    },
    iconButtonStyle: {
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.Colors.white
      }
    }
  };
});

const SalesBoxComp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const contentToDisplay = [
    {
      id: 1,
      heading: 'Today',
      value: 1
    },
    {
      id: 2,
      heading: 'Week',
      value: 2
    },
    {
      id: 3,
      heading: 'Month',
      value: 3
    },
    {
      id: 4,
      heading: 'Custom',
      value: 4
    }
  ];
  const [selectedCategoryTab, setSelectedCategoryTab] = useState(1);
  const [selectedCityTab, setSelectedCityTab] = useState(1);
  const [selectedCustomerCountTab, setSelectedCustomerCountTab] = useState(1);
  const [selectedKgsTab, setSelectedKgsTab] = useState(1);
  const [countCity, setCountCity] = useState([]);
  const [categoryCount, setCategoryCount] = useState([]);
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const inputRef = useRef(null);
  const [totalOrder, setTotalOrder] = useState({
    totalOrders: 0,
    // HomeCount: 0,
    CommercialCount: 0,
    IndustrialCount: 0
  });
  const [totalKgCount, setTotalKgCount] = useState({
    totalWeight: 0,
    // homeUserWeight: 0,
    commercialWeight: 0,
    industryWeight: 0
  });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const fetchData = useCallback(async () => {
    const response: any = await Promise.all([
      API_SERVICES.customerListService.getCustomerCount(
        selectedCustomerCountTab
      ),
      API_SERVICES.customerListService.getCustomerTotalKgs(selectedKgsTab),
      API_SERVICES.customerListService.getAllCustomerCity(selectedCityTab),
      API_SERVICES.customerListService.getAllCustomerCategory(
        selectedCategoryTab
      )
    ]);

    let count = {
      totalOrders: parseInt(response[0]?.data?.totalOrders),
      // HomeCount: parseInt(response[0]?.data?.ordersCount?.HomeCount),
      CommercialCount: parseInt(
        response[0]?.data?.ordersCount?.CommercialCount
      ),
      IndustrialCount: parseInt(response[0]?.data?.ordersCount?.IndustrialCount)
    };
    setTotalOrder(count);

    let kgCount = {
      totalWeight: parseInt(response[1]?.data?.totalWeight),
      // homeUserWeight: parseInt(response[1]?.data?.userWeight?.homeuserWeight),
      commercialWeight: parseInt(
        response[1].data?.userWeight?.commericalWeight
      ),
      industryWeight: parseInt(response[1]?.data?.userWeight?.industryWeight)
    };
    setTotalKgCount(kgCount);
    setCountCity(response[2]?.data?.city);
    setCategoryCount(response[3]?.data?.allCategoryOrderCount);
  }, [
    selectedCategoryTab,
    selectedCityTab,
    selectedCustomerCountTab,
    selectedKgsTab
  ]);
  let newData = [];
  let newCount = [];
  const cityCount = countCity?.map((city) => {
    newCount.push(city?.orderCount);
    return newCount;
  });
  const cityName: any = countCity?.map((name) => {
    newData.push(name.city);
    return newData;
  });
  let newCategory = [];
  let newCategoryData = [];
  const categoryOne = categoryCount?.map((name) => {
    newCategory.push(name.categoryName);
    return newCategory;
  });
  const categoryTwo = categoryCount?.map((city) => {
    newCategoryData.push(city.categoryOrderCount);
  });
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const fromDate = dateSelect[0].startDate.toLocaleDateString('en-US');
  const lastDate = dateSelect[0].endDate.toLocaleDateString('en-US');

  const handleTrashTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.customerListService.getAllCategoryTrashCustom(
          params
        );
      setCategoryCount(response?.data?.allCategoryOrderCount);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedCategoryTab(value);
    }
  };
  const handleSalesTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.customerListService.getAllCustomerCityCustom(params);
      setCountCity(response?.data?.city);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedCityTab(value);
    }
  };

  const handleOrderTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.customerListService.getCustomerCustom(params);
      let count = {
        totalOrders: parseInt(response?.data?.totalOrders),
        // HomeCount: parseInt(response?.data?.ordersCount.HomeCount),
        CommercialCount: parseInt(response?.data?.ordersCount.CommercialCount),
        IndustrialCount: parseInt(response?.data?.ordersCount.IndustrialCount)
      };
      setTotalOrder(count);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedCustomerCountTab(value);
    }
  };

  const handleKgsTabChange = (value) => {
    const onConfirmClick = async () => {
      let params: any = {
        fromDate: fromDate,
        toDate: lastDate
      };
      const response: any =
        await API_SERVICES.customerListService.getAllCustomerKgsCustom(params);
      let kgCount = {
        totalWeight: parseInt(response?.data?.totalWeight),
        // homeUserWeight: parseInt(response?.data?.userWeight?.homeuserWeight),
        commercialWeight: parseInt(response.data?.userWeight?.commericalWeight),
        industryWeight: parseInt(response?.data?.userWeight?.industryWeight)
      };
      setTotalKgCount(kgCount);
      setConfirmModal({ open: false });
    };
    if (value === 4) {
      setConfirmModal({ open: true, onConfirmClick });
    } else {
      setSelectedKgsTab(value);
    }
  };

  return (
    <>
      <div className="custom-chart">
        <Grid
          container
          xs={12}
          style={{ display: 'flex', marginTop: '30px', gap: '30px' }}
        >
          <Grid
            item
            container
            xs={6}
            style={{ display: 'flex' }}
            className={classes.gridContainer}
          >
            <Grid item xs>
              <SalesTimeComp
                header="Customer Sales"
                onTabChange={handleSalesTabChange}
                contentToDisplay={contentToDisplay}
              />
              <ChartComp
                height="200px"
                width="250px"
                type="bar"
                series={[
                  {
                    name: 'Customer Count',
                    data: newCount
                  }
                ]}
                options={{
                  chart: {
                    id: 'simple-bar',
                    toolbar: {
                      show: false
                    }
                  },
                  colors: ['#11294C'],
                  xaxis: {
                    categories: newData
                  },
                  yaxis: {
                    show: false
                  },
                  grid: {
                    show: false
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        position: 'top'
                      },
                      offsetY: -20
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs
            style={{ display: 'flex' }}
            className={classes.gridContainer}
          >
            <Grid item>
              <SalesTimeComp
                header="Customer Order"
                onTabChange={handleOrderTabChange}
                contentToDisplay={contentToDisplay}
              />
            </Grid>
            <Grid
              item
              container
              xs={10}
              direction="row"
              style={{ display: 'flex', justifyContent: 'space-evenly' }}
            >
              {/* <Grid item xs={3}>
                <ChartComp
                  height="150px"
                  width="90px"
                  type="radialBar"
                  series={[totalOrder.HomeCount]}
                  options={{
                    chart: {
                      id: 'simple-bar',
                      toolbar: {
                        show: false
                      }
                    },
                    colors: ['#11294C'],
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          name: {
                            position: 'bottom',
                            show: false
                          },
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return val;
                            }
                          }
                        }
                      }
                    },
                    labels: '32'
                  }}
                />
                <Typography>Home User</Typography>
              </Grid> */}
              {/* ))} */}
              <Grid item xs={3}>
                <ChartComp
                  height="150px"
                  width="90px"
                  type="radialBar"
                  series={[totalOrder.CommercialCount]}
                  options={{
                    colors: ['#11294C'],
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          name: {
                            position: 'top',
                            show: false
                          },
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return val;
                            }
                          }
                        }
                      }
                    },

                    labels: '112'
                  }}
                />
                <Typography align="center">Commercial User</Typography>
              </Grid>
              <Grid item xs={3}>
                <ChartComp
                  height="150px"
                  width="90px"
                  type="radialBar"
                  series={[totalOrder.IndustrialCount]}
                  options={{
                    colors: ['#11294C'],
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          name: {
                            position: 'bottom',
                            show: false
                          },
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return val;
                            }
                          }
                        }
                      }
                    },

                    labels: '1258'
                  }}
                />
                <Typography align="center">Industrial User</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: '50px' }}>
              <Typography className={classes.text}>Total Order</Typography>
              <Typography className={classes.subText}>
                {totalOrder.totalOrders}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          style={{ display: 'flex', marginTop: '30px', gap: '30px' }}
        >
          <Grid item xs={6} className={classes.gridContainer}>
            <Grid item style={{ display: 'flex' }}>
              <Grid item xs={4}>
                <ChartComp
                  height="250px"
                  width="250px"
                  type="pie"
                  series={[
                    // totalKgCount.homeUserWeight,
                    totalKgCount.commercialWeight,
                    totalKgCount.industryWeight
                  ]}
                  options={{
                    dataLabels: {
                      enabled: true
                    },

                    data: {
                      chartOptions: {
                        colors: ['#E6EDFF', '#FF9A6A', '#6CB044']
                      }
                    },
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          position: 'bottom',
                          show: false,
                          enabled: true,
                          offset: -35,
                          value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                              return 1;
                            }
                          }
                        }
                      }
                    },
                    legend: {
                      show: true,
                      position: 'bottom'
                    },

                    labels: ['Commercial', 'Industrial']
                  }}
                />
              </Grid>
              <Grid item xs style={{ marginLeft: '90px', marginTop: '50px' }}>
                <SalesTimeComp
                  onTabChange={handleKgsTabChange}
                  contentToDisplay={contentToDisplay}
                />
                <Grid item style={{ textAlign: 'center', padding: '20px' }}>
                  <Typography className={classes.text}>
                    Total Trash Weight
                  </Typography>
                  <Typography className={classes.subText}>
                    {totalKgCount.totalWeight} Kgs
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs className={classes.gridContainer}>
            <Grid item xs>
              <SalesTimeComp
                header="Category Trash"
                onTabChange={handleTrashTabChange}
                contentToDisplay={contentToDisplay}
              />
              <Grid style={{ marginLeft: 0 }}>
                <ChartComp
                  height="220px"
                  width="460px"
                  type="bar"
                  series={[
                    {
                      name: 'Category Trash',
                      data: newCategoryData
                    }
                  ]}
                  options={{
                    chart: {
                      id: 'simple-bar',
                      toolbar: {
                        show: false
                      }
                    },
                    colors: ['#11294C'],
                    xaxis: {
                      categories: newCategory,
                      position: 'bottom',
                      axisBorder: {
                        show: false
                      },
                      axisTicks: {
                        show: false
                      },
                      labels: {
                        rotate: -65,
                        style: {
                          fontSize: '12px',
                          colors: ['#11294C']
                        }
                      },
                      crosshairs: {
                        fill: {
                          type: 'gradient',
                          width: 150,
                          gradient: {
                            colorFrom: '#11294C',
                            colorTo: '#11294C',
                            stops: [20, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5
                          }
                        }
                      }
                    },
                    yaxis: {
                      axisBorder: {
                        show: false
                      },
                      axisTicks: {
                        show: false
                      },
                      labels: {
                        show: false
                      }
                    },
                    grid: {
                      show: false
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          position: 'top',
                          offsetY: 0
                        },
                        value: {
                          show: true,
                          fontSize: '14px',
                          formatter: function (val) {
                            return val;
                          }
                        }
                      }
                    },
                    dataLabels: {
                      enabled: true,
                      offsetY: -20,
                      style: {
                        fontSize: '12px',
                        colors: ['#11294C']
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid item>
        <IconButton
          onClick={exportMultipleChartsToPdf}
          className={classes.iconButtonStyle}
        >
          <img src={PDFIcon} />
        </IconButton>
      </Grid>

      {confirmModal.open && (
        <UHCalenderComp
          onCancelClick={() => setConfirmModal({ open: false })}
          handleChange={handleChange}
          ranges={dateSelect}
          {...confirmModal}
        />
      )}
    </>
  );
};
export default SalesBoxComp;
