import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import {
  AND_SEPARATOR,
  ASSIGNMENT_SEPARATOR,
  OR_SEPARATOR
} from 'src/Config/constant';

export const createAndFields = (fieldValuesPair) => {
  return fieldValuesPair
    .map((e) => {
      if (Array.isArray(e.value)) {
        return `${e.columnField}${ASSIGNMENT_SEPARATOR}${e.value.join(
          OR_SEPARATOR
        )}`;
      }
      return `${e.columnField}${ASSIGNMENT_SEPARATOR}${e.value}`;
    })
    .join(AND_SEPARATOR);
};

export const oldQueryMethod = (filters) => {
  const pairs = Object.entries(filters)
    .filter(([, value]) => value !== undefined)
    .map(([columnField, value]) => {
      return { columnField, value };
    });
  const query = createAndFields(pairs);
  return query;
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem('token'));
};

export const getUserId = () => {
  return JSON.parse(localStorage.getItem('userId'));
};

export const getDateFormat = (date: any) => {
  let data = new Date(date);
  const optionsMonth: any = { month: 'long' };
  const getMonth = new Intl.DateTimeFormat('en-US', optionsMonth).format(data);
  const getDate = data.getDate();
  const getYear = data.getFullYear();
  const getTime = data.toLocaleTimeString();
  return {
    getMonth,
    getDate,
    getYear,
    getTime
  };
};

export const getDateUnixFormat = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const optionsMonth: Intl.DateTimeFormatOptions = { month: 'long' };
  const getMonth = new Intl.DateTimeFormat('en-US', optionsMonth).format(date);
  const getDate = date.getDate();
  const getYear = date.getFullYear();

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const getTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
  return {
    getMonth,
    getDate,
    getYear,
    getTime
  };
};

export const getCurrentFieldValue = (initialVal, edits, fieldName) => {
  if (edits[fieldName] !== undefined) {
    return edits[fieldName];
  }
  if (initialVal) {
    return initialVal[fieldName];
  }
  return null;
};

export const isEmpty = (obj) => {
  if (typeof obj === 'object') {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
  return Array.isArray(obj) && obj.length === 0;
};

export const filterOrders = (tableData: any[], val: number) => {
  return (
    tableData.length &&
    tableData.filter((item) => item.status_id === val).length
  );
};

export const isValidEmail = (email: string) => {
  const emailExp =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  return email?.match(emailExp);
};

export const isPhoneNumber = (number: string) => {
  const numberExp = /^([0-9()-]{12})$/;
  return number?.match(numberExp);
};

export const isOneTimePassWord = (number: string) => {
  const otpExp = /^([0-9]{6})$/;
  return number?.match(otpExp);
};

export const isValidPinCode = (number: string) => {
  const pinCodeExp = /^[1-9][0-9]{5}$/;
  return number?.match(pinCodeExp);
};

export const isLandline = (num: string) => {
  const regExp = /^[0-9]{3,5}[-][0-9]{6,8}/;
  return num?.match(regExp);
};

export const isYear = (val: string) => {
  const regExp = /^[0-9]{4}$/;
  return val?.match(regExp);
};

export const isGSTNumber = (val: string) => {
  const regExp = /^[A-Z0-9]{15}?$/;
  return val?.match(regExp);
};

export const isWebsiteName = (val: string) => {
  const regExp =
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return val?.match(regExp);
};

export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export async function exportMultipleChartsToPdf() {
  const doc = new jsPDF('p', 'px');

  const elements = document.getElementsByClassName('custom-chart');

  await creatPdf({ doc, elements });

  doc.save(`charts.pdf`);
}

async function creatPdf({
  doc,
  elements
}: {
  doc: jsPDF;
  elements: HTMLCollectionOf<Element>;
}) {
  const padding = 10;
  const marginTop = 20;
  let top = marginTop;

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i) as HTMLElement;
    const imgData = await htmlToImage.toPng(el);

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding * 2;
      elWidth = elWidth * ratio - padding * 2;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = marginTop;
    }

    doc.addImage(imgData, 'PNG', padding, top, elWidth, elHeight, `image${i}`);
    top += elHeight + marginTop;
  }
}
export const dateFormate = (date: string) => {
  if (date == '-') {
    return '-';
  }
  var inputDate = date;
  var dateObject = new Date(inputDate);
  var day: any = dateObject?.getDate();
  var month: any = dateObject?.getMonth() + 1;
  var year = dateObject?.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  var formattedDate = day + '-' + month + '-' + year;
  console.log(formattedDate, 'dateFormate');
  return formattedDate;
};

export const getToday = (date: string) => {
  let day = new Date(date);
  if (day.getDay() == 0) {
    return 'Sun';
  } else if (day.getDay() == 1) {
    return 'Mon';
  } else if (day.getDay() == 2) {
    return 'Tue';
  } else if (day.getDay() == 3) {
    return 'Wed';
  } else if (day.getDay() == 4) {
    return 'Thu';
  } else if (day.getDay() == 5) {
    return 'Fri';
  } else {
    return 'Sat';
  }
};

export const getSortMonth = (val) => {
  if (val == '01') {
    return 'Jan';
  }
  if (val == '02') {
    return 'Feb';
  }
  if (val == '03') {
    return 'Mar';
  }
  if (val == '04') {
    return 'Apr';
  }
  if (val == '05') {
    return 'May';
  }
  if (val == '06') {
    return 'Jun';
  }
  if (val == '07') {
    return 'Jul';
  }
  if (val == '08') {
    return 'Aug';
  }
  if (val == '09') {
    return 'Sep';
  }
  if (val == '10') {
    return 'Oct';
  }
  if (val == '11') {
    return 'Nov';
  } else {
    return 'Dec';
  }
};

export const getTimestampFromDate = (dateString) => {
  const [day, month, year] = dateString.split('/')?.map(Number);
  const date = new Date(year, month - 1, day);
  return date.getTime();
};
