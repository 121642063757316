import React from 'react';
import {
  Container,
  Grid,
  IconButton,
  Theme,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Logo, PDFIcon, RupeeIcon } from 'src/Assets';
import { Heading } from 'src/components';
import UHExcelExport from 'src/components/UHExcelExport';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconButtonStyle: {
      padding: theme.spacing(1, 0),
      '&:hover': {
        backgroundColor: theme.Colors.white
      }
    }
  };
});

// Download data from URL

// try {
//   const url1 = 'https://www.pexels.com/photo/close-up-photography-flowers-in-a-vase-757889/'; // Replace with your PDF URL
//   const fileName = 'sample.pdf'; // Replace with your desired file name
//   const response = await fetch(url1);
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }

//   const blob = await response.blob();
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.click();
//   a.remove();
//   window.URL.revokeObjectURL(url);
// } catch (error) {
//   console.error('There was an error downloading the file:', error);
// }

const tableData = [
  {
    id: 1,
    category: 'Plastic Waste',
    quantity: '100Kg',
    price: '30/kg',
    amount: '3000'
  },
  {
    id: 2,
    category: 'Aluminium Waste',
    quantity: '150Kg',
    price: '30/kg',
    amount: '4500'
  }
];

const gsts = [
  { id: 1, name: 'CGST', price: '1000' },
  { id: 2, name: 'SGST', price: '1000' },
  { id: 3, name: 'IGST', price: '0.00' }
];

const notes = [
  {
    id: 1,
    description: 'Please send the invoice copy to the marketing office '
  },
  { id: 2, description: 'Only fresh material to be loaded' },
  {
    id: 3,
    description: 'The test certificate should sent along with the material'
  },
  { id: 4, description: 'Please do not send excess quantity' }
];
const DownloadInvoice = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs
          style={{
            border: '1px solid #1018281a',
            padding: '20px 30px',
            borderRadius: 8,
            marginRight: 20
          }}
        >
          <table style={{ width: '100%' }}>
            <tr>
              <td rowSpan={2}>
                <img src={Logo} />
              </td>
              <td>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  Invoice No :
                </Typography>
              </td>
              <td>
                <Typography
                  style={{ fontSize: 14, fontWeight: 400, color: '#696969' }}
                >
                  #25368654
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  Invoice Date :
                </Typography>
              </td>
              <td>
                <Typography
                  style={{ fontSize: 14, fontWeight: 400, color: '#696969' }}
                >
                  #25368654
                </Typography>
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  fontSize: 24,
                  fontWeight: 500,
                  textAlign: 'left',
                  padding: '20px 0px',
                  color: theme.Colors.neutralBlack
                }}
              >
                Dustman Waste Management & Recycling Pvt Ltd
              </th>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 2,
                  color: '#696969'
                }}
              >
                SF.No.179/6A, 8A, 9A, Mambakkam Road, Mambakkam Village,
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 2,
                  color: '#696969'
                }}
              >
                Sriperumbudur, Kanchipuram, Tamilnadu - 602106
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 2,
                  color: '#696969'
                }}
              >
                Contact: (+91) 89399 09555, (+91) 89399 76555
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 2,
                  color: '#696969'
                }}
              >
                GST - AJJK56738OIS989
              </td>
            </tr>
          </table>
        </Grid>
        <Grid
          item
          xs
          style={{
            border: '1px solid #1018281a',
            padding: '20px 30px',
            borderRadius: 8
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 400,
                color: '#696969'
              }}
            >
              BILL TO
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#696969'
              }}
            >
              Contact: 044-2440 4550
            </Typography>
          </div>
          <div
            style={{
              fontSize: 24,
              fontWeight: 500,
              textAlign: 'left',
              padding: '5px 0px',
              color: theme.Colors.neutralBlack
            }}
          >
            Anand Industries Pvt Ltd
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 2,
              color: '#696969'
            }}
          >
            Sriperumbudur, Kanchipuram, Tamilnadu - 602106
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 2,
              color: '#696969'
            }}
          >
            GST - AJJK56738OIS989
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0px' }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 400,
                color: '#696969'
              }}
            >
              SHIPPED By
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#696969'
              }}
            >
              Contact: 044-2440 4550
            </Typography>
          </div>
          <h3
            style={{
              fontSize: 24,
              fontWeight: 500,
              textAlign: 'left',
              padding: '5px 0px',
              color: theme.Colors.neutralBlack
            }}
          >
            ABC Steels
          </h3>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 2,
              color: '#696969'
            }}
          >
            Sriperumbudur, Kanchipuram, Tamilnadu - 602106
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 2,
              color: '#696969'
            }}
          >
            GST - AJJK56738OIS989
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          border: '1px solid #1018281a',
          padding: '20px 30px',
          borderRadius: 8,
          marginTop: 30
        }}
      >
        <h2>Summary</h2>
        <table style={{ width: '100%' }}>
          {tableData?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td
                  style={{
                    borderBottom: '1px solid #1018281a ',
                    padding: '20px 0px',
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  {item.id}
                </td>
                <td
                  colSpan={8}
                  style={{
                    borderBottom: '1px solid #1018281a ',
                    padding: '20px 0px',
                    fontSize: 13,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  {item.category}
                </td>
                <td
                  style={{
                    borderBottom: '1px solid #1018281a ',
                    padding: '20px 0px',
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  {item.quantity}
                </td>
                <td
                  style={{
                    borderBottom: '1px solid #1018281a ',
                    padding: '20px 0px',
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.Colors.neutralBlack
                  }}
                >
                  ₹{item.price}
                </td>
                <td
                  style={{
                    borderBottom: '1px solid #1018281a ',
                    padding: '20px 0px',
                    fontSize: 14,
                    fontWeight: 400,
                    textAlign: 'end',
                    color: theme.Colors.neutralBlack
                  }}
                >
                  ₹{item.amount}
                </td>
              </tr>
            );
          })}
        </table>
        {gsts?.map((item, idx) => {
          return (
            <>
              <div
                key={idx}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Typography
                    style={{
                      padding: '20px 0px',
                      fontSize: 14,
                      fontWeight: 400,
                      color: theme.Colors.neutralBlack
                    }}
                  >
                    {item.name}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      padding: '20px 0px',
                      fontSize: 14,
                      fontWeight: 400,
                      color: theme.Colors.neutralBlack
                    }}
                  >
                    ₹{item.price}
                  </Typography>
                </div>
              </div>
              <hr style={{ border: '1px solid #1018281a' }} />
            </>
          );
        })}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography
              style={{
                paddingTop: 20,
                fontSize: 14,
                fontWeight: 700,
                color: theme.Colors.neutralBlack
              }}
            >
              SubTotal
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                paddingTop: 20,
                fontSize: 14,
                fontWeight: 700,
                //textAlign: 'end',
                color: theme.Colors.neutralBlack
              }}
            >
              ₹25,500
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ padding: 30 }}>
        <Heading
          headingText={'NOTES:'}
          headerFontSize={14}
          headingColor={theme.Colors.neutralBlack}
        />
        {notes?.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{ display: 'flex', columnGap: 5, paddingLeft: 30 }}
            >
              <Typography
                style={{
                  lineHeight: 2,
                  fontSize: 14,
                  fontWeight: 400,
                  color: theme.Colors.neutralBlack
                }}
              >
                {item.id}.
              </Typography>
              <Typography
                style={{
                  lineHeight: 2,
                  fontSize: 14,
                  fontWeight: 400,
                  color: theme.Colors.neutralBlack
                }}
              >
                {item.description}.
              </Typography>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50
        }}
      >
        <div>
          <Typography
            style={{
              paddingTop: 20,
              fontSize: 14,
              fontWeight: 700,
              color: theme.Colors.neutralBlack
            }}
          >
            Ordered By
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              paddingTop: 20,
              fontSize: 14,
              fontWeight: 700,
              color: theme.Colors.neutralBlack
            }}
          >
            So Authorized By
          </Typography>
        </div>
      </div>

      <Grid
        item
        style={{ display: 'flex', marginTop: 20 }}
        className={classes.iconButtonStyle}
      >
        <UHExcelExport
          // excelData={excelData}
          // excelHeaders={excelHeaders}
          fileName={'Pincode'}
          excelData={[]}
          excelHeaders={[]}
        />
        <IconButton
          // onClick={handlePdfClick}
          className={classes.iconButtonStyle}
        >
          <img src={PDFIcon} />
        </IconButton>
      </Grid>
    </Container>
  );
};

export default DownloadInvoice;
