import React, { useState } from 'react';
import { SwitchComponent } from 'src/components';
import CustomerVendorManagement from './CustomerVendorManagement';

const NewRequestForVendorCustomer = () => {
  const [button, setButton] = useState(1);
  return (
    <>
      <SwitchComponent
        handleClick={(val) => setButton(val)}
        buttonValue={button}
        setButtonValue={setButton}
      />
      <CustomerVendorManagement buttonVal={button} />
    </>
  );
};

export default NewRequestForVendorCustomer;
