import React from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { DialogComp, DialogContentDetails } from 'src/components';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 700,
      padding: theme.spacing(2, 3, 2, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    }
  };
});

type Props = { onClose: () => void; rowData?: any };

const CustomerListModal = (props: Props) => {
  const { onClose, rowData } = props;
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: t('companyName'),
        value: capitalizeFirstLetter(rowData?.company_name)
      },
      {
        content: t('mobileNumber'),
        value: rowData?.registered_mobile_number?.slice(2)
      },
      {
        content: t('address'),
        value: rowData?.full_address || '-'
      },

      { content: t('quantityKg'), value: rowData?.quantity_kg || '-' },
      {
        content: t('document'),
        value: rowData?.document_urls,
        isLink: true
      }
    ];

    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={rowData?.contact_name}
      avatarImg={rowData?.image_url || 'DUSTMAN'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
    />
  );
};

export default CustomerListModal;
