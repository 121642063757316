import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { customerOrderData } from './AdminStub';

export type DefaultProp = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};
export const customerOrderService = {
  getAllBy: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/customerOrders/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
    //return customerOrderData.customerOrderGet(id)
  },

  getById: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getOrder/order/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },

  replace: async ({ data, successMessage, failureMessage }: DefaultProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceOrder/order`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  cancelOrder: async (
    order_id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/cancelOrder/order/${order_id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  moveToDustman: async ({
    data,
    successMessage,
    failureMessage
  }: {
    data: any;
    successMessage?: string;
    failureMessage?: string;
  }) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/moverToDustmanCustomer`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  getAcceptedOrder: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getCustomerOrdersVendorDetailById/order/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  completeCustomerOrder: async (
    order_id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminCompleteCustomerOrder/${order_id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  dustmanCompleteCustomerOrder: async (
    order_id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/dustmanCompleteCustomerOrder/order/${order_id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
