import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonComp, ListItemCell, UHTable } from 'src/components';

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
      {
        display: 'none'
      }
  }
});

const VendorModalTable = ({
  vendorList,
  onSelectionModelChange,
  handleOpenVendor,
  selectionModel
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.contact_name} />
    },
    {
      field: 'company_name',
      headerName: 'Company Name',
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.company_name} />
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.location} subTitle={row?.pincode} />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={row?.mobile_number || row?.registered_mobile_number}
        />
      )
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ButtonComp
          buttonText={'View'}
          buttonFontSize={theme.MetricsSizes.small_x}
          btnWidth={91}
          height={theme.MetricsSizes.regular_xxx}
          btnBorderRadius={theme.MetricsSizes.regular_x}
          onClickButton={() => handleOpenVendor(row)}
        />
      )
    }
  ];

  return (
    <UHTable
      title={'Vendor List'}
      columns={columns}
      rows={vendorList}
      isBorder={false}
      isDownloadButton={false}
      tableStyle={classes.root}
      autoHeight
      textColor={'#777777'}
      fontSize={13}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selectionModel}
    />
  );
};

export default VendorModalTable;
