import React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { ExcelIcon } from 'src/Assets';

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonStyle: {
    padding: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.Colors.white
    }
  }
}));

export type ExcelProps = {
  excelData: Record<string, any>[];
  excelHeaders: { label: string, key: string }[];
  fileName: string;
};

const UHExcelExport = (props: ExcelProps) => {
  const { excelData, excelHeaders, fileName } = props;
  const classes = useStyles();

  const handleExport = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');
      worksheet.columns = excelHeaders?.map(header => ({
        header: header.label,
        key: header.key,
        width: Math.max(header.label.length, 15) 
      }));
      worksheet.addRows(excelData);
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error exporting Excel file:', error);
    }
  };

  return (
    <>
      {excelData.length ? <IconButton className={classes.iconButtonStyle} onClick={handleExport}>
        <img src={ExcelIcon} />
      </IconButton> : <IconButton className={classes.iconButtonStyle} >
        <img src={ExcelIcon} />
      </IconButton>}
    </>
  );
};

export default React.memo(UHExcelExport);
