import React from 'react';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import {
  Checkbox,
  FormHelperText,
  Grid,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';

type StyleProp = {
  borderColor?: string;
  borderRadius?: number;
  selectHeight?: number;
  background?: string;
  iconColor?: string;
  selectWidth?: number;
};

const useStyles = makeStyles<Theme, StyleProp>((theme: Theme) =>
  createStyles({
    selectStyle: {
      height: (props) => props?.selectHeight || theme.MetricsSizes.large_xxx,
      color: theme.Colors.inputText,
      fontSize: theme.MetricsSizes.small_xx,
      fontWeight: theme.fontWeight.medium,
      marginTop: theme.MetricsSizes.tiny,
      width: (props) => props?.selectWidth || '100%',
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: (props) => props.borderColor || theme.Colors.lightGrey,
        borderWidth: '1px',
        borderRadius: (props) => props.borderRadius || 0,
        backgroundColor: (props) => props.background || 'transparent',
        opacity: (props) => props.background && 0.3
      },
      '& .MuiSelect-select:focus': {
        background: 'transparent',
        outline: 'none'
      },
      '& .MuiSelect-icon': {
        color: (props) => props.iconColor || '#0000008a'
      }
    },
    required: {
      color: theme.Colors.redPrimary,
      fontWeight: theme.fontWeight.bold
    },
    helperTextStyle: {
      textTransform: 'none',
      color: theme.Colors.redPrimary,
      paddingLeft: theme.spacing(2)
    },
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        backgroundColor: "transparent",
        color: theme.Colors.primary
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: 'transparent'
      }
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250
    }
  }
};

type Props = SelectProps & {
  selectItems?: any[];
  titleText?: string;
  variant?: string;
  multiple?: boolean;
  value?: any;
  onChange?: (val: any) => void;
  selectBoxStyle?: React.CSSProperties;
  isPlaceholderNone?: boolean;
  placeholderText?: string;
  isError?: boolean;
  borderColor?: string;
  helperText?: string;
  required?: boolean;
  borderRadius?: number;
  selectHeight?: number;
  background?: string;
  iconColor?: string;
  isCheckbox?: boolean;
  selectWidth?: number;
};

function MultipleSelectComp(props: Props) {
  const {
    selectItems,
    titleText,
    multiple = false,
    variant = 'outlined',
    value,
    onChange,
    selectBoxStyle,
    isPlaceholderNone = false,
    placeholderText,
    isError = false,
    borderColor,
    helperText,
    required,
    iconColor,
    borderRadius,
    selectHeight,
    background,
    isCheckbox = false,
    selectWidth,
    ...rest
  } = props;
  const theme = useTheme();
  const classes = useStyles({
    borderColor: (isError && theme.Colors.redPrimary) || borderColor,
    iconColor,
    borderRadius,
    selectHeight,
    background,
    selectWidth
  });
  const getMenuItems = () => {
    if (!selectItems?.length) {
      return null;
    }    
    return selectItems?.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };

  const getIconMenuItems = () => {
    if (!selectItems?.length) {
      return null;
    }
    return selectItems?.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        <ListItemIcon style={{ alignItems: 'center' }}>
          <Checkbox checked={value.includes(item.value)} className={classes.checkbox}/>
          <ListItemText primary={item.label} />
        </ListItemIcon>
      </MenuItem>
    ));
  };
  return (
    <Grid container direction="column">
      {titleText && (
        <Typography
          style={{
            color: (isError && theme.Colors.redPrimary) || theme.Colors.primary,
            fontWeight: theme.fontWeight.medium
          }}
        >
          {titleText}
          {required && <span className={classes.required}>&nbsp;*</span>}
        </Typography>
      )}
      <Select
        multiple={multiple}
        variant={variant}
        fullWidth
        value={value}
        onChange={onChange}
        className={classes.selectStyle}
        // MenuProps={MenuProps}
        style={{ ...selectBoxStyle }}
        autoWidth
        {...rest}
      >
        {isPlaceholderNone && (
          <MenuItem value={''}>{placeholderText || 'None'}</MenuItem>
        )}
        {isCheckbox ? getIconMenuItems() : getMenuItems()}
      </Select>
      {isError ? (
        <FormHelperText className={classes.helperTextStyle}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}
export default MultipleSelectComp;
