import React, { useCallback, useEffect, useState } from 'react';
import UHTable from 'src/components/UHTable';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  ButtonComp,
  DialogContentDetails,
  ListItemCell,
  MultipleSelectComp,
  TextInputComponent,
  UHCalenderComp
} from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import { useTranslation } from 'react-i18next';
import { Search } from '@material-ui/icons';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import { TableCalendar, WalletManagement } from 'src/Assets';
import { useLocation } from 'react-router-dom';
import { useEdit } from 'src/hooks/useEdit';
import AddMoneyModal from './AddMoneyModal';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { useDebounce } from 'src/hooks/useDebounce';
import { useSearchVal } from 'src/hooks/useSearchVal';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: theme.spacing(1)
    },
    titleStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    contentBoxStyles: {
      padding: theme.spacing(3, 0, 4, 0)
    },
    currentBalanceBoxStyles: {
      display: 'flex',
      //alignItems: 'center',
      justifyContent: 'flex-end'
      //height: '100%'
    },
    currentBalanceContentBoxStyles: {
      display: 'flex',
      border: '1px solid black',
      borderRadius: '2px',
      padding: theme.spacing(1.2),
      gap: theme.spacing(3)
    },
    currentBalanceTextStyles: {
      //

      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.small_x
    }
  };
});

type Props = {};

const actionData = ['Credit', 'Debit'];

const customerActionData = [
  'Pending',
  'Queued',
  'Processing',
  'Processed',
  'Cancelled',
  'Rejected',
  'Failed',
  'Scheduled',
  'Reversed'
];

const CustomerVendorViewDetails = ({}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { state } = useLocation();
  const viewDetailsData: any = state;
  const initialValue = {
    fromDate: '',
    toDate: '',
    payment_type: '',
    search: ''
  };
  const edit = useEdit(initialValue);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const [rowData, setRowData] = useState<any>([]);
  const [openAddMoneyDialog, setOpenAddMoneyDialog] = useState<any>({
    open: false
  });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [currentBalance, setCurrentBalance] = useState();
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);
  const contentDetails = [
    {
      content: t('vendor.contactName'),
      value: viewDetailsData?.contact_name || viewDetailsData.contact_person
    },
    {
      content: t('location'),
      value: viewDetailsData?.location
    },
    {
      content: t('contactNumber'),
      value:
        viewDetailsData?.registered_mobile_number ||
        viewDetailsData?.mobile_number
    }
  ];

  const fetchData = async () => {
    try {
      setRowData([]);
      const params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      let searchData = {
        searchString: edit.getValue('search'),
        fromDate: edit.getValue('date')?.split('to')[0],
        toDate: edit.getValue('date')?.split('to')[1],
        payment_type: edit.getValue('payment_type'),
        ...params
      };
      let searchViewData = {
        searchString: edit.getValue('search'),
        fromDate: edit.getValue('date')?.split('to')[0],
        toDate: edit.getValue('date')?.split('to')[1],
        payout_status: edit.getValue('payment_type'),
        ...params
      };
      if (
        viewDetailsData?.selectedTab === 1 ||
        viewDetailsData?.selectedTab === 3
      ) {
        const customerResponse: any =
          await API_SERVICES.WalletManagementService.getAllCustomerTransactionList(
            viewDetailsData?.customer_id,
            edit.getValue('search') === '' &&
              edit.getValue('date') === '' &&
              edit.getValue('payment_Type') === ''
              ? params
              : searchViewData
          );
        if (customerResponse.status < HTTP_STATUSES.BAD_REQUEST) {
          setRowData(customerResponse.data.data);
        }
      } else {
        const response: any =
          await API_SERVICES.WalletManagementService.getAllTransactionList(
            viewDetailsData?.vendor_id,
            edit.getValue('search') === '' &&
              edit.getValue('date') === '' &&
              edit.getValue('payment_Type') === ''
              ? params
              : searchData
          );

        if (
          response?.status < HTTP_STATUSES.BAD_REQUEST &&
          response.data.data?.length
        ) {
          setRowData(response.data.data);
        } else {
          setRowData([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [edit.edits]);

  const columns = [
    {
      field: 'sno',
      headerName: '#',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.id} />
    },
    {
      field: 'amount',
      headerName: t('walletManagement.amount'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.amount} />
    },
    {
      field: 'date',
      headerName: t('walletManagement.timeAndDate'),
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.transaction_date
        );
        let time = getTime?.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear} at ${time[0]}:${
              time[1]
            }${time[2]?.slice(2)}`}
          />
        );
      }
    },
    {
      field: 'admin_name',
      headerName: t('walletManagement.adminName'),
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.cashier_name || row?.admin_name)}
        />
      )
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) =>
        capitalizeFirstLetter(row?.payment_type || row?.payout_status)
    }
  ];

  const [colDefs, setColDefs] = useState(columns);

  useEffect(() => {
    const updatedDefs = [...columns];
    if (!viewDetailsData?.customer_id) {
      updatedDefs.splice(3, 0, {
        field: 'reference',
        headerName: t('walletManagement.reference'),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => <ListItemCell title={row?.payment_ref} />
      });
    }
    setColDefs(updatedDefs);
  }, [viewDetailsData]);

  let excelHeaders = [
    { label: 'Order No', key: 'order_no' },
    { label: 'Amount', key: 'amount' },
    { label: 'Payment Mode', key: 'payment_mode' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Transaction Type', key: 'transaction_type' },
    { label: 'Date', key: 'transaction_date' }
  ];

  let excelData =
    rowData?.length &&
    rowData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(
        rowData?.transaction_date
      );
      return {
        id: idx + 1,
        order_no: rowData?.id,
        amount: `${rowData?.amount}`,
        payment_mode: rowData?.payment_mode,
        payment_type: rowData?.payment_type || rowData?.payment_status,
        transaction_type: rowData?.transaction_type,
        transaction_date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  const handleChangeAction = (e) => {
    edit.update({ payment_type: e.target.value });
  };
  const renderSearchBox = () => {
    let data =
      viewDetailsData?.selectedTab === 1 ? customerActionData : actionData;

    console.log('data>>>>>>>>>>>>>>>>>>>>>>>>', data);

    return (
      <>
        <Grid item style={{ paddingRight: '10px' }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search"
            value={edit.getValue('search')}
            isBackground={true}
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    style={{
                      width: 16,
                      height: 16,
                      color: 'rgba(34, 51, 69, 0.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item style={{ paddingRight: '10px' }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search by Date"
            value={edit.getValue('date')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
        <Grid item style={{ paddingRight: '10px' }}>
          <MultipleSelectComp
            isPlaceholderNone
            placeholderText={'All'}
            selectWidth={150}
            selectItems={
              data?.length &&
              data?.map((item) => {
                return {
                  value: item,
                  label: item
                };
              })
            }
            value={edit.getValue('payment_type')}
            borderRadius={40}
            selectHeight={35}
            background={theme.Colors.greyDark}
            iconColor={theme.Colors.primary}
            onChange={handleChangeAction}
            displayEmpty
            renderValue={(value: any) => {
              return value ? value : 'All';
            }}
            required
          />
        </Grid>
      </>
    );
  };

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };
  const handleClickAddMoney = (e?: any) => {
    if (e.target.innerText == 'Add Money') {
      setOpenAddMoneyDialog({
        open: true,
        rowData: {
          ...viewDetailsData,
          wallet_amount: currentBalance,
          updatedData: getVendorByVendorId
        },
        type: 1
      });
    } else {
      setOpenAddMoneyDialog({
        open: true,
        rowData: {
          ...viewDetailsData,
          wallet_amount: currentBalance,
          updatedData: getVendorByVendorId
        },
        type: 2
      });
    }
  };
  const onCloseAddMoneyDialog = () => {
    setOpenAddMoneyDialog({ open: false });
  };

  const getVendorByVendorId = async () => {
    try {
      const response: any =
        await API_SERVICES.customerVendorListService.getVendorByVendorId(
          viewDetailsData?.vendor_id
        );
      if (response?.data?.vendorProfile) {
        setCurrentBalance(response?.data?.vendorProfile?.wallet_amount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (viewDetailsData?.selectedTab === 2) {
      getVendorByVendorId();
    } else {
      return;
    }
  }, []);

  return (
    <>
      <Box className={classes.titleStyle}>
        <ListItemCell
          avatarImg={viewDetailsData?.img || 'DustMan'}
          title={capitalizeFirstLetter(
            viewDetailsData.contact_name || viewDetailsData.contact_person
          )}
          titleStyle={{
            color: theme.Colors.blackPrimary,
            fontWeight: theme.fontWeight.medium,
            fontSize: theme.MetricsSizes.regular_xxx
          }}
        />
      </Box>
      <Grid container className={[classes.contentBoxStyles].join(' ')}>
        <Grid item xs={7}>
          <DialogContentDetails contentDetails={contentDetails} />
        </Grid>
        <Grid item xs={5}>
          <Box className={classes.currentBalanceBoxStyles}>
            <Box className={classes.currentBalanceContentBoxStyles}>
              <Box className={classes.currentBalanceTextStyles}>
                <WalletManagement /> <span>Current Balance</span>
              </Box>
              <Box className={classes.currentBalanceTextStyles}>
                {viewDetailsData?.customer_id
                  ? viewDetailsData?.order_amount
                  : currentBalance}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <UHTable
        title={`${capitalizeFirstLetter(
          viewDetailsData.contact_name || viewDetailsData.contact_person
        )} Wallet Details`}
        columns={colDefs}
        rows={rowData}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        renderSearchBox={renderSearchBox}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={`${capitalizeFirstLetter(
          viewDetailsData.contact_name
        )} Transaction Details`}
        addDeductMoneyNeeded={viewDetailsData?.customer_id ? false : true}
        onClickAddMoney={handleClickAddMoney}
      />
      {calendarOpen.open ? (
        <UHCalenderComp
          onCancelClick={() => setCalendarOpen({ open: false })}
          handleChange={handleChange}
          ranges={dateSelect}
          onConfirmClick={handleClick}
          {...calendarOpen}
        />
      ) : null}
      {openAddMoneyDialog.open && (
        <AddMoneyModal
          onClose={onCloseAddMoneyDialog}
          //rowData={...viewDetailsData, wallet_amount: currentBalance}
          updatedData={fetchData}
          {...openAddMoneyDialog}
        />
      )}
    </>
  );
};
export default React.memo(CustomerVendorViewDetails);
