import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  ButtonComp,
  DialogComp,
  SelectComp,
  TextInputComponent,
  DualActionButton,
  MultipleSelectComp
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import {
  isGSTNumber,
  isLandline,
  isPhoneNumber,
  isWebsiteName,
  isValidEmail,
  isValidPinCode,
  isYear,
  capitalizeFirstLetter
} from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    },
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    },
    buttonStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.MetricsSizes.tiny_xxx
    },
    textStyle: {
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.medium
    }
  };
});

export type VehicleData = {
  id: number;
  name: string;
  description: string;
  image: string;
};

type Props = {
  onClose: () => void;
  updateData?: () => void;
  rowData?: any;
  vehicleData?: VehicleData[];
  type?: string;
};

const VendorCreateModal = (props: Props) => {
  const { onClose, updateData, rowData, vehicleData, type } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isError, setIsError] = useState(false);

  const defaultValues = {
    order_management_id: '1,2,3',
    name: '',
    gst: '',
    full_address: '',
    location: '',
    contact_name: '',
    mobile_number: rowData?.registered_mobile_number?.slice(2) ?? '',
    landline_number: '',
    email_id: '',
    website: '',
    established_year: '',
    vehicle_owned: [],
    image_url: '',
    map_url: '',
    pincode: '',
    deposit: 2,
    ...rowData
  };

  const edit = useEdit(defaultValues);
  const RequiredFields = [
    'name',
    'full_address',
    'location',
    'mobile_number',
    'pincode'
  ];
  const depositData = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 }
  ];

  const companyNameError = isError && !edit.allFilled('name');
  const locationError = isError && !edit.allFilled('location');
  const addressError = isError && !edit.allFilled('full_address');
  const pinCodeError =
    (isError && !edit.allFilled('pincode')) ||
    (isError &&
      edit.allFilled('pincode') &&
      !isValidPinCode(edit.getValue('pincode')));
  const mobileError =
    (isError && !edit.allFilled('mobile_number')) ||
    (isError &&
      edit.allFilled('mobile_number') &&
      !isPhoneNumber(edit.getValue('mobile_number')));
  const EstYearError =
    isError &&
    edit.getValue('established_year') &&
    !isYear(edit.getValue('established_year'));
  const gstError =
    isError && edit.allFilled('gst') && !isGSTNumber(edit.getValue('gst'));
  const websiteError =
    isError &&
    edit.allFilled('website') &&
    !isWebsiteName(edit.getValue('website'));
  const landlineError =
    isError &&
    edit.allFilled('landline_number') &&
    !isLandline(edit.getValue('landline_number'));
  const emailError =
    isError &&
    edit.allFilled('email_id') &&
    !isValidEmail(edit.getValue('email_id'));

  const types = {
    [CONFIRM_MODAL.create]: {
      handleType: 1
    },
    [CONFIRM_MODAL.edit]: {
      handleType: 2
    }
  };

  const handleCreate = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setIsError(true);
        return toast.error('Please fill all the required fields');
      }
      if (
        !isPhoneNumber(edit.getValue('mobile_number')) ||
        !isValidPinCode(edit.getValue('pincode')) ||
        (edit.allFilled('email_id') &&
          !isValidEmail(edit.getValue('email_id'))) ||
        (edit.allFilled('established_year') &&
          !isYear(edit.getValue('established_year'))) ||
        (edit.allFilled('gst') && !isGSTNumber(edit.getValue('gst'))) ||
        (edit.allFilled('landline_number') &&
          !isLandline(edit.getValue('landline_number'))) ||
        (edit.allFilled('website') && !isWebsiteName(edit.getValue('website')))
      ) {
        setIsError(true);
        return;
      } else {
        setIsError(false);
      }
      let userData = { ...defaultValues, ...edit.edits };
      let response: any;
      if (types[type].handleType === 1) {
        response = await API_SERVICES.vendorManagementService.createByAdmin({
          data: userData,
          successMessage: 'Vendor created successfully!',
          failureMessage: 'Error: Failed to create vendor'
        });
      } else if (types[type].handleType === 2) {
        response = await API_SERVICES.vendorManagementService.replace(
          userData?.id,
          {
            data: userData,
            successMessage: 'Vendor updated successfully!',
            failureMessage: 'Error: Failed to update vendor'
          }
        );
      }
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateData();
        onClose();
        setIsError(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderValue = (selected: any[]) => {
    if (!selected.length) {
      return 'Select owned vehicle';
    }
    return (
      <div className={classes.buttonStyle}>
        {selected?.length &&
          selected.map((item, index) => (
            <ButtonComp
              key={index}
              buttonText={
                vehicleData.length &&
                vehicleData.filter((list) => list.id === item)[0].name
              }
              height={theme.MetricsSizes.regular}
              buttonFontSize={theme.MetricsSizes.tiny_xxx}
              buttonTextColor={theme.Colors.primary}
              btnBorderRadius={theme.MetricsSizes.tiny}
              backgroundColor={theme.Colors.greyScaleMedium}
              btnWidth={78}
            />
          ))}
      </div>
    );
  };

  const renderDialogContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.companyName')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('name')}
              onChange={(e) => {
                edit.update({ name: capitalizeFirstLetter(e.target.value) });
              }}
              isError={companyNameError}
              helperText={companyNameError && 'Please enter your company name'}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.GSTNumber')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('gst')}
              onChange={(e) => {
                edit.update({ gst: e.target.value.toUpperCase() });
              }}
              helperText={gstError && 'Please enter your valid GST number'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('location')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('location')}
              onChange={(e) =>
                edit.update({ location: capitalizeFirstLetter(e.target.value) })
              }
              isError={locationError}
              helperText={locationError && 'Please enter your location'}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('address')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('full_address')}
              onChange={(e) =>
                edit.update({
                  full_address: capitalizeFirstLetter(e.target.value)
                })
              }
              isError={addressError}
              helperText={addressError && 'Please enter your address detail'}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('enquiry.pincode')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('pincode')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                edit.update({ pincode: e.target.value });
              }}
              helperText={pinCodeError && 'Please enter your valid pincode'}
              isError={pinCodeError}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('mapLocation')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('map_url')}
              onChange={(e) => edit.update({ map_url: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.contactName')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('contact_name')}
              onChange={(e) =>
                edit.update({
                  contact_name: capitalizeFirstLetter(e.target.value)
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('mobileNumber')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('mobile_number')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                edit.update({ mobile_number: e.target.value });
              }}
              helperText={
                mobileError && 'Please enter your valid mobile number'
              }
              isError={mobileError}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.emailID')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('email_id')}
              onChange={(e) => edit.update({ email_id: e.target.value })}
              helperText={emailError && 'Please enter your valid email id'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.website')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('website')}
              onChange={(e) => edit.update({ website: e.target.value })}
              helperText={websiteError && 'Please enter your valid website'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.landlineNumber')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('landline_number')}
              onChange={(e) => edit.update({ landline_number: e.target.value })}
              helperText={
                landlineError && 'Please enter your valid landline number'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('vendor.establishedYear')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('established_year')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                edit.update({ established_year: e.target.value });
              }}
              helperText={
                EstYearError && 'Please enter your valid established year'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleSelectComp
              titleText={t('vendor.vehiclesOwned')}
              value={edit.getValue('vehicle_owned')}
              onChange={(e: any) =>
                edit.update({
                  vehicle_owned: e.target.value
                })
              }
              multiple={true}
              displayEmpty
              renderValue={renderValue}
              selectItems={
                vehicleData.length &&
                vehicleData?.map((item) => ({
                  label: item.name,
                  value: item.id
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textStyle}>
              Do you want to Deposit?
            </Typography>
            <SelectComp
              initialValue={[edit.getValue('deposit')]}
              labelData={depositData}
              isCheckbox
              handleChangeItem={(selectedItem: number[]) => {
                edit.update({ deposit: selectedItem[0] });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleCreate}
      />
    );
  };

  return (
    <DialogComp
      dialogTitle={
        types[type].handleType === 2
          ? 'Edit Vendor Details'
          : t('createNewVendor')
      }
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        color: theme.Colors.blackMedium
      }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default VendorCreateModal;
