import React from 'react';
import {
  Chip,
  Grid,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TableCalendar } from 'src/Assets';
import {
  ListItemCell,
  Loader,
  TextInputComponent,
  UHTable
} from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';

const useStyles = makeStyles((theme) => {
  return {
    tooltipStyle: {
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      color: theme.Colors.neutralBlack,
      padding: 12,
      borderRadius: 8
    },
    arrowStyle: {
      color: theme.Colors.white
    }
  };
});

const RazorpayTable = ({
  selectedTab,
  tableData,
  handleOpenCalendar,
  currentBalance,
  loading,
  edit
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = [
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.company_name)} />
      )
    },
    {
      field: 'details',
      headerName: 'Contact Person',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.contact_name)} />
      )
    },
    {
      field: 'amount',
      headerName: 'Total Amount',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.amount} />
    },
    {
      field: 'tax',
      headerName: 'Tax & Fees',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        let amt = row?.tax + row?.fees;
        return (
          <Tooltip
            title={
              <>
                <Grid>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: theme.fontWeight.medium
                    }}
                  >
                    Tax - {row?.tax}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: theme.fontWeight.medium
                    }}
                  >
                    Fees - {row?.fees}
                  </Typography>
                </Grid>
              </>
            }
            arrow
            placement="top"
            classes={{
              tooltip: classes.tooltipStyle,
              arrow: classes.arrowStyle
            }}
          >
            <div>
              <Typography>{Math.round(amt)}</Typography>
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.transaction_id} />
    },
    {
      field: 'date',
      headerName: 'Transaction Date',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.created_at
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Chip
          label={row?.payment_type}
          style={{
            backgroundColor:
              row?.payment_type.toLowerCase() == 'credit'
                ? theme.Colors.primary
                : theme.Colors.orangeSecondary,
            color: theme.Colors.white
          }}
        />
      )
    }
  ];

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            placeholder="Search"
            value={edit.getValue('search')}
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    style={{
                      width: 16,
                      height: 16,
                      color: 'rgba(34, 51, 69, 0.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              edit.update({ search: e.target.value });
            }}
          />
        </Grid>
        <Grid item>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
      </>
    );
  };

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Name', key: 'name' },
    { label: 'Total Amount', key: 'amount' },
    { label: 'Tax & Fees', key: 'tax' },
    { label: 'Transaction ID', key: 'transaction_id' },
    { label: 'Transaction Date', key: 'date' }
  ];

  let excelData =
    tableData?.length &&
    tableData?.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.created_at);
      let amt = rowData?.tax + rowData?.fees;
      return {
        id: idx + 1,
        company_name: capitalizeFirstLetter(rowData?.company_name),
        name: capitalizeFirstLetter(rowData?.contact_name),
        amount: rowData?.amount,
        tax: Math.round(amt),
        transaction_id: `${rowData?.transaction_id}`,
        date: `${getMonth} ${getDate}, ${getYear}`
      };
    });
  if (loading) {
    return <Loader />;
  } else {
    return (
      <UHTable
        columns={columns}
        rows={tableData}
        title={'Razorpay'}
        autoHeight
        renderSearchBox={renderSearchBox}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName="Razorpay Details"
      />
    );
  }
};
export default RazorpayTable;
