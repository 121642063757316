import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

type MoneyCreate = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const WalletManagementService = {
  getAllTransactionList: async (vendor_id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorWallet/${vendor_id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  createVendorWallet: async ({
    data,
    successMessage,
    failureMessage
  }: MoneyCreate) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createVendorWallet`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  deductVendorWallet: async ({
    data,
    successMessage,
    failureMessage
  }: MoneyCreate) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deductVendorWallet`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  getAllCustomerTransactionList: async (customer_id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllOrderTransactionHistoryByUserId/${customer_id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllVendorPayoutList: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/getAllVendorOrderTransactionHistory`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  getAllCustomerPayoutList: async (params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/admin/getAllCustomerOrderTransactionHistory`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },
  adminPayout: async ({
    data,
    successMessage,
    failureMessage
  }: MoneyCreate) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/adminPayout`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  updateOrderTransactionHistory: async (
    id: number,
    { data, successMessage, failureMessage }: MoneyCreate
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateOrderTransactionHistoryById/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
