import React, { useContext, useEffect, useState } from 'react';
import { IconButton, TextField, useTheme } from '@material-ui/core';
import { DeleteOutlineSharp } from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import {
  ButtonComp,
  ListItemCell,
  UHConfirmModal,
  UHTable
} from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import { ADMIN_ROLE, CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import toast from 'react-hot-toast';
import { UserInfoContext } from 'src/contexts/UserContext';
import { useTranslation } from 'react-i18next';

type Props = {
  onClickActionButton: (row: any) => void;
  rowsData: any[];
  updateData?: () => void;
};

const UserManagementTable = ({
  onClickActionButton,
  rowsData,
  updateData
}: Props) => {
  const theme = useTheme();
  const [passwordVisibleRowId, setPasswordVisibleRowId] = useState<number>();
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const { userDetails } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);

  useEffect(() => {
    let newList = rowsData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [rowsData, sortData]);

  const InputField = (props: any) => {
    const { inputId, value, disabled } = props;
    const handlePassword = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      selectedId: React.SetStateAction<number>
    ) => {
      if (passwordVisibleRowId === selectedId) {
        setPasswordVisibleRowId(0);
      } else {
        setPasswordVisibleRowId(selectedId);
      }
    };

    return (
      <>
        <TextField
          disabled={disabled}
          value={value}
          type={passwordVisibleRowId === inputId ? t('text') : t('password')}
          size="small"
          InputProps={{ disableUnderline: true }}
        />
        <IconButton onClick={(e) => handlePassword(e, inputId)}>
          {passwordVisibleRowId === inputId ? (
            <VisibilityOutlinedIcon />
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </IconButton>
      </>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.name)}
          subTitle={row?.role}
        />
      )
    },
    {
      field: 'user_name',
      headerName: t('userName'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => <ListItemCell title={row?.user_name} />
    },
    {
      field: 'password',
      headerName: t('password'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        return (
          <InputField
            inputId={row?.id}
            value={row?.password}
            showPassword={row?.showPassword}
          />
        );
      }
    },
    {
      field: 'updated_at',
      headerName: t('date'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.updated_at
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      disableColumnSeparator: true,
      renderCell: ({ row }) => (
        <ButtonComp
          btnBorderRadius={20}
          buttonText={t('button.view')}
          buttonFontSize={12}
          btnWidth={50}
          height="25px"
          onClickButton={() => onClickActionButton(row)}
        />
      )
    }
  ];

  const handleClickDeleteUser = (rowItem: any) => {
    if (
      rowItem.role === ADMIN_ROLE.superAdmin ||
      rowItem?.id === userDetails.id
    ) {
      return toast.error(t('accessDenied'));
    }
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      const deleteUserRes: any = await API_SERVICES.userService.delete(
        rowItem?.id,
        { successMessage: t('userDeletedSuccessfully') }
      );
      if (deleteUserRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        updateData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('areYouSureWantToDeleteTheUser'),
      title: t('delete'),
      iconType: CONFIRM_MODAL.delete
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const renderRowActions = () => {
    return [
      {
        text: t('deleteUser'),
        onClick: handleClickDeleteUser,
        renderIcon: () => <DeleteOutlineSharp />
      }
    ];
  };

  const onSortClick = () => {
    setSortData(!sortData);
  };

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.updated_at);
      return {
        name: rowData?.name,
        user_name: rowData?.user_name,
        password: rowData?.password,
        role: rowData?.role,
        Date: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  let excelHeaders = [
    { label: 'Name', key: 'name' },
    { label: 'User Name', key: 'user_name' },
    { label: 'Password', key: 'password' },
    { label: 'Role', key: 'role' },
    { label: 'Created At', key: 'Date' }
  ];

  return (
    <>
      <UHTable
        title={t('allUser')}
        columns={columns}
        rows={rowItems}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        getRowActions={renderRowActions}
        onSortClick={onSortClick}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={t('userManagement')}
      />
      {confirmModal.open && <UHConfirmModal {...confirmModal} />}
    </>
  );
};

export default UserManagementTable;
