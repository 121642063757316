import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DEFAULT_TABLE_ROW_COUNT } from 'src/Config/constant';

const data = [
  {
    id: 1,
    amount: '1234',
    date: 'October 20, 2023 at 11:54 AM',
    name: 'Kavitha',
    details: 'Deposited'
  },
  {
    id: 2,
    amount: '1234',
    date: 'September 24, 2023 at 11:54 AM',
    name: 'Kavitha',
    details: 'Deposited'
  },
  {
    id: 3,
    amount: '1234',
    date: 'September 24, 2023 at 11:54 AM',
    name: 'Kavitha',
    details: 'Withdraw'
  },
  {
    id: 4,
    amount: '1234',
    date: 'September 24, 2023 at 11:54 AM',
    name: 'Kavitha',
    details: 'Deposited'
  },
  {
    id: 5,
    amount: '1234',
    date: 'September 16, 2023 at 09:39 AM',
    name: 'Kavitha',
    details: 'Deposited'
  },
  {
    id: 6,
    amount: '1234',
    date: 'September 16, 2023 at 09:39 AM',
    name: 'Kavitha',
    details: 'Withdraw'
  }
];

const TransactionTable = (props) => {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (data?.length) {
      let newArr = [];
      let rowIndex = 0;
      let rowCount = 0;
      for (let arr of data) {
        if (rowCount === DEFAULT_TABLE_ROW_COUNT) {
          rowCount = 0;
          rowIndex++;
        }
        if (!newArr[rowIndex]) {
          newArr[rowIndex] = [];
        }
        newArr[rowIndex].push(arr);
        rowCount++;
      }
      setTableData(newArr);
    }
  }, [data]);

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value-1)
    setPage(value);
  };

  useEffect(() => {
    const getPageCount = () => {
      let rowCount = 0;
      let newPageCount = 1;
      if (data?.length)
        data?.forEach(() => {
          if (rowCount === DEFAULT_TABLE_ROW_COUNT) {
            newPageCount++;
            rowCount = 0;
          }
          rowCount++;
        });
      return newPageCount;
    };
    setPageCount(getPageCount());
    setPage(1);
  }, [data]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        item
        style={{ backgroundColor: theme.Colors.primary, paddingLeft: 20 }}
      >
        <Typography
          style={{
            color: theme.Colors.white,
            fontWeight: theme.fontWeight.bold,
            fontSize: 20
          }}
        >
          Balance: ₹ 30000
        </Typography>
      </Grid>
      <Grid
        item
        container
        style={{
          border: '1px solid rgba(51, 51, 51, 0.3)',
          paddingLeft: 20,
          height: 300
        }}
      >
        {tableData[currentPage]?.map((item, idx) => {
          return (
            <Grid
              item
              container
              key={idx}
            //   style={{
            //     padding: '10px 0px'
            //   }}
            >
              <Grid item xs={2}>
                <Typography
                  style={{
                    color: theme.Colors.black,
                    fontSize: 18,
                    fontWeight: theme.fontWeight.medium
                  }}
                >
                  ₹ {item.amount}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  style={{
                    color: '#808080',
                    fontSize: 18,
                    fontWeight: theme.fontWeight.medium
                  }}
                >
                  {item.date}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{
                    color: '#808080',
                    fontSize: 18,
                    fontWeight: theme.fontWeight.medium
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{
                    color: item.details == 'Deposited' ? '#6CB044' : '#FF9A6A',
                    fontSize: 18,
                    fontWeight: theme.fontWeight.medium
                  }}
                >
                  {item.details}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs justifyContent="flex-end">
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePagination}
          color="primary"
        />
      </Grid>
    </Grid>
  );
};

export default TransactionTable;
