import React from 'react';
import {
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { ButtonComp } from 'src/components';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.Colors.whiteGreyLight,
      borderRadius: theme.MetricsSizes.regular_x,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2, 7, 2, 3.2),
      margin: theme.spacing(3.7, 1.5),
      '&.MuiGrid-grid-xs-3': {
        maxWidth: '30%',
        flexBasis: '30%'
      }
    },
    text: {
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.small_xxx,
      color: theme.Colors.mediumGrey,
      paddingBottom: theme.spacing(1.376)
    },
    subText: {
      fontWeight: theme.fontWeight.regular,
      fontSize: theme.MetricsSizes.small_xxx,
      color: theme.Colors.mediumGrey
    },
    dividerStyle: {
      background: theme.Colors.lightBlueGrey,
      margin: theme.spacing(0.6)
    },
    iconStyle: {
      color: theme.Colors.primary,
      width: theme.MetricsSizes.large_xx,
      height: theme.MetricsSizes.large_xx
    },
    buttonStyle: {
      paddingTop: theme.spacing(2.2)
    },
    labelStyle: {
      flexDirection: 'column'
    },
    gridStyle: {
      borderWidth: '1px',
      borderStyle: 'dashed',
      padding: theme.spacing(0, 0, 0, 0)
    },
    button: {
      minWidth: 0,
      padding: theme.spacing(0, 1, 0, 0)
    }
  };
});

type AddressProps = {
  data?: any[];
  handleCreateAddress?: () => void;
  handleCreateOrEditAddress?: (item?: any) => void;
  handleDeleteAddress?: (item: any) => void;
};

const AddressBoxComp = (props: AddressProps) => {
  const {
    data,
    handleCreateAddress,
    handleDeleteAddress,
    handleCreateOrEditAddress
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        className={`${classes.gridContainer} ${classes.gridStyle}`}
      >
        <ButtonComp
          startIcon={<AddIcon className={classes.iconStyle} />}
          onClickButton={() => handleCreateAddress()}
          backgroundColor={'transparent'}
          height={data.length === 0 ? '160px' : theme.MetricsSizes.x_large}
          buttonText={t('settings.addAddress')}
          buttonTextColor={theme.Colors.blueDark}
          classes={{ label: classes.labelStyle }}
          buttonFontWeight={theme.fontWeight.bold}
          buttonFontSize={theme.MetricsSizes.small_xxx}
          btnWidth={'150px'}
        />
      </Grid>
      {data?.length
        ? data.map((item, index) => {
            return (
              <Grid item xs={3} key={index} className={classes.gridContainer}>
                <Grid>
                  <Typography className={classes.text}>
                    {item.dustman_location}
                  </Typography>
                  <Typography className={classes.subText}>
                    {item.address_line1}, {item.address_line2},{' '}
                    {item.address_line3}, {item.dustman_location}, {item.city},{' '}
                    {item.state}, {item.pincode}
                  </Typography>
                </Grid>
                <Grid container className={classes.buttonStyle}>
                  <Grid item>
                    <ButtonComp
                      buttonText={t('edit')}
                      backgroundColor={'transparent'}
                      height={theme.MetricsSizes.regular_xx}
                      buttonFontSize={theme.MetricsSizes.small_xx}
                      buttonFontWeight={theme.fontWeight.regular}
                      buttonTextColor={theme.Colors.lightBlueGrey}
                      onClickButton={() => handleCreateOrEditAddress(item)}
                      classes={{ root: classes.button }}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem={true}
                    classes={{ root: classes.dividerStyle }}
                  />
                  <Grid item>
                    <ButtonComp
                      buttonText={t('settings.remove')}
                      backgroundColor={'transparent'}
                      buttonTextColor={theme.Colors.lightBlueGrey}
                      height={theme.MetricsSizes.regular_xx}
                      buttonFontSize={theme.MetricsSizes.small_xx}
                      buttonFontWeight={theme.fontWeight.regular}
                      onClickButton={() => handleDeleteAddress(item)}
                      style={{ padding: theme.spacing(0, 0, 0, 1) }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export default AddressBoxComp;
