import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export type DefaultProp = {
  data: any;
  successMessage?: string;
  failureMessage?: string;
};

export const depositManagementService = {
  getAllBy: async (id: number, params?: any) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/allvendordeposit/${id}`,
      method: 'get',
      params: params
    });
    return apiRequest(options);
  },

  update: async (id: number, vendorId: number,{ data, successMessage, failureMessage }: DefaultProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/updateVendorProfile/vendor/${vendorId}/vendor_profile/${id}`,
      method: 'patch',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
