import React, { useCallback, useEffect, useState } from 'react';
import { PriceSheet } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  UHConfirmModal,
  Loader,
  UHCalenderComp
} from 'src/components';
import { Box, Divider, useTheme } from '@material-ui/core';
import { API_SERVICES } from 'src/Services';
import {
  CONFIRM_MODAL,
  CUSTOMER_STATUS,
  HTTP_STATUSES
} from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import CustomerVendorListTable from './CustomerVendorListTable';
import { useEdit } from 'src/hooks/useEdit';
import CustomerVendorViewDetailsModal from './CustomerVendorViewDetailsModal';
import CustomerViewVendorModal from '../CustomerManagement/CustomerViewVendorModal';
import VendorEditModal from './VendorEditModal';
import CustomerEditModal from './CustomerEditModal';
import { useNavigate } from 'react-router';

const CustomerVendorList = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const customerVendorTabValues = [
    {
      heading: t('CustomerList'),
      iconImage: PriceSheet,
      value: 1
    },
    {
      heading: t('vendorList'),
      iconImage: PriceSheet,
      value: 2
    }
  ];

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    customerVendorTabValues[0].value
  );
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [tableData, setTableData] = useState<any>([]);
  const [vendorTableData, setVendorTableData] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [assignModal, setAssignModal] = useState<any>({
    open: false
  });
  const [regionData, setRegionData] = useState([]);
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const [openEditVendorModal, setOpenEditVendorModal] = useState<any>({
    open: false
  });
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState<any>({
    open: false
  });

  const initialValue = {
    region_id: '',
    location: '',
    date: ''
  };

  const edit = useEdit(initialValue);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  const handleSelectedTab = (value: number | string) => {
    setSelectedTab(value);
    edit.update({ region_id: '', location: '', date: '' });
  };

  const getAllRegion = async () => {
    try {
      setRegionData([]);
      const response: any =
        await API_SERVICES.regionManagementService.getAllRegion();
      if (response?.data?.statusCode <= HTTP_STATUSES.BAD_REQUEST) {
        setRegionData(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      let searchData = {
        toDate: edit.getValue('date').split('to')[1],
        fromDate: edit.getValue('date').split('to')[0],
        region_id: edit.getValue('region_id'),
        location: edit.getValue('location'),
        ...params
      };

      const response: any = await Promise.all([
        API_SERVICES.customerVendorListService.getAllCustomer(searchData),
        API_SERVICES.customerVendorListService.getAllVendors(searchData)
      ]);
      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.customerProfile) {
          setTableData(response[0]?.data?.customerProfile);
        }
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[1]?.data?.vendorProfiles) {
          setVendorTableData(response[1]?.data?.vendorProfiles);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [debValue, edit.edits]);

  useEffect(() => {
    getAllRegion();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onActionButtonClick = (rowData: any, rowIds?: number[]) => {
    if (
      rowData &&
      Object.keys(rowData).length &&
      CUSTOMER_STATUS[rowData?.status_id] !== CUSTOMER_STATUS[0]
    ) {
      return onClickViewOrder(rowData);
    }
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    let updateData = {
      status_id: 1,
      orders: rowIds ?? [rowData?.id]
    };
    const onConfirmClick = async () => {
      const response: any = await API_SERVICES.customerOrderService.replace({
        data: updateData,
        successMessage: 'Customer order published successfully!'
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.secondary,
      description: t('customerOrder.publishCustomerOrder'),
      title: t('customerOrder.publish'),
      iconType: CONFIRM_MODAL.publish
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickCancelOrAccept = (rowData: any, statusId: number) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        status_id: statusId
      };
      const response: any =
        await API_SERVICES.newRequestService.adminUpdateCustomer(rowData.id, {
          data: updateData,
          successMessage: t('newRequest.customerRejectMessage')
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('newRequest.rejectCustomer'),
      title: t('vendorManagement.reject'),
      iconType: CONFIRM_MODAL.reject
    };
    if (selectedTab == 2) {
      props = {
        color: theme.Colors.redPrimary,
        description: t('vendorManagement.rejectVendor'),
        title: t('vendorManagement.reject'),
        iconType: CONFIRM_MODAL.reject
      };
    }
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickMoveToDustman = (rowData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let data = {
        orderer_address_id: rowData?.orderer_address_id,
        customer_id: rowData?.customer_id
      };
      const response: any =
        await API_SERVICES.customerManagementService.customerMovetoDustman({
          data: { ...data },
          successMessage: t('customerOrder.moveToDustmanCustomer')
        });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.primary,
      description: t('customerOrder.customerMoveToDustman'),
      title: t('customerOrder.moveToDustman'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickViewOrder = (rowData: any) => {
    setModalOpen({ open: true, rowData: rowData });
  };

  const onClickReassignVendor = (rowData?: any) => {
    setAssignModal({ open: true, rowData: rowData });
  };

  const onClickEditVendor = async (rowData: any) => {
    try {
      const response: any =
        await API_SERVICES.customerVendorListService.getVendorBankDetailsById(
          rowData?.vendor_id
        );
      console.log(response);
      setOpenEditVendorModal({
        open: true,
        rowData: rowData,
        updatedData: fetchData,
        bankData: response.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClickEditCustomer = async (rowData: any) => {
    try {
      const response: any =
        await API_SERVICES.customerVendorListService.getCustomerBankDetailById(
          rowData?.customer_id
        );
      setOpenEditCustomerModal({
        open: true,
        rowData: rowData,
        updatedData: fetchData,
        bankData: response.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClickViewCommission = (rowData: any) => {
    navigateTo('/dashboards/customer-vendor-list/vendor-details', {
      state: { rowData: rowData, tab: 3 }
    });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <ContentDisplayTiles
          displayContent={customerVendorTabValues}
          isTileTypeOrders={true}
          onTabChange={handleSelectedTab}
          tabValue={selectedTab}
        />
        <Divider style={{ backgroundColor: theme.Colors.lightBlue }} />
        <Box sx={{ mt: 3 }}>
          <CustomerVendorListTable
            onClickActionButton={onActionButtonClick}
            tableRowData={selectedTab == 1 ? tableData : vendorTableData}
            onClickViewOrder={onClickViewOrder}
            onClickCancelOrAccept={onClickCancelOrAccept}
            onClickMoveToDustman={onClickMoveToDustman}
            selectedTab={selectedTab}
            edit={edit}
            regionData={regionData}
            handleChangeRegion={(e) => {
              edit.update({ region_id: e.target.value });
            }}
            onClickReassignVendor={onClickReassignVendor}
            handleOpenCalendar={handleOpenCalendar}
            onClickEditVendor={onClickEditVendor}
            onClickEditCustomer={onClickEditCustomer}
            onClickViewCommission={onClickViewCommission}
          />
        </Box>
        {modalOpen?.open && (
          <CustomerVendorViewDetailsModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {assignModal.open ? (
          <CustomerViewVendorModal
            onClose={() => setAssignModal({ open: false })}
            {...assignModal}
            updateData={fetchData}
          />
        ) : null}
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
        {openEditVendorModal?.open ? (
          <VendorEditModal
            onClose={() => setOpenEditVendorModal({ open: false })}
            {...openEditVendorModal}
          />
        ) : null}
        {openEditCustomerModal?.open ? (
          <CustomerEditModal
            onClose={() => setOpenEditCustomerModal({ open: false })}
            {...openEditCustomerModal}
          />
        ) : null}
      </>
    );
  }
};

export default CustomerVendorList;
