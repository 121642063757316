import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import { AddressBook, Pincode, WalletManagement } from 'src/Assets';
import { ContentDisplayTiles, Loader, UHCalenderComp } from 'src/components';
import RazorpayTable from './RazorpayTable';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { useEdit } from 'src/hooks/useEdit';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import RazorpayXTable from './RazorpayXTable';
import { getTimestampFromDate } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: theme.spacing(1)
    },
    titleStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    contentBoxStyles: {
      padding: theme.spacing(3, 0, 4, 0)
    },
    currentBalanceBoxStyles: {
      display: 'flex',
      //alignItems: 'center',
      justifyContent: 'flex-end'
      //height: '100%'
    },
    currentBalanceContentBoxStyles: {
      display: 'flex',
      border: '1px solid black',
      borderRadius: '2px',
      padding: theme.spacing(1.2),
      gap: theme.spacing(3)
    },
    currentBalanceTextStyles: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.small_x
    }
  };
});

const AdminWallet = () => {
  const classes = useStyles();
  const [razorpayData, setRazorpayData] = useState<any[]>([]);
  const [currentBalanceData, setCurrentBalanceData] = useState({
    razorpayBalance: 0,
    razorpayXBalance: 0
  });
  const [razorpayXData, setRazorpayXData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const adminWalletTabValues = [
    {
      heading: 'Razorpay',
      subText: razorpayData?.length,
      iconImage: AddressBook,
      value: 1
    }
    // {
    //   heading: 'Razorpay-X',
    //   subText: razorpayXData?.length,
    //   iconImage: Pincode,
    //   value: 2
    // }
  ];

  const initialValue = {
    search: '',
    date: ''
  };
  const edit = useEdit(initialValue);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    adminWalletTabValues[0].value
  );
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
    edit.update({ date: '', search: '' });
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setRazorpayData([]);
      setRazorpayXData([]);
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const searchData = {
        searchString: edit.getValue('search'),
        toDate: edit.getValue('date').split('to')[1],
        fromDate: edit.getValue('date').split('to')[0],
        ...params
      };
      const xSearchData = {
        searchString: edit.getValue('search'),
        toDate:
          edit.getValue('date') == ''
            ? ''
            : getTimestampFromDate(edit.getValue('date')?.split('to')[1]),
        fromDate:
          edit.getValue('date') == ''
            ? ''
            : getTimestampFromDate(edit.getValue('date')?.split('to')[0]),
        ...params
      };
      const response: any = await Promise.all([
        API_SERVICES.AdminWalletService.getAllRazorpayTransaction(
          selectedTab == 1 &&
            edit.getValue('search') == '' &&
            edit.getValue('date') == ''
            ? params
            : selectedTab == 1
            ? searchData
            : ''
        ),
        API_SERVICES.AdminWalletService.getCurrentBalance()
        // API_SERVICES.AdminWalletService.getAllRazorpayXTransaction(
        //   selectedTab == 2 &&
        //     edit.getValue('search') == '' &&
        //     edit.getValue('date') == ''
        //     ? params
        //     : selectedTab == 2
        //     ? xSearchData
        //     : ''
        // )
      ]);
      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.data?.length) {
          setRazorpayData(response[0]?.data?.data);
        }
      }
      if (response[1]?.data?.data?.items) {
        let balance = response[1]?.data?.data?.items?.filter(
          (item) => item?.type == 'primary'
        )[0]?.balance;
        let XBalance = response[1]?.data?.data?.items?.filter(
          (item) => item?.type == 'banking'
        )[0]?.balance;
        setCurrentBalanceData({
          ...currentBalanceData,
          razorpayBalance: balance / 100,
          razorpayXBalance: XBalance / 100
        });
      }
      // if (response[2]?.data?.data) {
      //   setRazorpayXData(response[2]?.data?.data);
      // }
      console.log(response[2], 'jjjjjjjjjjjjjjjjjj');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [edit.edits, debValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    // let new =
    console.log(
      dateSelect[0].startDate,
      `${fromDate[1]}/${fromDate[0]}/${fromDate[2]}`,
      '<-------'
    );

    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };
  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          <ContentDisplayTiles
            displayContent={adminWalletTabValues}
            isTileTypeOrders={true}
            onTabChange={handleSetSelectedTab}
            tabValue={selectedTab}
            tabWidth={true}
          />
        </Grid>
        <Grid item xs style={{ paddingTop: 20 }}>
          <Box className={classes.currentBalanceBoxStyles}>
            <Box className={classes.currentBalanceContentBoxStyles}>
              <Box className={classes.currentBalanceTextStyles}>
                <WalletManagement /> <span>Current Balance</span>
              </Box>
              <Box className={classes.currentBalanceTextStyles}>
                {selectedTab == 1
                  ? currentBalanceData?.razorpayBalance
                  : currentBalanceData?.razorpayXBalance}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {selectedTab == 1 ? (
        <RazorpayTable
          selectedTab={selectedTab}
          tableData={razorpayData}
          edit={edit}
          handleOpenCalendar={handleOpenCalendar}
          currentBalance={currentBalanceData}
          loading={loading}
        />
      ) : null}
      {selectedTab == 2 ? (
        <RazorpayXTable
          tableData={razorpayXData}
          edit={edit}
          handleOpenCalendar={handleOpenCalendar}
          loading={loading}
        />
      ) : null}
      {calendarOpen.open ? (
        <UHCalenderComp
          onCancelClick={() => setCalendarOpen({ open: false })}
          handleChange={handleChange}
          ranges={dateSelect}
          onConfirmClick={handleClick}
          {...calendarOpen}
        />
      ) : null}
    </>
  );
};

export default AdminWallet;
