import { authService } from './authService';
import { customerOrderService } from './customerOrderService';
import { priceMgntService } from './priceMgntService';
import { userService } from './userService';
import { vendorOrderService } from './vendorOrderService';
import { vendorManagementService } from './vendorManagementService';
import { enquiryManagementService } from './enquiryManagementService';
import { overViewServices } from './overViewServices';
import { imageUploadService } from './imageUploadService';
import { generalService } from './generalService';
import { notificationService } from './notificationService';
import { settingsPageService } from './settingsPageService';
import { depositManagementService } from './depositManagementService';
import { generateOtpService } from './generateOtpService';
import { vendorListService } from './vendorListService';
import { customerListService } from './customerListService';
import { regionManagementService } from './regionManagementService';
import { newRequestService } from './newRequestService';
import { customerVendorListService } from './customerVendorListService';
import { customerManagementService } from './customerManagementService';
import { WalletManagementService } from './walletManagementService';
import { InvoiceManagementService } from './invoiceManagementService';
import { AdminWalletService } from './adminWalletService';

export const API_SERVICES = {
  authService,
  userService,
  customerOrderService,
  priceMgntService,
  vendorOrderService,
  vendorManagementService,
  enquiryManagementService,
  overViewServices,
  imageUploadService,
  generalService,
  notificationService,
  settingsPageService,
  depositManagementService,
  generateOtpService,
  vendorListService,
  customerListService,
  regionManagementService,
  newRequestService,
  customerVendorListService,
  customerManagementService,
  WalletManagementService,
  InvoiceManagementService,
  AdminWalletService
};
