import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  DialogComp,
  TextInputComponent,
  DualActionButton
} from 'src/components';
import { CONFIRM_MODAL, HTTP_STATUSES } from 'src/Config/constant';
import { useEdit } from 'src/hooks/useEdit';
import { API_SERVICES } from 'src/Services';
import { capitalizeFirstLetter, isValidPinCode } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    contentStyle: {
      padding: theme.spacing(0, 10, 0, 0)
    },
    dialogPaper: {
      width: 447,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: 18
    },
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        backgroundColor: theme.Colors.white,
        color: theme.Colors.primary
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: theme.Colors.white
      }
    },
    textStyle: {
      color: theme.Colors.primary,
      fontWeight: theme.fontWeight.medium,
      paddingLeft: '0px'
    }
  };
});

type Props = {
  open?: boolean;
  onClose?: () => void;
  areaDetails?: any;
  updateData?: () => void;
  type?: string;
};

const PincodeCreateModal = (props: Props) => {
  const { open, onClose, areaDetails, updateData, type } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const initialValues = {
    pincode: areaDetails?.pincode || '',
    area: areaDetails?.area || '',
    is_enable: areaDetails?.is_enable || false
  };
  const edit = useEdit(initialValues);
  const RequiredFields = ['pincode', 'area'];

  const pincodeError =
    (isError && !edit.allFilled('pincode')) ||
    (isError &&
      edit.allFilled('pincode') &&
      !isValidPinCode(edit.getValue('pincode')));
  const areaError = isError && !edit.getValue('area');
  const checkboxError = isError && !edit.getValue('is_enable');

  const types = {
    [CONFIRM_MODAL.create]: {
      handleType: 1
    },
    [CONFIRM_MODAL.edit]: {
      handleType: 2
    }
  };

  const handleSave = async () => {
    try {
      if (!edit.allFilled(...RequiredFields)) {
        setIsError(true);
        return toast.error('Please fill all the required fields');
      } else if (!isValidPinCode(edit.getValue('pincode'))) {
        setIsError(true);
        return;
      } else {
        setIsError(false);
      }
      let data = { ...initialValues, ...edit.edits };
      if (types[type].handleType === 2) {
        const response: any =
          await API_SERVICES.settingsPageService.replacePincode(
            areaDetails?.id,
            {
              data: data,
              successMessage: 'Vendor Area Edited successfully',
              failureMessage: 'Failed to edit Vendor area'
            }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      } else if (types[type].handleType === 1) {
        const response: any =
          await API_SERVICES.settingsPageService.createPincode({
            data: data,
            successMessage: 'Vendor Area Added Successfully',
            failureMessage: 'Failed to Create Vendor Area'
          });
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid container spacing={1} className={classes.contentStyle}>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.pinCode')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('pincode')}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                }
                edit.update({ pincode: e.target.value });
              }}
              required
              isError={pincodeError}
              helperText={pincodeError && 'Please enter your valid pincode'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputComponent
              inputLabel={t('settings.area')}
              labelColor={theme.Colors.primary}
              value={edit.getValue('area')}
              onChange={(e) =>
                edit.update({
                  area: capitalizeFirstLetter(e.target.value)
                })
              }
              required
              isError={areaError}
              helperText={areaError && 'Please enter the area'}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={edit.getValue('is_enable')}
                  onChange={(e) => edit.update({ is_enable: e.target.checked })}
                />
              }
              label={
                <Typography
                  className={classes.textStyle}
                  style={{ color: checkboxError && 'red' }}
                >
                  {t('settings.enableOrDisable')}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const handleAction = () => {
    return (
      <DualActionButton
        onLeftButtonClick={onClose}
        onRightButtonClick={handleSave}
        disabledRightBtn={!edit.isAnyModified()}
      />
    );
  };
  return (
    <DialogComp
      open={open}
      dialogTitle={
        types[type].handleType === 2
          ? t('settings.editArea')
          : t('settings.addArea')
      }
      dialogTitleStyle={{ color: theme.Colors.primary }}
      dialogClasses={{ paper: classes.dialogPaper }}
      onClose={onClose}
      renderDialogContent={handleContent}
      renderAction={handleAction}
    />
  );
};
export default PincodeCreateModal;
