import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { RupeeSymbol } from 'src/Assets';
import {
  ButtonComp,
  DialogComp,
  TextInputComponent,
  DualActionButton
} from 'src/components';
import OTPInput from 'otp-input-react';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { isOneTimePassWord } from 'src/Utils';

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      width: 847,
      minHeight: 405,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    gridStyle: {
      justifyContent: 'space-between',
      background: theme.Colors.secondary,
      color: theme.Colors.white,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 13,
      marginBottom: 10
    },
    textStyle: { fontWeight: theme.fontWeight.medium, fontSize: 16 },
    containerStyle: {
      border: '1px solid',
      borderRadius: 10,
      borderColor: theme.Colors.lightShadeGrey,
      marginTop: 15
    },
    badgeStyle: {
      width: 44,
      height: 43,
      borderRadius: 50,
      backgroundColor: theme.Colors.secondary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      right: 90
    },
    titleStyle: {
      fontSize: 14,
      fontWeight: theme.fontWeight.bold,
      color: theme.Colors.black,
      paddingBottom: 10
    },
    subTextStyle: {
      fontSize: 12,
      fontWeight: theme.fontWeight.medium,
      color: theme.Colors.black,
      opacity: 0.4
    },
    amountStyle: {
      color: theme.Colors.white,
      fontSize: 11,
      fontWeight: theme.fontWeight.bold,
      paddingLeft: 5
    },
    totalStyle: {
      backgroundColor: theme.Colors.secondary,
      color: theme.Colors.white,
      padding: 6,
      textAlign: 'center',
      borderBottomRightRadius: 15,
      position: 'absolute',
      fontSize: 11,
      fontWeight: theme.fontWeight.bold
    },
    inputOtpStyle: {
      background: 'transparent',
      border: 'none',
      borderBottom: '2px solid black',
      fontWeight: theme.fontWeight.mediumBold,
      fontSize: '16px'
    },
    otpStyle: {
      padding: theme.spacing(2, 2, 2, 0),
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.MetricsSizes.small_xx,
      color: theme.Colors.primary
    },
    imageStyle: {
      width: 145,
      borderRadius: 12,
      height: 100,
      border: '1px solid rgba(0, 0, 0, 0.2)'
    }
  };
});

const GenerateOtp = (props) => {
  const { onClose, open, rowData, updateData } = props;
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  const [count, setCount] = useState<any>();
  const [isOtpGenerate, setIsOtpGenerate] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageData, setImageData] = useState<any>([]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setImageData(rowData);
  }, [rowData]);

  let totalQuantity = 0;
  let totalPrice = 0;
  rowData?.order_item?.forEach((item) => {
    totalQuantity += item.category_quantity;
    totalPrice += item.category_quantity * item.category_price;
    return { totalPrice, totalQuantity };
  });

  const initialValues = {
    otp: ''
  };
  const edit = useEdit(initialValues);

  const handleChange = (idx: string | number, quantity: any, price: number) => {
    let updatedOrderItems = [...rowData.order_item];
    updatedOrderItems[idx].category_quantity = Number(quantity);
    updatedOrderItems[idx].final_quantity = Number(quantity);
    updatedOrderItems[idx].price = Number(quantity) * price;
    let newTotalQuantity = 0;
    let newTotalPrice = 0;
    updatedOrderItems.forEach((item) => {
      newTotalQuantity += item.category_quantity;
      newTotalPrice += item.category_quantity * item.category_price;
    });
    setCount(newTotalQuantity);
  };

  const onBrowseButtonClick = async (event: any, idx) => {
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    img.onload = async () => {
      // if (img.width <= 120 && img.height <= 240) {
      const uploadImageRes: any =
        await API_SERVICES.imageUploadService.uploadImage(formData);
      if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (uploadImageRes?.data?.images.length) {
          let updatedOrderItems = [...rowData.order_item];
          updatedOrderItems[idx].final_image =
            uploadImageRes.data.images[0].Location;
          rowData?.order_item?.forEach((item) => {
            if (item.price === 0 && item.final_quantity === 0) {
              item.final_quantity = item.category_quantity;
              item.price = item.category_price * item.final_quantity;
            }
          });
          setImageData({ ...rowData, updatedOrderItems });
        }
      }
      // } else {
      //   alert(`Sorry, this image doesn't look like the size we wanted. It's
      //   ${img.width} x ${img.height} but we require 120 x 240 size image or below this size.`);
      // }
    };
  };

  const renderDialogContent = () => {
    return (
      <Grid>
        <Box style={{ position: 'relative', marginBottom: 30 }}>
          {imageData?.order_item?.length &&
            imageData?.order_item?.map((item, idx) => {
              let quantity = item?.category_quantity;
              return (
                <Grid
                  xs={10}
                  key={idx}
                  style={{
                    display: 'flex'
                  }}
                >
                  <Grid container item xs className={styles.containerStyle}>
                    <Grid container item xs>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <img
                          className={styles.imageStyle}
                          src={item?.image_url}
                        />
                      </Grid>
                      <Grid style={{ alignSelf: 'center', paddingLeft: 20 }}>
                        <Typography className={styles.titleStyle}>
                          {item.category_name}
                        </Typography>
                        <Grid style={{ display: 'inline-flex' }}>
                          <TextInputComponent
                            ref={inputRef}
                            value={quantity}
                            type="number"
                            // borderColor="transparent"
                            inputWidth={150}
                            onChange={(e) => {
                              handleChange(
                                idx,
                                e?.target?.value,
                                item?.category_price
                              );
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kgs
                                </InputAdornment>
                              )
                            }}
                            disabled={isOtpGenerate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                      }}
                    >
                      {item.final_image ? (
                        <>
                          <img
                            className={styles.imageStyle}
                            src={item.final_image}
                          />
                          <Typography className={styles.totalStyle}>
                            {`${item.final_quantity} Kg`}
                          </Typography>
                        </>
                      ) : (
                        <ButtonComp
                          backgroundColor={theme.Colors.primary}
                          buttonText={t('button.browse')}
                          buttonFontSize={theme.MetricsSizes.small_xxx}
                          buttonTextColor="white"
                          height={30}
                          buttonFontWeight={theme.fontWeight.medium}
                          disableElevation={true}
                          onBrowseButtonClick={(e) =>
                            onBrowseButtonClick(e, idx)
                          }
                          disabled={isOtpGenerate}
                          isBrowseButton
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item className={styles.badgeStyle}>
                    <img src={RupeeSymbol} />
                    <Typography className={styles.amountStyle}>
                      {item?.category_price}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
        <Grid container xs={10} className={styles.gridStyle}>
          <Typography className={styles.textStyle}>Weight</Typography>
          <Typography
            className={styles.textStyle}
          >{`${totalQuantity} Kgs`}</Typography>
        </Grid>
        <Grid container xs={10} className={styles.gridStyle}>
          <Typography className={styles.textStyle}>Price</Typography>
          <>
            <Typography className={styles.textStyle}>
              <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
              {totalPrice}
            </Typography>
          </>
        </Grid>
        {isOtpGenerate ? (
          <Grid item xs={12}>
            <Typography className={styles.otpStyle}>Enter Otp</Typography>
            <OTPInput
              OTPLength={6}
              otpType="number"
              autoFocus={false}
              inputClassName={styles.inputOtpStyle}
              value={edit.getValue('otp')}
              onChange={(val: any) => {
                edit.update({ otp: val });
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const handleCompleteClick = async () => {
    try {
      if (isOtpGenerate) {
        if (
          !edit.allFilled('otp') ||
          !isOneTimePassWord(edit.getValue('otp'))
        ) {
          toast.error('Please enter valid 6 digit otp');
          return;
        }
        const response: any = await API_SERVICES.generateOtpService.update(
          rowData?.id,
          {
            data: { otp: Number(edit.edits.otp) },
            successMessage: 'Order moved to completed successfully!'
          }
        );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      } else {
        rowData?.order_item?.forEach((item) => {
          if (item.price === 0 && item.final_quantity === 0) {
            item.final_quantity = item.category_quantity;
            item.price = item.category_price * item.final_quantity;
          }
        });
        let data = {
          total_quantity_kg: totalQuantity,
          total_price: totalPrice,
          order_items: rowData?.order_item,
          complete_user_id: localStorage.getItem('userId'),
          is_admin: true
        };
        const response: any = await API_SERVICES.generateOtpService.create(
          rowData?.id,
          { data: data, successMessage: 'Otp sent successfully' }
        );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          setIsOtpGenerate(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAction = () => {
    return (
      <DualActionButton
        buttonText={isOtpGenerate ? t('confirm') : t('vendorOrder.generateOtp')}
        onLeftButtonClick={onClose}
        onRightButtonClick={handleCompleteClick}
        btnWidth={150}
      />
    );
  };

  return (
    <DialogComp
      open={open}
      dialogClasses={{ paper: styles.dialogPaper }}
      dialogTitle={'Complete Order'}
      onClose={onClose}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default GenerateOtp;
