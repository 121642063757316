import React from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { DialogComp, DialogContentDetails } from 'src/components';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/Utils';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 620,
      height: 365,
      padding: theme.spacing(2, 1, 2, 5),
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

type Props = {
  onClose: () => void;
  rowData: any;
};

const CustomerVendorViewModal = (props: Props) => {
  const { onClose, rowData } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const renderDialogContent = () => {
    const contentDetails = [
      {
        content: t('vendor.contactName'),
        value: capitalizeFirstLetter(rowData?.contact_name)
      },
      {
        content: t('mobileNumber'),
        value:
          rowData?.registered_mobile_number?.slice(2) ||
          rowData?.mobile_number?.slice(2)
      },
      {
        content: t('document'),
        value: rowData?.document_urls,
        isLink: true
      }
    ];

    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
      </Grid>
    );
  };

  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'Dustman'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      dialogTitleStyle={{
        //cannot apply makestyles
        color: theme.Colors.blackMedium
      }}
      renderDialogContent={renderDialogContent}
    />
  );
};

export default CustomerVendorViewModal;
