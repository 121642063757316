import React, { useEffect, useState } from 'react';
import { Block, Done, EditOutlined } from '@material-ui/icons';
import { Typography, useTheme } from '@material-ui/core';
import UHTable from 'src/components/UHTable';
import ButtonComp from '../../components/ButtonComp/index';
import { ListItemCell } from 'src/components';
import { capitalizeFirstLetter, dateFormate } from 'src/Utils';
import {
  VENDOR_MANAGEMENT_STATUS,
  VENDOR_MG_STATUS
} from 'src/Config/constant';
import { useTranslation } from 'react-i18next';

type Props = {
  onClickActionButton?: (row: any) => void;
  onClickCancelOrAccept?: (row: any, statusId: number) => void;
  tableRowData?: any[];
  onClickEditButton?: (row: any) => void;
  buttonVal?: number;
};

const CustomerVendorTable = ({
  onClickActionButton,
  onClickCancelOrAccept,
  tableRowData,
  onClickEditButton,
  buttonVal
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.updated_at.localeCompare(next.updated_at);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.updated_at.localeCompare(current.updated_at);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  const columns = [
    {
      field: 'name',
      headerName: t('vendor.companyName'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.company_name)} />
      )
    },
    {
      field: 'contact_name',
      headerName: t('enquiry.contactName'),
      flex: 2.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={capitalizeFirstLetter(row?.contact_name)} />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={
            row?.mobile_number?.slice(2) ||
            row?.registered_mobile_number?.slice(2)
          }
        />
      )
    },
    {
      field: 'document_urls',
      headerName: t('document'),
      flex: 2.1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Typography
            style={{
              color: row?.document_urls?.length ? '#6CB044' : '#EE871E',
              fontSize: 14,
              fontWeight: 500
            }}
          >
            {row?.document_urls?.length ? 'Uploaded' : 'Pending'}
          </Typography>
        );
      }
    },
    {
      field: 'status_id',
      headerName: t('action'),
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ButtonComp
          btnBorderRadius={theme.MetricsSizes.regular_x}
          buttonText={t('button.view')}
          buttonFontSize={theme.MetricsSizes.small_x}
          btnWidth={71}
          height={theme.MetricsSizes.regular_xxx}
          onClickButton={() => onClickActionButton(row)}
        />
      )
    }
  ];

  const [colDefs, setColDefs] = useState(columns);
  useEffect(() => {
    const updatedDefs = [...columns];
    if (buttonVal == 2) {
      updatedDefs.splice(3, 0, {
        field: 'pincode',
        headerName: 'Pincode',
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => <ListItemCell title={row?.pincode} />
      });
    }
    setColDefs(updatedDefs);
  }, [buttonVal]);

  const renderRowActions = (rowData: any) => {
    if (
      VENDOR_MANAGEMENT_STATUS[rowData?.status_id] ===
      VENDOR_MANAGEMENT_STATUS[1]
    ) {
      return [
        {
          text: t('accept'),
          renderIcon: () => <Done />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Accepted)
        },
        {
          text: t('reject'),
          renderIcon: () => <Block />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Rejected)
        }
      ];
    }
    if (
      VENDOR_MANAGEMENT_STATUS[rowData?.status_id] ===
      VENDOR_MANAGEMENT_STATUS[2]
    ) {
      return [
        {
          text: t('reject'),
          renderIcon: () => <Block />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Rejected)
        }
        // {
        //   text: t('edit'),
        //   renderIcon: () => <EditOutlined />,
        //   onClick: (rowData) => onClickEditButton(rowData)
        // }
      ];
    }
    if (
      VENDOR_MANAGEMENT_STATUS[rowData?.status_id] ===
      VENDOR_MANAGEMENT_STATUS[3]
    ) {
      return [
        {
          text: t('approve'),
          renderIcon: () => <Done />,
          onClick: (rowData) =>
            onClickCancelOrAccept(rowData, VENDOR_MG_STATUS.Accepted)
        }
      ];
    }
  };

  const onSortClick = () => {
    setSortData(!sortData);
  };

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData) => {
      return {
        contact_name: capitalizeFirstLetter(rowData?.contact_name),
        company_name: capitalizeFirstLetter(rowData?.company_name),
        registered_mobile_number: rowData?.registered_mobile_number?.slice(2),
        updated_at: dateFormate(rowData?.updated_at)
      };
    });

  let excelHeaders = [
    { label: 'Company Name', key: 'company_name' },
    { label: 'Contact Name', key: 'contact_name' },
    { label: 'Mobile Number', key: 'registered_mobile_number' },
    { label: 'Date', key: 'updated_at' }
  ];

  return (
    <UHTable
      title={
        buttonVal == 1 ? t('newRequest.newCustomer') : t('newRequest.newVendor')
      }
      columns={colDefs}
      rows={rowItems}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      hideFooterPagination={true}
      getRowActions={renderRowActions}
      onSortClick={onSortClick}
      excelData={excelData}
      excelHeaders={excelHeaders}
      fileName={
        buttonVal == 1 ? t('CustomerManagement') : t('vendor.vendorManagement')
      }
    />
  );
};

export default CustomerVendorTable;
