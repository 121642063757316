import React from 'react';
import { Box, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { RupeeSign, RupeeSymbol } from 'src/Assets';
import { CUSTOMER_ORDER_STATUS } from 'src/Config/constant';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  headingStyle: {
    color: '#333333',
    fontSize: 24,
    fontWeight: theme.fontWeight.bold,
    opacity: 0.5,
    textAlign: 'center',
    paddingRight: 5
  },
  gridStyle: {
    justifyContent: 'space-between',
    background: theme.Colors.secondary,
    color: theme.Colors.white,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 13,
    marginBottom: 10
  },
  textStyle: { fontWeight: theme.fontWeight.medium, fontSize: 16 },
  containerStyle: {
    border: '1px solid',
    borderRadius: 10,
    borderColor: theme.Colors.lightShadeGrey,
    marginTop: 15
  },
  badgeStyle: {
    width: 44,
    height: 43,
    borderRadius: 50,
    backgroundColor: theme.Colors.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 90
  },
  titleStyle: {
    fontSize: 14,
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.black,
    paddingBottom: 10
  },
  subTextStyle: {
    fontSize: 12,
    fontWeight: theme.fontWeight.medium,
    color: theme.Colors.black,
    opacity: 0.4
  },
  amountStyle: {
    color: theme.Colors.white,
    fontSize: 11,
    fontWeight: theme.fontWeight.bold,
    paddingLeft: 5
  },
  totalStyle: {
    backgroundColor: theme.Colors.secondary,
    color: theme.Colors.white,
    padding: 6,
    textAlign: 'center',
    borderBottomRightRadius: 15,
    position: 'absolute',
    fontSize: 11,
    fontWeight: theme.fontWeight.bold
  },
  imageStyle: {
    width: 145,
    borderRadius: 12,
    height: 100,
    border: '1px solid rgba(0, 0, 0, 0.2)'
  }
}));

const CustomerVendorImageBox = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, isVendor } = props;

  let totalCommission = 0;

  data?.order_items?.forEach((item) => {
    totalCommission += item?.commission_amount;
    return totalCommission;
  });

  return (
    <>
      <Grid container style={{ paddingTop: 20, paddingBottom: 10, gap: 10 }}>
        <Typography className={styles.headingStyle}>
          Category Details
        </Typography>
        <Typography className={styles.headingStyle}>-</Typography>
        <Typography className={styles.headingStyle}>
          Delivery Summary
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: 20 }}>
        <ImageBoxComp data={data?.order_items} isVendor={isVendor} />
      </Grid>
      <Grid container xs={10} className={styles.gridStyle}>
        <Typography className={styles.textStyle}>Weight</Typography>
        <Typography
          className={styles.textStyle}
        >{`${data?.quantity_kg} Kgs`}</Typography>
      </Grid>
      <Grid container xs={10} className={styles.gridStyle}>
        <Typography className={styles.textStyle}>Price</Typography>
        <>
          <Typography className={styles.textStyle}>
            <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
            {data?.total_price}
          </Typography>
        </>
      </Grid>
      {isVendor ? null : (
        <>
          <Grid container xs={10} className={styles.gridStyle}>
            <Typography className={styles.textStyle}>Commission</Typography>
            <>
              <Typography className={styles.textStyle}>
                <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
                {totalCommission}
              </Typography>
            </>
          </Grid>
          <Grid container xs={10} className={styles.gridStyle}>
            <Typography className={styles.textStyle}>Total Price</Typography>
            <>
              <Typography className={styles.textStyle}>
                <img src={RupeeSymbol} style={{ paddingRight: 5 }} />
                {Number(data?.total_price) + Number(totalCommission)}
              </Typography>
            </>
          </Grid>
        </>
      )}
    </>
  );
};

export default CustomerVendorImageBox;

export const ImageBoxComp = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, isVendor } = props;

  return (
    <Box style={{ position: 'relative' }}>
      {data?.length &&
        data?.map((item, idx) => {
          let commissionAmount =
            (item?.category_price *
              item?.final_quantity *
              item?.commission_amount) /
            100;
          return (
            <Grid
              xs={10}
              key={idx}
              style={{
                display: 'flex'
              }}
            >
              <Grid container item xs className={styles.containerStyle}>
                <Grid container item xs>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <img
                      style={
                        {
                          //position: 'relative',
                        }
                      }
                      className={styles.imageStyle}
                      src={item?.image_url}
                    />
                    <Typography className={styles.totalStyle}>
                      {`${item.category_quantity} Kg`}
                    </Typography>
                  </Grid>
                  <Grid style={{ alignSelf: 'center', paddingLeft: 20 }}>
                    <Typography className={styles.titleStyle}>
                      {item.category_name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={styles.subTextStyle}
                    >
                      {t('value')} -
                      <img
                        src={RupeeSign}
                        width={10}
                        height={15}
                        style={{ paddingTop: 5, marginTop: 2 }}
                      />
                      {item.category_price * item.final_quantity}
                    </Typography>
                    {isVendor ? (
                      <Typography
                        variant="subtitle2"
                        className={styles.subTextStyle}
                      >
                        Commission Value -
                        <img
                          src={RupeeSign}
                          width={10}
                          height={15}
                          style={{ paddingTop: 5, marginTop: 2 }}
                        />
                        {commissionAmount}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <img className={styles.imageStyle} src={item.final_image} />
                  <Typography className={styles.totalStyle}>
                    {`${item.final_quantity} Kg`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={styles.badgeStyle}>
                <img src={RupeeSymbol} />
                <Typography className={styles.amountStyle}>
                  {item?.category_price}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </Box>
  );
};
