import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SortIcon, FilterIcon, PDFIcon } from '../../Assets/Images';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import { MoreVert } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import MenuActionComp from '../MenuActionComp';
import ButtonComp from '../ButtonComp';
import { DEFAULT_TABLE_ROW_COUNT } from 'src/Config/constant';
import UHExcelExport, { ExcelProps } from '../UHExcelExport';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import MultipleSelectComp from '../MultipleSelectComp';

type ThemeProps = {
  isBorder?: boolean;
  textColor?: string;
  fontSize?: number;
  addDeductMoneyNeeded?: boolean;
};

const useStyles = makeStyles<Theme, ThemeProps>((theme: Theme) => ({
  tableContainer: {
    width: '100%',
    flex: 1,
    minHeight: 400,
    '&.MuiDataGrid-root': {
      border: 'none',
      '& .MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRenderer': {
        justifyContent: 'flex-start'
      },
      '& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
        {
          flexDirection: 'row'
        },
      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none !important'
      }
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.small_xx,
      color: theme.Colors.greyMedium
    },
    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell ': {
      borderBottom: 0
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: '4px'
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
      wordWrap: 'break-word !important'
    }
  },
  heading: {
    fontWeight: theme.fontWeight.bold,
    fontSize: (props) => props.fontSize || theme.MetricsSizes.regular_x,
    color: (props) => props.textColor || theme.Colors.blueDark
  },
  iconStyle: {
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.MetricsSizes.small_xx,
    paddingLeft: theme.spacing(1)
  },
  gridStyle: {
    display: 'flex',
    alignItems: 'center',
    border: (props) => props.isBorder && '1px solid #DFE0EB',
    borderBottom: (props) => (props.isBorder ? '' : '1px solid #DFE0EB'),
    margin: (props) =>
      props.addDeductMoneyNeeded
        ? theme.spacing(0, 0, 2, 0)
        : theme.spacing(0, 0, 4, 0),
    padding: (props) => props.isBorder && theme.spacing(3, 4),
    borderRadius: (props) => props.isBorder && theme.MetricsSizes.tiny_xx
  },
  iconButtonStyle: {
    padding: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.Colors.white
    }
  },
  selectStyle: {
    color: theme.Colors.blueDark,
    fontSize: theme.MetricsSizes.regular,
    fontWeight: theme.fontWeight.bold
  },
  addDeductMoneyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.Colors.primary,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.MetricsSizes.small_xxx
  }
}));
type Props = DataGridProps &
  Partial<ExcelProps> & {
    title?: String;
    rows: any[];
    columns: any[];
    checkboxSelection?: boolean | any;
    disableSelectionOnClick?: boolean | any;
    rowHeight?: number;
    getRowActions?: any;
    actionIconStyle?: React.CSSProperties;
    onFilterClick?: () => void;
    onSortClick?: () => void;
    renderActionButton?: () => React.ReactNode;
    isFilters?: boolean;
    onSelectionModelChange?: (id?: number[], details?: any) => void;
    handleExcelClick?: (item?: any) => void;
    isDownloadButton?: boolean;
    selectOptions?: any[];
    isError?: boolean;
    helperText?: any;
    selectOptionChange?: (val?: any) => void;
    value?: any;
    isBorder?: boolean;
    fontSize?: number;
    textColor?: string;
    renderSearchBox?: () => React.ReactNode;
    addDeductMoneyNeeded?: boolean;
    onClickAddMoney?: () => void;
    selectionModel?: any;
    tableStyle?: any;
  };
const UHTable = (props: Props) => {
  const {
    rowHeight,
    title,
    rows,
    columns,
    checkboxSelection,
    getRowActions,
    actionIconStyle,
    onSortClick,
    onFilterClick,
    isFilters = true,
    onSelectionModelChange,
    renderActionButton,
    handleExcelClick,
    isDownloadButton = true,
    excelData = [],
    excelHeaders = [],
    fileName,
    selectOptions,
    helperText,
    isError,
    selectOptionChange,
    value,
    isBorder = true,
    fontSize,
    textColor,
    renderSearchBox,
    addDeductMoneyNeeded = false,
    onClickAddMoney,
    selectionModel,
    tableStyle,
    ...rest
  } = props;
  const theme = useTheme();
  const classes = useStyles({
    isBorder: isBorder,
    textColor: textColor,
    fontSize: fontSize,
    addDeductMoneyNeeded: addDeductMoneyNeeded
  });
  const [colDefs, setColDefs] = useState(columns);
  const [selectedActionRow, setSelectedActionRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionsIconSelect = (e, row) => {
    if (row?.id === selectedActionRow?.id) {
      setSelectedActionRow(null);
      setAnchorEl(null);
    } else {
      setSelectedActionRow(row);
      setAnchorEl(e.currentTarget);
    }
    e.stopPropagation();
  };

  const renderActions = () => {
    if (!selectedActionRow) {
      return;
    }
    const rowActions = getRowActions(selectedActionRow);
    if (!rowActions?.length) {
      return;
    }

    return (
      <MenuActionComp
        open={open}
        selectedActionRow={selectedActionRow}
        rowActions={rowActions}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    );
  };

  useEffect(() => {
    const getPageCount = () => {
      let rowCount = 0;
      let newPageCount = 1;
      if (rows?.length)
        rows.forEach(() => {
          if (rowCount === DEFAULT_TABLE_ROW_COUNT) {
            newPageCount++;
            rowCount = 0;
          }
          rowCount++;
        });
      return newPageCount;
    };
    setPageCount(getPageCount());
    setPage(1);
  }, [rows]);

  useEffect(() => {
    const updatedDefs = [...columns];
    if (getRowActions) {
      updatedDefs.push({
        headerName: '',
        field: '',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 1,
        renderCell: ({ row }) => (
          <IconButton onClick={(e) => handleActionsIconSelect(e, row)}>
            <MoreVert style={actionIconStyle} />
            {row.id === selectedActionRow?.id && renderActions()}
          </IconButton>
        )
      });
    }
    setColDefs(updatedDefs);
  }, [selectedActionRow, columns]);

  const handlePdfClick = () => {
    if (!excelData?.length) {
      return;
    }

    let pdfDoc = new jsPDF('p', 'pt', 'a4'); // Ensure the document is in portrait orientation and A4 size
    const pdfHeader = excelHeaders.length
      ? excelHeaders.map((item) => {
          return { header: item.label, dataKey: item.key };
        })
      : [];

    pdfDoc.text(fileName, 10, 20); // Adjust the text position to leave space for the table

    autoTable(pdfDoc, {
      body: excelData,
      columns: pdfHeader,
      styles: {
        cellWidth: 'auto', // Adjust the cell width to fit content
        cellPadding: 4,
        fontSize: 10 // Adjust font size to fit more content on one page
      },
      margin: { top: 40, left: 10, right: 10, bottom: 10 }, // Adjust the top margin to avoid empty space
      tableWidth: 'auto', // Ensure the table width wraps within the page width
      startY: 30, // Ensure the table starts below the text
      pageBreak: 'auto' // Automatically handle page breaks
    });

    pdfDoc.save(`${fileName}.pdf`);
  };

  return (
    <>
      <Grid container className={classes.gridStyle}>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: theme.spacing(3) }}
          alignItems="center"
        >
          <Grid
            container
            spacing={isBorder && 2}
            item
            xs={11}
            style={{ alignItems: 'center' }}
          >
            <Grid item>
              <Typography className={classes.heading}>{title}</Typography>
            </Grid>
            {renderSearchBox ? (
              <Grid container item xs style={{ flexDirection: 'row' }}>
                {renderSearchBox()}
              </Grid>
            ) : null}
            {selectOptions ? (
              <Grid item>
                <MultipleSelectComp
                  isPlaceholderNone
                  selectItems={
                    selectOptions?.length &&
                    selectOptions?.map((item: any) => {
                      console.log(item);

                      return {
                        label: item.region_name,
                        value: item.region_id
                      };
                    })
                  }
                  value={value}
                  borderRadius={40}
                  selectHeight={35}
                  background={theme.Colors.greyDark}
                  iconColor={theme.Colors.primary}
                  selectBoxStyle={{ minWidth: 200 }}
                  classes={{ root: classes.selectStyle }}
                  onChange={selectOptionChange}
                  isError={isError}
                  helperText={helperText}
                  displayEmpty
                  renderValue={(value: any) => {
                    return value
                      ? selectOptions?.filter(
                          (item) => item.region_id === value
                        )[0]?.region_name
                      : 'Select Region';
                  }}
                  required
                />
              </Grid>
            ) : null}
          </Grid>
          {isFilters ? (
            <Grid
              container
              item
              xs={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              {renderActionButton ? (
                <Grid item>{renderActionButton()}</Grid>
              ) : null}

              {onSortClick ? (
                <Grid item>
                  <ButtonComp
                    backgroundColor={'transparent'}
                    buttonFontSize={theme.MetricsSizes.small}
                    buttonFontWeight={theme.fontWeight.mediumBold}
                    buttonText="Sort"
                    onClickButton={onSortClick}
                    buttonTextColor={theme.Colors.greyScale}
                    startIcon={<SortIcon width="13" height="13" />}
                  />
                </Grid>
              ) : null}
              {onFilterClick ? (
                <Grid item>
                  <ButtonComp
                    backgroundColor={'transparent'}
                    buttonFontSize={theme.MetricsSizes.small}
                    buttonFontWeight={theme.fontWeight.mediumBold}
                    buttonText="Filter"
                    onClickButton={onFilterClick}
                    buttonTextColor={theme.Colors.greyScale}
                    startIcon={<FilterIcon width="13" height="13" />}
                    disableRipple
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            className={`${classes.tableContainer} ${tableStyle}`}
            rows={rows}
            columns={colDefs}
            checkboxSelection={checkboxSelection}
            disableSelectionOnClick
            rowHeight={rowHeight || 90}
            hideFooter
            onSelectionModelChange={onSelectionModelChange}
            page={page - 1}
            pageSize={DEFAULT_TABLE_ROW_COUNT}
            selectionModel={selectionModel}
            {...rest}
          />
        </Grid>
      </Grid>
      {addDeductMoneyNeeded ? (
        <Box className={classes.addDeductMoneyContainer}>
          <Grid style={{ cursor: 'pointer' }} onClick={onClickAddMoney}>
            Add Money
          </Grid>
          <Grid style={{ cursor: 'pointer' }} onClick={onClickAddMoney}>Deduct Money</Grid>
        </Box>
      ) : null}
      <Grid container alignItems="center">
        {isDownloadButton ? (
          <Grid item style={{ display: 'flex' }}>
            <UHExcelExport
              excelData={excelData}
              excelHeaders={excelHeaders}
              fileName={fileName}
            />
            <IconButton
              onClick={handlePdfClick}
              className={classes.iconButtonStyle}
            >
              <img src={PDFIcon} />
            </IconButton>
          </Grid>
        ) : null}
        <Grid container item xs justifyContent="flex-end">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePagination}
            color="primary"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default React.memo(UHTable);
