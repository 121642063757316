import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import {
  Heading,
  Loader,
  MultiSelectChip,
  UHCalenderComp
} from 'src/components';
import EnquiryManagementModal from './EnquiryManagementModal';
import EnquiryTable from './EnquiryTable';
import { API_SERVICES } from 'src/Services';
import { GET_FILTER_VALUES, HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import { useEdit } from 'src/hooks/useEdit';

const FILTER_CHIPS = ['Today', 'This Week', 'This Month', 'All'];

function EnquiryManagement() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [enquiryRequestChipFilter, setEnquiryRequestChipFilter] = useState([
    FILTER_CHIPS[0]
  ]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });
  const initialValue = {
    location: '',
    date: ''
  };
  const edit = useEdit(initialValue);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const handleChangeEnquiryRequestChip = (selectedChipItem: string[]) => {
    setEnquiryRequestChipFilter(selectedChipItem);
  };

  const fetchData = useCallback(async () => {
    try {
      setTableData([]);
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }
      const response: any =
        await API_SERVICES.enquiryManagementService.getAllEnquiryCalls(
          GET_FILTER_VALUES[enquiryRequestChipFilter[0]],

          edit.getValue('location') == '' && edit.getValue('date') == ''
            ? params
            : {
                searchString: edit.getValue('location'),
                fromDate: edit.getValue('date')?.split('to')[0],
                toDate: edit.getValue('date')?.split('to')[1],
                ...params
              }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.enquiryCalls?.length) {
          setTableData(response.data.enquiryCalls);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [enquiryRequestChipFilter, debValue, edit.edits]);

  const handleClickClose = () => {
    setModalOpen({ open: false });
  };

  const onClickActionButton = (itemData: any) => {
    setModalOpen({ open: true, rowData: itemData });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Heading headingText={t('enquiry.enquiryRequest')} />
        <MultiSelectChip
          chipItems={FILTER_CHIPS}
          selectedChipItem={enquiryRequestChipFilter}
          handleChange={handleChangeEnquiryRequestChip}
          chipStyle={{
            padding: theme.spacing(2, 0.8),
            height: theme.MetricsSizes.large
          }}
          containerStyle={{ marginBottom: theme.spacing(4) }}
        />
        <EnquiryTable
          tableData={tableData}
          onClickActionButton={onClickActionButton}
          edit={edit}
          enquiryRequestChipFilter={enquiryRequestChipFilter[0]}
          handleOpenCalendar={handleOpenCalendar}
        />
        {modalOpen.open && (
          <EnquiryManagementModal
            onClose={handleClickClose}
            updateData={fetchData}
            {...modalOpen}
          />
        )}
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
      </>
    );
  }
}

export default EnquiryManagement;
