import React from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListItemCell, UHTable } from 'src/components';
import { capitalizeFirstLetter } from 'src/Utils';

const AssignedCustomerTable = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tableRowData } = props;
  const columns = [
    {
      field: 'contact_name',
      headerName: t('vendor.contactName'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={capitalizeFirstLetter(row?.contact_name)}
          avatarImg={row?.image_url || 'DustMan'}
        />
      )
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell title={row?.city} subTitle={row?.pincode} />
      )
    },
    {
      field: 'mobile_number',
      headerName: t('mobileNumber'),
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={
            row?.mobile_number?.slice(2) ||
            row?.registered_mobile_number?.slice(2)
          }
        />
      )
    },
    {
      field: 'period',
      headerName: t('customerVendorList.assignedPeriod'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ListItemCell
          title={`${row?.subscription_from?.split('T')[0]} to ${
            row?.subscription_to?.split('T')[0]
          }`}
        />
      )
    }
  ];

  const renderRowActions = () => {
    return [];
  };

  return (
    <UHTable
      columns={columns}
      rows={tableRowData}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      autoHeight={true}
      getRowActions={renderRowActions}
      getRowId={(row) => row?.order_address_id}
      //   excelData={excelData}
      //   excelHeaders={excelHeaders}
      //   fileName={selectedTab == 1 ? 'Customer List' : 'Vendor List'}
      //   selectOptions={regionData}
      //   value={regionId}
      //   selectOptionChange={handleChange}
    />
  );
};

export default AssignedCustomerTable;
