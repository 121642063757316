import React, { useEffect, useState } from 'react';
import {
  Block,
  Done,
  EditOutlined,
  GetApp,
  ListAlt,
  MoreVert
} from '@material-ui/icons';
import {
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import UHTable from 'src/components/UHTable';
import { ListItemCell, TextInputComponent } from 'src/components';
import { capitalizeFirstLetter, getDateFormat } from 'src/Utils';
import {
  VENDOR_MANAGEMENT_STATUS,
  VENDOR_MG_STATUS
} from 'src/Config/constant';
import { useTranslation } from 'react-i18next';
import { Compass, TableCalendar } from 'src/Assets';
import MenuActionComp from 'src/components/MenuActionComp';
import { ChipComp } from 'src/components/MultiSelectChip/ChipComp';

const useStyles = makeStyles((theme) => {
  return {
    tooltipStyle: {
      boxShadow: '0px 4px 4px #00000042',
      backgroundColor: theme.Colors.white,
      color: theme.Colors.neutralBlack,
      padding: 12,
      borderRadius: 8
    },
    arrowStyle: {
      color: theme.Colors.white
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      },
      '& .MuiChip-label': {
        fontSize: theme.MetricsSizes.tiny_xx,
        fontWeight: theme.fontWeight.regular
      },
      '& .MuiChip-root': {
        fontSize: theme.MetricsSizes.tiny_xx,
        fontWeight: theme.fontWeight.regular
      }
    },
    chipStyle: {
      display: 'flex',
      padding: theme.MetricsSizes.tiny,
      border: '1px solid #C5C7CD'
    }
  };
});

type Props = {
  handleViewDetails?: (row: any) => void;
  handleClickDownload?: (row: any) => void;
  tableRowData?: any[];
  handleOpenCalendar?: () => void;
  edit?: any;
};

const InvoiceManagementTable = ({
  handleViewDetails,
  handleClickDownload,
  tableRowData,
  handleOpenCalendar,
  edit
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [rowItems, setRowItems] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedActionRow, setSelectedActionRow] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClickIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowData: any
  ) => {
    if (rowData?.id === selectedActionRow?.id) {
      setSelectedActionRow(null);
      setAnchorEl(null);
    } else {
      setSelectedActionRow(rowData);
      setAnchorEl(e.currentTarget);
    }
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let newList = tableRowData;
    if (!sortData) {
      newList.sort((current, next) => {
        return current.orderdate.localeCompare(next.orderdate);
      });
    }
    if (sortData) {
      newList.sort((current, next) => {
        return next.orderdate.localeCompare(current.orderdate);
      });
    }
    setRowItems([...newList]);
  }, [tableRowData, sortData]);

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Grid>
            <Typography style={{ textDecoration: 'underline' }}>
              {row?.type === 1 ? 'Customer' : 'Vendor'}
            </Typography>
            <Typography variant="subtitle2" className={classes.root}>
              <ChipComp
                label={
                  row?.user_type === 1
                    ? 'Commercial'
                    : row?.user_type === 2
                    ? 'Industry'
                    : row?.user_type === 3
                    ? 'Pick up'
                    : 'Drop'
                }
                className={classes.chipStyle}
                size="small"
                variant="default"
                style={{
                  backgroundColor: 'transparent'
                }}
              />
            </Typography>
          </Grid>
        );
      }
    },
    {
      field: 'id',
      headerName: t('InvoiceDetails.orderId'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.order_no} />
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.contact_person} />
    },
    {
      field: 'location',
      headerName: t('location'),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.location} />
    },
    {
      field: 'qty',
      headerName: 'Order',
      flex: 2.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const totalCategoryQuantity = row?.order_item?.reduce(
          (total: any, item: { category_quantity: any }) =>
            total + item.category_quantity,
          0
        );

        const categoryNames = row?.order_item?.map(
          (item: { category_name: any }) => item?.category_name
        );
        return (
          <Tooltip
            title={
              <>
                {row?.order_item?.map((item, idx) => (
                  <Grid key={idx}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: theme.fontWeight.medium
                      }}
                    >
                      {`${item?.category_name} - ${item?.category_quantity} Kgs`}
                    </Typography>
                  </Grid>
                ))}
              </>
            }
            arrow
            placement="top"
            classes={{
              tooltip: classes.tooltipStyle,
              arrow: classes.arrowStyle
            }}
          >
            <div>
              <ListItemCell
                title={`${totalCategoryQuantity} Kgs`}
                subTitle={categoryNames?.toString()}
              />
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'orderdate',
      headerName: t('InvoiceDetails.dateTime'),
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { getMonth, getDate, getYear, getTime } = getDateFormat(
          row?.orderdate
        );
        let time = getTime.split(':');
        return (
          <ListItemCell
            title={`${getMonth} ${getDate}, ${getYear}`}
            subTitle={`${time[0]}:${time[1]}${time[2].slice(2)}`}
          />
        );
      }
    },

    {
      field: 'price',
      headerName: t('price'),
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <ListItemCell title={row?.amount} />
    },
    {
      field: 'action',
      headerName: t('action'),
      flex: 1.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <IconButton onClick={(e) => handleClickIcon(e, row)}>
          <MoreVert />
        </IconButton>
      )
    }
  ];

  const renderRowActions = (rowData: any) => {
    return [
      {
        text: 'View Details',
        renderIcon: () => <ListAlt />,
        onClick: () => {
          handleViewDetails(rowData);
          handleClose();
        }
      },
      {
        text: 'Download Invoice',
        renderIcon: () => <GetApp />,
        onClick: () => {
          handleClickDownload(rowData);
          handleClose();
        }
      }
    ];
  };

  const onSortClick = () => {
    setSortData(!sortData);
  };

  let excelData =
    rowItems?.length &&
    rowItems.map((rowData, idx) => {
      const { getMonth, getDate, getYear } = getDateFormat(rowData?.orderdate);
      return {
        id: idx + 1,
        company_name: capitalizeFirstLetter(rowData?.company_name),
        contact_person: capitalizeFirstLetter(rowData?.contact_person),
        order_no: rowData?.order_no,
        amount: rowData?.amount,
        location: rowData?.location,
        payout_status: rowData?.payout_status,
        updated_at: `${getMonth} ${getDate}, ${getYear}`
      };
    });

  let excelHeaders = [
    { label: 'S.No', key: 'id' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Name', key: 'contact_person' },
    { label: 'Order No', key: 'order_no' },
    { label: 'Payout Status', key: 'payout_status' },
    { label: 'Ordered Date', key: 'updated_at' },
    { label: 'Price', key: 'amount' },
    { label: 'Location', key: 'location' }
  ];

  const renderSearchBox = () => {
    return (
      <>
        <Grid item style={{ paddingRight: 20 }}>
          <TextInputComponent
            type="search"
            inputWidth={190}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('location')}
            onChange={(e) => {
              edit.update({ location: e.target.value });
            }}
            placeholder="Search Location"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Compass} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item>
          <TextInputComponent
            inputWidth={250}
            borderRadius={40}
            inputHeight={30}
            value={edit.getValue('date')}
            placeholder="Search by Date"
            isBackground={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  <img src={TableCalendar} />
                </InputAdornment>
              )
            }}
            onClick={handleOpenCalendar}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <UHTable
        title={t('InvoiceDetails.invoiceList')}
        columns={columns}
        rows={rowItems}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        hideFooterPagination={true}
        // getRowActions={renderRowActions}
        getRowId={(row) => row.id}
        onSortClick={onSortClick}
        excelData={excelData}
        excelHeaders={excelHeaders}
        fileName={'Invoice Management'}
        renderSearchBox={renderSearchBox}
      />
      <MenuActionComp
        open={open}
        selectedActionRow={selectedActionRow}
        rowActions={renderRowActions(selectedActionRow)}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default InvoiceManagementTable;
