import React from 'react';
import {
  Grid,
  Typography,
  Theme,
  useTheme,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Dashboard, Logo, Path } from 'src/Assets';

const useStyles: any = makeStyles((theme: Theme) => ({
  mainContainer: {
    padding: theme.spacing(2, 1, 1, 4),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  heading: {
    fontSize: theme.MetricsSizes.medium_x,
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.blueGreen
  },
  subText: {
    fontSize: theme.MetricsSizes.regular,
    color: theme.Colors.blueGreen,
    marginTop: theme.MetricsSizes.small_xx
  },
  imgAlign: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  imgStyle: {
    maxWidth: '100%',
    height: 'auto',
    padding: 0,
    margin: 0
  },
  imgStyle1: {
    position: 'absolute',
    bottom: 0,
    maxWidth: '100%',
    height: 'auto',
    padding: 0,
    margin: 0
  }
}));

const LeftContainer = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  return (
    <>
      <Grid container spacing={2} className={styles.mainContainer}>
        <Grid item xs={12}>
          <img className={styles.imgStyle} src={Logo} alt="logo" />
        </Grid>
        <Grid item xs={12}>
          <Typography className={styles.heading}>
            {t('dustmanWasteManagement&RecyclingPvtLtd')}
          </Typography>

          <Typography className={styles.subText}>
            {t('solutionForEveryPollution')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.imgAlign}>
        <img
          src={Dashboard}
          alt={t('wasteWithTruck')}
          className={styles.imgStyle}
        />
        <img src={Path} alt={t('path')} className={styles.imgStyle1} />
      </Grid>
    </>
  );
};
export default LeftContainer;
